/**
 * @ The internal dependecies.
 */

import ApplePayLogo from '../../assets/svg/apple-pay.svg';
import GooglePayLogo from '../../assets/svg/google-pay.svg';
import CardPayLogo from '../../assets/svg/card-pay.svg';
import VisaLogo from '../../assets/svg/visa.svg';
import MastercardLogo from '../../assets/svg/mastercard.svg';
import AmexLogo from '../../assets/svg/amex.svg';
import JcbLogo from '../../assets/svg/jcb.svg';
import DiscoverLogo from '../../assets/svg/discover.svg';
import { clearFreedomPay, sendFormRequest } from '../../store/state/freedompay/actions';
import ComponentLoader from 'components/loader/component-loader';
import freedompayImage from '../../assets/images/freedompay.png';

/**
 * @ The external dependecies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import IconSvg from 'components/common/icon-svg';

class FreedomPayDropIn extends Component {
	state = {
		payWithCard: false,
		payWithGooglePay: false,
		payWithApplePay: false,
		cardholderName: ''
	}

	replaceVariables(html) {
		return html.replace("{paymentProvider}", "Freedom Pay");
	}

	componentDidMount = () => {
		this.setup();
	}

	loadFPApplePayScripts = (callback) => {
		const existingScript = document.getElementById('fpApplePayScripts');

		if (!existingScript) {
			const script = document.createElement('script');
			script.src = this.props.applePayDetails.JavascriptURL;
			script.id = 'fpApplePayScripts';
			document.body.appendChild(script);

			script.onload = () => {
				if (callback) callback();
			};
		}

		if (existingScript && callback) callback();
	};

	shouldComponentUpdate = (nextProps, nextState) => {
		return true;
	}

	componentDidUpdate = (prevProps, prevState) => {
		if (!this.props.loading && prevProps.loading) {
			this.loadFPApplePayScripts(() => {
			});
		}

		if (!this.props.isVisible && prevProps.isVisible) {
			this.setState({
				payWithCard: false,
				payWithGooglePay: false,
				payWithApplePay: false
			});
			this.props.clearFreedomPay();
		}

		if (this.props.isVisible && !prevProps.isVisible) {
			this.props.sendFormRequest();
		}
	}

	componentWillUnmount = () => {
		this.tearDown();
	}

	setup = () => {
		this.props.clearFreedomPay();
		window.addEventListener("message", this.receiveMessage);
		//this.loadFPApplePayScripts(() => {
		//});
		this.props.sendFormRequest();
	}

	tearDown = () => {
		if (this.props.onDestroyStart) {
			this.props.onDestroyStart()
		}

		window.removeEventListener("message", this.receiveMessage);
	}

	receiveMessage = (e) => {
		var message = e.data, data = message.data;

		switch (message.type) {
			case 1:
				if (this.props.onError) {
					this.props.onError(data.errors);
				}
				break;
			case 2:
				if(this.state.payWithCard)
					this.cardPayiframe.height = data.height;

				if (this.state.payWithGooglePay)
					this.googlePayiframe.height = data.height;

				if (this.state.payWithApplePay)
					this.applePayiframe.height = data.height;
				break;
			case 3:
				var paymentData = {
					...data,
					sessionKey: (this.state.payWithCard && this.props.cardDetails.SessionKey) || (this.state.payWithGooglePay && this.props.googlePayDetails.SessionKey) || (this.state.payWithApplePay && this.props.applePayDetails.SessionKey),
					type: (this.state.payWithCard && 'CreditCard') || (this.state.payWithGooglePay && 'AndroidPayCard') || (this.state.payWithApplePay && 'ApplePayCard'),
					ccCardholderName: this.state.cardholderName
				};
				this.props.handlePaymentMethod(paymentData);
				break;
			case 4:
				// data.emittedBy, data.isValid, data.message, data.type
				break;
			default:
				break;
		}
	}

	handleChange = (e) => {
		this.setState({
			cardholderName: e.target.value
		});
	}

	handleCard = (e) => {
		this.setState({
			payWithCard: true
		});
	};

	handleGooglePay = (e) => {
		this.setState({
			payWithGooglePay: true
		});
	};

	handleApplePay = (e) => {
		this.setState({
			payWithApplePay: true
		});
	};

	handleChooseAnotherWayToPay = (e) => {
		this.setState({
			payWithCard: false,
			payWithGooglePay: false,
			payWithApplePay: false
		});
	};

	render = () => {
		return (
			<div className="freedom-pay-dropin">
				{this.props.loading && <div>
					<br />
					<br />
					<ComponentLoader />
					<br />
					<br />
				</div>}
				{!this.props.loading && !(this.state.payWithCard || this.state.payWithGooglePay || this.state.payWithApplePay) && (
				<div>
				  <div className="freedom-pay-heading">Choose a way to pay</div>
						<div className="freedom-pay-options-list">
						{this.props.applePay && this.props.applePayDetails && this.props.applePayDetails.SessionKey && this.props.applePayDetails.IFrameElement && window.ApplePaySession && window.ApplePaySession.canMakePayments() && <div className="freedom-pay-option" tabIndex="2" onClick={this.handleApplePay}>
						<div className="freedom-pay-option__logo">
							<IconSvg className="freedom-pay-option__logo-svg" src={ApplePayLogo} />
						</div>
						<div className="freedom-pay-option__label" aria-label="Paying with Apple Pay">Apple Pay</div>
						</div>}
						{this.props.googlePay && this.props.googlePayDetails && this.props.googlePayDetails.SessionKey && this.props.googlePayDetails.IFrameElement && !(this.props.applePayDetails.SessionKey && this.props.applePayDetails.IFrameElement && window.ApplePaySession && window.ApplePaySession.canMakePayments()) &&  <div className="freedom-pay-option" tabIndex="1" onClick={this.handleGooglePay}>
						<div className="freedom-pay-option__logo">
							<IconSvg className="freedom-pay-option__logo-svg" src={GooglePayLogo} />
						</div>
						<div className="freedom-pay-option__label" aria-label="Paying with Google Pay">Google Pay</div>
							</div>}
						{this.props.card && this.props.cardDetails && this.props.cardDetails.SessionKey && this.props.cardDetails.IFrameElement && <div className="freedom-pay-option" tabIndex="0" onClick={this.handleCard}>
								<div className="freedom-pay-option__logo">
									<IconSvg className="freedom-pay-option__logo-svg" src={CardPayLogo} />
								</div>
								<div className="freedom-pay-option__label" aria-label="Paying with Card">Card</div>
						</div>}
						</div>
					</div>)}				
				{this.state.payWithCard && <div className="freedom-pay-sheet">
					<div className="freedom-pay-sheet__header">
						<div className="freedom-pay-sheet__header-label">
							<div className="freedom-pay-sheet__logo--header">
								<IconSvg className="freedom-pay-option__logo-svg" src={CardPayLogo} />
							</div>
							<div className="freedom-pay-sheet__label">Pay with card</div>
						</div>
						<div className="freedom-pay-sheet__icons">
							<div className="freedom-pay-sheet__card-icon">
								<IconSvg src={VisaLogo} />
							</div>
							<div className="freedom-pay-sheet__card-icon">
								<IconSvg src={MastercardLogo} />
							</div>
							<div className="freedom-pay-sheet__card-icon">
								<IconSvg src={AmexLogo} />
							</div>
							<div className="freedom-pay-sheet__card-icon">
								<IconSvg src={JcbLogo} />
							</div>
							<div className="freedom-pay-sheet__card-icon">
								<IconSvg src={DiscoverLogo} />
							</div>
						</div>
					</div>
					<div className="freedom-pay-sheet__content--form">
						<div class="control-group">
							<label for="Cardholder">Cardholder Name</label>
							<div class="control-container">
								<input class="required" id="Cardholder" name="Cardholder" placeholder="" value={this.state.cardholderName} onChange={(event) => this.handleChange(event)} autoCapitalize="off" autoComplete="false" autoCorrect="off" spellCheck="false" />
							</div>
							<div class="validation-message feedback"></div>
						</div>
						<iframe title="Card Payment" ref={(iframe) => { this.cardPayiframe = iframe; }} height="399" src={this.props.cardDetails.IFrameElement} />
					</div>
				</div>}
				{this.state.payWithGooglePay && <div className="freedom-pay-sheet">
					<div className="freedom-pay-sheet__header">
						<div className="freedom-pay-sheet__header-label">
							<div className="freedom-pay-sheet__logo--header">
								<IconSvg className="freedom-pay-option__logo-svg" src={GooglePayLogo} />
							</div>
							<div className="freedom-pay-sheet__label">Google Pay</div>
						</div>
					</div>
					<div className="freedom-pay-sheet__content--form">
						<iframe allow="payment" title="Google Pay Payment" ref={(iframe) => { this.googlePayiframe = iframe; }} height="60" src={this.props.googlePayDetails.IFrameElement} />
					</div>
				</div>}
				{this.state.payWithApplePay && <div className="freedom-pay-sheet">
					<div className="freedom-pay-sheet__header">
						<div className="freedom-pay-sheet__header-label">
							<div className="freedom-pay-sheet__logo--header">
								<IconSvg className="freedom-pay-option__logo-svg" src={ApplePayLogo} />
							</div>
							<div className="freedom-pay-sheet__label">Apple Pay</div>
						</div>
					</div>
					<div className="freedom-pay-sheet__content--form">
						<iframe title="Apple Pay Payment" ref={(iframe) => { this.applePayiframe = iframe; }} height="60" src={this.props.applePayDetails.IFrameElement} />
					</div>
				</div>}
				{(this.state.payWithCard || this.state.payWithGooglePay || this.state.payWithApplePay) &&
					<div onClick={this.handleChooseAnotherWayToPay} className="freedom-pay-large-button" tabindex="0">
					<span>Choose another way to pay</span>
					</div>}
				
				<div className='payment-secure'><img style={{ maxHeight:'30px' }} src={freedompayImage} alt="Secured By FreedomPay" /></div>
				{this.props.message && <div className='payment-message editable' dangerouslySetInnerHTML={{ __html: this.props.message }}></div>}
			</div>
		)
	}
}

FreedomPayDropIn.propTypes = {
	authorizationToken: PropTypes.string.isRequired,
	handlePaymentMethod: PropTypes.func.isRequired,
	onCreate: PropTypes.func,
	onError: PropTypes.func,
	onDestroyStart: PropTypes.func,
	onDestroyEnd: PropTypes.func,
	amount: PropTypes.number,
	message: PropTypes.string,
	card: PropTypes.bool,
	applePay: PropTypes.bool,
	googlePay: PropTypes.bool
}

export default connect(
	(state) => ({
		loading: state.freedompay.data == null,
		cardDetails: state.freedompay.data && state.freedompay.data.IFrames.find(x => x.PaymentMethodType === 'card'),
		googlePayDetails: state.freedompay.data && state.freedompay.data.IFrames.find(x => x.PaymentMethodType === 'google'),
		applePayDetails: state.freedompay.data && state.freedompay.data.IFrames.find(x => x.PaymentMethodType === 'apple')
	}),
	{
		clearFreedomPay,
		sendFormRequest
	}
)(FreedomPayDropIn);