/**
 * @ The external dependecies.
 */
import { createAction } from 'redux-actions';

/**
 * Opens the modal.
 *
 * @return {Object}
 */
export const openModal = createAction('ui/open-modal');

/**
 * Closes a modal.
 * 
 * @return {Object}
 */
export const closeModal = createAction('ui/close-modal');

export const redirectToError = createAction('ui/redirect-to-error');

export const setLoadingResources = createAction('ui/set-loading-resources');