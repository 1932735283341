/**
 * @ The external dependecies
 */
import React, { Component } from 'react';

/**
 * @ The internal dependencies.
 */
import ProductDisplay from 'views/product/product-display';
import withDataValidation from 'lib/helpers/hocs/with-data-validation'; //Shows spinner while loading config

/**
 * Class for product.
 *
 * @class Product (name)
 */
class Product extends Component {

    render() {
		return (
			<ProductDisplay {...this.props} redirectPath={window.isMarketplace ? `/marketplace/${window.marketplaceId}/${window.waypointID}/menu` : "/menu"} />
		)
	}
}

export default withDataValidation(Product);