/**
 * @ The external dependencies.
 */
 import {
    all,
    call,
    takeLatest,
    select
} from 'redux-saga/effects';

import { requestAdyenPaymentMethods, receiveAdyenPaymentMethods } from './actions';
import makeRequest from '../../../lib/helpers/api-request';

export function* requestAdyenPaymentMethodsWorker(action) {
    const payload = {
        waypointId: action.payload.waypointid, 
        currencyCode: action.payload.currencyCode, 
        adyenPayload: {
            currencyCode: action.payload.currencyCode,
            countryCode: action.payload.countryCode,
            totalAmount: action.payload.totalAmount,
            languageCode: action.payload.languageCode
        }
    }

    yield call(makeRequest, {
        endpoint: 'getAdyenPaymentMethods', 
        payload: payload, 
        requestAction: action,
        receiveAction: receiveAdyenPaymentMethods
    });
}

/**
 * Start all workers.
 *
 * @return {Generator}
 */
 export default function* foreman() {
    yield all([
        takeLatest(requestAdyenPaymentMethods, requestAdyenPaymentMethodsWorker)
	]);
}