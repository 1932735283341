/**
 * @ The external dependecies.
 */
 import { handleActions } from 'redux-actions';

 /**
 * @ The internal dependecies.
 */
import { updateObject } from '../../../lib/utility';

/**
 * @ The actions.
 */
 import {
    receiveAdyenPaymentMethods
} from './actions';

/**
 * @ The reducer.
 */
 const defaultState = {
    configuration: null
}

/**
 * The actions Handler.
 *
 * @type {Function}
 */
const onReceivePaymentMethods = (state, action) => {
    if (!action.payload.exception) {
        return updateObject(state, {
            configuration: {
                paymentMethodsResponse: JSON.parse(action.payload.value),
                clientKey: action.payload.adyenClientKey, 
                locale: `${window.language}`, 
                environment: action.payload.adyenEnvironment, 
                analytics: { enabled: false } 
                // onSubmit: (state, dropin) => {
                //     alert('submiting')
                // }, 
                // onAdditionalDetails: (state, dropin) => {
                //     alert('getting additional details')
                // }
            }
        });
    }
    else {
        return updateObject(state, {
            configuration: null
        });
    }
}

const adyen = handleActions({
    [receiveAdyenPaymentMethods]: (state, action) => onReceivePaymentMethods(state, action)
}, defaultState);

export default adyen;