/**
 * @ The external dependencies.
 */
import { call, put } from 'redux-saga/effects';

/**
 * @ The internal dependencies.
 */
import api from 'lib/api';
import {
	startRequest,
	endRequest,
	endRequestWithError
} from 'store/state/requests/actions';

/**
 * Start a network request to the Grab's API.
 *
 * @param  {Object}    options
 * @return {Generator}
 */
export default function*(options) {
	const {
		endpoint,
		payload = {},
		requestAction,
        receiveAction,
        onError
	} = options;

	try {
        yield put(startRequest(requestAction.type));

        const result = yield call([api, endpoint], payload);

        const testNon200Responses = false;
        const testNon200ResponseActions = ['cart/start-cart-update','cart/start-cart-save'];

        if (testNon200Responses && testNon200ResponseActions.includes(requestAction.type)) {
            console.log('Testing non 200 response');
            throw new Error('Testing non 200 response');
        }

        yield put(receiveAction(result));

		yield put(endRequest(requestAction.type));
    } catch (message) {
        yield put(endRequestWithError(requestAction.type, message));
        if (onError)
            onError();
	}
}
