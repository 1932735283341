/**
 * @ The external dependecies.
 */
import { handleActions } from 'redux-actions';

/**
 * @ The internal dependecies.
 */
import { updateObject } from '../../../lib/utility';

/**
 * @ The actions.
 */
import {
    receivePenniesCalculationResponse
} from './actions';

/**
 * @ The reducer.
 */
const defaultState = {
    donationinfo: null
}

/**
 * The actions Handler.
 *
 * @type {Function}
 */
const onReceivePenniesCalculationResponse = (state, action) => {
    if (!action.payload.exception) {
        return updateObject(state, {
            donationinfo: action.payload
        });
    }
    else {
        return updateObject(state, {
            donationinfo: null
        });
    }
}

const donation = handleActions({
    [receivePenniesCalculationResponse]: (state, action) => onReceivePenniesCalculationResponse(state, action)
}, defaultState);

export default donation;