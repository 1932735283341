/**
 * @ The external dependencies.
 */
import { createAction } from 'redux-actions';

export const clearCheckout = createAction('checkout/clear-checkout');

export const startSendEmailReceipt = createAction('checkout/start-send-email-receipt');

export const receiveSendEmailReceiptData = createAction('checkout/receive-send-email-receipt-data');

export const startSendTextReceipt = createAction('checkout/start-send-text-receipt');

export const receiveSendTextReceiptData = createAction('checkout/receive-send-text-receipt-data');

export const updateSaveMode = createAction('checkout/update-save-mode');

export const updateTableNumber = createAction('checkout/update-table-number');

export const clearAdyenSession = createAction('checkout/clear-adyen-session');
