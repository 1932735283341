/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

/**
 * @ The internal dependencies.
 */
import { closeModal } from 'store/state/ui/actions';

/**
 * Class for modal warning.
 *
 * @class ModalWarning (name)
 */
class ModalWarning extends Component {
    state = {
        backPressed: false,
        preventOnBackOrForwardButtonEvent: true
    }

    handleButtonClick = (e) => {
        this.setState({ backPressed: true });

        this.props.closeModal();
        if (this.props.data.fun) {
            this.props.data.fun();
        }
    }

    handleCancelClick = () => {
        this.props.closeModal();

        if (this.props.data.funCancel) {
            this.props.data.funCancel();
        }
    }

    //2. if back or forward button is pressed, press cancel button
    handleBackOrForwardButton = () => {
        if (this.props.data.preventOnBackOrForwardButtonEvent && this.state.preventOnBackOrForwardButtonEvent) {
            this.setState({ preventOnBackOrForwardButtonEvent: false });
        }
        else {
            this.setState({ backPressed: true });
            this.handleCancelClick();
        }
    }
    
    componentDidMount() {
        if (this.props.data.btnTextCancel) {
    
            //1. Add current location to history stack
            this.props.history.push(this.props.location.pathname);

            window.onpopstate = this.handleBackOrForwardButton;
        }
    }

    componentWillUnmount() {
        if (this.props.data.btnTextCancel) {
            window.onpopstate = null;

            //3. if back button NOT pressed, remove location from history stack
            if (!this.state.backPressed && !(this.props.data.allowRedirect)) {
                this.props.history.goBack();
            }
        }
    }

	render() {
        const { message, btnText, btnTextCancel } = this.props.data;

		return (
			<div className="modal-frame modal-frame--warning modal-frame--card">
				<div className="modal-frame__body">
					<div className="modal-frame__card">
						<div className="modal-frame__head">
							<div className="modal-frame__card-icon">
								<FontAwesomeIcon icon={faExclamationTriangle} />
							</div>

                            <p className="editable" dangerouslySetInnerHTML={{ __html: message }} ></p>
						</div>

                        <div className="modal-frame__actions modal-frame__actions--flex">
                            {btnTextCancel &&
                                <button
                                onClick={this.handleCancelClick}
                                    className="btn btn--secondary">{btnTextCancel}</button>}
							<button
								onClick={this.handleButtonClick}
								className="btn">{ btnText }</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(connect(
	(state) => ({
		cart: state.cart.data,
		data: state.ui.modal.data
	}),
	{
		closeModal
	}
)(ModalWarning));
