import { getSessionLocalization, setSessionLocalization } from 'lib/helpers/local-storage';
import * as queryString from 'lib/helpers/query-string';

export const getCurrentLanguageDescription = () => {
    if (window.supportedLanguages.hasOwnProperty(window.language)) {
        return window.supportedLanguages[window.language].description;
    }
    return 'English';
}

export const getCurrentLanguageDirection = () => {
    if (window.supportedLanguages.hasOwnProperty(window.language)) {
        return window.supportedLanguages[window.language].direction;
    }
    return 'ltr';
}

export const isLtr = () => {
    return getCurrentLanguageDirection() === 'ltr';
}

export const setTextDirectionAttribute = (direction) => {
    document.documentElement.setAttribute('dir', direction);
}

export const setLanguageAttribute = (lang) => {
    document.documentElement.setAttribute('lang', lang);
}

export const showLanguageWidget = () => {
    return Object.keys(getSupportedLanguageList()).length > 1;
}

export const getSupportedLanguageList = () => {
    let supportedLanguages = window.languages;

    var languageList = {};

    for (var i = 0; i < supportedLanguages.length; i++) {
        languageList[supportedLanguages[i]] = window.supportedLanguages[supportedLanguages[i]].description;
    }

    return languageList;
}

//1. Query String + Persist to Local Storage
//2. Local Storage
//3. Browser Language
//4. First Store Language
export const getLanguage = () => {
    let supportedLanguages = window.languages;

    let queryStringLanguage = queryString.getValue('lang');
    if (queryStringLanguage) {
        queryStringLanguage = queryStringLanguage.toLowerCase();
        if (supportedLanguages.includes(queryStringLanguage)) {
            setSessionLocalization({ language: queryStringLanguage });
            return queryStringLanguage;
        }
        const twoLetterLanguage = queryStringLanguage.slice(0, 2);
        if (supportedLanguages.includes(twoLetterLanguage)) {
            setSessionLocalization({ language: twoLetterLanguage });
            return twoLetterLanguage;
        }
    }

    const persistedLocalization = getSessionLocalization();
    if (persistedLocalization) {
        const persistedLocalizationLanguage = persistedLocalization.language;
        if (persistedLocalizationLanguage) {
            if (supportedLanguages.includes(persistedLocalizationLanguage)) {
                return persistedLocalizationLanguage;
            }
            const twoLetterLanguage = persistedLocalizationLanguage.slice(0, 2);
            if (supportedLanguages.includes(twoLetterLanguage)) {
                return twoLetterLanguage;
            }
        }
    }

    if (navigator.languages && navigator.languages.length) {
        for (var i = 0; i < navigator.languages.length; i++) {
            const language = navigator.languages[i].toLowerCase();
            if (supportedLanguages.includes(language)) {
                return language;
            }
            const twoLetterLanguage = language.slice(0, 2);
            if (supportedLanguages.includes(twoLetterLanguage)) {
                return twoLetterLanguage;
            }
        }
    } else {
        let systemLanguage = (navigator.userLanguage || navigator.language || navigator.browserLanguage);
        if (systemLanguage) {
            systemLanguage = systemLanguage.toLowerCase();
            if (supportedLanguages.includes(systemLanguage)) {
                return systemLanguage;
            }
            const twoLetterLanguage = systemLanguage.slice(0, 2);
            if (supportedLanguages.includes(twoLetterLanguage)) {
                return twoLetterLanguage;
            }
        }
    }

    return supportedLanguages[0];
}

//1. Query String + Persist to Local Storage
//2. Local Storage
//3. Browser Language
//4. First Store Language
export const getNetsLanguage = () => {
    if (window.supportedLanguages.hasOwnProperty(window.language)) {
        return window.supportedLanguages[window.language].netsCode;
    }

    return 'en-GB';
}

export const getLanguageMultiConcept = () => {
    let supportedLanguages = window.languages;

    let queryStringLanguage = queryString.getValue('lang');
    if (queryStringLanguage) {
        queryStringLanguage = queryStringLanguage.toLowerCase();
        if (supportedLanguages.includes(queryStringLanguage)) {
            setSessionLocalization({ language: queryStringLanguage });
            return queryStringLanguage;
        }
        const twoLetterLanguage = queryStringLanguage.slice(0, 2);
        if (supportedLanguages.includes(twoLetterLanguage)) {
            setSessionLocalization({ language: twoLetterLanguage });
            return twoLetterLanguage;
        }
    }

    const persistedLocalization = getSessionLocalization();
    if (persistedLocalization) {
        const persistedLocalizationLanguage = persistedLocalization.language;
        if (persistedLocalizationLanguage) {
            if (supportedLanguages.includes(persistedLocalizationLanguage)) {
                return persistedLocalizationLanguage;
            }
            const twoLetterLanguage = persistedLocalizationLanguage.slice(0, 2);
            if (supportedLanguages.includes(twoLetterLanguage)) {
                return twoLetterLanguage;
            }
        }
    }

    if (navigator.languages && navigator.languages.length) {
        for (var i = 0; i < navigator.languages.length; i++) {
            const language = navigator.languages[i].toLowerCase();
            if (supportedLanguages.includes(language)) {
                return language;
            }
            const twoLetterLanguage = language.slice(0, 2);
            if (supportedLanguages.includes(twoLetterLanguage)) {
                return twoLetterLanguage;
            }
        }
    } else {
        let systemLanguage = (navigator.userLanguage || navigator.language || navigator.browserLanguage);
        if (systemLanguage) {
            systemLanguage = systemLanguage.toLowerCase();
            if (supportedLanguages.includes(systemLanguage)) {
                return systemLanguage;
            }
            const twoLetterLanguage = systemLanguage.slice(0, 2);
            if (supportedLanguages.includes(twoLetterLanguage)) {
                return twoLetterLanguage;
            }
        }
    }

    return supportedLanguages[0];
}