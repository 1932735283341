/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**
 * @ The internal dependencies.
 */
import { closeModal } from 'store/state/ui/actions';
import { sendPreOrderQuestionnaireAnswer, preOrderQuestionnaireComplete } from 'store/state/questionnaire/actions';
import { updateObject, checkValidity } from '../../lib/utility';
import QtyField from 'components/common/qty-field';

/**
 * Class for modal table number.
 *
 * @class ModalTableNumber (name)
 */
class ModalQuestionnaire extends Component {
    state = {
        questions: [],
        index: 0
    }

    componentDidMount() {
        const questions = this.props.questions.map(q => {
            switch (q.subType) {
                case 'LIST':
                    return {
                        contentID: q.contentID,
                        question: q.contentText,
                        type: 'LIST',
                        options: q.contentDetailList.map(cd => cd.value),
                        value: '',
                        validation: {
                            required: true
                        },
                        valid: false,
                        touched: false
                    }
                case 'LIST_HIDESELECT':
                    return {
                        contentID: q.contentID,
                        question: q.contentText,
                        type: 'LIST_HIDESELECT',
                        options: q.contentDetailList.map(cd => cd.value),
                        value: q.contentDetailList.map(cd => cd.value)[0],
                        validation: {
                            required: true
                        },
                        valid: true,
                        touched: false
                    }
                case 'NUMBER_RANGE':
                    return {
                        contentID: q.contentID,
                        question: q.contentText,
                        type: 'NUMBER_RANGE',
                        min: Number(q.contentDetailList.find(x => x.subType === 'MIN').value),
                        max: Number(q.contentDetailList.find(x => x.subType === 'MAX').value),
                        value: 1,
                        validation: {
                            required: true
                        },
                        valid: true,
                        touched: false
                    }
                default:
                    return null;
            }
        }).filter(x => true);

        this.setState({ questions: questions });
    }

    checkValidityCustom(value, rules) {
        let isValid = checkValidity(value, rules);

        return isValid;
    }

    handleNumberChange = (event, questionNo) => {
      
        const updatedQuestion = updateObject(this.state.questions[questionNo], {
            value: Number(event.target.value),
            valid: this.checkValidityCustom(event.target.value, this.state.questions[questionNo].validation),
            touched: true
        });

        const updatedQuestions = this.state.questions.map((item, j) => {
            if (j === questionNo) {
                return updatedQuestion;
            } else {
                return item;
            }
        });

        this.setState({ questions: updatedQuestions});
    }

    handleSelectChange = (event, questionNo) => {
        const updatedQuestion = updateObject(this.state.questions[questionNo], {
            value: event.target.value,
            valid: this.checkValidityCustom(event.target.value, this.state.questions[questionNo].validation),
            touched: true
        });

        const updatedQuestions = this.state.questions.map((item, j) => {
            if (j === questionNo) {
                return updatedQuestion;
            } else {
                return item;
            }
        });

        this.setState({ questions: updatedQuestions});
    }

    handleBackButton = (e) => {
        e.preventDefault();
        this.setState({ index: this.state.index - 1 });
    }

    handleNextButton = (e) => {
        e.preventDefault();
        if (this.state.questions[this.state.index].valid) {

            this.submitAnswer();

            //Move to next question
            this.setState({ index: this.state.index + 1 });
        }
    }

    handleSubmitButton = (e) => {
        e.preventDefault();

        if (this.state.questions[this.state.index].valid) {
            this.submitAnswer();

            if (this.state.index === this.state.questions.length - 1) {
                this.props.preOrderQuestionnaireComplete();

                this.props.closeModal();

                if (this.props.data.fun) {
                    this.props.data.fun();
                }
            }
            else {
                //Move to next question
                this.setState({ index: this.state.index + 1 });
            }
        }
    }

    submitAnswer = () => {
        const question = this.state.questions[this.state.index];

        //submit answer
        this.props.sendPreOrderQuestionnaireAnswer({
            contentID: question.contentID,
            question: question.question,
            answer: question.value.toString(),
            sessionID: this.props.sessionID
        });
    }

    renderQuestion(question, index) {
        switch (question.type) {
            case 'LIST':
                return (<div className="modal-frame__card-field">
                    <p className="modal-frame--center" dangerouslySetInnerHTML={{ __html: question.question }}></p>
                    <div className="select">
                        <select defaultValue={question.value} onChange={(event) => this.handleSelectChange(event, index)}>
                            <option value="">{window.resources.questionnaire.select_text}</option>
                            {question.options.map((option) => {
                                return (
                                    <option key={option}>{option}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>);
            case 'LIST_HIDESELECT':
                return (<div className="modal-frame__card-field">
                    <p className="modal-frame--center" dangerouslySetInnerHTML={{ __html: question.question }}></p>
                    <div className="select">
                        <select defaultValue={question.value} onChange={(event) => this.handleSelectChange(event, index)}>
                            {question.options.map((option) => {
                                return (
                                    <option key={option}>{option}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>);
            case 'NUMBER_RANGE':
                return (<div className="modal-frame__card-field">
                    <p className="modal-frame--center" dangerouslySetInnerHTML={{ __html: question.question }}></p>
                    <div className="box__qty">
                        <QtyField
                            modifier="small"
                            variant="primary"
                            withanimation="true"
                            min={question.min}
                            max={question.max}
                            onChange={(event) => this.handleNumberChange(event, index)}
                            value={question.value}
                        />
                    </div>
                </div>);
            default:
                return null;
        }
    }

    render() {
        const label = (
            <div>
                {window.resources.questionnaire.label_text}
            </div>
        );

		return (
			<div className="modal-frame modal-frame--card">
				<div className="modal-frame__body">
                    <div className="modal-frame__card">
                        {this.props.groupID === 1 && <div className="modal-frame__head">
                            <h6 className="modal-frame__title">{label}</h6>
                        </div>}
                        {this.state.questions.length > 0 && <div className="modal-frame__card-body">
                            {this.state.questions.length > 1 && <div className="modal-frame__navigation">
                                <a style={{ float: 'left', visibility: this.state.index > 0 ? 'visible' : 'hidden' }} onClick={this.handleBackButton}>
                                    <i className="fas fa-arrow-left"></i>
                                </a>
                                {this.state.index + 1}/{this.state.questions.length}
                                <a style={{ float: 'right', visibility: this.state.index + 1 < this.state.questions.length ? 'visible' : 'hidden' }} onClick={this.handleNextButton}>
                                    <i className="fas fa-arrow-right"></i>
                                </a>
                            </div>}
                            {this.renderQuestion(this.state.questions[this.state.index], this.state.index)}
                        </div>}
						<div className="modal-frame__actions modal-frame__actions--flex">
                            <button
                                disabled={(this.state.questions.length > 0 && !this.state.questions[this.state.index].valid)}
								onClick={this.handleSubmitButton}
                                className="btn">{window.resources.questionnaire.continue_text}</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
    return {
        data: state.ui.modal.data,
        questions: state.questionnaire.data.contentList,
        groupID: state.questionnaire.data.groupID,
        sessionID: state.questionnaire.sessionID
    };
}

export default withRouter(connect(
    mapStateToProps,
	{
        closeModal,
        sendPreOrderQuestionnaireAnswer,
        preOrderQuestionnaireComplete
	}
)(ModalQuestionnaire));