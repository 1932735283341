/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**
 * @ The internal dependencies.
 */
import { closeModal } from 'store/state/ui/actions';

class ModalReceiptSuccess extends Component {
    componentDidMount() {
        setTimeout(function () {
            this.props.closeModal();
        }.bind(this), 3000);
    }

	render() {
		return (
			<div className="modal-frame modal-frame--card">
				<div className="modal-frame__body">
                    <div className="modal-frame__card modal-frame__receipt">
                        <div className="modal-frame__head">
                            <i className="fas fa-3x fa-check-circle"></i>
                            <br />
                            <br />
                            <h6 className="modal-frame__title editable" dangerouslySetInnerHTML={{ __html: window.resources.messages.receipt_success_html }}></h6>
                            <br />
                            {this.props.receiptRecipient}
                        </div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
    return {
        receiptRecipient: state.ui.modal.data.receiptRecipient
    };
}

export default withRouter(connect(
    mapStateToProps,
    {
        closeModal
	}
)(ModalReceiptSuccess));
