/**
 * @ The external dependecies
 */
import React from 'react';
import PropTypes from 'prop-types';
import Slick from 'react-slick';

/**
 * Class for slider.
 *
 * @class Slider (name)
 */

const Slider = ({
	settings,
	children,
	className
}) => <Slick className={className} {...settings}>{children}</Slick>;

/**
 * Define the interface of the component.
 *
 * @type {Object}
 */
Slider.propTypes = {
	settings: PropTypes.object.isRequired,
	className: PropTypes.string
};

export default Slider;