/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'ramda';

/**
 * @ The internal dependecies.
 */
import IconSvg from 'components/common/icon-svg';
import withDataValidation from 'lib/helpers/hocs/with-data-validation';
import PoweredByGrab from '../../assets/images/powered_by_grab@3x.png';
import config from '../../config';

/**
 * Class for MultiConcept.
 *
 * @class Home (name)
 */

class MultiConcept extends Component {

    buildStoreUrl = (store) => {
        if (store.bStoreIsCurrentlyOpen && store.storeWaypointID) {
            if (store.OverrideURL) {
                var fowardSlashes = (store.OverrideURL.match(/\//g) || []).length;
                if (store.OverrideURL.indexOf('://') > 0) {
                    return store.OverrideURL + (this.props.match.params.tableNumber && fowardSlashes < 5 ? `/${this.props.match.params.tableNumber}` : '');
                }
                else {
                    return window.location.protocol + "//" + window.location.host + store.OverrideURL + (this.props.match.params.tableNumber && fowardSlashes < 3 ? `/${this.props.match.params.tableNumber}` : '');
                }
            }
            else {
                return window.location.protocol + "//" + window.location.host + "/" + window.posID + "/" + store.storeWaypointID + (this.props.match.params.tableNumber ? `/${this.props.match.params.tableNumber}` : '');
            }
        }
        else {
            return '#';
        }
    }

    render() {
        const showClosedStores = !(this.props.data.Stores.filter(store => store.bStoreIsCurrentlyOpen).length > 0);
        const stores = this.props.data.Stores.filter(store => showClosedStores || store.bStoreIsCurrentlyOpen);
        return (
            <div
                className="multi-concept">
                <header className="multi-concept__head">
                    <div className="shell">
                     
                    </div>
                </header>

                <div className="multi-concept__body">
                    <div className="multi-concept__header"></div>
                    <h4 className="multi-concept__title"> {window.resources.multiconcept.title}</h4>

                    <div className="shell">
                        <div className="multi-concept__body-inner">
                            {stores.map((store, key) =>
                                <div key={key} className={`multi-concept__store ${stores.length < 7 ? 'multi-concept__store--2-per-row' : ''}`}>
                                    <a className="store__link" href={this.buildStoreUrl(store)}>

                                        <figure className={`store__image ${!store.bStoreIsCurrentlyOpen ? 'store__image--closed' : ''}`}>
                                            <IconSvg
                                                src={config.REACT_APP_STORE_ASSETS_URL + store.storeWaypointID +'/logo-multi.svg'} />
                                        </figure>
                                        <h3 className="store__title">{store.storeName}</h3>
                                        <h3 className="store__closed">{!store.bStoreIsCurrentlyOpen && 'CLOSED'}</h3>
                                    </a>
                                </div>
                            )}
                        </div>
                        <h4 className="multi-concept__title-footer"> {window.resources.multiconcept.title_footer}</h4>
                        <div className="intro__outro editable" dangerouslySetInnerHTML={{ __html: window.resources.multiconcept.footer_html }}></div>
                    </div>
                </div>

                <div className="multi-concept__footer">
                    <div className="shell">
                        <span className="multi-concept__powered-by">
                            <img src={PoweredByGrab} alt="Powered by Grab" style={{ width:122, height: 20  }} />
                        </span>
                    </div>
                </div>
            </div>
		)
    }
}

const mapStateToProps = state => {
    return {
       
    };
}

export default compose(
	connect(
        mapStateToProps,
		{

		}
	),
	withDataValidation
)(MultiConcept);
