/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { withRouter, Switch, Route  } from 'react-router-dom';
import { connect } from 'react-redux';

/**
 * @ The internal dependecies.
 */
import '../../assets/css/_main-multi-concept.css';
import MultiConcept from 'views/multi-concept/multi-concept';
import { Aux } from '../../lib/utility';
import InvalidStore from '../../components/error/invalid-store';

/**
 * @ The redux actions.
 */
import { requestMultiConceptData } from 'store/state/app/actions';

/**
 * Class for app.
 *
 * @class App (name)
 */
class AppMultiConcept extends Component {
    componentDidMount() {
        window.addEventListener('message', (e) => {
            if (window.preview) {
                this.forceUpdate();
            }
        });

        Promise.all([
            this.props.requestMultiConceptData()
        ]);
    }

    render() {
        let app = <InvalidStore />;

        if (this.props.loading || this.props.validMultiConcept) {
            app = (
                <Aux>
                    <Switch>
                        <Route exact path="/:tableNumber(\d+|[vV][kK])?" component={MultiConcept} />
                    </Switch>
               </Aux>);
        }

        return (
            <div className="wrapper">
                {app}
            </div>);
	}
}

const mapStateToProps = state => {
    return {
        validMultiConcept: state.app.validMultiConcept,
        loading: state.app.loading
    };
}

export default withRouter(connect(
    mapStateToProps,
    {
        requestMultiConceptData
	}
)(AppMultiConcept));
