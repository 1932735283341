/**
 * @ The external dependecies
 */
import React, { Component } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Loader from 'components/loader/loader';
import braintree from 'braintree-web-drop-in';

/**
 * @ The internal dependecies.
 */
import NetsDropin from 'components/payment/nets-drop-in';
import BraintreeDropin from 'components/payment/braintree-drop-in';
import SpreedlyDropin from 'components/payment/spreedly-drop-in';
import FreedomPayDropin from 'components/payment/freedom-pay-drop-in';
import AdyenDropin from './adyen-drop-in';
import { getBraintreeCardDetails, getFreedomPayCardDetails, getSpreedlyExpressCardDetails, getGooglePayCardDetails, getApplePayCardDetails, getNetsCardDetails, getAdyenCardDetails } from 'components/payment/payment-helper';
import { localizeNumber } from '../../assets/resources/resources-manager';
/**
 * Class for Payment.
 *
 * @class Payment (name)
 */
class Payment extends Component {
	state = {
        isLoading: false,
        error : false
	}

    handlePaymentMethodBraintree = (payload) => {
        console.log('Payment Success');
        const cardDetails = getBraintreeCardDetails(payload);
        this.props.onPaymentSuccess(cardDetails);
    }

    handlePaymentMethodAdyen = (payload) => {
        console.log('Adyen Payment Success');
        this.props.onPaymentSuccess(payload);
    }

    handlePaymentMethodSpreedlyExpress = (payload) => {
        console.log('Payment Success');
        const cardDetails = getSpreedlyExpressCardDetails(payload);
        this.props.onPaymentSuccess(cardDetails);
    }

    handlePaymentMethodGooglePay = (payload) => {
        console.log('Payment Success');
        const cardDetails = getGooglePayCardDetails(payload);
        this.props.onPaymentSuccess(cardDetails);
    }

    handlePaymentMethodApplePay = (payload) => {
        console.log('Payment Success');
        const cardDetails = getApplePayCardDetails(payload);
        this.props.onPaymentSuccess(cardDetails);
    }

    handlePaymentMethodFreedomPay = (payload) => {
        console.log('Payment Success');
        const cardDetails = getFreedomPayCardDetails(payload);
        this.props.onPaymentSuccess(cardDetails);
    }

    handlePaymentMethodNets = (payload) => {
        console.log('Payment Success');
        const cardDetails = getNetsCardDetails(payload);
        this.props.onPaymentSuccess(cardDetails);
    }

    onSubmit = () => {
        console.log('Submitting');
        this.setState({ isLoading: true });
    }

    onSubmitResponse = () => {
        this.setState({ isLoading: false });
        console.log('Submitting Completed');
    }

	onCreate = (instance) => {
		console.log('onCreate')
	}

	onDestroyStart = () => {
		console.log('onDestroyStart')
	}

	onDestroyEnd = () => {
		console.log('onDestroyEnd')
	}

	onError = (error) => {
        console.log('onError', error);
        this.setState({ error: true });
    }

	renderSubmitButton = ({ onClick, isDisabled, text }) => {
		return (
			<button
				className="btn payment__btn"
				onClick={onClick}
				disabled={isDisabled}
			>{text}</button>
		)
	}

    render()
    {
        const token = this.props.clientToken;
        const googlePayRequest = {
            merchantId: this.props.merchantId,
            //environment: 'TEST',
            transactionInfo: {
                totalPriceStatus: 'FINAL',
                totalPrice: this.props.total,
                currencyCode: this.props.currencyCode
            }
        }

        const applePayRequest = {
            displayName: 'Grab',
            paymentRequest: {
                total: {
                    label: 'Total',
                    amount: this.props.total
                },
                // We recommend collecting billing address information, at minimum
                // billing postal code, and passing that billing postal code with all
                // Google Pay transactions as a best practice.
                requiredBillingContactFields: ["postalAddress"],
                currencyCode: this.props.currencyCode,
                countryCode: this.props.countryCode
            }
        };

        let loading = (<div key="loading" className={classnames({
            'payment': true,
            'visible': this.props.isVisible
        })}>
            <Loader
                title={window.resources.spinners.processing_payment_text}
            />
        </div>);
	
        const payment = {
            'braintree': (
                <div key="payment" style={window.isMarketplace ? { position: 'unset', background: 'unset' } : {}} className={classnames({
                    'payment': true,
                    'visible': this.props.isVisible
                })}>

                    <div style={window.isMarketplace ? { display: 'none' } : {}} className="payment__head">
                        <div className="shell">
                            <div className="payment__head-inner">
                                <div className="payment__head-btn-container">
                                    <a
                                        onClick={this.props.closePyament}
                                        className="payment__head-btn" aria-label={window.resources.navigation.back_text}>
                                        <i className="fas arrow-left" title={window.resources.navigation.back_text}></i>
                                    </a>
                                </div>
                                <h6 className="payment__title">{window.resources.payment.label_text}</h6>
                            </div>

                        </div>
                    </div>

                    <div className="payment__inner">
                        <div className="shell">


                            <div className="payment__body">
                                <div className="payment__total">Total {localizeNumber(this.props.totalDisplay)}</div>
                                {(!this.state.error && <div className="payment__info">{window.resources.payment.pending_charge_text}</div>) || (this.state.error && <div className="payment__error">{window.resources.payment.error_text}</div>)}
                                <BraintreeDropin
                                    submitButtonText={window.resources.payment.confirm_text}
                                    locale={window.language}
                                    translations={window.resources.braintree}
                                    braintree={braintree}
                                    authorizationToken={token}
                                    applePay={this.props.applePayEnabled ? applePayRequest : null}
                                    googlePay={googlePayRequest}
                                    handlePaymentMethod={this.handlePaymentMethodBraintree}
                                    onCreate={this.onCreate}
                                    onDestroyStart={this.onDestroyStart}
                                    onDestroyEnd={this.onDestroyEnd}
                                    onError={this.onError}
                                    onSubmit={this.onSubmit}
                                    onSubmitResponse={this.onSubmitResponse}
                                    renderSubmitButton={this.renderSubmitButton}
                                    card={{

                                    }}
                                    amount={this.props.total}
                                    message={this.props.message}
                                    threeDSecure={this.props.threeDSecureEnabled}
                                    email={this.props.email}
                                    phone={this.props.phone}
                                />
                            </div>
                        </div>
                    </div>
                </div>),
            'freedompay': (
                <div key="payment" style={window.isMarketplace ? { position: 'unset', background: 'unset' } : {}} className={classnames({
                    'payment': true,
                    'visible': this.props.isVisible
                })}>

                    <div style={window.isMarketplace ? { display: 'none' } : {}} className="payment__head">
                        <div className="shell">
                            <div className="payment__head-inner">
                                <div className="payment__head-btn-container">
                                    <a
                                        onClick={this.props.closePyament}
                                        className="payment__head-btn" aria-label={window.resources.navigation.back_text}>
                                        <i className="fas arrow-left" title={window.resources.navigation.back_text}></i>
                                    </a>
                                </div>
                                <h6 className="payment__title">{window.resources.payment.label_text}</h6>
                            </div>

                        </div>
                    </div>

                    <div className="payment__inner">
                        <div className="shell">
                            <div className="payment__body">
                                <div className="payment__total">Total {localizeNumber(this.props.totalDisplay)}</div>
                                {(!this.state.error && <div className="payment__info">{window.resources.payment.pending_charge_text}</div>) || (this.state.error && <div className="payment__error">{window.resources.payment.error_text}</div>)}
                                <FreedomPayDropin
                                    authorizationToken={token}
                                    handlePaymentMethod={this.handlePaymentMethodFreedomPay}
                                    onCreate={this.onCreate}
                                    onDestroyStart={this.onDestroyStart}
                                    onDestroyEnd={this.onDestroyEnd}
                                    onError={this.onError}
                                    onSubmit={this.onSubmit}
                                    onSubmitResponse={this.onSubmitResponse}
                                    amount={this.props.total}
                                    message={this.props.message}
                                    card={true}
                                    applePay={this.props.applePayEnabled}
                                    googlePay={true}
                                    isVisible={this.props.isVisible}
                                />
                            </div>
                        </div>
                    </div>
                </div>),
            'spreedly': (
                <div key="payment" style={window.isMarketplace ? { position: 'unset', background: 'unset' } : {}} className={classnames({
                    'payment': true,
                    'visible': this.props.isVisible
                })}>

                    <div style={window.isMarketplace ? { display: 'none' } : {}} className="payment__head">
                        <div className="shell">
                            <div className="payment__head-inner">
                                <div className="payment__head-btn-container">
                                    <a
                                        onClick={this.props.closePyament}
                                        className="payment__head-btn" aria-label={window.resources.navigation.back_text}>
                                        <i className="fas arrow-left" title={window.resources.navigation.back_text}></i>
                                    </a>
                                </div>
                                <h6 className="payment__title">{window.resources.payment.label_text}</h6>
                            </div>

                        </div>
                    </div>

                    <div className="payment__inner">
                        <div className="shell">
                            <div className="payment__body">
                                <div className="payment__total">Total {this.props.totalDisplay}</div>
                                {(!this.state.error && <div className="payment__info">{window.resources.payment.pending_charge_text}</div>) || (this.state.error && <div className="payment__error">{window.resources.payment.error_text}</div>)}
                                < SpreedlyDropin
                                    key="spreedly"
                                    displayOptions={{
                                        company_name: this.props.storeName,
                                        ...window.resources.spreedly
                                    }}
                                    paymentMethodParams={{}}
                                    SpreedlyExpress={window.SpreedlyExpress}
                                    //authorizationToken='OpDkJXaJzLPFJhGnciGWdNNk5Mf'
                                    authorizationToken={token}
                                    handlePaymentMethodSpreedlyExpress={this.handlePaymentMethodSpreedlyExpress}
                                    handlePaymentMethodGooglePay={this.handlePaymentMethodGooglePay}
                                    handlePaymentMethodApplePay={this.handlePaymentMethodApplePay}
                                    onCreate={this.onCreate}
                                    onDestroyEnd={this.onDestroyEnd}
                                    onError={this.onError}
                                    onSubmit={this.onSubmit}
                                    amountDisplay={this.props.totalDisplay}
                                    amount={this.props.total}
                                    isVisible={this.props.isVisible}
                                    closePyament={this.props.closePyament}
                                    currencyCode={this.props.currencyCode}
                                    countryCode={this.props.countryCode}
                                    card={true}
                                    applePay={this.props.applePayEnabled}
                                    googlePay={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>),
            'nets': (
                <div key="payment" style={window.isMarketplace ? { position: 'unset', background: 'unset' } : {}} className={classnames({
                    'payment': true,
                    'visible': this.props.isVisible
                })}>

                    <div style={window.isMarketplace ? { display: 'none' } : {}} className="payment__head">
                        <div className="shell">
                            <div className="payment__head-inner">
                                <div className="payment__head-btn-container">
                                    <a
                                        onClick={this.props.closePyament}
                                        className="payment__head-btn" aria-label={window.resources.navigation.back_text}>
                                        <i className="fas arrow-left" title={window.resources.navigation.back_text}></i>
                                    </a>
                                </div>
                                <h6 className="payment__title">{window.resources.payment.label_text}</h6>
                            </div>

                        </div>
                    </div>

                    <div className="payment__inner">
                        <div className="shell">
                            <div className="payment__body">
                                <div className="payment__total">Total {this.props.totalDisplay}</div>
                                {!this.state.error && <div className="payment__info">{window.resources.payment.pending_charge_text}</div>}
                                < NetsDropin
                                    key="nets"
                                    displayOptions={{
                                        company_name: this.props.storeName,
                                        ...window.resources.spreedly
                                    }}
                                    paymentMethodParams={{}}
                                    //SpreedlyExpress={window.SpreedlyExpress}
                                    //authorizationToken='OpDkJXaJzLPFJhGnciGWdNNk5Mf'
                                    authorizationToken={token}
                                    handlePaymentMethodNetsEasy={this.handlePaymentMethodNets}
                                    handlePaymentMethodGooglePay={this.handlePaymentMethodGooglePay}
                                    handlePaymentMethodApplePay={this.handlePaymentMethodApplePay}
                                    onCreate={this.onCreate}
                                    onDestroyEnd={this.onDestroyEnd}
                                    onError={this.onError}
                                    onSubmit={this.onSubmit}
                                    amountDisplay={this.props.totalDisplay}
                                    amount={this.props.total}
                                    isVisible={this.props.isVisible}
                                    closePyament={this.props.closePyament}
                                    currencyCode={this.props.currencyCode}
                                    countryCode={this.props.countryCode}
                                    card={true}
                                    applePay={this.props.applePayEnabled}
                                    googlePay={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>), 
            'adyen': (
                <div key='payment' style={window.isMarketplace ? { position: 'unset', background: 'unset' } : {}} className={classnames({
                    'payment': true,
                    'visible': this.props.isVisible
                })}>

                    <div style={window.isMarketplace ? { display: 'none' } : {}} className="payment__head">
                        <div className="shell">
                            <div className="payment__head-inner">
                                <div className="payment__head-btn-container">
                                    <a
                                        onClick={this.props.closePyament}
                                        className="payment__head-btn" aria-label={window.resources.navigation.back_text}>
                                        <i className="fas arrow-left" title={window.resources.navigation.back_text}></i>
                                    </a>
                                </div>
                                <h6 className="payment__title">{window.resources.payment.label_text}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="payment__inner">
                        <div className="shell">
                            <div className="payment__body">
                            <div className="payment__total">Total {this.props.totalDisplay}</div>
                            {!this.state.error && <div className="payment__info">{window.resources.payment.pending_charge_text}</div>}
                                <AdyenDropin 
                                    handlePaymentMethod={this.handlePaymentMethodAdyen}
                                    amount={this.props.total}
                                    currencyCode={this.props.currencyCode}
                                    countryCode={this.props.countryCode}
                                />
                            </div>
                        </div>
                    </div>
                </div>)
        }[this.props.paymentProvider];

            if (this.state.isLoading) {
                return [payment, loading];
            }
            else {
                return [payment];
            }
	}
}

Payment.defaultProps = {
    paymentProvider: 'braintree'
}

export default Payment;
