/**
 * @ The external dependencies.
*/
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

/**
 * @ The internal dependencies.
 */
import { closeModal } from '../../store/state/ui/actions';
import { donationsModalShown } from '../../store/state/cart/actions';
import { startDonationAmountUpdate } from '../../store/state/cart/actions';

const ModalDonations = () => {
    const dispatch = useDispatch();

    const donationCalculation = useSelector(state => state.donation.donationinfo);
    const currencySymbol = useSelector(state => state.app.currencySymbol);

    useEffect(() => {
        dispatch(donationsModalShown());
    }, [])

    const addDonation = (calculation, amount) => {
        if (calculation && calculation.DonationAmount && calculation.DonationAmount > 0) {
            calculation.selectedAmount = amount;
            dispatch(startDonationAmountUpdate(calculation));
            dispatch(closeModal());
        }
    }

    return (
        <div className="modal-frame modal-frame--card">
            <div className="modal-frame__body">
                <div className="modal-frame__card">
                    <div className="modal-frame__head">
                        {   donationCalculation && donationCalculation.AdditionalDonationAmounts && donationCalculation.AdditionalDonationAmounts.length > 0 ?
                            <h6 className="modal-frame__title">{`${window.resources.donation.question}?`}</h6> : 
                            <h6 className="modal-frame__title">{`${window.resources.donation.question} ${currencySymbol}${donationCalculation.DonationAmount}?`}</h6>
                        }                        
                    </div>
                    <div className="modal-frame__actions modal-frame__actions--flex column-actions">
                        <div className='info'>
                            {donationCalculation.Charities.Charity[0].SolicitationMessage}
                        </div>
                        <div className='info-logo'>
                            <img src={`https://s3-eu-west-1.amazonaws.com/penniesfoundation/charities/${donationCalculation.Charities.Charity[0].Id}-75.png`} />
                            <img src='https://s3-eu-west-1.amazonaws.com/penniesfoundation/charities/000000-75.png' alt='Pennies' />
                        </div>
                        <div>
                            {   donationCalculation && donationCalculation.AdditionalDonationAmounts && donationCalculation.AdditionalDonationAmounts.length > 0 ? 
                                <div className='action-selections'>
                                    {   donationCalculation.AdditionalDonationAmounts.map(d => (
                                            <button key={d} className="btn btn--secondary-2 donation-btn multi-donation" onClick={() => addDonation(donationCalculation, d)}>{currencySymbol}{d.toFixed(2)}</button>
                                        ))
                                    }
                                </div> :
                                <button onClick={() => addDonation(donationCalculation, donationCalculation.DonationAmount)}
                                        className="btn btn--block donation-btn single-donation">{window.resources.donation.dontate_btn_text.replace('{amount}', currencySymbol + donationCalculation.DonationAmount.toFixed(2))}</button>
                            }
                        </div>
                    </div>
                    <div className='modal-frame modal-frame__foot'>
                        <h6 className="modal-frame__title" onClick={() => dispatch(closeModal())}>No Thanks</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalDonations;