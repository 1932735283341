/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalInput from './modal-input'

/**
 * @ The internal dependencies.
 */
import { openModal, closeModal } from 'store/state/ui/actions';
import { startCartUpdate } from 'store/state/cart/actions';
import { tabAuthorization } from 'store/state/tab/actions';
import { updateObject, checkValidity } from '../../lib/utility';
import { MODAL_TAB_NEW_ORDER, MODAL_WARNING } from 'lib/constants';
import { hasTabOpenAndAuthorized, getDataForVerificationModal, tabRequiresCCAuthorization } from '../../store/state/tab/selectors';

const Aux = (props) => {
    return props.children;
};

/**
 * Class for modal table number.
 *
 * @class ModalTableNumber (name)
 */
class ModalTabVerification extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
                form: {
                cardLastFour: {
                        value: '',
                        validation: {
                            minLength: 4,
                            maxLength: 4,
                            required: true
                            //isNumber: true
                        },
                        valid: false,
                        touched: false
                    }
                },

                formIsValid: false,
                formSubmitted: false
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.hasTabOpenAndAuthorized) {
            this.props.closeModal();
            this.props.data.onAuthorized();
        }
    }

    replaceVariables(html) {
        return html.replace("{this.props.tableNumber}", this.props.tableNumber)
            .replace("{this.props.itemCount}", this.props.itemCount)
            .replace("{this.props.orderTotalDisplay}", this.props.orderTotalDisplay)
            .replace("{this.props.orderDueAmountDisplay}", this.props.orderDueAmountDisplay)
            .replace("className", "class");
    }

    checkValidityCustom(value, rules) {
        let isValid = checkValidity(value, rules);

        return isValid;
    }

    handleInputChange = (event, field) => {
        const updatedFormElement = updateObject(this.state.form[field], {
            value: event.target.value.slice(0, 4),
            valid: this.checkValidityCustom(event.target.value.slice(0, 4), this.state.form[field].validation),
            touched: true
        });

        const updatedForm = updateObject(this.state.form, {
            [field]: updatedFormElement
        });

        let formIsValid = true;
        for (let field in updatedForm) {
            formIsValid = updatedForm[field].valid && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid: formIsValid });
    }

    handleSubmitButton = (e) => {
        e.preventDefault();

        this.setState({ formSubmitted: true });

        this.props.tabAuthorization({
            waypointID: window.waypointID,
            tableName: this.props.tableNumber,
            cardLastFour: this.state.form.cardLastFour.value
        });
    }

    handleSubmitNoCCAuthorizationButton = (e) => {
        e.preventDefault();

        this.setState({ formSubmitted: true });

        //Authorization + Cart Update
        this.props.tabAuthorization({
            waypointID: window.waypointID,
            tableName: this.props.tableNumber,
            cardLastFour: '',
            authorized: true
        });
    }

    handleStartNewOrderButton = (e) => {
        e.preventDefault();
        this.props.closeModal();

        if (this.props.tabRequiresCCAuthorization) {
            this.props.openModal({
                type: MODAL_TAB_NEW_ORDER,
                data: this.props.data
            });
        }
        else {
            this.props.openModal({
                type: MODAL_WARNING,
                data: {
                    message: window.resources.messages.please_see_server_html,
                    btnText: window.resources.modal.confirm_text
                }
            });
        }
    }

    render() {
        const label = this.replaceVariables(window.resources.tab_verification.label_text);
		return (
			<div className="modal-frame modal-frame--card">
				<div className="modal-frame__body">
                    <div className="modal-frame__card">
                        <div className="modal-frame__head">
                            <h5>{window.resources.tab_verification.title_text}</h5>
                        </div>

                        {this.props.tabRequiresCCAuthorization ? (
                            <Aux>
                                <ModalInput showErrorMessage={this.state.form.cardLastFour.touched && !this.props.tabAuthorizing && this.state.formSubmitted} errorMessage={window.resources.tab_verification.error_text} maxlength="4" max="9999" touched={this.state.form.cardLastFour.touched} invalid={!this.state.form.cardLastFour.valid} label={label} placeholder={window.resources.tab_verification.placeholder_text} type="number" pattern="[0-9]*" value={this.state.form.cardLastFour.value} onChange={(event) => this.handleInputChange(event, 'cardLastFour')} onKeyUp={(event) => this.handleInputChange(event, 'cardLastFour')} />
						<div className="modal-frame__actions modal-frame__actions--flex">
							<button
								onClick={() => this.props.closeModal()}
								className="btn btn--secondary">{window.resources.tab_verification.cancel_text}</button>
                            <button
                                disabled={!this.state.formIsValid || this.props.tabAuthorizing}
								onClick={this.handleSubmitButton}
                                className="btn">{window.resources.tab_verification.verify_text}</button>
                                </div>
                            </Aux>) : (
                                <Aux>
                                    <div className="modal-frame__head">
                                        <h6 className="modal-frame__title editable" dangerouslySetInnerHTML={{ __html: label }}></h6>
                                    </div>
                                    <div className="modal-frame__card-body">
                                        <div className="modal-frame__card-field">
                                            <div className="modal-frame__head">
                                                <p>{window.resources.tab_verification.existing_tab_text}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modal-frame__actions modal-frame__actions--flex">
                                        <button
                                            onClick={() => this.props.closeModal()}
                                            className="btn btn--secondary">{window.resources.tab_verification.cancel_text}</button>
                                        <button
                                            disabled={this.props.tabAuthorizing}
                                            onClick={this.handleSubmitNoCCAuthorizationButton}
                                            className="btn">{window.resources.tab_verification.yes_text}</button>
                                    </div>
                                </Aux>
                                )}

                        <div className="modal-frame__links">
                            <a
                             onClick={this.handleStartNewOrderButton}
                            >{window.resources.tab_verification.not_my_tab_text}</a>
                        </div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
    return {
        itemCount: getDataForVerificationModal(state).itemCount,
        orderTotalDisplay: getDataForVerificationModal(state).orderTotalDisplay,
        orderDueAmountDisplay: getDataForVerificationModal(state).orderDueAmountDisplay, 
        data: state.ui.modal.data,
        cardLastFour: state.cart.data.cardLastFour,
        tableNumber: state.checkout.tableNumber,
        tabAuthorizing: state.ui.tabAuthorizing,
        hasTabOpenAndAuthorized: hasTabOpenAndAuthorized(state),
        tabRequiresCCAuthorization: tabRequiresCCAuthorization(state)
    };
}

export default withRouter(connect(
    mapStateToProps,
    {
        tabAuthorization,
        openModal,
        closeModal,
        startCartUpdate
	}
)(ModalTabVerification));
