/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CurrencyInput from 'react-currency-input-field';

/**
 * @ The internal dependencies.
 */
import loyaltyImageLogin from '../../assets/images/loyalty.png';
import { closeModal } from 'store/state/ui/actions';
import { updateLoyaltyAmount, startCartUpdate } from 'store/state/cart/actions';
import { updateObject, checkValidity } from '../../lib/utility';

class ModalLoyaltyAmount extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            form: {
                amount: {
                    value: '',
                    validation: {
                        required: true,
                        isCurrency: true
                    },
                    valid: false,
                    touched: false
                }
            },

            formIsValid: false,
            formSubmitted: false
        }
    }

    replaceVariables(html) {
        return html.replace("{balance}", `${this.props.currencySymbol}${this.floorFigure(this.props.balance)}`)
            .replace("{tier}", this.props.tier)
            .replace("{total}", this.props.totalDisplay);
    }

    componentDidMount() {
        if (this.props.loyaltyAmount > 0) {
            this.handleValueChange(this.props.loyaltyAmount > this.props.total ? this.props.total.toFixed(2) : this.props.loyaltyAmount.toFixed(2), 'amount');
        }
        else {
            this.handleValueChange(this.props.balance > this.props.total ? this.props.total.toFixed(2) : this.floorFigure(this.props.balance), 'amount');
        }
    }

    floorFigure(figure, decimals = 2) {
        var d = Math.pow(10, decimals);
        return (parseInt(figure * d, 10) / d).toFixed(decimals);
    };

    checkValidityCustom(value, rules) {
        let isValid = checkValidity(value, rules);
        isValid = isValid && parseFloat(value) <= this.props.balance;
        isValid = isValid && parseFloat(value) <= this.props.total;
        return isValid;
    }

    handleValueChange = (value, field) => {
        const updatedFormElement = updateObject(this.state.form[field], {
            value: value,
            valid: this.checkValidityCustom(value, this.state.form[field].validation),
            touched: true
        });

        const updatedForm = updateObject(this.state.form, {
            [field]: updatedFormElement
        });

        let formIsValid = true;
        for (let field in updatedForm) {
            formIsValid = updatedForm[field].valid && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid: formIsValid });
    }

    handleCancelButton = (e) => {
        e.preventDefault();

        this.props.closeModal();
    }

    handleSubmitButton = (e) => {
        e.preventDefault();

        this.setState({ formSubmitted: true });

        this.props.updateLoyaltyAmount(parseFloat(this.state.form.amount.value));

        this.props.startCartUpdate();

        this.props.closeModal();
    }

    render() {
        return (
            <div className="modal-frame modal-frame--loyalty">
                <div className="modal-frame__body">
                    <div className="modal-frame__loyalty">
                        <div className="modal-frame__head" style={{marginBottom: '30px'}}>
                            <h3 className="modal-frame__title" style={{ fontSize: '16px' }}> <img style={{width: '25px', filter: 'invert(77%) sepia(96%) saturate(212%) hue-rotate(356deg) brightness(89%) contrast(87%)', marginRight: '5px', paddingBottom: '10px',  }} src={loyaltyImageLogin} alt="" /> {this.props.name}</h3>
                            <h3 className="modal-frame__title" dangerouslySetInnerHTML={{ __html: this.replaceVariables(window.resources.loyalty_amount.header_line_2) }}></h3>
                        </div>

                        <div className="modal-frame__head">
                            {this.props.loyaltyAmount > 0 ? <h5 style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: window.resources.loyalty_amount.edit_label_html }}></h5> : <h5 style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: window.resources.loyalty_amount.label_html }}></h5>}
                            <h6 className="modal-frame__title" dangerouslySetInnerHTML={{ __html: this.replaceVariables(window.resources.loyalty_amount.basket_total_text) }}></h6>
                        </div>

                        <div className="modal-frame__card-body">
                            <div className="modal-frame__card-field">
                                <CurrencyInput
                                    prefix="$"
                                    className="field"
                                    name="amount"
                                    placeholder="Enter Amount"
                                    decimalsLimit={2}
                                    onValueChange={this.handleValueChange}
                                    value={this.state.form.amount.value}
                                />
                            </div>                          
                        </div>

                        {parseFloat(this.state.form.amount.value) > this.props.balance && <p style={{ backgroundColor: '#FFCCBA', color: '#D83B0B', padding: '10px', textAlign: 'center' }}>{window.resources.loyalty_amount.insufficient_balance_text}</p>}
                        {parseFloat(this.state.form.amount.value) > this.props.total && !(parseFloat(this.state.form.amount.value) > this.props.balance) && <p style={{ backgroundColor: '#FFCCBA', color: '#D83B0B', padding: '10px', textAlign: 'center' }}>{window.resources.loyalty_amount.amount_higher_than_total_text}</p>}

                        <div className="modal-frame__actions modal-frame__actions--flex">
                            <button
                                onClick={this.handleCancelButton}
                                className="btn btn--secondary-3">{window.resources.loyalty_amount.cancel_text}</button>
                            <button
                                disabled={!this.state.formIsValid || this.props.loyaltyLoggingIn}
                                onClick={this.handleSubmitButton}
                                className="btn">{window.resources.loyalty_amount.submit_text}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currencySymbol: state.app.currencySymbol,
        data: state.ui.modal.data,
        total: state.cart.data.totalCost + state.cart.cartPricingData.loyaltyAmount,
        totalDisplay: state.app.currencySymbol + (state.cart.data.totalCost + state.cart.cartPricingData.loyaltyAmount).toFixed(2),
        loyaltyAmount: state.cart.data.loyaltyAmount,
        balance: state.loyalty.balance,
        name: state.loyalty.name,
        tier: state.loyalty.tier,
    };
}

export default withRouter(connect(
    mapStateToProps,
    {
        closeModal,
        updateLoyaltyAmount,
        startCartUpdate
    }
)(ModalLoyaltyAmount));
