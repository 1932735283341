/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalInput from './modal-input'

/**
 * @ The internal dependencies.
 */
import { openModal, closeModal } from 'store/state/ui/actions';
import { sendLoyaltyRegisterRequest } from 'store/state/loyalty/actions';
import { updateObject, checkValidity } from '../../lib/utility';
import { MODAL_LOYALTY_LOGIN_SUCCESS } from 'lib/constants';
import { loyaltyLoggedIn, loyaltyException } from '../../store/state/loyalty/selectors';

class ModalLoyaltyRegister extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            form: {
                phoneEmail: {
                    value: '',
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                }
            },

            formIsValid: false,
            formSubmitted: false
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.loyaltyLoggedIn) {
            this.props.closeModal();

            if (this.props.tier) {
                this.props.openModal({
                    type: MODAL_LOYALTY_LOGIN_SUCCESS,
                    data: {
                        onAuthorized: this.props.data && this.props.data.onAuthorized
                    }
                });
            }
        }
    }

    checkValidityCustom(value, rules) {
        let isValid = checkValidity(value, rules);
        isValid = isValid && (checkValidity(value, { isMobileNoCountryCode: true }));
        return isValid;
    }

    handleInputChange = (event, field) => {
        const updatedFormElement = updateObject(this.state.form[field], {
            value: event.target.value,
            valid: this.checkValidityCustom(event.target.value, this.state.form[field].validation),
            touched: true
        });

        const updatedForm = updateObject(this.state.form, {
            [field]: updatedFormElement
        });

        let formIsValid = true;
        for (let field in updatedForm) {
            formIsValid = updatedForm[field].valid && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid: formIsValid });
    }

    handleSubmitButton = (e) => {
        e.preventDefault();

        this.setState({ formSubmitted: true });

        this.props.sendLoyaltyRegisterRequest(
            { identifier: this.state.form.phoneEmail.value }
        );
    }

    render() {
        return (
            <div className="modal-frame modal-frame--loyalty">
                <div className="modal-frame__body">
                    <div className="modal-frame__loyalty">
                        <div className="modal-frame__head">
                            <h3>{window.resources.loyalty_register.title_text}</h3>
                        </div>

                        <ModalInput showErrorMessage={this.state.form.phoneEmail.touched && !this.props.loyaltyLoggingIn && this.state.formSubmitted} errorMessage={this.props.loyaltyException || ''} touched={this.state.form.phoneEmail.touched} invalid={!this.state.form.phoneEmail.valid} label={window.resources.loyalty_register.label_text} placeholder={window.resources.loyalty_register.placeholder_text} type="text" value={this.state.form.phoneEmail.value} onChange={(event) => this.handleInputChange(event, 'phoneEmail')} onKeyUp={(event) => this.handleInputChange(event, 'phoneEmail')} />
                        <div className="modal-frame__actions modal-frame__actions--flex">
                            <button
                                onClick={() => this.props.closeModal()}
                                className="btn btn--secondary-3">{window.resources.loyalty_register.cancel_text}</button>
                            <button
                                disabled={!this.state.formIsValid}
                                onClick={this.handleSubmitButton}
                                className="btn">{window.resources.loyalty_register.submit_text}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.ui.modal.data,
        loyaltyLoggingIn: state.ui.loyaltyLoggingIn,
        loyaltyLoggedIn: loyaltyLoggedIn(state),
        loyaltyException: loyaltyException(state),
        tier: state.loyalty.tier
    };
}

export default withRouter(connect(
    mapStateToProps,
    {
        openModal,
        closeModal,
        sendLoyaltyRegisterRequest
    }
)(ModalLoyaltyRegister));
