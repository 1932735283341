/**
 * @ The external dependencies.
 */
import {
    all,
    call,
    takeLatest
} from 'redux-saga/effects';

import {
    sendLoadedValueAccountBalanceRequest,
    receiveLoadedValueAccountBalanceResponse,
} from './actions';
import makeRequest from '../../../lib/helpers/api-request';


export function* sendLoadedValueAccountBalanceRequestWorker(action) {
    const payload = {
        accountNumber: action.payload.accountNumber,
    };

    yield call(makeRequest, {
        endpoint: 'loadedValueAccountBalanceCheck',
        payload: payload,
        requestAction: action,
        receiveAction: receiveLoadedValueAccountBalanceResponse
    });
}

/**
 * Start all workers.
 *
 * @return {Generator}
 */
export default function* foreman() {
    yield all([
        takeLatest(sendLoadedValueAccountBalanceRequest, sendLoadedValueAccountBalanceRequestWorker)
    ]);
}
