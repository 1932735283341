/**
 * @ The external dependecies
 */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
/**
 * @ The internal dependecies.
 */
import { isRequestRunning, getRequestError } from 'store/state/requests/selectors';
import { requestTableTopData, requestTableTopConfig, requestMultiConceptData } from 'store/state/app/actions';
import { requestPreOrderQuestionnaireData } from 'store/state/questionnaire/actions';
import Loader from 'components/loader/loader';
import { hasTabOpen } from 'store/state/tab/selectors';
import { loyaltyLoggedIn } from 'store/state/loyalty/selectors'
import TabOpenHeader from 'components/header/tab-open-header';
import { Aux } from '../../utility'

/**
 * Data validation hoc
 *
 * @param {Component}  ComposedComponent  The composed component
 */
const withDataValidation = ComposedComponent => {
    class ReduxContainer extends React.Component {
		render() {
            const { config, data, error, requestingConfig, requestingData, requestingMultiConceptData, requestingPreOrderQuestionnaireData, showSpinnerCartSaveOrClearTab, showSpinnerCartUpdate, requestingResources } = this.props;
            const home = this.props.location.pathname === '/' || this.props.location.pathname.search(/^\/[0-9]+$/) !== -1;

			if (error) {
				return <h1>{error.message}</h1>
			}

            const noData = (config || data) === null;

            if (requestingConfig || (requestingData && !(home && !requestingConfig)) || requestingMultiConceptData || requestingPreOrderQuestionnaireData || requestingResources || showSpinnerCartSaveOrClearTab || showSpinnerCartUpdate || noData || window.loadingResources) {
                //triggers remount
                return <Loader title={window.resources.spinners.loading_text} />
            }

            let tabOpenHeader = this.props.hasTabOpen && this.props.location.pathname !== '/' && this.props.location.pathname !== '/tab/view' && this.props.location.pathname !== '/tab/receipt' && this.props.location.pathname !== '/split' && !this.props.location.pathname.startsWith('/split/invite') && this.props.location.pathname.search(/^\/[0-9]+|[vV][kK]$/) === -1 && (<TabOpenHeader />);

            return (
                <Aux>
                    {tabOpenHeader}
                    <ComposedComponent {...this.props} {...this.state} />
                </Aux>
                    )
		}
	};

    return withRouter(connect(
        (state) => ({
            hasTabOpen: hasTabOpen(state),
            loyaltyLoggedIn: loyaltyLoggedIn(state),
            tier: state.loyalty.tier,
            data: state.app.data || state.app.multiConceptData,
            config: state.app.config,
            showSpinnerCartSaveOrClearTab: state.ui.showSpinnerCartSaveOrClearTab,
            showSpinnerCartUpdate: state.ui.showSpinnerCartUpdate,
            error: getRequestError(state, requestTableTopConfig) || getRequestError(state, requestTableTopData) || getRequestError(state, requestMultiConceptData),
            requestingConfig: isRequestRunning(state, requestTableTopConfig),
            requestingData: isRequestRunning(state, requestTableTopData),
            requestingMultiConceptData: isRequestRunning(state, requestMultiConceptData),
            requestingPreOrderQuestionnaireData: isRequestRunning(state, requestPreOrderQuestionnaireData),
            requestingResources: state.ui.loadingResources
		})
	)(ReduxContainer));
}

export default withDataValidation;