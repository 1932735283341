export const getBraintreeCardDetails = (brainTreePayload) => {
    let btNonce = brainTreePayload.nonce;
    let cardType = '';
    let lastFour = '';

    if (brainTreePayload.type === 'ApplePayCard') {
        cardType = "APPLEPAY";
        lastFour = brainTreePayload.details.lastFour;
    }
    else if (brainTreePayload.type === 'AndroidPayCard') {
        cardType = "GOOGLEPAY";
        lastFour = brainTreePayload.details.lastFour;
    }
    else if (brainTreePayload.type === 'CreditCard') {
        let btCardType = brainTreePayload.details.cardType.toUpperCase();

        if (btCardType === "VISA") {
            cardType = "VISA";
        }
        else if (btCardType === "MASTERCARD") {
            cardType = "MC";
        }
        else if (btCardType === "AMERICAN EXPRESS") {
            cardType = "AMEX";
        }
        else if (btCardType === "DISCOVER") {
            cardType = "DISCOVER";
        }

        lastFour = brainTreePayload.details.lastFour;
    }

    return {
        paymentDetails: {
            paymentProvider: 'braintree',
            btNonce: btNonce,
            ccInfo: {
                cardHolder: "",
                cardLastFour: lastFour,
                BraintreeV2CardType: cardType,
                cvv: "",
                cardNumber: "",
                zipCode: "",
                expiration: ""
            },
            FreedomPaySession: {
                SessionKey: "",
                PaymentKeys: []
            },
            SpreedlySession: {
                PaymentToken: "",
                GooglePayData: "",
                ApplePayData: "",
                CardType: "",
                CardLastFour: "",
                BrowserInfo: null
            }, 
            adyenSession: {
                AdyenRequestPayload: null, 
                AdyenResponsePayload: '', 
                Reference: ''
            }
        }
    };
}; 

export const getAdyenCardDetails = (payload) => {
    return {
        paymentDetails: {
            paymentProvider: 'adyen',
            btNonce: '',
            ccInfo: {
                cardHolder: '',
                cardLastFour: '',
                cvv: '', 
                cardNumber: '',
                zipCode: '',
                expiration: ''
            },
            adyenSession: payload
        }
    }
}

export const getFreedomPayCardDetails = (freedomPayPayload) => {
    let sessionKey = freedomPayPayload.sessionKey;
    let cardType = '';
    let lastFour = (freedomPayPayload.attributes.find(a => a.Key === "MaskedCardNumber") && freedomPayPayload.attributes.find(a => a.Key === "MaskedCardNumber").Value.slice(-4)) || '';
    let cardHolder = '';


    if (freedomPayPayload.type === 'ApplePayCard') {
        cardType = "APPLEPAY";
        let billingContact = (freedomPayPayload.attributes.find(a => a.Key === "billingContact") && freedomPayPayload.attributes.find(a => a.Key === "billingContact").Value) || '';
        let contactJSON = null;

        if (billingContact.length > 0) {
            contactJSON = JSON.parse(billingContact);

            if (contactJSON) {
                cardHolder = contactJSON["givenName"] + ' ' + contactJSON["familyName"];
            }
        }
    }
    else if (freedomPayPayload.type === 'AndroidPayCard') {
        cardType = "GOOGLEPAY"
        cardHolder = (freedomPayPayload.attributes.find(a => a.Key === "Name") && freedomPayPayload.attributes.find(a => a.Key === "Name").Value) || '';
    }
    else if (freedomPayPayload.type === 'CreditCard') {
        cardHolder = freedomPayPayload.ccCardholderName;
        let btCardType = ((freedomPayPayload.attributes.find(a => a.Key === "CardIssuer") && freedomPayPayload.attributes.find(a => a.Key === "CardIssuer").Value) || '').toUpperCase();

        if (btCardType === "VISA") {
            cardType = "VISA";
        }
        else if (btCardType === "MASTERCARD") {
            cardType = "MC";
        }
        else if (btCardType === "AMERICANEXPRESS" || btCardType === "AMEX") {
            cardType = "AMEX";
        }
        else if (btCardType === "DISCOVER") {
            cardType = "DISCOVER";
        }
    }

    return {
        paymentDetails: {
            paymentProvider: 'freedompay',
            btNonce: '',
            ccInfo: {
                cardHolder: cardHolder,
                cardLastFour: lastFour,
                BraintreeV2CardType: cardType,
                cvv: "",
                cardNumber: "",
                zipCode: "",
                expiration: ""
            },
            FreedomPaySession: {
                SessionKey: sessionKey,
                PaymentKeys: freedomPayPayload.paymentKeys.map(paymentKey => ({ Value: paymentKey, Used: false }))
            },
            SpreedlySession: {
                PaymentToken: "",
                GooglePayData: "",
                ApplePayData: "",
                CardType: "",
                CardLastFour: "",
                BrowserInfo: null
            },
            NetsSession: {
                PaymentId: "",
                CardType: "",
                CardLastFour: ""
            }, 
            adyenSession: {
                AdyenRequestPayload: null, 
                AdyenResponsePayload: '', 
                Reference: ''
            }
        }
    };
};

export const getSpreedlyExpressCardDetails = (spreedlyPayload) => {
    let paymentToken = spreedlyPayload.token;
    //let payment_method = spreedlyPayload.transaction.payment_method;
    //let cardType = payment_method.cart_type;
    //let lastFour = payment_method.last_four_digits;
    //let paymentToken = payment_method.token;

    //if (spreedlyPayload.type === 'ApplePayCard') {
    //    cardType = "APPLEPAY";
    //}
    //else if (spreedlyPayload.type === 'AndroidPayCard') {
    //    cardType = "GOOGLEPAY"
    //}
    //else if (spreedlyPayload.type === 'CreditCard') {
    //    let btCardType = ((spreedlyPayload.attributes.find(a => a.Key === "CardIssuer") && spreedlyPayload.attributes.find(a => a.Key === "CardIssuer").Value) || '').toUpperCase();

    //    if (btCardType === "VISA") {
    //        cardType = "VISA";
    //    }
    //    else if (btCardType === "MASTERCARD") {
    //        cardType = "MC";
    //    }
    //    else if (btCardType === "AMERICAN EXPRESS") {
    //        cardType = "AMEX";
    //    }
    //    else if (btCardType === "DISCOVER") {
    //        cardType = "DISCOVER";
    //    }
    //}

    var browser_size = '01';
    // The accept header from your server side rendered page. You'll need to inject it into the page. Below is an example.
    var acceptHeader = 'text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8'
    // The request should include the browser data collected by using `Spreedly.ThreeDS.serialize().
    let browser_info = window.Spreedly.ThreeDS.serialize(
        browser_size,
        acceptHeader
    );

    return {
        paymentDetails: {
            paymentProvider: 'spreedly',
            btNonce: '',
            ccInfo: {
                cardHolder: "",
                cardLastFour: "",
                BraintreeV2CardType: "",
                cvv: "",
                cardNumber: "",
                zipCode: "",
                expiration: ""
            },
            FreedomPaySession: {
                SessionKey: "",
                PaymentKeys: []
            },
            SpreedlySession: {
                PaymentToken: paymentToken,
                GooglePayData: "",
                ApplePayData: "",
                CardType: "",
                CardLastFour: "",
                BrowserInfo: browser_info
            },
            NetsSession: {
                PaymentId: "",
                CardType: "",
                CardLastFour: ""
            }, 
            adyenSession: {
                AdyenRequestPayload: null, 
                AdyenResponsePayload: '', 
                Reference: ''
            }
        }
    };
};

export const getGooglePayCardDetails = (googlePayPayload) => {
    var browser_size = '01';
    // The accept header from your server side rendered page. You'll need to inject it into the page. Below is an example.
    var acceptHeader = 'text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8'
    // The request should include the browser data collected by using `Spreedly.ThreeDS.serialize().
    let browser_info = window.Spreedly.ThreeDS.serialize(
        browser_size,
        acceptHeader
    );

    return {
        paymentDetails: {
            paymentProvider: 'spreedly',
            btNonce: '',
            ccInfo: {
                cardHolder: googlePayPayload.paymentMethodData.info.billingAddress.name,
                cardLastFour: googlePayPayload.paymentMethodData.info.cardDetails,
                BraintreeV2CardType: googlePayPayload.paymentMethodData.info.cardNetwork,
                cvv: "",
                cardNumber: "",
                zipCode: "",
                expiration: ""
            },
            FreedomPaySession: {
                SessionKey: "",
                PaymentKeys: []
            },
            SpreedlySession: {
                PaymentToken: "",
                GooglePayData: googlePayPayload.paymentMethodData.tokenizationData.token,
                ApplePayData: "",
                CardType: googlePayPayload.paymentMethodData.info.cardNetwork,
                CardLastFour: googlePayPayload.paymentMethodData.info.cardDetails,
                BrowserInfo: browser_info
            },
            NetsSession: {
                PaymentId: "",
                CardType: "",
                CardLastFour: ""
            }, 
            adyenSession: {
                AdyenRequestPayload: null, 
                AdyenResponsePayload: '', 
                Reference: ''
            }
        }
    };
};

export const getApplePayCardDetails = (applePayPayload) => {
    let strRegex = applePayPayload.token.paymentMethod.network + "\\s(\\d{4})";
    let regex = new RegExp(strRegex);
    let cardLastFour = "";

    if (regex.test(applePayPayload.token.paymentMethod.displayName)) {
        cardLastFour = regex.exec(applePayPayload.token.paymentMethod.displayName)[1];
    }

    var browser_size = '01';
    // The accept header from your server side rendered page. You'll need to inject it into the page. Below is an example.
    var acceptHeader = 'text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8'
    // The request should include the browser data collected by using `Spreedly.ThreeDS.serialize().
    let browser_info = window.Spreedly.ThreeDS.serialize(
        browser_size,
        acceptHeader
    );

    return {
        paymentDetails: {
            paymentProvider: 'spreedly',
            btNonce: '',
            ccInfo: {
                cardHolder: applePayPayload.billingContact.givenName + " " + applePayPayload.billingContact.familyName,
                cardLastFour: "",
                BraintreeV2CardType: "",
                cvv: "",
                cardNumber: "",
                zipCode: "",
                expiration: ""
            },
            FreedomPaySession: {
                SessionKey: "",
                PaymentKeys: []
            },
            SpreedlySession: {
                PaymentToken: "",
                GooglePayData: "",
                ApplePayData: applePayPayload.token.paymentData,
                CardType: applePayPayload.token.paymentMethod.network,
                CardLastFour: cardLastFour,
                BrowserInfo: browser_info
            },
            NetsSession: {
                PaymentId: "",
                CardType: "",
                CardLastFour: ""
            }, 
            adyenSession: {
                AdyenRequestPayload: null, 
                AdyenResponsePayload: '', 
                Reference: ''
            }
        }
    };
};

export const getNetsCardDetails = (netsPayload) => {
    let paymentToken = netsPayload.token;

    return {
        paymentDetails: {
            paymentProvider: 'nets',
            btNonce: '',
            ccInfo: {
                cardHolder: "",
                cardLastFour: "",
                BraintreeV2CardType: "",
                cvv: "",
                cardNumber: "",
                zipCode: "",
                expiration: ""
            },
            FreedomPaySession: {
                SessionKey: "",
                PaymentKeys: []
            },
            SpreedlySession: {
                PaymentToken: "",
                GooglePayData: "",
                ApplePayData: "",
                CardType: "",
                CardLastFour: "",
                BrowserInfo: null
            },
            NetsSession: {
                PaymentId: paymentToken.paymentId,
                CardType: "",
                CardLastFour: ""
            }, 
            adyenSession: {
                AdyenRequestPayload: null, 
                AdyenResponsePayload: '', 
                Reference: ''
            }
        }
    };
};

export const getNetsCallbackCardDetails = (paymentId) => {
    return getNetsCardDetails(
        {
            token: {
                paymentId: paymentId
            }
        }
    );
};

export const getBlankCardDetails = () => {
    return {
        paymentDetails: {
            paymentProvider:'',
            btNonce: '',
            ccInfo: {
                cardHolder: "",
                cardLastFour: "",
                BraintreeV2CardType: "",
                cvv: "",
                cardNumber: "",
                zipCode: "",
                expiration: ""
            },
            FreedomPaySession: {
                SessionKey: "",
                PaymentKeys: []
            },
            SpreedlySession: {
                PaymentToken: "",
                GooglePayData: "",
                ApplePayData: "",
                CardType: "",
                CardLastFour: "",
                BrowserInfo: null
            },
            NetsSession: {
                PaymentId: "",
                CardType: "",
                CardLastFour: ""
            }, 
            adyenSession: {
                AdyenRequestPayload: null, 
                AdyenResponsePayload: '', 
                Reference: ''
            }
        }
    };
}