/**
 * @ The external dependecies.
 */
import React, { Component } from 'react';

/**
 * @ The internal dependecies.
 */
import MenuProduct from 'views/menu/menu-product';
import MenuSubCategory from 'views/menu/menu-sub-category';

/**
 * Class for menu item.
 *
 * @class MenuItem (name)
 */
class MenuItem extends Component {
    state = {
       
    }

	totalSelectedCount(id) {
		let totalCount = 0;

		// eslint-disable-next-line
		this.props.selectedProducts.find(product => {
            if (product.inventoryItemID === id) {
                totalCount = totalCount + product.quantity;
			}
		});

		return totalCount !== 0 ? totalCount : null;
	}

	getProducts = (id) => {
		return this.props.products.filter(item => item.inventoryTitles.find(innerItem => innerItem.inventorySubTitles.find(subCategory => subCategory.inventorySubTitleID === id)));
	}

	isProductInRange = (product) => {
		if (!this.props.dayPartMsgEnabled || !product.showDayPartIcon) return false;

		const dayPartItems = this.props.products.filter(item => item.showDayPartIcon);
		const sortedItems = dayPartItems ? dayPartItems.sort((a, b) => { return new Date(a.endTimeLocalString) - new Date(b.endTimeLocalString); }) : null;

		if (!sortedItems || sortedItems.length === 0) return false;

		const currentDateTime = new Date(this.props.serverTimeLocal);
		const endDateTime = new Date(product.endTimeLocalString);

		const diff = Math.round(((endDateTime.getTime() - currentDateTime.getTime()) / 1000) / 60);
		const inRange = diff < this.props.dayPartMsgWarningMin && diff > 0;

		return inRange;
	}

    componentDidMount() {

    }

	render() {
        const { category, products, id } = this.props;

		const subCategories = category.inventorySubTitles.concat().sort((a, b) => parseInt(a.inventorySubTitleOrder, 10) - parseInt(b.inventorySubTitleOrder, 10)).filter(x => x.inventorySubTitleDescription.toLowerCase() !== 'hidden');

		return (
			<div id={id} className="item-menu srollspy-section">
				<div className="item-menu__head">
					<h6 className="item-menu__title">{category.inventoryTitleDescription}</h6>

					<div className="item-menu__entry">
						<p>{category.detailsDescription}</p>
					</div>
				</div>

				<div className="item-menu__body">
					<div className="item-menu__container">
						{subCategories.length > 0 ? subCategories.map((subCategory, index) => {
							return <MenuSubCategory key={subCategory.inventorySubTitleID + index} category={subCategory} showCalories={this.props.showCalories} inventoryTitleID={this.props.id} selectedProducts={this.props.selectedProducts} products={this.getProducts(subCategory.inventorySubTitleID)} alcoholMealLimitEnabled={this.props.alcoholMealLimitEnabled} onItemClick={this.props.onItemClick} isCombo={this.props.isCombo} dayPartMsgEnabled={this.props.dayPartMsgEnabled} dayPartMsgWarningMin={this.props.dayPartMsgWarningMin} serverTimeLocal={this.props.serverTimeLocal} />
						})
                        : products.map((product, index) => {
							return <MenuProduct key={product.inventoryItemID + index} showCalories={this.props.showCalories} inventoryTitleID={this.props.id} selectedProducts={this.props.selectedProducts} product={product} alcoholMealLimitEnabled={this.props.alcoholMealLimitEnabled} onItemClick={this.props.onItemClick} isCombo={this.props.isCombo(product.inventoryItemID)} inRange={this.isProductInRange(product)} />
                        })}
					</div>
				</div>
			</div>
		);
	}
}

export default MenuItem;
