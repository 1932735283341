/**
 * @ The external dependencies.
 */
import { handleActions } from 'redux-actions';

/**
 * @ The actions.
 */
import { startCartSave, receiveOrderConfirmationData } from '../cart/actions';
import { clearCheckout, updateSaveMode, updateTableNumber, clearAdyenSession } from './actions';
import { receiveTableTopData } from '../app/actions';
import { receiveTableCheckResponse } from '../tablecheck/actions';
import { updateObject } from '../../../lib/utility';

import {
    endRequestWithError
} from '../requests/actions';

/**
 * @ The reducer.
 */

 //persisted to local storage
const defaultState = {
    email:'',
    orderID: '',
    posOrderID:'',
    prepTime: '',
    error: '',
    tableNumber: '',
    showSurveyLink: true,
    totalCost: '',
    saveMode: '', //PAY_NOW, TAB_NEW, TAB_ADDITEMS, TAB_ADDITEMSCLOSE, TAB_CLOSE
    spreedlyTransaction: null, 
    adyenSession: null
}

/**
 * The actions Handler.
 *
 * @type {Function}
 */
const onReceiveTableTopData = (state, action) => {
    return updateObject(state, {
        prepTimeMin: action.payload.prepTimeMin,
        prepTimeMax: action.payload.prepTimeMax
    });
}

const onUpdateSaveMode = (state, action) => {
    return updateObject(state, { saveMode: action.payload });
}

const onStartCartSave = (state, action) => {
    return updateObject(state, { processingOrder: true, error : '' });
}

const onReceiveOrderConfirmationData = (state, action) => {
    return updateObject(state, {
        email: action.payload.email,
        orderID: action.payload.orderID,
        posOrderID: action.payload.posOrderID,
        error: action.payload.exception,
        showSurveyLink: action.payload.ShowSurveyLink,
        totalCost: action.payload.totalCost,
        spreedlyTransaction: action.payload.spreedlyTransaction, 

        adyenSession: action.payload.adyenSession, 
        data: action.payload
    });
}

const onEndRequestWithError = (state, action) => {
    if (action.payload.id === `'${startCartSave}'`) {
        return updateObject(state, {
            processingOrder: false,
            error: 'An unexpected error has occured.'
        });
    }

    return state;
}

const onReceiveTableCheckResponse = (state, action) => {
    if (action.payload.success) {
        var successState = updateObject(state, { tableNumber: action.payload.locationName });
        return successState;
    }
    else {
        var failState = updateObject(state, { tableNumber: '' });
        return failState;
    }
}

const onClearCheckout = (state, action) => {
    return updateObject(state, { ...defaultState });
}

const onClearAdyenSession = (state, action) => {
    return updateObject(state, { adyenSession: null })
}

const onUpdateTableNumber = (state, action) => {
    return updateObject(state, { tableNumber: action.payload });
}

const checkout = handleActions({
    [updateSaveMode]: (state, action) => onUpdateSaveMode(state, action),
    [clearCheckout]: (state, action) => onClearCheckout(state, action),
    [receiveTableCheckResponse]: (state, action) => onReceiveTableCheckResponse(state, action),
    [startCartSave]: (state, action) => onStartCartSave(state, action),
    [receiveTableTopData]: (state, action) => onReceiveTableTopData(state, action),
    [receiveOrderConfirmationData]: (state, action) => onReceiveOrderConfirmationData(state, action),
    [endRequestWithError]: (state, action) => onEndRequestWithError(state, action),
    [updateTableNumber]: (state, action) => onUpdateTableNumber(state, action), 
    [clearAdyenSession]: (state, action) => onClearAdyenSession(state, action)
}, defaultState);

export default checkout;
