import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Backdrop, Box, Modal, Fade, Typography, Button, TextField, ToggleButtonGroup, ToggleButton, styled } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import EmailIcon from '@mui/icons-material/Email';
import CurrencyInput from 'react-currency-input-field';

import { clearCart } from '../../../store/state/cart/actions';
import { Context } from '../../../store/state/marketplace/store';
import { localizeNumber } from '../../../assets/resources/resources-manager';
import { startTipAmountUpdate, startTipPercentageUpdate, startPromotionUpdate } from '../../../store/state/cart/actions';
import * as features from '../../../store/state/features/selectors';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
};
const CapitalCaseButton = styled(Button)(({ theme }) => ({
    textTransform: 'capitalize'
}));

export const DeliveryChange = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { open, handleClose } = props;
    const { selectedStore, setDeliveryLocation } = useContext(Context);

    const handleClearCart = () => {
        dispatch(clearCart());
        setDeliveryLocation(undefined);
        window.sessionStorage.removeItem('deliverylocation');
        history.push(`/marketplace/${window.marketplaceId}`);
    }
    const replaceVariables = (html) => {
        if (!selectedStore || !selectedStore.store) handleClearCart();
        else return html.replace('{store-name}', selectedStore.store.storeName);
    }

    return (
        <Modal
            aria-labelledby='transition-modal-title'
            aria-describedby='transition-modal-description'
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className='marketplace-prompt-modal'>
                        <Typography id='transition-modal-title' component='h2' sx={{ fontWeight: 'bold'}}>
                            {window.resources.marketplace.modal.clear_cart.title_text}
                        </Typography>
                        <Typography 
                            id='transition-modal-description' 
                            sx={{ mt: 2 }} 
                            component='div' 
                            dangerouslySetInnerHTML={{__html: replaceVariables(window.resources.marketplace.modal.clear_cart.message_text)}}>
                        </Typography>
                        <div className='prompt-buttons'>
                            <CapitalCaseButton className='marketplace-secondary-button' variant='text' sx={{width: 152, height: 48}} onClick={handleClose}>{window.resources.marketplace.modal.clear_cart.cancel_text}</CapitalCaseButton>
                            <CapitalCaseButton className='marketplace-primary-button' variant='contained' sx={{width: 152, height: 48}} onClick={handleClearCart}>{window.resources.marketplace.modal.clear_cart.continue_text}</CapitalCaseButton>
                        </div>
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}
export const DiscountCode = (props) => {
    const dispatch = useDispatch();

    const { open, handleClose, isEdit } = props;
    const { fetchValidateDiscount, validateDiscountState, selectedStore, deliveryLocation, setDiscountEmail, setPromoCode, promoCode } = useContext(Context);

    const promotion = useSelector(state => (state.cart.data && state.cart.data.promotion && state.cart.data.promotion.promotionCode) || promoCode || undefined);
    const cartEmail = useSelector(state => state.cart.data && state.cart.data.email);

    const [discount, setDiscount] = useState(promotion || '');
    const [email, setEmail] = useState(cartEmail || '');

    const [inValidDiscount, setInValidDiscount] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(undefined);

    useEffect(() => {
        if (validateDiscountState.discountInfo && validateDiscountState.discountInfo.success && validateDiscountState.discountInfo.promotions) {
            handleClose();
        }
        else if (validateDiscountState.discountInfo && validateDiscountState.discountInfo.success === false) {
            setInValidDiscount(true);
        }
    }, [validateDiscountState.discountInfo]);
    useEffect(() => {
        if (isValidEmail) {
            setDiscountEmail(email);
            fetchValidateDiscount(discount, email, selectedStore.waypointID, !deliveryLocation || deliveryLocation.toLowerCase() === 'pickup' ? false : true);
        }
    }, [isValidEmail]);
    useEffect(() => {
        setIsValidEmail(undefined);
    }, [discount])

    const handleProcessDiscount = () => {
        emailValidation();
    }
    const handleBackRemove = () => {
        if (isEdit) { dispatch(startPromotionUpdate(null)); setPromoCode(undefined); window.sessionStorage.removeItem('promoCode');}

        handleClose();
    }
    const emailValidation = () => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!email || regex.test(email) === false) setIsValidEmail(false);
        else setIsValidEmail(true);
    }

    return (
        <Modal
            aria-labelledby='transition-modal-title'
            aria-describedby='transition-modal-description'
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Box sx={style}>
                <div className='marketplace-prompt-modal'>
                    <Typography id='transition-modal-title' component='h2' sx={{ fontWeight: 'bold'}}>
                        {isEdit ? window.resources.marketplace.modal.discount_code.edit_title_text : window.resources.marketplace.modal.discount_code.title_text}
                    </Typography>
                    <div className='icon-text-item'>
                        <div className='icon'><LocalOfferIcon fontSize='12px' sx={{ color: 'white', transform: 'rotate(-90deg)' }} /></div>
                        <TextField
                            error={inValidDiscount}
                            helperText={inValidDiscount ? validateDiscountState.discountInfo.exception : ''}
                            value={discount}
                            onChange={e => setDiscount(e.target.value)}
                            fullWidth
                            id='discount-code-textfield'
                            label={window.resources.marketplace.modal.discount_code.discount_code_label_text}
                            required
                            aria-required
                            variant='standard'
                        />
                    </div>
                    <div className='icon-text-item'>
                        <div className='icon'><EmailIcon fontSize='12px' sx={{ color: 'white' }} /></div>
                        <TextField
                            error={isValidEmail === false}
                            helperText={isValidEmail === false ? window.resources.marketplace.modal.discount_code.invalid_email_helper_text : ''}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            fullWidth
                            id='email-textfield'
                            label={window.resources.marketplace.modal.discount_code.email_label_text}
                            required
                            aria-required
                            variant='standard'
                        />
                    </div>
                    <div className='prompt-buttons'>
                        <CapitalCaseButton className='marketplace-secondary-button' variant='text' sx={{width: 152, height: 48}} onClick={() => handleBackRemove()}>{isEdit ? window.resources.marketplace.modal.discount_code.no_discount_text : window.resources.marketplace.modal.back_text}</CapitalCaseButton>
                        <CapitalCaseButton className='marketplace-primary-button' variant='contained' sx={{width: 152, height: 48}} onClick={() => { handleProcessDiscount(); }} disabled={!email || !discount}>{window.resources.marketplace.modal.ok_text}</CapitalCaseButton>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}
export const Tips = (props) => {
    const dispatch = useDispatch();
    const { open, handleClose, title } = props;

    const [tipPercentage, setTipPercentage] = useState(undefined);
    const [customTip, setCustomTip] = useState(undefined);

    const currencySymbol = useSelector(state => state.app.currencySymbol);
    const subTotal = useSelector(state => state.cart.cartPricingData ? state.cart.cartPricingData.subTotalCostBeforeDiscount : 0.00);
    const tipAdded = useSelector(state => state.cart.data && ((state.cart.data.tipAmount && state.cart.data.tipAmount > 0) || (state.cart.data.tipPercentage && state.cart.data.tipPercentage > 0)));

    const percentage = (num) => (num / 100) * Number(subTotal);
    const handlePercentChange = (event, newPercentage) =>  setTipPercentage(newPercentage);

    const addTip = (e) => {
        e.preventDefault();

        let exceededMaxTipPercentage = false;

        if (customTip && customTip.length > 0) {
            if (window.resources.tip.maxPercentage !== 0 && Number(customTip) > percentage(window.resources.tip.maxPercentage)) exceededMaxTipPercentage = true;
            else dispatch(startTipAmountUpdate(Number(customTip)));

        } 
        else {
            if (window.resources.tip.maxPercentage !== 0 && tipPercentage > window.resources.tip.maxPercentage) exceededMaxTipPercentage = true;
            else {
                const decPercentage = Number(tipPercentage / 100);
                dispatch(startTipPercentageUpdate(decPercentage));
            }
		}
        handleClose();

        // if (exceededMaxTipPercentage) {
        //     this.props.openModal({
        //         type: MODAL_WARNING,
        //         data: {
        //             message: this.replaceVariables(window.resources.messages.exceeded_max_tip_percentage_text),
        //             btnText: window.resources.modal.confirm_text
        //         }
        //     });
        // }
    }
    const noTip = (e) => {
        e.preventDefault();
        if (tipAdded) dispatch(startTipAmountUpdate(0.00));

        handleClose();
    }

    return (
        <Modal
            aria-labelledby='transition-modal-title'
            aria-describedby='transition-modal-description'
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Box sx={style}>
                <div className='marketplace-prompt-modal'>
                    <Typography id='transition-modal-title' component='h2' sx={{ fontWeight: 'bold'}}>
                        {title}
                    </Typography>
                    <React.Fragment>
                        { window.resources.tip.percentages && window.resources.tip.percentages.length > 0 && 
                            <div className='tip-percent-options'>
                                <ToggleButtonGroup
                                    color='primary'
                                    value={tipPercentage}
                                    exclusive
                                    onChange={handlePercentChange}
                                    aria-label='Tip Percent'
                                >
                                    { window.resources.tip.percentages.map((e, i) => (
                                        <ToggleButton key={e} value={localizeNumber(e)} sx={{ width: '97px', height: '54px', display: 'flex', flexDirection: 'column' }}>
                                            <div>{localizeNumber(e)}%</div>
                                            <div>{`${currencySymbol} ${localizeNumber(percentage(e).toFixed(2))}`}</div>
                                            {/* <div>{localizeNumber(this.percentage(e).toFixed(2))}</div> */}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </div> 
                        }
                        <div className='custom-tip-amount'>
                            <CurrencyInput
                                id='custom-tip'
                                name='custom-tip'
                                placeholder='Enter Custom Amount'
                                allowNegativeValue={false}
                                decimalsLimit={2}
                                prefix={currencySymbol}
                                disableAbbreviations={true}
                                // disabled={selectedTip !== 'custom'}
                                value={customTip || ''}
                                onValueChange={(value) => {
                                    setCustomTip(value)
                                }}
                                onClick={() => setTipPercentage(undefined)}
                            />
                        </div>
                    </React.Fragment>                    
                    <div className='prompt-buttons'>
                        <CapitalCaseButton className='marketplace-secondary-button' variant='text' sx={{width: 152, height: 48}} onClick={noTip}>{tipAdded ? window.resources.marketplace.modal.tip.remove_tip_text : window.resources.marketplace.modal.back_text}</CapitalCaseButton>
                        <CapitalCaseButton className='marketplace-primary-button' variant='contained' sx={{width: 152, height: 48}} onClick={addTip}>{tipAdded ? window.resources.marketplace.modal.tip.update_tip_text : window.resources.marketplace.modal.tip.add_tip_text }</CapitalCaseButton>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}
export const EditEmail = (props) => {
    const history = useHistory();
    const { open, handleClose } = props;

    return (
        <Modal
            aria-labelledby='transition-modal-title'
            aria-describedby='transition-modal-description'
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Box sx={style}>
                <div className='marketplace-prompt-modal'>
                    <Typography id='transition-modal-title' component='h2' sx={{ fontWeight: 'bold'}}>
                        {window.resources.marketplace.modal.edit_email.title_text}
                    </Typography>
                    <Typography id='transition-modal-description' sx={{ mt: 2 }}>
                        {window.resources.marketplace.modal.edit_email.message_text}
                    </Typography>
                    <div className='prompt-buttons'>
                        <CapitalCaseButton className='marketplace-secondary-button' variant='text' sx={{width: 152, height: 48}} onClick={() => history.push(`/marketplace/${window.marketplaceId}/${window.waypointID}/cart`)}>{window.resources.marketplace.modal.edit_email.continue_text}</CapitalCaseButton>
                        <CapitalCaseButton className='marketplace-primary-button' variant='contained' sx={{width: 152, height: 48}} onClick={handleClose}>{window.resources.marketplace.modal.edit_email.cancel_text}</CapitalCaseButton>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}