/**
 * @ The external dependencies.
 */
import axios from 'axios';
import * as Sentry from '@sentry/react';

/**
 * @ Setup api config.
 */
const client = axios.create({
  baseURL: '/api',
});

//Append kobp to all requests
client.interceptors.request.use((req) => {
  req.params = {
    ...req.params,
    kobp: window.kobp,
    platformType: window.platformType,
    languageCode: window.language,
  };
  return req;
});

/**
 * Class for api.
 *
 * @class Api (name)
 */
class Api {
  constructor(client) {
    this.client = client;
  }

  async getMultiConceptData(payload) {
    var qs = {
      params: payload,
    };

    var response = await this.client.get('/cursus/grabMobile_OATGetStoresForMultiConcept', qs);
    return response.data;
  }

  //Store Config
  async getTableTopConfig() {
    var response = await this.client.get(`/cursus/grabTableTop_GetStoreConfiguration?waypointId=${window.waypointID}`);
    return response.data;
  }

  //Marketplace Config
  async getMarketplaceConfig() {
    var response = await this.client.get(`/cursus/grabTableTop_GetMarketplaceConfiguration?marketplaceId=${window.marketplaceId}`);
    return response.data;
  }

  //Store Inventory
  async getTableTopData() {
    var response = await this.client.get(`/cursus/grabMobile_StoreInventoryV2?waypointId=${window.waypointID}`);
    return response.data;
  }

  // airport information
  async getAirportInformation(payload) {
    // const response = await this.client.get(`/cursus/Cursus_GetAirportInformationByAirportIdentV3?airportIdent=${payload.airportCode}`);
    const response = await this.client.get(`/cursus/Cursus_GetAirportInformation?airportIdent=${payload.airportCode}`);
    return response.data;
  }

  // validate promotion
  async validatePromotion(payload) {
    const response = await this.client.get(
      `/cursus/Cursus_ValidatePromotionV2?promotioncode=${payload.promotionCode}&email=${payload.email}&storewaypointid=${payload.storeWaypointID}&isappliedtoaccount=${
        payload.isAppliedToAccount
      }&isdelivery=${payload.isDeliveryOrder}`
    );
    return response.data;
  }

  // delivery location information
  async getDeliveryLocations(payload) {
    console.log('delivery locs', payload);
    const response = await this.client.get(`/cursus/Cursus_PartnerDirect_Delivery_GetDeliveryLocationsByTerminal?airportIdent=${payload.airportCode}&terminalId=${payload.terminalId || 0}`);
    return response.data;
  }

  // order status information
  async getOrderStatus(payload) {
    const response = await this.client.get(`/cursus/Cursus_GetOrderHistoryByOrderArrayV3?kobp=${payload.kobp}&arrOrderIDs=${payload.orderId}`);
    return response.data;
  }

  // save rating and review
  async saveRatingReview(payload) {
    const response = await this.client.get(
      `/cursus/Cursus_SaveOrderStarRating?email=${payload.email}&orderID=${payload.orderId}&storeWaypointID=${payload.waypointId}&storeRating=${payload.rating}&storeComment=${payload.review}&kobp=${
        payload.kobp
      }`
    );
    return response.data;
  }

  //Cart
  async postToCart(payload, timeout = 0) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log('Calculating Order Cost from Server...');
    }

    const payloadWithLanguage = {
      ...payload,
      languageCode: window.language,
      platformType: window.platformType,
    };

    //This timeout will cause spinner to be shown for the async requests.
    var response = await this.client.post('/cursus/Cursus_GetShoppingCartTaxFee', payloadWithLanguage, { timeout: timeout });
    return response.data;
  }

  async saveCart(payload) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log('Saving Order (or Closing Tab)...');
    }

    const payloadWithLanguage = {
      ...payload,
      languageCode: window.language,
      lang: window.language,
      xp: window.xpID,
      gid: window.gID,
      platformType: window.platformType,
    };

    if (payload.paymentProvider && payload.paymentProvider.toLowerCase() === 'nets') {
      var response = await this.client.post('/cursus/Cursus_SaveResponsiveWebCheckoutOrderV3', payloadWithLanguage);
      return response.data;
    } else {
      var response = await this.client
        .post('/cursus/Cursus_SaveResponsiveWebCheckoutOrderV3', payloadWithLanguage)
        .then((resp) => {
          // console.log('Cursus_SaveResponsiveWebCheckoutOrderV3 response', resp.data)
          return resp;
        })
        .catch((err) => {
          err.message = 'Web Checkout Order V3 error';

          const attachmentUrlFromDsn = (dsn, eventId) => {
            const { host, path, projectId, port, protocol, user } = dsn;
            return `${protocol}://${host}${port !== '' ? `:${port}` : ''}${
              path !== '' ? `/${path}` : ''
            }/api/${projectId}/events/${eventId}/attachments/?sentry_key=${user}&sentry_version=7&sentry_client=custom-javascript`;
          };

          Sentry.captureException(err, (scope) => {
            scope.setTag('error-type', 'checkout-error');
            scope.setTag('store', window.waypointID);
            scope.addEventProcessor((event) => {
              try {
                const client = Sentry.getCurrentHub().getClient();
                const endpoint = attachmentUrlFromDsn(client.getDsn(), event.event_id);

                const responseData = new FormData();
                responseData.append(
                  'attachment-response',
                  new Blob([JSON.stringify(err.response)], {
                    type: 'application/json',
                  }),
                  'error-response.json'
                );
                fetch(endpoint, {
                  method: 'POST',
                  body: responseData,
                }).catch((ex) => {
                  // we have to catch this otherwise it throws an infinite loop in Sentry
                  console.error(ex);
                });

                const reqeustData = new FormData();
                reqeustData.append(
                  'attachment-request',
                  new Blob([err.response.config.data], {
                    type: 'application/json',
                  }),
                  'error-request.json'
                );
                fetch(endpoint, {
                  method: 'POST',
                  body: reqeustData,
                }).catch((ex) => {
                  // we have to catch this otherwise it throws an infinite loop in Sentry
                  console.error(ex);
                });
                return event;
              } catch (ex) {
                console.error(ex);
              }
            });
          });

          const qs = {
            params: { token: payload.cartToken, waypointId: window.waypointID },
          };
          const dupresponse = this.client
            .get('/orders/Order_CheckForDuplicate', qs)
            .then((r) => {
              if (!r.data || r.data === '') return err;
              return r;
            })
            .catch((e) => {
              return e;
            });
          return dupresponse;
        });
      //var response = await this.client.post('Cursus_SaveKioskOrderV3', payload);
      return response.data;
    }
  }

  //async getAdyenPaymentMethods(payload) {
  //    const response = await this.client.post('/payment/adyen_paymentmethods', payload);
  //    return response.data;
  //}

  async getPenniesCalculation(payload) {
    console.log('getting pennies calculation');
    var response = await this.client.post('/donations/Donation_PenniesCalculation', payload);
    return response.data;
  }

  async applyPartial(payload) {
    console.log('applying partial payment');
    const payloadWithLanguage = {
      ...payload,
      languageCode: window.language,
      platformType: window.platformType,
    };

    // const oclient = axios.create({ baseURL: '/o-api' });
    // var response = await oclient.post('/Order_ApplyPartialPayment', payloadWithLanguage);
    var response = await this.client.post('/orders/Order_ApplyPartialPayment', payloadWithLanguage);
    return response.data;
  }

  async getCartByToken(payload) {
    const qs = {
      params: payload,
    };
    var response = await this.client.get('/cursus/Cursus_GetCartByToken', qs);
    return response.data || null;
  }

  //Freedom Pay
  async getFreedomPayForm(payload) {
    payload.waypointid = window.waypointID;
    payload.platformID = 3;

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log('Generating Freedom Pay iframe urls...');
    }

    var qs = {
      params: payload,
    };
    var response = await this.client.get('/cursus/Cursus_PaymentProvider_GetFreedomPayForm', qs);
    return response.data;
  }

  //Spreedly
  async getSpreedlyPayForm(payload) {
    payload.waypointid = window.waypointID;
    payload.platformID = 3;

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log('Generating Spreedly data...');
    }

    var qs = {
      params: payload,
    };
    var response = await this.client.get('/cursus/Cursus_PaymentProvider_GetSpreedlyPayForm', qs);
    return response.data;
  }

  async sendSpreedlyComplete(payload) {
    payload.waypointid = window.waypointID;
    //payload.platformID = 3;

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log('Sending Spreedly complete...');
    }

    var qs = {
      params: payload,
    };
    var response = await this.client.get('/payment/Cursus_SpreedlyTransactionComplete', qs);
    return response.data;
  }

  //Nets
  async getNetsPaymentID(payload) {
    payload.waypointid = window.waypointID;
    payload.platformID = 3;

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log('Generating Nets data...');
    }

    const payloadWithLanguage = {
      ...payload,
      languageCode: window.language,
    };

    var response = await this.client.post(`/payment/Payment_GetNetsPaymentID?waypointId=${window.waypointID}`, payloadWithLanguage);
    return response.data;
  }

  // adyen
  async getAdyenPaymentMethods(payload) {
    var qs = {
      params: payload,
    };
    payload.waypointid = window.waypointID;
    const response = await this.client
      .post(`payment/Adyen_PaymentMethods?waypointId=${window.waypointID}`, payload)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        //todo: add catch info
        return { data: 'error' };
      });
    return response.data;
  }

  // grecaptcha
  async verifyGrecaptcha(token) {
    const response = await this.client
      .post('payment/greCaptchaVerify', token)
      .then((resp) => {
        console.log('recaptcha response', resp.data);
        if (resp && resp.data && resp.data.success && resp.data.Score > 0.5) {
          return {
            success: true,
              error: false,
              recaptchaGuid: resp.data.value
          };
        } else {
          return {
            success: false,
              error: false,
              recaptchaGuid: ''
          };
        }
      })
      .catch((err) => {
        return {
          success: false,
            error: true,
            recaptchaGuid: ''
        };
      });
    return response;
  }

  //Receipts
  async sendOrderReceipt(payload) {
    var qs = {
      params: payload,
    };
    var response = await this.client.get('/cursus/Cursus_SendCustomerOrderConfirmationToEmail', qs);
    return response.data;
  }

  async sendOrderTextReceipt(payload) {
    payload.storeWaypointID = window.waypointID;

    var qs = {
      params: payload,
    };
    var response = await this.client.get('/cursus/Cursus_TableTop_GetTextReceipt', qs);
    return response.data;
  }

  //Table Check
  async tableCheck(payload) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log('Completing Table Check...');
    }

    payload.wayPointID = window.waypointID;
    payload.posID = window.posID;

    var qs = {
      params: payload,
    };

    var response = await this.client.get('/cursus/Cursus_TableTop_TableCheck', qs);
    return response.data;
  }

  //Customer
  async createCustomerId(payload) {
    var qs = {
      params: payload,
    };

    var response = await this.client.get('/cursus/Cursus_Track_SaveCreateCustomerID', qs);
    return response.data;
  }

  async trackByCustomerID(payload) {
    var response = await this.client.post('/cursus/Cursus_Track_SaveTrackByCustomerID', payload);
    return response.data;
  }

  //Tabs
  async closeTab(payload) {
    var qs = {
      params: payload,
    };

    var response = await this.client.get('/cursus/CursusAdmin_CloseOmnivoreTicket', qs);
    return response.data;
  }

  async authorizeTab(payload) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log('Verifying Tab...');
    }

    var qs = {
      params: payload,
    };

    var response = await this.client.get('/cursus/Cursus_TableTop_TabVerify', qs);

    return response.data;
  }

  //Survey/Ratings
  async saveOrderRatings(payload) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log('Saving Order Ratings...');
    }

    var response = await this.client.post('/cursus/Cursus_TableTop_SaveOrderRatings', payload);
    return response.data;
  }

  //PreOrder Questionnaire
  async getPreOrderQuestionnaireData() {
    var qs = {
      params: {
        waypointid: window.waypointID,
        category: 'PREORDER',
        contentType: 'Q',
      },
    };

    var response = await this.client.get('/cursus/Cursus_FindContentByWaypointID', qs);
    return response.data;
  }

  async saveQuestionnaireAnswer(payload) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log('Saving Questionnaire Answer...');
    }

    const payloadWithAdditionalData = {
      ...payload,
      languageCode: window.language,
      waypointid: window.waypointID,
      kobp: window.kobp,
    };

    var response = await this.client.post('/cursus/Cursus_SaveContentAnswerV2', payloadWithAdditionalData);
    return response.data;
  }

  async saveQuestionnaireOrderInfo(payload) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log('Saving Questionnaire Order Info...');
    }

    var qs = {
      params: payload,
    };

    var response = await this.client.get('/cursus/Cursus_UpdateContentAnswerOrderInfo', qs);
    return response.data;
  }

  //Loaded value account balance request
  async loadedValueAccountBalanceCheck(payload) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log('Completing Table Check...');
    }

    payload.waypointID = window.waypointID;
    payload.posID = window.posID;

    var qs = {
      params: payload,
    };

    var response = await this.client.get('/cursus/Cursus_TableTop_LoadedAccountBalanceCheck', qs);
    return response.data;
  }

  //Loyalty
  async loyaltyLogin(payload) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log('Loyalty Login...');
    }

    payload.posID = window.posID;

    var qs = {
      params: payload,
    };

    var response = await this.client.get('/cursus/Cursus_Loyalty_BalanceCheck', qs);

    return response.data;
  }

  async loyaltyRegister(payload) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log('Loyalty Register...');
    }

    payload.posID = window.posID;

    var qs = {
      params: payload,
    };

    var response = await this.client.get('/cursus/Cursus_Loyalty_Enroll', qs);

    return response.data;
  }
}

export default new Api(client);
