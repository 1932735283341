/**
 * The external dependencies.
 */
import { createAction } from 'redux-actions';

export const loadCustomer = createAction('customer/load-customer');

export const startCreateCustomer = createAction('customer/start-create-customer');

export const updateCustomer = createAction('customer/update-customer');

export const peopleAlsoAddedView = createAction('customer/people-also-added-view');

export const peopleAlsoAddedAddition = createAction('customer/people-also-added-addition');

export const peopleAlsoAddedCancel = createAction('customer/people-also-added-addition-cancel');

export const canWeTemptYouView = createAction('customer/can-we-tempt-you-view');

export const canWeTemptYouAddition = createAction('customer/can-we-tempt-you-addition');