/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**
 * @ The internal dependencies.
 */
import { hasTabOpenAndAuthorized } from '../../store/state/tab/selectors'
import { openModal } from 'store/state/ui/actions';
import { MODAL_TAB_VERIFICATION } from 'lib/constants';
import { clearTableCheck } from '../../store/state/tablecheck/actions';
import { startCartUpdate } from '../../store/state/cart/actions';

/**
 * Class for header.
 *
 * @class Header (name)
 */
class TabOpenHeader extends Component {
	handleViewTab = (e) => {
        e.preventDefault();

        if (this.props.hasTabOpenAndAuthorized) {
            this.props.history.push(`/tab/view`);
        }
        else {
            this.props.openModal({
                type: MODAL_TAB_VERIFICATION,
                data: {
                    onAuthorized: () => {
                        this.props.history.push('/tab/view');
                    },
                    onNewOrder: () => {
                        this.props.history.push('/categories');
                        this.props.clearTableCheck();
                        this.props.startCartUpdate();
                    }
                }
            });
        }
	}

    render() {
		return (
			<header className="tab-open-header">
				<div className="shell">
                    <div className="tab-open-header__content">
						<div
                            className="tab-open-header__tab-open">
                            {window.resources.tab_open_header.message_text}
                        </div>

                        <a
                            onClick={this.handleViewTab}
                            className="tab-open-header__view-tab">
                            {window.resources.tab_open_header.view_tab_text}
						</a>
					</div>
				</div>
			</header>
		);
	}
}

export default withRouter(connect(
    (state) => ({
        tableNumber: state.checkout.tableNumber,
        hasTabOpenAndAuthorized: hasTabOpenAndAuthorized(state),
        currencySymbol: state.app.currencySymbol,
        cartPricing: state.cart.cartPricingData
    }),
    {
        openModal,
        clearTableCheck,
        startCartUpdate
    }
)(TabOpenHeader));
