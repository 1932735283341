import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';
import EastIcon from '@mui/icons-material/East';

import AlertIcon from '../../../assets/svg/marketplace/attention.svg';
import IconSvg from '../../common/icon-svg';

const DeliveryInfo = (props) => {
    const history = useHistory()
    const { locationTitle, location, timeTitle, time, showStatus, deliveryType, orderId, isCancelled } = props;

    const handleViewStatusClick = (orderId) => {
        history.push(`/marketplace/${window.marketplaceId}/${window.waypointID}/order-status/${orderId}/${window.kobp}`)
    }

    return (
        <div className='shell shell--no-padding'>
            <div className='marketplace-section-container'>
                <div className='deliver-info-section'>
                    { showStatus && orderId ? 
                        <div className='label-status'>
                            <div className='label'>{locationTitle}</div>
                            <div className='status' onClick={() => handleViewStatusClick(orderId)}><div>{window.resources.marketplace.delivery_pickup_info.view_status_text}</div><EastIcon fontSize='small' /></div>
                        </div> :
                        <div className='label'>{locationTitle}</div>
                    }
                    <div className='info'>{location}</div>
                </div>
                { isCancelled ? 
                    <div className='deliver-info-section deliver-info-section-cancel'>
                        {window.resources.marketplace.delivery_pickup_info.cancelled_message_text}
                    </div>
                    :
                    <div className='deliver-info-section'>
                        <div className='label'>{timeTitle}</div>
                        <div className='info'>{time}</div>
                    </div>
                }
                { deliveryType && deliveryType !== 'pickup' ?
                    <React.Fragment>
                        <div className='deliver-info-section line-break'></div>
                        <div className='deliver-info-section'>
                            <div className='delivery-alert'>
                                <IconSvg src={AlertIcon} />
                                <div>{window.resources.marketplace.delivery_pickup_info.delivery_attention_title}</div>
                            </div>
                            <Typography className='delivery-alert-info' id='delivery-alert-info' component='div'>
                                {window.resources.marketplace.delivery_pickup_info.delivery_attention_text}
                            </Typography>
                        </div>
                    </React.Fragment> : null
                }
            </div>
        </div>
    )
}

export default DeliveryInfo;