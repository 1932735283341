import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import PropTypes from 'prop-types';

import { requestAdyenPaymentMethods } from '../../store/state/adyen/actions';
import { getAdyenCardDetails } from './payment-helper';
import api from '../../lib/api';
import { loadDynamicScript } from '../../lib/utility';
import { updateRecaptchaGuid} from '../../store/state/cart/actions';

const AdyenDropIn = ({ amount, currencyCode, countryCode, handlePaymentMethod }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);
  const configuration = useSelector((state) => state.adyen.configuration);
  const checkout = useSelector((state) => state.checkout);
  const storeConfiguration = useSelector((state) => state.app.config);

  useEffect(() => {
    loadDynamicScript('recaptcha', `https://www.google.com/recaptcha/api.js?render=${storeConfiguration.RecaptchaKey}`, null, null);

    // get adyen payment methods
    dispatch(
      requestAdyenPaymentMethods({
        waypointid: window.waypointID,
        currencyCode: currencyCode,
        totalAmount: amount * 100,
        countryCode: countryCode,
        languageCode: `${window.language}-${countryCode}`,
      })
    );
  }, []);
  useEffect(
    () => {
      if (urlParams) {
        const redirect = urlParams.get('redirectResult');
        if (redirect && checkout && checkout.adyenSession) {
          const session = { ...checkout.adyenSession };
          session.AdyenRequestPayload = { details: { redirectResult: redirect } };
          session.AdyenResponsePayload = '';

          handlePaymentMethod(getAdyenCardDetails(session));
          window.isMarketplace ? history.push(`/marketplace/${window.marketplaceId}/${window.waypointID}/checkout-complete`) : history.push('/checkout-complete');
        }
      }
    },
    [urlParams]
  );

  return (
    <div className='adyen-dropin-container'>
          {!configuration ? (
              <div />
          ) : (
              <Checkout
          configuration={configuration}
          amount={amount}
          currencyCode={currencyCode}
          countryCode={countryCode}
          handlePaymentMethod={handlePaymentMethod}
          adyenSession={checkout && checkout.adyenSession}
                  />
          )}
          <div className='customer-detail-disclaimer' style={{ marginTop: '5px', display: 'flex', justifyContent: 'center' }} ><span>This site is protected by reCAPTCHA and the Google&nbsp;<a target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a>&nbsp;and&nbsp;<a target="_blank" href="https://policies.google.com/terms">Terms of Service</a>&nbsp;apply.</span>
          </div>
    </div>
  );
};
const Checkout = ({ configuration, amount, currencyCode, countryCode, handlePaymentMethod, adyenSession }) => {
  const history = useHistory();
  const paymentContainer = useRef(null);

  const storeConfiguration = useSelector((state) => state.app.config);
  const dispatch = useDispatch();

  const processPayment = (state, dropin, fixedAmount, recaptchaGuid) => {
    dropin.setStatusAutomatically = true;
    state.data.amount = { currency: currencyCode, value: fixedAmount };
    state.data.reference = '';
    state.data.additionalData = { allow3DS2: true };
    state.data.returnUrl = window.location.href;

    const session = {
      AdyenRequestPayload: state.data,
      AdyenResponsePayload: '',
      Reference: '',
    };
    handlePaymentMethod(getAdyenCardDetails(session));
  };

  useEffect(
    () => {
      if (configuration) {
        const createCheckout = async () => {
          const fixedAmount = Math.round(amount * 100);
          const applePayConfiguration = {
            amount: { value: fixedAmount, currency: currencyCode },
            countryCode: countryCode,
          };

          const checkout = await AdyenCheckout({
            ...configuration,
            setStatusAutomatically: false,
            onSubmit: (state, dropin) => {
              dropin.setStatus('loading');

              if (!storeConfiguration.RecaptchaEnabled) {
                if (state.data) processPayment(state, dropin, fixedAmount);
              } else {
                window.grecaptcha.ready(function() {
                  window.grecaptcha.execute(storeConfiguration.RecaptchaKey, { action: 'paymentSubmit' }).then(function(token) {
                    api.verifyGrecaptcha(token).then((info) => {
                        if (state.data && info && info.success && !info.error)
                        {
                            dispatch(updateRecaptchaGuid(info.recaptchaGuid));
                            processPayment(state, dropin, fixedAmount, info.recaptchaGuid);
                        }
                        else {
                            console.warn('Failed reCaptcha verificatiion');
                            alert('Failed reCaptcha verificatiion');
                            dropin.setStatus('ready');
                        }
                    });
                  });
                });
              }
            },
            onAdditionalDetails: (state, dropin) => {
              console.log('adyen additional details state', state);
              const session = {
                AdyenRequestPayload: state.data,
                AdyenResponsePayload: '',
                Reference: adyenSession.Reference,
              };
              if (state.data) handlePaymentMethod(getAdyenCardDetails(session));
            },
            paymentMethodsConfiguration: {
              applepay: applePayConfiguration,
            },
          });

          if (paymentContainer.current) {
            const dropin = checkout.create('dropin').mount(paymentContainer.current);

            if (adyenSession && adyenSession.AdyenResponsePayload && adyenSession.AdyenResponsePayload) {
              try {
                const result = JSON.parse(adyenSession.AdyenResponsePayload);
                if (result) {
                  if (result.errorCode) dropin.setStatus('error', { message: result.message });
                  else {
                    if (result.resultCode.toLowerCase() === 'authorised')
                      history.push(window.isMarketplace ? `/marketplace/${window.marketplaceId}/${window.waypointID}/checkout-complete` : '/checkout-complete');
                    if (result.resultCode.toLowerCase() === 'refused') dropin.setStatus('error', { message: result.refusalReason });
                    else if (result.action) dropin.handleAction(result.action);
                  }
                }
              } catch (e) {
                dropin.setStatus('error', { message: 'Something went wrong.' });
              }
            }
          }
        };
        createCheckout();
      }
    },
    [adyenSession]
  );
  // console.log('recaptcha verify info', recaptchaVerifyInfo);
  return <div className='adyen-payment-container' ref={paymentContainer} />;
};

AdyenDropIn.propTypes = {
  amount: PropTypes.number,
  currencyCode: PropTypes.string,
  countryCode: PropTypes.string,
};

export default AdyenDropIn;
