/**
 * @ The external dependecies.
 */
import React from 'react';
import PropTypes, { instanceOf } from 'prop-types';
import { ReactSVG } from 'react-svg';

const IconSvg = (props) => {
    return <ReactSVG afterInjection={(error, svg) => {
        if (props.title) {
            var svgType = typeof (svg);

            // If there is no SVG to load, this fails.  So adding object check
            if (svgType == "object") {
                var existingTitle = svg.querySelector('title');
                if (existingTitle) {
                    existingTitle.parentElement.removeChild(existingTitle);
                }

                var title = document.createElementNS("http://www.w3.org/2000/svg", "title");
                title.innerHTML = props.title;
                svg.appendChild(title);
            }
        }

        if (error && props.onError) {
            console.log(error)
            props.onError();
        }
    }} src={props.src} className={"svg-icon " + (props.className || "")} />
}

/**
 * Define the interface of the component.
 *
 * @type {Object}
 */
IconSvg.propTypes = {
	src: PropTypes.string.isRequired
};

export default IconSvg;