/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalInput from './modal-input'

/**
 * @ The internal dependencies.
 */
import { closeModal } from 'store/state/ui/actions';
import { startCartUpdate, updateName } from 'store/state/cart/actions';
import { sendTableCheckRequest, setDineInTakeAwaySelected } from 'store/state/tablecheck/actions';
import { updateObject, checkValidity } from '../../lib/utility';
import * as features from '../../store/state/features/selectors'
import { loyaltyLoggedIn } from '../../store/state/loyalty/selectors';

/**
 * Class for modal table number.
 *
 * @class ModalTableNumber (name)
 */
class ModalDineIn extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            form: {
                tableNumber: {
                    value: this.props.validTableNumber == true && this.props.urlTableNumber != null && this.props.urlTableNumber != undefined ? this.props.urlTableNumber : '',
                    validation: {
                        required: true
                        //isNumber: true
                    },
                    valid: this.props.validTableNumber == true && this.props.urlTableNumber != null && this.props.urlTableNumber != undefined,
                    touched: this.props.validTableNumber == true && this.props.urlTableNumber != null && this.props.urlTableNumber != undefined
                },
                name: {
                    value: '',
                    validation: {
                        required: this.props.nameRequired && !this.props.nameOptional,
                        isString: true
                    },
                    valid: !this.props.nameRequired,
                    touched: !this.props.nameRequired,
                }
            },
            formIsValid: (this.props.validTableNumber) && (!this.props.nameRequired)
        }
    }

    componentDidMount() {
        if (this.props.loyaltyLoggedIn && this.props.name) {
            this.handleInputChange({ target: { value: this.props.name } }, 'name');
        }
    }

    checkValidityCustom(value, rules) {
        let isValid = checkValidity(value, rules);

        return isValid;
    }

    handleInputChange = (event, field) => {
        const updatedFormElement = updateObject(this.state.form[field], {
            value: event.target.value,
            valid: this.checkValidityCustom(event.target.value, this.state.form[field].validation),
            touched: true
        });

        const updatedForm = updateObject(this.state.form, {
            [field]: updatedFormElement
        });

        let formIsValid = true;
        for (let field in updatedForm) {
            formIsValid = updatedForm[field].valid && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid: formIsValid });
    }

    handleSubmitButton = (e) => {
        e.preventDefault();

        this.props.sendTableCheckRequest({
            showSpinner: true,
            getOpenTickets: this.props.tabFeatureEnabled,
            tableNumber: this.state.form.tableNumber.value
        });

        if (this.props.nameRequired || this.props.nameOptional) {
            this.props.updateName(this.state.form.name.value);
        }
        this.props.setDineInTakeAwaySelected(true)
        this.props.closeModal();
    }

    render() {
        return (
            <div className="modal-frame modal-frame--card">
                <div className="modal-frame__body">
                    <div className="modal-frame__card">
                        <div className="modal-frame__head">
                            <h1 className="modal-frame__title">{window.resources.home.dinein.toLocaleUpperCase()}</h1>
                            {(this.props.nameRequired || this.props.nameOptional) && <h2 className='modal-frame__sub-title'>{window.resources.home.dinein_instructions_name}</h2>}
                            {!this.props.nameRequired && !this.props.nameOptional && <h2 className='modal-frame__sub-title'>{window.resources.home.dinein_instructions}</h2>}
                        </div>
                        <ModalInput touched={this.state.form.tableNumber.touched} invalid={!this.state.form.tableNumber.valid} placeholder={window.resources.table_number.placeholder_text} type={this.state.form.tableNumber.value} value={this.state.form.tableNumber.value} onChange={(event) => this.handleInputChange(event, 'tableNumber')} onKeyUp={(event) => this.handleInputChange(event, 'tableNumber')} />
                        {this.props.nameRequired && <ModalInput touched={this.state.form.name.touched} invalid={!this.state.form.name.valid} placeholder={window.resources.home.your_name} type="string" value={this.state.form.name.value} onChange={(event) => this.handleInputChange(event, 'name')} onKeyUp={(event) => this.handleInputChange(event, 'name')} />}
                        {!this.props.nameRequired && this.props.nameOptional && <ModalInput touched={this.state.form.name.touched} invalid={!this.state.form.name.valid} placeholder={window.resources.home.your_name_optional} type="string" value={this.state.form.name.value} onChange={(event) => this.handleInputChange(event, 'name')} onKeyUp={(event) => this.handleInputChange(event, 'name')} />}

                        {/*<ModalInput touched={this.state.form.tableNumber.touched} invalid={!this.state.form.tableNumber.valid} label={label} placeholder="Table number" value={this.state.form.tableNumber.value} onChange={(event) => this.handleInputChange(event, 'tableNumber')} onKeyUp={(event) => this.handleInputChange(event, 'tableNumber')} /> */}
                        <div className="modal-frame__actions modal-frame__actions--flex">
                            <button
                                onClick={() => this.props.closeModal()}
                                className="btn btn--secondary">{window.resources.table_number.cancel_text}</button>
                            <button
                                disabled={!this.state.formIsValid}
                                onClick={this.handleSubmitButton}
                                className="btn">{window.resources.table_number.confirm_text}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tabFeatureEnabled: features.tabFeatureEnabled(state),
        nameRequired: features.dineInNameRequired(state),
        validTableNumber: state.tablecheck.validTableNumber,
        urlTableNumber: state.tablecheck.urlTableNumber,
        nameOptional: features.dineInNameOptional(state),
        loyaltyLoggedIn: loyaltyLoggedIn(state),
        name: state.loyalty.name,
        inputType: "number"//features.tableTentOverride(state) ? "text" : "number"
    };
}

export default withRouter(connect(
    mapStateToProps,
    {
        closeModal,
        sendTableCheckRequest,
        startCartUpdate,
        updateName,
        setDineInTakeAwaySelected
    }
)(ModalDineIn));
