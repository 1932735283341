/**
 * @ The external dependencies.
 */
import {
    all,
    fork,
    cancel,
	put,
	call,
    takeLatest,
    takeEvery,
    select
} from 'redux-saga/effects';

import {
    delay
} from 'redux-saga';

/**
 * @ The internal dependencies.
 */
import config from 'config';
import { showSuggestionsModal } from '../app/actions';
import * as Analytics from '../../../lib/analytics-ga4';
import { getBlankCardDetails } from '../../../components/payment/payment-helper';

import { redirectToError }
    from '../ui/actions'

import {
	addToCart,
	removeFromCart,
	reciveCartData,
    startRemoveFromCart,
    startDeliveryUpdate, 
    deliveryUpdate, 
    startDonationAmountUpdate, 
    donationAmountUpdate, 
    startPromotionUpdate, 
    promotionUpdate, 
    startTipAmountUpdate,
    startTipPercentageUpdate,
    tipAmountUpdate,
    tipPercentageUpdate,
    startCartUpdate,
    cartUpdateInitiated,
    cartUpdateCompleted,
    startAddToCart,
    startCartSave,
    receiveOrderConfirmationData,
    cartSaveCompleted,
    updateEmployeeID
} from './actions';

import { sendTableCheckRequest }
    from '../tablecheck/actions'

import { sendPreOrderQuestionnaireOrderInfo }
    from '../questionnaire/actions';

import { clearSurvey }
    from '../survey/actions'
import { reloadCartWithLoadedValueAccountNumber, addLoadedValueAccountNumberToCart, removeLoadedValueAccountNumberFromCart } from '../loadedvalueaccount/actions'

import { getSelectedItems, retryCartUpdate } from './selectors';
import makeRequest from 'lib/helpers/api-request';

import { getTabId, hasTabOpenAndAuthorized } from '../tab/selectors'
import { tabFeatureEnabled } from '../features/selectors'

export function* saveCartRequest(action) {
    yield put(clearSurvey());

    let { cart, loyalty } = yield select();

    let tabId = '';
    if (!action.payload.newTab) {
        tabId = ((yield select(hasTabOpenAndAuthorized)) || action.payload.clearTab) ? (yield select(getTabId)) : '';
    }

    let tableNumber = (yield select()).checkout.tableNumber;
    let cartWithPaymentDetails = {
        ...cart.data,
        loyaltyIdentifier: loyalty.accountNumber
    };

    if (cart.data.loadedValueAccountNumber !== null && cart.data.loadedValueAccountNumber !== undefined && cart.data.loadedValueAccountNumber.length > 0) {
        cartWithPaymentDetails = {
            ...cartWithPaymentDetails,
            bLoyaltyTransaction: true,
            loyaltyAccountNumber: cart.data.loadedValueAccountNumber
        }
    }

    //Only want to send tabId if authorized and has tabId.
    if (tabId || action.payload.newTab) {
        cartWithPaymentDetails = {
            ...cartWithPaymentDetails,
            openCheckTicketID: tabId,
            bOpenCheck: action.payload.createOrUpdateTab
        };
    }

    if (action.payload.isPartial || action.payload.isPartialTabOwner) {
        cartWithPaymentDetails.OrderId = action.payload.orderId;
        cartWithPaymentDetails.POSTicketID = action.payload.posTicketId;
    }
    if (action.payload.isPartialTabOwner) { 
        cartWithPaymentDetails.isPartialTabOwner = action.payload.isPartialTabOwner;
        cartWithPaymentDetails.totalCost = action.payload.totalAmount;
        cartWithPaymentDetails.tipAmount = action.payload.tipAmount || 0;
    }
    if (action.payload.isPartial) {
        cartWithPaymentDetails.urlTableNumber = action.payload.tableNumber;
        cartWithPaymentDetails.orderCostInfo = action.payload.orderCostInfo;
        cartWithPaymentDetails.partialTip = action.payload.partialTip;
        cartWithPaymentDetails.partialTotal = action.payload.partialTotal;
    }
    if (action.payload.payBalance) cartWithPaymentDetails.payBalance = action.payload.payBalance;

    let arrCart = [];
    //When dealing with tabs sometimes don't want to send cart.
    if (action.payload.sendCartItems) {
        arrCart = cart.data.arrCart.map(product => ({
            inventoryItemSubs: product.inventoryItemSubs.filter(x => x.selected).map(item => ({
                inventoryItemSubID: item.inventoryItemSubID,
                inventoryItemSubName: item.inventoryItemSubName,
                selected: item.selected,
                cost: item.cost
            })),
            inventoryItemID: product.inventoryItemID,
            inventoryItemName: product.inventoryItemName,
            quantity: product.quantity,
            specialNotes: "",
            inventoryOrder: product.inventoryOrder,
            inventoryMainOptionChoice: {
                options: getSelectedItems(product.inventoryMainOptionChoice.options, 'option'),
                choices: getSelectedItems(product.inventoryMainOptionChoice.choices, 'choice'),
            },
            comboID: product.comboID,
            comboOrderID: product.comboOrderID,
            comboChoiceGroupID: product.comboChoiceGroupID
        }))
    }

    //When tabs are enabled saving cart = cart save + table check
    yield call(makeRequest, {
        endpoint: action.payload.isPartial ? 'applyPartial' : 'saveCart',

        payload: {
            ...cartWithPaymentDetails,
            arrCart: arrCart, 
            reloadCart: action.payload.reloadCart
        },
        requestAction: action,
        receiveAction: receiveOrderConfirmationData
    });

    //Link Questionnaire sessionID to orderID
    let { checkout, questionnaire } = yield select();
    if (checkout.orderID && questionnaire.sessionID) {
        yield put(sendPreOrderQuestionnaireOrderInfo({
            orderID: checkout.orderID,
            sessionID: questionnaire.sessionID
        }));
    }

    let tabsEnabled = yield select(tabFeatureEnabled);

    //Lets check if the tab is closed by making a table check call.
    if (tabsEnabled) {
        //sendTableCheckRequest to check if tab is still open. posOpenTicketID !== ''
        yield put(sendTableCheckRequest({
            showSpinner: action.payload.showSpinner,
            getOpenTickets: true,
            tableNumber: tableNumber
        }));
    } else {
        yield put(cartSaveCompleted());
    }
}

function* saveCartWorker(action) {
    yield call(saveCartRequest, action);
}

function* spinnerWorker(delayPeriod) {
    yield delay(delayPeriod)
    //starts spinner
    yield put(cartUpdateInitiated({ showSpinner: true }));
}

//Cursus_GetShoppingCartTaxFee
export function* postToCartRequest(action) {
    let tabId = (yield select(hasTabOpenAndAuthorized)) ? (yield select(getTabId)) : '';
   
    //The code below is making the Cart Calculation call resilient with retry logic.
    //The app has a strong dependency on the Server Side Cart Calculation call and needs to show spinner if this call ever fails due to connection dropping.
    //This call is sometimes:
    //a. sync where a spinner is shown(Adding Item) 
    //b. async where a spinner is not shown (Increasing Quantity, Removing Items, Tips, Addon Items).

    let calculationFailed = false;

    const retries = config.RETRIES;
    const delayBetweenRetries = config.RETRY_DELAY;

    //How long before the spinner appears for sync and async flows.
    let spinnerDelay = (action.payload && action.payload.showSpinner) ? 0 : window.resources.spinners.async_delay;
    let spinnerTask = yield fork(spinnerWorker, spinnerDelay);

    const onError = () => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log('Failed calculating Order Cost from Server, Retrying...')
        }
        calculationFailed = true;
    };

    //retries
    for (let i = 0; i < retries; i++) {

        calculationFailed = false;

        let { cart, loyalty } = yield select();

        let cartDetails = {
            ...cart.data,
            loyaltyIdentifier: loyalty.accountNumber
        };

        if (cartDetails.paymentProvider !== 'braintree') {
            cartDetails.clientToken = '';
        }

        if (action.payload && action.payload.initializeTips) {
            cartDetails = {
                ...cartDetails,
                tipAmount: -1.00,
                tipPercentage: -1.000
            };
        }

        //Only want to send tabId if authorized and has tabId.
        if (tabId) {
            cartDetails = {
                ...cartDetails,
                openCheckTicketID: tabId
            };
        }

        yield call(makeRequest, {
            endpoint: 'postToCart',
            payload: {
                ...cartDetails,
                arrCart: cart.data.arrCart.map(product => ({
                    inventoryItemSubs: product.inventoryItemSubs.filter(x => x.selected).map(item => ({
                        inventoryItemSubID: item.inventoryItemSubID,
                        inventoryItemSubName: item.inventoryItemSubName,
                        selected: item.selected,
                        cost: item.cost
                    })),
                    inventoryItemID: product.inventoryItemID,
                    inventoryItemName: product.inventoryItemName,
                    quantity: product.quantity,
                    specialNotes: "",
                    inventoryOrder: product.inventoryOrder,
                    inventoryMainOptionChoice: {
                        options: getSelectedItems(product.inventoryMainOptionChoice.options, 'option'),
                        choices: getSelectedItems(product.inventoryMainOptionChoice.choices, 'choice'),
                    },
                    comboID: product.comboID,
                    comboOrderID: product.comboOrderID,
                    comboChoiceGroupID: product.comboChoiceGroupID
                }))
            },
            requestAction: action,
            receiveAction: reciveCartData,
            onError: onError,
            timeout: config.TIMEOUT
        });

        if (!calculationFailed) {
            calculationFailed = (yield select(retryCartUpdate));
            if (calculationFailed) {
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log('Recalculating Order Cost, Retrying...')
                }
            }
        }

        if (calculationFailed && i < retries) {
            yield delay(delayBetweenRetries);
        }
        else {
            //exit retry loop
            break;
        }
    }

    //If the spinner hasn't started yet cancel it. The async calls will generally finish within 5 seconds and no spinner will be seen.
    if (spinnerTask) {
        yield cancel(spinnerTask)
    }

    if (calculationFailed) {
        yield put(redirectToError());

        //stops spinner
        yield put(cartUpdateCompleted({
            ...action.payload,
            showSpinner: true
        }));
    }
    else {
        //stops spinner
        yield put(cartUpdateCompleted({
            ...action.payload,
            showSpinner : true
        }));
    }
}

//Processes Cart Calculation in a queue one at a time
function* postToCartWorker(action) {
    yield call(postToCartRequest, action);
}

function* updateDeliveryWorker(action) {
    //update UI
    yield put(deliveryUpdate(action.payload));
    //call server
    yield put(startCartUpdate({ showSpinner: true }));
}

function* updateDonationAmountWorker(action) {
    //update UI
    yield put(donationAmountUpdate(action.payload));
    //call server
    yield put(startCartUpdate({ showSpinner: true }));
}

function* updatePromotionWorker(action) {
    //update UI
    yield put(promotionUpdate(action.payload));
    //call server
    yield put(startCartUpdate({ showSpinner: true }));
}

function* updateTipAmountWorker(action) {
    //update UI
    yield put(tipAmountUpdate(action.payload));
    //call server
    yield put(startCartUpdate({ showSpinner: true }));
}

function* updateTipPercentageWorker(action) {
    //update UI
    yield put(tipPercentageUpdate(action.payload));
    //call server
    yield put(startCartUpdate({ showSpinner: true }));
}

function* updateEmployeeIDWorker(action) {
    //call server
    yield put(startCartUpdate());
}

/**
 * Start add to cart network.
 *
 * @param {Object}
 * @return {Generator}
 */
function* addToCartWorker(action) {
    yield put(addToCart(action.payload));

    let showSpinner = false;
    if (action.payload.showSuggestions) {
        showSpinner = true;
        yield put(showSuggestionsModal());
    }

    yield put(startCartUpdate({ showSpinner: showSpinner}));
    if (action.payload.ga) Analytics.logAddToCart(action.payload.products[0], action.payload.type || null, action.payload.currencyCode || null);
}

/**
 * Start add to cart network.
 *
 * @param {Object}
 * @return {Generator}
 */
function* removeFromCartWorker(action) {
    yield put(removeFromCart(action.payload));

    yield put(startCartUpdate());
}

function* reloadCartWithLoadedValueAccountNumberWorker(action) {
    //update UI
    if (action.payload.accountNumber == false) {
        yield put(removeLoadedValueAccountNumberFromCart(action.payload));
    } else {
        yield put(addLoadedValueAccountNumberToCart(action.payload));
    }
    //call server
    yield put(startCartUpdate({ showSpinner: true }));
}

/**
 * Start all workers.
 *
 * @return {Generator}
 */
export default function* foreman() {
    yield all([
		takeEvery(startAddToCart, addToCartWorker),
        takeLatest(startRemoveFromCart, removeFromCartWorker),
        takeLatest(startCartUpdate, postToCartWorker),
        takeLatest(startDeliveryUpdate, updateDeliveryWorker), 
        takeLatest(startDonationAmountUpdate, updateDonationAmountWorker), 
        takeLatest(startPromotionUpdate, updatePromotionWorker), 
        takeLatest(startTipAmountUpdate, updateTipAmountWorker),
        takeLatest(startTipPercentageUpdate, updateTipPercentageWorker),
        takeLatest(startCartSave, saveCartWorker),
        takeLatest(updateEmployeeID, updateEmployeeIDWorker),
        takeLatest(reloadCartWithLoadedValueAccountNumber, reloadCartWithLoadedValueAccountNumberWorker)
	]);
}
