/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**
 * @ The internal dependencies.
 */
import { openModal, closeModal } from 'store/state/ui/actions';
import { startCartUpdate } from 'store/state/cart/actions';
import { tabAuthorization } from 'store/state/tab/actions';
import { hasTabOpenAndAuthorized, getDataForVerificationModal, tabRequiresCCAuthorization } from '../../store/state/tab/selectors';

/**
 * Class for modal table number.
 *
 * @class ModalTableNumber (name)
 */
class ModalTabVerificationAuto extends Component {

    componentDidMount() {
        //Authorization + Cart Update
        this.props.tabAuthorization({
            waypointID: window.waypointID,
            tableName: this.props.tableNumber,
            cardLastFour: this.props.tabRequiresCCAuthorization ? '1111': '',
            authorized: true
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.hasTabOpenAndAuthorized) {
            this.props.closeModal();
            this.props.data.onAuthorized();
        }
    }

    render() {
		return (null);
	}
}

const mapStateToProps = state => {
    return {
        itemCount: getDataForVerificationModal(state).itemCount,
        orderTotalDisplay: getDataForVerificationModal(state).orderTotalDisplay,
        orderDueAmountDisplay: getDataForVerificationModal(state).orderDueAmountDisplay, 
        data: state.ui.modal.data,
        cardLastFour: state.cart.data.cardLastFour,
        tableNumber: state.checkout.tableNumber,
        tabAuthorizing: state.ui.tabAuthorizing,
        hasTabOpenAndAuthorized: hasTabOpenAndAuthorized(state),
        tabRequiresCCAuthorization: tabRequiresCCAuthorization(state)
    };
}

export default withRouter(connect(
    mapStateToProps,
    {
        tabAuthorization,
        openModal,
        closeModal,
        startCartUpdate
	}
)(ModalTabVerificationAuto));
