/**
 * @ The external dependecies.
 */
import React from 'react';
import PropTypes from 'prop-types';

const ApplePayButton = ({ paymentRequest, onLoadPaymentData, paymentSessionUrl, buttonType, buttonStyle, version }) => {

    const { ApplePaySession } = window;

    const handleButtonClick = (e) => {
        e.preventDefault();

        var applePaySession = new ApplePaySession(version, paymentRequest);

        applePaySession.onvalidatemerchant = (event) => {
            var validationData = { ValidationUrl: event.validationURL };

            //https://www.oshyn.com/blog/2017/01/apple-pay-development
            //https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/requesting_an_apple_pay_payment_session
            fetch(paymentSessionUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(validationData)
            })
            .then(response => response.json())
            .then(data => {
                //Validation successful, complete the merchant validation
                applePaySession.completeMerchantValidation(data);
             })
            .catch((error) => {
                //Validation not successful or error occurred
                console.log(error);
                applePaySession.abort();
            });
        }

        applePaySession.onpaymentauthorized = (event) => {
            //Close Apple Pay on device.
            applePaySession.completePayment(ApplePaySession.STATUS_SUCCESS);

            //Send payment token to backend
            onLoadPaymentData(event.payment)
        }

        //Launch Apple Pay on device.
        applePaySession.begin();
    }

    return (
        <React.Fragment>
            <style dangerouslySetInnerHTML={{
        __html: `
				.apple-pay-button { 
				display: inline-block;
				-webkit-appearance: -apple-pay-button;
				-apple-pay-button-type: ${buttonType};
				-apple-pay-button-style: ${buttonStyle};
				cursor: pointer;
				min-width: 152px;
				width: 240px;
				height: 40px;
			}
			`}}></style>
            <div onClick={handleButtonClick} className="apple-pay-button"></ div>
        </React.Fragment>);
}

ApplePayButton.propTypes = {
    paymentRequest: PropTypes.object.isRequired,
    onLoadPaymentData: PropTypes.func.isRequired,
    paymentSessionUrl: PropTypes.string.isRequired,
    buttonType: PropTypes.string,
    buttonStyle: PropTypes.string,
    version: PropTypes.number
}

ApplePayButton.defaultProps = {
    buttonType: 'buy',
    buttonStyle: 'black',
    version: 2
}

export default ApplePayButton;