/**
 * @ The external dependecies
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';

/**
 * @ The internal dependecies.
 */
import ModalSuggestions from 'components/modal/modal-suggestions';
import ModalTip from 'components/modal/modal-tip';
import ModalDiscount from 'components/modal/modal-discount';
import ModalTableNumber from 'components/modal/modal-table-number';
import ModalWarning from 'components/modal/modal-warning';
import ModalReceiptChoice from 'components/modal/modal-receipt-choice';
import ModalReceiptEmail from 'components/modal/modal-receipt-email';
import ModalReceiptText from 'components/modal/modal-receipt-text';
import ModalReceiptSuccess from 'components/modal/modal-receipt-success';
import ModalAllergens from 'components/modal/modal-allergens';
import ModalAlcohol from 'components/modal/modal-alcohol';
import ModalAirlineEmployeeDiscount from 'components/modal/modal-airline-employee-discount';
import ModalTabVerification from 'components/modal/modal-tab-verification';
import ModalTabVerificationAuto from 'components/modal/modal-tab-verification-auto';
import ModalTabNewOrder from 'components/modal/modal-tab-new-order';
import ModalLanguage from 'components/modal/modal-language';
import ModalQuestionnaire from 'components/modal/modal-questionnaire';
import ModalIFrame from 'components/modal/modal-iframe';
import ModalLoadedValueAccountBalanceCheck from 'components/modal/modal-loaded-value-account-balance-check';
import ModalLoadedValueAccountBalanceResponse from 'components/modal/modal-loaded-value-account-balance-response';
import ModalChooseDineInTakeAway from 'components/modal/modal-choose-dinein-takeaway';
import ModalDineIn from 'components/modal/modal-dine-in';
import ModalTakeAway from 'components/modal/modal-takeaway';
import ModalLoyaltyLogin from 'components/modal/modal-loyalty-login';
import ModalLoyaltyAmount from 'components/modal/modal-loyalty-amount';
import ModalLoyaltyLoginSuccess from 'components/modal/modal-loyalty-login-success';
import ModalLoyaltyRegister from 'components/modal/modal-loyalty-register';
import ModalBrowse from 'components/modal/modal-browse';
import ModalCombo from 'components/modal/modal-combo';
import ModalChoosePayType from './modal-pay-type';
import ModalSplitQRCode from './modal-split-qrcode';
import ModalSplitAmountDetails from './modal-split-amount-details';
import ModalDonations from './modal-donations';

import ModalComboUpgradePaths from 'components/modal/modal-combo-upgrade-paths';
/**
 * @ Mount the react modal portal. 
 */
Modal.setAppElement('#root');

/**
 * Class for Modal Manager.
 *
 * @class ModalManager (name)
 */
class ModalManager extends Component {
    lookupModalComponent() {
		const { type } = this.props.modal;
		const { modals } = ModalManager;

		return modals[type];
	}

	render() {
		const { modal } = this.props;

		const ModalComponent = this.lookupModalComponent();

		return (
			<Modal
				className="Modal"
				overlayClassName="Overlay"
				isOpen={modal.isOpen}>
				<div className="ReactModal__Inner">
					{
						ModalComponent
						? <ModalComponent data={modal.data} />
						: <p>MODAL-NOT-FOUND</p>
					}
				</div>
			</Modal>
		)
	}
}

/**
 * Define the supported modals.
 *
 * @type {Object}
 */
ModalManager.modals = {
	ModalSuggestions,
	ModalTip,
	ModalDiscount,
	ModalTableNumber,
    ModalWarning,
    ModalReceiptChoice,
    ModalReceiptEmail,
    ModalReceiptText,
    ModalReceiptSuccess,
    ModalAllergens,
    ModalAlcohol,
    ModalAirlineEmployeeDiscount,
	ModalTabVerification,
	ModalTabVerificationAuto,
    ModalTabNewOrder,
	ModalLanguage,
	ModalQuestionnaire,
	ModalIFrame,
	ModalLoadedValueAccountBalanceCheck,
	ModalLoadedValueAccountBalanceResponse,
	ModalComboUpgradePaths,
	ModalBrowse,
	ModalChooseDineInTakeAway,
	ModalDineIn,
	ModalTakeAway,
	ModalLoyaltyLogin,
	ModalLoyaltyAmount,
	ModalLoyaltyLoginSuccess,
	ModalLoyaltyRegister,
	ModalCombo, 
	ModalChoosePayType, 
	ModalSplitQRCode,
	ModalSplitAmountDetails, 
	ModalDonations
};

export default connect(
	(state) => ({
		modal: state.ui.modal
	})
)(ModalManager);
