let config;


const hostname = window && window.location && window.location.hostname.toLowerCase();

if (hostname.endsWith('orderandpaystage.com')) {
    //staging
    config = {
        REACT_APP_STORE_CSS_URL: 'https://grabmobilestagev2.com/Tabletopassets/store-assets/',
        REACT_APP_STORE_ASSETS_URL: 'https://grabmobilestagev2.com/Tabletopassets/store-assets/',
        REACT_APP_STORE_LANGUAGES_URL: 'https://grabmobilestagev2.com/Tabletopassets/store-assets/',
        REACT_APP_STORE_RESOURCES_URL: 'https://grabmobilestagev2.com/Tabletopassets/store-assets/',
        REACT_APP_MULTI_CONCEPT_ASSETS_URL: 'https://grabmobilestagev2.com/Tabletopassets/multi-concept-assets/',
        REACT_APP_MULTI_CONCEPT_LANGUAGES_URL: 'https://grabmobilestagev2.com/Tabletopassets/multi-concept-assets/',
        REACT_APP_MULTI_CONCEPT_RESOURCES_URL: 'https://grabmobilestagev2.com/Tabletopassets/multi-concept-assets/',
        REACT_APP_LANGUAGES_URL: process.env.PUBLIC_URL + '/languages/',
        REACT_APP_RESOURCES_URL: process.env.PUBLIC_URL + '/resources/',
        REACT_APP_MP_CONFIGS_URL: "https://dev-assets.orderandpay.net" + '/marketplace/',
        REACT_APP_DEFAULT_MP_CONFIGS_URL: process.env.PUBLIC_URL + '/configs/',
        RETRIES: 10,
        RETRY_DELAY: 1000,
        TIMEOUT: 0,
        ENVIRONMENT: 'STAGE',
        SENTRY_ENVIRONMENT: 'STAGE',
        SENTRY_ENABLED: true
    };
}
 else if (hostname.includes('localhost') || hostname.includes('dev.'))
 {
    // Localhost/DEV
    config = {
        // REACT_APP_STORE_CSS_URL: "https://test-assets.orderandpay.net" + '/store-assets/',
        // REACT_APP_STORE_ASSETS_URL: "https://test-assets.orderandpay.net" + '/store-assets/',
        // REACT_APP_STORE_LANGUAGES_URL: "https://test-assets.orderandpay.net" + '/store-assets/',
        // REACT_APP_STORE_RESOURCES_URL: "https://test-assets.orderandpay.net" + '/store-assets/',
        // REACT_APP_MULTI_CONCEPT_ASSETS_URL: "https://test-assets.orderandpay.net" + '/multi-concept-assets/',
        // REACT_APP_MULTI_CONCEPT_LANGUAGES_URL: "https://test-assets.orderandpay.net" + '/multi-concept-assets/',
        // REACT_APP_MULTI_CONCEPT_RESOURCES_URL: "https://test-assets.orderandpay.net" + '/multi-concept-assets/',
        // REACT_APP_MARKETPLACE_RESOURCES_URL: "https://test-assets.orderandpay.net" + '/marketplace-assets/',
        // REACT_APP_LANGUAGES_URL: "https://test-assets.orderandpay.net" + '/languages/',
        // REACT_APP_RESOURCES_URL: "https://test-assets.orderandpay.net" + '/resources/',
        // REACT_APP_MP_CONFIGS_URL: "https://test-assets.orderandpay.net" + '/marketplace/',
        // REACT_APP_DEFAULT_MP_CONFIGS_URL: "https://test-assets.orderandpay.net" + '/configs/',

        REACT_APP_STORE_CSS_URL: process.env.PUBLIC_URL + '/store-assets/',
        REACT_APP_STORE_ASSETS_URL: process.env.PUBLIC_URL + '/store-assets/',
        REACT_APP_STORE_LANGUAGES_URL: process.env.PUBLIC_URL + '/store-assets/',
        REACT_APP_STORE_RESOURCES_URL: process.env.PUBLIC_URL + '/store-assets/',
        REACT_APP_MULTI_CONCEPT_ASSETS_URL: process.env.PUBLIC_URL + '/multi-concept-assets/',
        REACT_APP_MULTI_CONCEPT_LANGUAGES_URL: process.env.PUBLIC_URL + '/multi-concept-assets/',
        REACT_APP_MULTI_CONCEPT_RESOURCES_URL: process.env.PUBLIC_URL + '/multi-concept-assets/',
        REACT_APP_MARKETPLACE_RESOURCES_URL: process.env.PUBLIC_URL + '/marketplace-assets/',
        REACT_APP_LANGUAGES_URL: process.env.PUBLIC_URL + '/languages/',
        REACT_APP_RESOURCES_URL: process.env.PUBLIC_URL + '/resources/',
        REACT_APP_MP_CONFIGS_URL: process.env.PUBLIC_URL + '/marketplace/',
        REACT_APP_DEFAULT_MP_CONFIGS_URL: process.env.PUBLIC_URL + '/configs/',

        RETRIES: 10,
        RETRY_DELAY: 1000,
        TIMEOUT: 0,
        ENVIRONMENT: 'STAGE',
        SENTRY_ENVIRONMENT: 'DEV_LOCAL',
        SENTRY_ENABLED: false
    };
 }
else if(hostname.startsWith('mp-stage'))
{
     //MP Stage - comment to force another checkin
     config = {
         REACT_APP_STORE_CSS_URL: process.env.PUBLIC_URL + '/store-assets/',
         REACT_APP_STORE_ASSETS_URL: process.env.PUBLIC_URL + '/store-assets/',
         REACT_APP_STORE_LANGUAGES_URL: process.env.PUBLIC_URL + '/store-assets/',
         REACT_APP_STORE_RESOURCES_URL: process.env.PUBLIC_URL + '/store-assets/',
         REACT_APP_MULTI_CONCEPT_ASSETS_URL: process.env.PUBLIC_URL + '/multi-concept-assets/',
         REACT_APP_MULTI_CONCEPT_LANGUAGES_URL: process.env.PUBLIC_URL + '/multi-concept-assets/',
         REACT_APP_MULTI_CONCEPT_RESOURCES_URL: process.env.PUBLIC_URL + '/multi-concept-assets/',
         REACT_APP_MARKETPLACE_RESOURCES_URL: process.env.PUBLIC_URL + '/marketplace-assets/',
         REACT_APP_LANGUAGES_URL: process.env.PUBLIC_URL + '/languages/',
         REACT_APP_RESOURCES_URL: process.env.PUBLIC_URL + '/resources/',
         REACT_APP_MP_CONFIGS_URL: "https://dev-assets.orderandpay.net" + '/marketplace/',
         REACT_APP_DEFAULT_MP_CONFIGS_URL: process.env.PUBLIC_URL + '/configs/',
         RETRIES: 10,
         RETRY_DELAY: 1000,
         TIMEOUT: 0,
         ENVIRONMENT: 'STAGE',
         SENTRY_ENVIRONMENT: 'STAGE',
         SENTRY_ENABLED: true
     };
}
else if (hostname.includes('test.')) {
    // Localhost/DEV
    config = {
        REACT_APP_STORE_CSS_URL: "https://test-assets.orderandpay.net" + '/store-assets/',
        REACT_APP_STORE_ASSETS_URL: "https://test-assets.orderandpay.net" + '/store-assets/',
        REACT_APP_STORE_LANGUAGES_URL: "https://test-assets.orderandpay.net" + '/store-assets/',
        REACT_APP_STORE_RESOURCES_URL: "https://test-assets.orderandpay.net" + '/store-assets/',
        REACT_APP_MULTI_CONCEPT_ASSETS_URL: "https://test-assets.orderandpay.net" + '/multi-concept-assets/',
        REACT_APP_MULTI_CONCEPT_LANGUAGES_URL: "https://test-assets.orderandpay.net" + '/multi-concept-assets/',
        REACT_APP_MULTI_CONCEPT_RESOURCES_URL: "https://test-assets.orderandpay.net" + '/multi-concept-assets/',
        REACT_APP_MARKETPLACE_RESOURCES_URL: "https://test-assets.orderandpay.net" + '/marketplace-assets/',
        REACT_APP_LANGUAGES_URL: "https://test-assets.orderandpay.net" + '/languages/',
        REACT_APP_RESOURCES_URL: "https://test-assets.orderandpay.net" + '/resources/',
        REACT_APP_MP_CONFIGS_URL: "https://test-assets.orderandpay.net" + '/marketplace/',
        REACT_APP_DEFAULT_MP_CONFIGS_URL: "https://test-assets.orderandpay.net" + '/configs/',
        RETRIES: 10,
        RETRY_DELAY: 1000,
        TIMEOUT: 0,
        ENVIRONMENT: 'TEST',
        SENTRY_ENVIRONMENT: 'TEST',
        SENTRY_ENABLED: false
    };
}
else {
    //Production
    config = {
        REACT_APP_STORE_CSS_URL: "https://assets.orderandpay.net" + '/store-assets/',
        REACT_APP_STORE_ASSETS_URL: "https://assets.orderandpay.net" + '/store-assets/',
        REACT_APP_STORE_LANGUAGES_URL: "https://assets.orderandpay.net" + '/store-assets/',
        REACT_APP_STORE_RESOURCES_URL: "https://assets.orderandpay.net" + '/store-assets/',
        REACT_APP_MULTI_CONCEPT_ASSETS_URL: "https://assets.orderandpay.net" + '/multi-concept-assets/',
        REACT_APP_MULTI_CONCEPT_LANGUAGES_URL: "https://assets.orderandpay.net" + '/multi-concept-assets/',
        REACT_APP_MULTI_CONCEPT_RESOURCES_URL: "https://assets.orderandpay.net" + '/multi-concept-assets/',
        REACT_APP_MARKETPLACE_RESOURCES_URL: "https://assets.orderandpay.net" + '/marketplace-assets/',
        REACT_APP_LANGUAGES_URL: "https://assets.orderandpay.net" + '/languages/',
        REACT_APP_RESOURCES_URL: "https://assets.orderandpay.net" + '/resources/',
        REACT_APP_MP_CONFIGS_URL: "https://assets.orderandpay.net" + '/marketplace/',
        REACT_APP_DEFAULT_MP_CONFIGS_URL: "https://assets.orderandpay.net" + '/configs/',
        RETRIES: 10,
        RETRY_DELAY: 1000,
        TIMEOUT: 0,
        ENVIRONMENT: 'PRODUCTION',
        SENTRY_ENVIRONMENT: 'PRODUCTION',
        SENTRY_ENABLED: true
    };
    if (hostname.toLocaleLowerCase().includes('uat')) {
        config.ENVIRONMENT = 'UAT'
        config.SENTRY_ENVIRONMENT = 'UAT'
    }
}
export default config