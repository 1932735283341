/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

/**
 * @ The internal dependencies.
 */
import { openModal, closeModal } from 'store/state/ui/actions';
import { tipsModalShown } from 'store/state/cart/actions';
import { startTipAmountUpdate, startTipPercentageUpdate } from 'store/state/cart/actions';
import { MODAL_WARNING } from 'lib/constants';
import { localizeNumber } from '../../assets/resources/resources-manager';

/**
 * Class for modal tip.
 *
 * @class ModalTip (name)
 */
class ModalTip extends Component {
    state = {
        index: 0,
		tip: '',
        customAmount: ''
    }

    replaceVariables(html) {
        return html.replace("{maxPercentage}", window.resources.tip.maxPercentage);
    }

    componentDidMount() {
        this.props.tipsModalShown();
        if (this.props.previousTipAmountDisplay !== 0.00) {
            this.setState({
                index: 0,
                customAmount: this.props.previousTipAmountDisplay.toFixed(2),
                tip: ''
            });
        }
        else if (this.props.previousTipPercentageDisplay !== 0.000) {
            const percentage = this.props.previousTipPercentageDisplay * 100;
            const value = this.percentage(percentage).toFixed(2);
            this.setState({
                index: percentage,
                tip: value,
                customAmount: ''
            });
        }
    }

    setTip = (index) => (e) => {
        this.setState({
            index: index,
            tip: e.target.value,
            customAmount: ''
        });
	}

	setCustomTip = (e) => {
        this.setState({
            index: 0,
            customAmount: e.target.value,
            tip: ''
		});
	}

	percentage(num) {
        // return (num / 100) * Number((this.props.data && this.props.data.updatedTotals) || this.props.subTotal);
        return (num / 100) * Number(this.props.subTotal);
	}

	addTip = (e) => {
        e.preventDefault();

        let exceededMaxTipPercentage = false;

        if (this.state.customAmount.length) {
            if (window.resources.tip.maxPercentage !== 0 && Number(this.state.customAmount) > this.percentage(window.resources.tip.maxPercentage)) {
                exceededMaxTipPercentage = true;
            }
            else {
                this.props.startTipAmountUpdate(Number(this.state.customAmount));
            }
        } else {
            if (window.resources.tip.maxPercentage !== 0 && this.state.index > window.resources.tip.maxPercentage) {
                exceededMaxTipPercentage = true;
            }
            else {
                const decPercentage = Number(this.state.index / 100);
                this.props.startTipPercentageUpdate(decPercentage);
            }
		}

        this.closeModal();

        if (exceededMaxTipPercentage) {
            this.props.openModal({
                type: MODAL_WARNING,
                data: {
                    message: this.replaceVariables(window.resources.messages.exceeded_max_tip_percentage_text),
                    btnText: window.resources.modal.confirm_text
                }
            });
        }
    }

    noTip = (e) => {
        e.preventDefault();
        if (this.props.tipAdded) {
            this.props.startTipAmountUpdate(0.00);
        }
        this.closeModal();
    }

    closeModal = () => {
        this.closeModal();
    }

    closeModal = () => {
        if (this.props.data && this.props.data.onClose) {
            this.props.data.onClose();
        }

        this.props.closeModal();
    }

	render() {
		return (
			<div className="modal-frame modal-frame--card">
				<div className="modal-frame__body">
					<div className="modal-frame__card">
						<div className="modal-frame__head">
                            <h6 className="modal-frame__title">{window.resources.tip.label_text}</h6>
                            {this.props.data && this.props.data.updatedTotals ? <div style={{fontSize: '10px'}}>{window.resources.tip.message_text}</div> : null}
						</div>

						<div className="modal-frame__card-body">
                            {window.resources.tip.percentages && window.resources.tip.percentages.length > 0 && <div className="modal-frame__card-group">
                                <div className="radio-group">
                                    {
                                        window.resources.tip.percentages.map((e, i) => (
                                            <div key={i} className="radio-square">
                                                <input
                                                    type="radio"
                                                    id={`radio-${e}`}
                                                    name="tip-group"
                                                    value={this.percentage(e).toFixed(2)}
                                                    checked={this.state.index === e}
                                                    onChange={this.setTip(e)}
                                                />

                                                <label htmlFor={`radio-${e}`}>
                                                    <strong>{localizeNumber(e)}%</strong>

                                                    <span>{this.props.currencySymbol + localizeNumber(this.percentage(e).toFixed(2))}</span>
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>}

                            <div className="modal-frame__card-field-preceder">
                                {window.resources.tip.showCurrencySymbol && <span className="field-preceder">{this.props.currencySymbol}</span>}
                                <input className="field" type="number" onChange={this.setCustomTip} placeholder={window.resources.tip.placeholder_text} value={this.state.customAmount} />
							</div>
						</div>

						<div className="modal-frame__actions modal-frame__actions--flex">
							<button
                                onClick={this.noTip}
                                className="btn btn--secondary">{window.resources.tip.cancel_text}</button>

                            <button
                                disabled={(!this.state.customAmount.length && !this.state.tip) || (Number(this.state.customAmount) < 0 && !this.state.tip)}
								onClick={this.addTip}
                                className="btn">{window.resources.tip.confirm_text}</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(
    (state) => ({
        subTotal: state.cart.cartPricingData ? state.cart.cartPricingData.subTotal : 0.00,
        previousTipAmountDisplay: state.cart.data.tipAmount != null && state.cart.data.tipAmount >= 0 ? state.cart.data.tipAmount : 0.00,
        previousTipPercentageDisplay: state.cart.data.tipPercentage != null && state.cart.data.tipPercentage >= 0 ? state.cart.data.tipPercentage : 0.000,
        tipAdded: state.cart.data && ((state.cart.data.tipAmount && state.cart.data.tipAmount > 0) || (state.cart.data.tipPercentage && state.cart.data.tipPercentage > 0)),
        currencySymbol: state.app.currencySymbol,
        data: state.ui.modal.data
	}),
    {
        openModal,
        tipsModalShown,
		closeModal,
        startTipAmountUpdate,
        startTipPercentageUpdate
	}
)(ModalTip);
