/**
 * @ The external dependencies.
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
/**
 * @ The internal dependencies.
 */
import Loader from '../../components/loader/loader';
import { localizeNumber } from '../../assets/resources/resources-manager';
import { closeModal } from '../../store/state/ui/actions';
import { updateSaveMode, clearCheckout } from '../../store/state/checkout/actions';
import { clearSurvey } from '../../store/state/survey/actions';
import { clearTab } from '../../store/state/tab/actions';
import { clearLoyalty } from '../../store/state/loyalty/actions';
import { updatePaymentDetails, startCartSave, startCartUpdate, clearCart } from '../../store/state/cart/actions';
import { hasTabOpenAndAuthorized } from '../../store/state/tab/selectors';
import * as features from '../../store/state/features/selectors';
import { getBlankCardDetails } from '../payment/payment-helper';
import { TAB_ADDITEMS, BALANCE_CHECK_INITIAL, BALANCE_CHECK_CHECKING, BALANCE_CHECK_UPDATED } from '../../lib/constants';

const ModalChoosePayType = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [balanceCheck, setBalanceCheck] = useState(BALANCE_CHECK_INITIAL);
    const [showLoader, setShowLoader] = useState(false);

    const total = useSelector(state => !state.ui.modal.isOpen ? 0 : state.ui.modal.data.total);
    const onPayInFull = useSelector(state => !state.ui.modal.isOpen ? null : state.ui.modal.data.onPayInFull);
    const onShowUpdateMsg = useSelector(state => !state.ui.modal.isOpen ? null : state.ui.modal.data.onShowUpdateMsg);
    const arrCart = useSelector(state => !state.ui.modal.isOpen ? null : state.ui.modal.data.arrCart);
    const currencySymbol = useSelector(state => state.app.currencySymbol);
    const cart = useSelector(state => state.cart);
    const tabRequiresCCDetails = useSelector(state => !state.tab.cardLastFour);
    const hasTabOpenAndAuthorize = useSelector(state => hasTabOpenAndAuthorized(state));
    const orderOnlyNoPayment = useSelector(state => features.orderOnlyNoPayment(state));

    useEffect(() => {
        if (balanceCheck === BALANCE_CHECK_CHECKING) {
            if ((cart.cartPricingData && cart.cartPricingData.Tabs && total <= cart.cartPricingData.Tabs[0].DueAmount) || 
                (arrCart && arrCart.length > 0)) { payInFull(); }
            else { onShowUpdateMsg(); dispatch(closeModal()); }

            setBalanceCheck(BALANCE_CHECK_UPDATED);
            if (!cart.cartPricingData || !cart.cartPricingData.Tabs) {
                dispatch(clearCart()); dispatch(clearTab());
                dispatch(clearCheckout()); dispatch(clearLoyalty());
                history.push('/split/invite/error/no-balance-remaining');
            }
        }
    }, [cart.cartPricingData])

    const payInFull = () => {
        dispatch(closeModal());
        setShowLoader(false);
        onPayInFull();
    } 
    const checkPaymentAmount = (e) => {
        e.preventDefault();

        setShowLoader(true);
        dispatch(startCartUpdate());
        setBalanceCheck(BALANCE_CHECK_CHECKING);
    }
    const splitPay = (e) => {
        e.preventDefault();
        const isDisabled = tabRequiresCCDetails && hasTabOpenAndAuthorize && !orderOnlyNoPayment && arrCart && arrCart.length > 0;
        if (!isDisabled) {
            dispatch(updateSaveMode(TAB_ADDITEMS));
            dispatch(updatePaymentDetails(getBlankCardDetails().paymentDetails));
            dispatch(startCartSave(
                {
                    newTab: false,
                    createOrUpdateTab: true,
                    sendCartItems: true
                }
            ));
            
            dispatch(closeModal()); 
            dispatch(clearSurvey());
            history.push({
                pathname: '/split',
                state: {
                    total: total
                }
            }); 
        }
    }

    return (
        showLoader ? 
        <div key="loading" className={classnames({'payment': true,'visible': true})}>
            <Loader title={window.resources.spinners.processing_order_text} />
        </div>
        :
        <div className="modal-frame modal-frame--card">
            <div className="modal-frame__body">
                <div className="modal-frame__card">
                    <div className="modal-frame__head">
                        <h6 className="modal-frame__title">{window.resources.payment_type.label_text}</h6>
                    </div>
                    <div className="modal-frame__actions modal-frame__actions--flex column-actions">
                        <button
                            onClick={checkPaymentAmount}
                            className="btn btn--block multi-line-btn"><div>{`${currencySymbol}${localizeNumber(total.toFixed(2))}`}</div><div>{window.resources.payment_type.full_text}</div></button>
                        <button 
                            style={{marginTop: '10px', marginLeft: '0' }}
                            onClick={splitPay}
                            className="btn btn--block"
                            disabled={tabRequiresCCDetails && hasTabOpenAndAuthorize && !orderOnlyNoPayment && arrCart && arrCart.length > 0}>{window.resources.payment_type.split_text}</button>
                    </div>
                    { tabRequiresCCDetails && hasTabOpenAndAuthorize && !orderOnlyNoPayment && arrCart && arrCart.length > 0 && <div className="modal-frame__actions modal-frame__actions--flex column-actions" style={{fontSize: '10px', fontStyle: 'italic', marginTop: '15px'}}>{window.resources.payment_type.no_cc_split_msg_text}</div>}
                    <div className='modal-frame modal-frame__foot'>
                        <h6 className="modal-frame__title" onClick={() => dispatch(closeModal())}>{window.resources.payment_type.back_text}</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalChoosePayType;