
export const initialize = (measurementId) => {
    const existing = document.getElementById('googleAnalytics4');
    if (!existing) {
        if (!measurementId) throw new Error('Require GA_MEASUREMENT_ID');

        if (measurementId) {
            const script = document.createElement('script');
            script.async = true;
            script.id = 'googleAnalytics4';
            //script.src = `https://www.googletagmanager.com/gtag/js?id=G-NR3RDN1S0Q`;
            script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
            document.body.appendChild(script);

            window.dataLayer = window.dataLayer || [];
            window.gtag = function gtag() {
                window.dataLayer.push(arguments);
            };
            window.gtag('js', new Date());
            window.gtag('config', measurementId);
        }
    }
}

export const logAddToCart = (item, type, currencyCode) => {
    window.gtag('event', 'add_to_cart', {
        currency: currencyCode,
        value: parseFloat(item.costDisplay),
        items:[type ? {
            item_name: item.inventoryItemName,
            item_id: item.inventoryItemID, 
            price: parseFloat(item.costDisplay),
            quantity: item.quantity, 
            item_list_name: type,
        } : {
            item_name: item.inventoryItemName,
            item_id: item.inventoryItemID, 
            price: parseFloat(item.costDisplay),
            quantity: item.quantity
        }]
    });
}

export const logRemoveFromCart = (item, currencyCode) => {
    window.gtag('event', 'remove_from_cart', {
        currency: currencyCode,
        value: parseFloat(item.costDisplay),
        items:[{
            item_name: item.inventoryItemName,
            item_id: item.inventoryItemID, 
            price: parseFloat(item.costDisplay),
            quantity: item.quantity
        }]
    });
}

export const logUpsellOrAddonProductList = (type, items) => {
    const products = items.map(i => ({
        item_name: i.inventoryItemName,
        item_id: i.inventoryItemID, 
        price: parseFloat(i.costDisplay)
    }));

    window.gtag('event', 'view_item_list',  {
        item_list_name: type, 
        items: products
    })
}

export const logPurchase = (currency, transactionId, totalCost, taxesCost, items) => {
    window.gtag('event', 'purchase', {
        currency, 
        transaction_id: transactionId, 
        value: totalCost, 
        tax: taxesCost, 
        items
    });
}

export const logPageView = (location = window.location) => {
    window.gtag('event', 'page_view', {
        page_path: location.pathname
    })
}