/**
 * @ The external dependencies.
 */
import {
    all,
    call,
    put,
    takeEvery,
} from 'redux-saga/effects';

/**
 * @ The internal dependencies.
 */
import { setSessionLocalization } from 'lib/helpers/local-storage';
import {
    setLanguage,
} from './actions';

import { getCurrentLanguageDirection, setTextDirectionAttribute, setLanguageAttribute } from '../../../lib/helpers/language'

import { requestTableTopConfig, requestTableTopData } from 'store/state/app/actions';
import { requestPreOrderQuestionnaireData } from 'store/state/questionnaire/actions';

export function* setLanguageWorker(action) {
    window.language = action.payload.language;
    setTextDirectionAttribute(getCurrentLanguageDirection());
    setLanguageAttribute(window.language.substring(0, 2));
    yield call(setSessionLocalization, { language: action.payload.language });

    //Call in parallel
    yield all([
        put(requestTableTopConfig()),
        put(requestTableTopData()),
        put(requestPreOrderQuestionnaireData())
    ]);
}

/**
 * Start all workers.
 *
 * @return {Generator}
 */
export default function* foreman() {
    yield all([
        takeEvery(setLanguage, setLanguageWorker)
    ]);
}