/**
 * @ The internal dependecies.
 */

import ApplePayLogo from '../../assets/svg/apple-pay.svg';
import GooglePayLogo from '../../assets/svg/google-pay.svg';
import CardPayLogo from '../../assets/svg/card-pay.svg';
import VisaLogo from '../../assets/svg/visa.svg';
import MastercardLogo from '../../assets/svg/mastercard.svg';
import AmexLogo from '../../assets/svg/amex.svg';
import JcbLogo from '../../assets/svg/jcb.svg';
import DiscoverLogo from '../../assets/svg/discover.svg';
import { clearNets, sendFormRequest } from '../../store/state/nets/actions';
import ComponentLoader from 'components/loader/component-loader';
import ApplePayButton from './apple-pay-button';
import { getNetsLanguage } from '../../lib/helpers/language';

/**
 * @ The external dependecies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IconSvg from 'components/common/icon-svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import GooglePayButton from '@google-pay/button-react';
import { none } from 'ramda';
import { visible } from 'chalk';

/**
 * Class for nets drop in.
 *
 * @class NetsDropIn (name)
 */
class NetsDropIn extends Component {
	state = {
		payWithCard: false,
		payWithGooglePay: false,
		payWithApplePay: false,
		submitted: false,
		checkout: null
	}

	replaceVariables(html) {
		return html.replace("{paymentProvider}", "Nets");
	}

	componentDidMount = () => {
		this.props.sendFormRequest();

		this.interval = setInterval(() => this.toggleNetsEasySubmitButton(), 500);
	}

	componentDidUpdate = (prevProps, prevState) => {
		if (!this.props.loading && prevProps.loading) {
			this.loadNetsScripts(() => {
				this.setupNetsEasy();
			});
		}

		if (!this.props.isVisible && prevProps.isVisible) {
			this.setState({
				payWithCard: false,
				payWithGooglePay: false,
				payWithApplePay: false
			});
			this.props.clearNets();
		}
	}

	componentWillUnmount = () => {
		//this.props.NetsEasy.unload();
		clearInterval(this.interval);
		if (this.state.checkout != null) {
			this.state.checkout.cleanup();
		}
		this.props.clearNets();
	}

	//showNetsEasy = () => {
	//	this.props.NetsEasy.openView();
	//	document.getElementById("nets-submit-button").disabled = true;
	//}

	setupNetsEasy = () => {
		if (this.state.checkout == null) {
			var checkoutOptions = {
				//checkoutKey: "test-checkout-key-7814d1dfba944205ad6f895efa47c63d", // [Required] Test or Live checkout key with dashes
				checkoutKey: this.props.data.NetsCheckoutKey, // [Required] Test or Live checkout key with dashes
				paymentId: this.props.data.PaymentID, // [Required] Payment ID (GUID format) without dashes.
				language: getNetsLanguage(), // [Optional] Default value: en-GB
				containerId: "dibs-complete-checkout" // [Optional] Default: dibs-checkout-content
			};
			var checkoutTEMP = new window.Dibs.Checkout(checkoutOptions);
			this.setState({ checkout : checkoutTEMP });
			checkoutTEMP.on('payment-completed', this.handleNetsEasyPayment);
		}
		

		//if (this.state.checkout != null) {
		//	this.state.checkout.cleanup();
		//}

		

		//this.setState({
		//	checkout: checkout
		//});

		//checkout.cleanup();

		//this is the event that the merchant should listen to redirect to the �payment-is-ok� page
		//checkout.on('payment-completed', this.handleNetsEasyPayment);

		////onCreate
		//this.props.NetsEasy.onInit(() => {
		//	document.getElementById("nets-submit-button").disabled = true;

		//	document.getElementById("nets-name").placeholder = this.props.displayOptions.full_name_placeholder;

		//	//nets-modal-form
		//	document.getElementById("nets-submit-button").addEventListener("click", () => {
		//		if (this.props.onSubmit) {
		//			this.props.onSubmit();
		//		}
		//	}, false);

		//	if (this.props.onCreate) {
		//		this.props.onCreate(this.props.NetsEasy);
		//	}
		//});

		////onSubmit
		//this.props.NetsEasy.onPaymentMethod(this.handleNetsEasyPayment);
	}

	setNetsEasyDisplayOptions = () => {
		this.props.NetsEasy.setDisplayOptions({
			...this.props.displayOptions,
			amount: this.props.amountDisplay
		});
	}

	loadNetsScripts = (callback) => {
		const existingScript = document.getElementById('netsScripts');

		if (!existingScript) {
			const script = document.createElement('script');
			//script.src = this.props.applePayDetails.JavascriptURL;
			//script.src = 'https://test.checkout.dibspayment.eu/v1/checkout.js?v=1'
			script.src = this.props.data.NetsScriptURL;
			script.id = 'netsScripts';
			document.body.appendChild(script);

			script.onload = () => {
				if (callback) callback();
			};
		}

		if (existingScript && callback) callback();
	};

	setNetsEasyPaymentMethodParams = () => {
		this.props.NetsEasy.setPaymentMethodParams(this.props.paymentMethodParams);
	}

	toggleNetsEasySubmitButton = () => {
		const submitButton = document.getElementById('nets-submit-button');
		if (submitButton) {
			submitButton.disabled = document.getElementsByClassName("nets-field-valid").length !== 4;
		}
	}

	handleNetsEasyPayment = (token, formData) => {
		this.setState({
			submitted: true
		});

		const payload = {
			token,
			formData
		};

		if (this.props.onSubmitResponse) {
			this.props.onSubmitResponse();
		}

		if (token) {
			this.props.handlePaymentMethodNetsEasy(payload);
		}
		else {
			if (this.props.onError) {
				this.props.onError('An Error has occured');
			}
		}
	}

	handleGooglePayPayment = (paymentData) => {
		this.setState({
			submitted: true
		});

		if (this.props.onSubmitResponse) {
			this.props.onSubmitResponse();
		}

		this.props.handlePaymentMethodGooglePay(paymentData);
	}

	handleApplePayPayment = (paymentData) => {
		this.setState({
			submitted: true
		});

		if (this.props.onSubmitResponse) {
			this.props.onSubmitResponse();
		}

		this.props.handlePaymentMethodApplePay(paymentData);
	}

	handleCard = (e) => {
		this.setState({
			payWithCard: true
		});
		/*this.setupNetsEasy();*/
	};

	handleGooglePay = (e) => {
		this.setState({
			payWithGooglePay: true
		});
	};

	handleApplePay = (e) => {
		this.setState({
			payWithApplePay: true
		});
	};

	handleChooseAnotherWayToPay = (e) => {
		this.setState({
			payWithCard: false,
			payWithGooglePay: false,
			payWithApplePay: false
		});
	};

	render = () => {
		return (
			<div className="nets-dropin">
				{this.props.loading && <div>
					<br />
					<br />
					<ComponentLoader />
					<br />
					<br />
				</div>}
				{/*!this.props.loading && !(this.state.payWithCard || this.state.payWithGooglePay || this.state.payWithApplePay) && (
					<div>
						<div className="nets-heading">Choose a way to pay</div>
						<div className="nets-options-list">
							{this.props.applePay && this.props.applePayEnabled && window.ApplePaySession && window.ApplePaySession.canMakePayments() && <div className="nets-option" tabIndex="2" onClick={this.handleApplePay}>
								<div className="nets-option__logo">
									<IconSvg className="nets-option__logo-svg" src={ApplePayLogo} />
								</div>
								<div className="nets-option__label" aria-label="Paying with Apple Pay">Apple Pay</div>
							</div>}
							{this.props.googlePay && this.props.googlePayEnabled && !(this.props.applePayEnabled && window.ApplePaySession && window.ApplePaySession.canMakePayments()) && <div className="nets-option" tabIndex="1" onClick={this.handleGooglePay}>
								<div className="nets-option__logo">
									<IconSvg className="nets-option__logo-svg" src={GooglePayLogo} />
								</div>
								<div className="nets-option__label" aria-label="Paying with Google Pay">Google Pay</div>
							</div>}
							{this.props.card && this.props.creditCardEnabled && <div className="nets-option" tabIndex="0" onClick={this.handleCard}>
								<div className="nets-option__logo">
									<IconSvg className="nets-option__logo-svg" src={CardPayLogo} />
								</div>
								<div className="nets-option__label" aria-label="Paying with Card">Card</div>
							</div>}
						</div>
					</div>)*/}
				{/*<div className="nets-sheet">*/}
				{/*	<div className="nets-sheet__header">*/}
				{/*		<div className="nets-sheet__header-label">*/}
				{/*			<div className="nets-sheet__logo--header">*/}
				{/*				<IconSvg className="nets-option__logo-svg" src={CardPayLogo} />*/}
				{/*			</div>*/}
				{/*			<div className="nets-sheet__label">Pay with card</div>*/}
				{/*		</div>*/}
				{/*		<div className="nets-sheet__icons">*/}
				{/*			<div className="nets-sheet__card-icon">*/}
				{/*				<IconSvg src={VisaLogo} />*/}
				{/*			</div>*/}
				{/*			<div className="nets-sheet__card-icon">*/}
				{/*				<IconSvg src={MastercardLogo} />*/}
				{/*			</div>*/}
				{/*			<div className="nets-sheet__card-icon">*/}
				{/*				<IconSvg src={AmexLogo} />*/}
				{/*			</div>*/}
				{/*			<div className="nets-sheet__card-icon">*/}
				{/*				<IconSvg src={JcbLogo} />*/}
				{/*			</div>*/}
				{/*			<div className="nets-sheet__card-icon">*/}
				{/*				<IconSvg src={DiscoverLogo} />*/}
				{/*			</div>*/}
				{/*		</div>*/}
				{/*	</div>*/}
					<div id="dibs-complete-checkout" className="nets-sheet__content--form">
						{/* TODO Spreedly iFrame Payment Form */}
					</div>
				{/*</div>*/}
				{/*this.state.payWithGooglePay && <div className="nets-sheet">
					<div className="nets-sheet__header">
						<div className="nets-sheet__header-label">
							<div className="nets-sheet__logo--header">
								<IconSvg className="nets-option__logo-svg" src={GooglePayLogo} />
							</div>
							<div className="nets-sheet__label">Google Pay</div>
						</div>
					</div>
					<div className="nets-sheet__content--form">
						<GooglePayButton
							//environment={this.props.isTestEnvironment ? 'TEST' : 'PRODUCTION' }
							environment={'PRODUCTION'}
							paymentRequest={{
								apiVersion: 2,
								apiVersionMinor: 0,
								allowedPaymentMethods: [
									{
										type: 'CARD',
										parameters: {
											allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
											allowedCardNetworks: ["AMEX", "DISCOVER", "JCB", "MASTERCARD", "VISA"],
											billingAddressRequired: true,
											billingAddressParameters: {
												format: "MIN"
											}
										},
										tokenizationSpecification: {
											type: 'PAYMENT_GATEWAY',
											parameters: {
												gateway: 'nets',
												gatewayMerchantId: this.props.authorizationToken
											},
										}
									}
								],
								merchantInfo: {
									merchantId: '05111731990316967312',
								},
								transactionInfo: {
									totalPriceStatus: 'FINAL',
									totalPrice: this.props.amount.toFixed(2),
									currencyCode: this.props.currencyCode
								}
							}}
							onLoadPaymentData={this.handleGooglePayPayment}
						/>
					</div>
				</div>*/}
				{/*this.state.payWithApplePay && <div className="nets-sheet">
					<div className="nets-sheet__header">
						<div className="nets-sheet__header-label">
							<div className="nets-sheet__logo--header">
								<IconSvg className="nets-option__logo-svg" src={ApplePayLogo} />
							</div>
							<div className="nets-sheet__label">Apple Pay</div>
						</div>
					</div>
					<div className="nets-sheet__content--form">
						<ApplePayButton
							paymentRequest={{
								currencyCode: this.props.currencyCode,
								countryCode: this.props.countryCode,
								merchantCapabilities: ['supports3DS'],
								supportedNetworks: ['amex', 'discover', 'jcb', 'masterCard', 'visa'],
								requiredBillingContactFields: [
									'name',
									'postalAddress'
								],
								total: {
									label: 'Grab OAT',
									amount: this.props.amount.toFixed(2),
									type: 'final'
								}
							}}
							paymentSessionUrl="/api/Cursus_PaymentProvider_StartApplePaySession"
							onLoadPaymentData={this.handleApplePayPayment}
						/>
					</div>
				</div>*/}
				{/*(this.state.payWithCard || this.state.payWithGooglePay || this.state.payWithApplePay) &&
					<div onClick={this.handleChooseAnotherWayToPay} className="nets-large-button" tabIndex="0">
						<span>Choose another way to pay</span>
					</div>*/}

				<div className='payment-secure'><FontAwesomeIcon icon={faLock} /> {this.replaceVariables(window.resources.payment.secure_text)}</div>
				{this.props.message && <div className='payment-message editable' dangerouslySetInnerHTML={{ __html: this.props.message }}></div>}
			</div>
		)
	}
}

NetsDropIn.propTypes = {
	//NetsEasy: PropTypes.object.isRequired,
	authorizationToken: PropTypes.string.isRequired,
	handlePaymentMethodNetsEasy: PropTypes.func.isRequired,
	handlePaymentMethodGooglePay: PropTypes.func.isRequired,
	handlePaymentMethodApplePay: PropTypes.func.isRequired,
	onCreate: PropTypes.func,
	onError: PropTypes.func,
	onDestroyEnd: PropTypes.func,
	displayOptions: PropTypes.object,
	paymentMethodParams: PropTypes.object,
	amountDisplay: PropTypes.string,
	currencyCode: PropTypes.string,
	countryCode: PropTypes.string
}

export default connect(
	(state) => ({
		loading: state.nets.data == null,
		data: state.nets.data,
		creditCardEnabled: state.nets.data && state.nets.data.creditCardEnabled,
		googlePayEnabled: state.nets.data && state.nets.data.googlePayEnabled,
		applePayEnabled: state.nets.data && state.nets.data.applePayEnabled,
		isTestEnvironment: state.nets.data && state.nets.data.isTestEnvironment,
		checkout: state.nets.checkout
	}),
	{
		clearNets,
		sendFormRequest
	}
)(NetsDropIn);