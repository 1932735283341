/**
 * @ The external dependecies.
 */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import {
    compose
} from 'ramda';
import { Link } from 'react-router-dom';

/**
 * @ The internal dependencies.
 */
import * as queryString from 'lib/helpers/query-string';
import withDataValidation from 'lib/helpers/hocs/with-data-validation';
import Payment from 'components/payment/payment';
import { startCartSave, updatePaymentDetails } from 'store/state/cart/actions';
import { hasTabOpenAndAuthorized } from '../../store/state/tab/selectors';
import * as features from '../../store/state/features/selectors';
import { openModal, closeModal } from 'store/state/ui/actions';
import { getNetsCallbackCardDetails } from 'components/payment/payment-helper';
import { MODAL_RECEIPT_EMAIL, MODAL_RECEIPT_TEXT } from 'lib/constants';

class Receipt extends Component {
    state = {
        paymentIsVisible: false,
    }

    handleBackButton = (e) => {
        e.preventDefault();
        this.props.history.push('/cart');
    }

    openPayment = () => {

        this.setState({
            paymentIsVisible: true
        });
    }

    closePyament = (e) => {
        if (e) {
            e.preventDefault();
        }

        if (!((this.props.autoReceiptFeatureEnabled && !this.isVirtualKiosk()) || (this.props.autoReceiptVKFeatureEnabled && this.isVirtualKiosk()))) {
            this.props.history.push('/cart');
        }
        else {
            this.setState({
                paymentIsVisible: false
            });
        }
    }

    handlePaymentMethodNetsCallback = (paymentId) => {
        console.log('Payment Success');
        const cardDetails = getNetsCallbackCardDetails(paymentId);
        this.handlePaymentSuccess(cardDetails);
    }

    handlePaymentSuccess = (cardDetails) => {
        this.makePaymentSave(cardDetails);
    }

    makePaymentSave = (cardDetails) => {
        this.props.updatePaymentDetails(cardDetails.paymentDetails);
        this.props.startCartSave(
            {
                createOrUpdateTab: false,
                sendCartItems: true
            }
        );
        if (this.props.paymentProvider.toLowerCase() !== 'adyen') this.props.history.push('/checkout-complete');
    }

    handleEmailClick = (e) => {
        e.preventDefault();
        this.props.openModal({
            type: MODAL_RECEIPT_EMAIL,
            data: {
                send: false,
                fun: () => {
                    if (this.props.totalCost <= 0) {
                        this.props.startCartSave(
                            {
                                createOrUpdateTab: false,
                                sendCartItems: true
                            }
                        );
                        this.props.history.push('/checkout-complete');
                    }
                    else {
                        this.openPayment();
                    }
                }
            }
        });
    }

    handleTextClick = (e) => {
        e.preventDefault();
        this.props.openModal({
            type: MODAL_RECEIPT_TEXT,
            data: {
                send: false,
                fun: () => {
                    if (this.props.totalCost <= 0) {
                        this.props.startCartSave(
                            {
                                createOrUpdateTab: false,
                                sendCartItems: true
                            }
                        );
                        this.props.history.push('/checkout-complete');
                    }
                    else {
                        this.openPayment();
                    }
                }
            }
        });
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        if (!((this.props.autoReceiptFeatureEnabled && !this.isVirtualKiosk()) || (this.props.autoReceiptVKFeatureEnabled && this.isVirtualKiosk())) || (urlParams && urlParams.get('redirectResult'))) {
            //if ((!this.props.autoReceiptFeatureEnabled && !this.isVirtualKiosk()) || (!this.props.autoReceiptVKFeatureEnabled && this.isVirtualKiosk())) {
            this.setState({
                paymentIsVisible: true
            });
        }
        else {
            this.setState({
                paymentIsVisible: false
            });
        }

        //NETS callback
        const paymentId = queryString.getValue('paymentId');
        if (paymentId) {
           this.handlePaymentMethodNetsCallback(paymentId)
        }
    }

    isVirtualKiosk = () => {
        return this.props.tableNumber === 'vk' || this.props.tableNumber === 'VK';
    }

    render() {
        if (this.props.hasTabOpenAndAuthorized) {
            return (<Redirect to="/menu" />);
        }

        return (
            <div className="receipt">
                <div className="receipt__head">
                    <div className="shell">
                        <div className="receipt__head-inner">
                            <Link
                                onClick={this.handleBackButton}
                                to="/cart"
                                className="receipt__head-btn" aria-label={window.resources.navigation.back_text}>
                                <i className="fas arrow-left" title={window.resources.navigation.back_text}></i>
                            </Link>
                            <h6 className="receipt__title">{window.resources.receipt_auto.title_text}</h6>
                        </div>

                        <div className="receipt__head-entry">
                            <p></p>
                        </div>
                    </div>
                </div>

                
                { !this.state.paymentIsVisible ? 
                <div className="receipt__body">
                    <div className="receipt__hr"></div>

                    <div className="receipt__section receipt__section--modified">
                        <div className="shell">
                            <h6 style={{ textAlign: 'center' }}>{window.resources.receipt_auto.label_text}</h6>
                            <div className="receipt__section-text">
                                <p>{window.resources.receipt_auto.message_text}</p>
                            </div>
                            <div className="receipt__section-actions receipt__section-actions--flex receipt__section-actions--space">
                                {this.props.emailReceiptFeatureEnabled && <button
                                    onClick={this.handleEmailClick}
                                    className="btn">{window.resources.receipt_auto.email_choice_text}</button>}
                                {this.props.textReceiptFeatureEnabled && <button
                                    onClick={this.handleTextClick}
                                    className="btn">{window.resources.receipt_auto.text_choice_text}</button>}
                            </div>
                        </div>
                    </div>
                </div>
                :
                <Payment
                    clientToken={this.props.clientToken}
                    merchantId={this.props.merchantId}
                    total={this.props.totalCost}
                    totalDisplay={this.props.totalDisplay}
                    countryCode={this.props.countryCode}
                    currencyCode={this.props.currencyCode}
                    onPaymentSuccess={this.handlePaymentSuccess}
                    closePyament={this.closePyament}
                    applePayEnabled={true}
                    isVisible={this.state.paymentIsVisible}
                    storeName={this.props.storeName}
                    paymentProvider={this.props.paymentProvider}
                    threeDSecureEnabled={this.props.threeDSecureEnabled}
                    email={this.props.cart.email || ''}
                    phone={this.props.cart.guestCustomerDetails.mobileNumber}
                    />
                }
            </div>
        );
    }
}

export default compose(
    withRouter,
    connect(
        (state) => ({
            cart: state.cart.data,
            totalCost: state.cart.data.totalCost,
            totalDisplay: state.cart.cartPricingData && state.cart.cartPricingData.orderTotalDisplay,
            clientToken: state.cart.data && state.cart.data.clientToken,
            merchantId: state.cart.data && state.cart.cartPricingData && state.cart.cartPricingData.googlePayMerchantID, 
            currencyCode: state.cart.cartPricingData && state.cart.cartPricingData.isoCurrencyCode,
            countryCode: state.cart.cartPricingData && state.cart.cartPricingData.isoCountryCode,
            hasTabOpenAndAuthorized: hasTabOpenAndAuthorized(state),
            textReceiptFeatureEnabled: features.textReceiptFeatureEnabled(state),
            emailReceiptFeatureEnabled: features.emailReceiptFeatureEnabled(state),
            storeName: state.app.config && state.app.config.storeName,
            paymentProvider: state.app.config && state.app.config.PaymentProviderName,
            threeDSecureEnabled: features.threeDSecureEnabled(state),
            autoReceiptFeatureEnabled: features.autoReceiptFeatureEnabled(state),
            autoReceiptVKFeatureEnabled: features.autoReceiptVKFeatureEnabled(state),
            tableNumber: state.checkout.tableNumber,
        }),
        {
            startCartSave,
            openModal,
            closeModal,
            updatePaymentDetails
        }
    ),
    withDataValidation
)(Receipt);
