import config from '../../config';
//import config from 'config';

export const setupConfigs = (airportIdent, platformTypeID, waypointID, onLoad, onError) => {
    var defaultConfigsUrl = config.REACT_APP_DEFAULT_MP_CONFIGS_URL + 'mpconfig.json';

    var overrideConfigsurls;

    if (waypointID) {
        overrideConfigsurls = [config.REACT_APP_MP_CONFIGS_URL + airportIdent + "/mpconfig.json"
            , config.REACT_APP_MP_CONFIGS_URL + airportIdent + "/" + platformTypeID + "/mpconfig.json"
            , config.REACT_APP_MP_CONFIGS_URL + airportIdent + "/" + waypointID + "/mpconfig.json"
            , config.REACT_APP_MP_CONFIGS_URL + airportIdent + "/" + platformTypeID + "/" + waypointID + "/mpconfig.json"];
    }
    else {
        overrideConfigsurls = [config.REACT_APP_MP_CONFIGS_URL + airportIdent + "/mpconfig.json"
            , config.REACT_APP_MP_CONFIGS_URL + airportIdent + "/" + platformTypeID + "/mpconfig.json"];
    }

    var fetchDefaultConfigsPromise = fetch(defaultConfigsUrl)
        .then(response => response.json())
        .then(config => {
            window.mpconfigs = config;
        })
        .catch(error => {
            window.mpconfigs = {};
            console.error(`Cannot load ${defaultConfigsUrl}`);
        });

    var fetchOverridePromises = overrideConfigsurls.map(overrideConfigsurl => fetch(overrideConfigsurl).then(response => response.json()));

    Promise.allSettled([fetchDefaultConfigsPromise, ...fetchOverridePromises]).then(results => {
       if (results.some(result => result.status === 'fulfilled')) {
            //set the config value to the fulfilled promises. override the default if we pulled down an override
            for (var i = results.length - 1; i > 0; i--) {
                if (results[i].status === 'fulfilled') {
                    window.mpconfigs = results[i].value;
                    break;
                }
            }

            if (onLoad) onLoad();
        }
        else {
            if (onError) onError();
        }
    })
}