/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';
import { Button, styled } from '@mui/material';

/**
 * @ The internal dependencies.
 */
import { openModal } from 'store/state/ui/actions';
import { updateCartTotal, startDonationAmountUpdate } from 'store/state/cart/actions';
import { hasAlcoholInCart } from 'store/state/cart/selectors';
import { MODAL_TIP, MODAL_AIRLINE_EMPLOYEE_DISCOUNT, MODAL_DONATIONS } from 'lib/constants';
import * as features from '../../store/state/features/selectors';
import { localizeNumber } from '../../assets/resources/resources-manager';
import LoadedValueAccountButton from './loaded-value-account-button';
import LoyaltyButton from './loyalty-button';
import { DiscountCode, Tips } from '../marketplace/modals/modals';
import { Context } from '../../store/state/marketplace/store';

/**
 * Class for cart calculator.
 *
 * @class CartCalculator (name)
 */
class CartCalculator extends Component {
    state = {
        showDiscount: false, 
        showTips: false
    }
    static contextType = Context;

	calculator() {
		const { cartPricing, arrCart } = this.props;

        if (!isEmpty(arrCart)) {
            //client side tipAmount. use server side tip when/if it becomes available.
            const tip = (cartPricing.tipAmount != null ? cartPricing.tipAmount : this.props.tipAmount);
            //server side food cost.
            const subTotal = cartPricing.subTotal;
            //server side tax.
            const tax = cartPricing.taxes;
            //server side total.
            let total = cartPricing.orderTotal;

            //if the server side total doesn't include tip add it for display purposes.
            if (total - subTotal - tax === 0) {
                total = total + tip;
            }

			return {
                subTotal: subTotal.toFixed(2),
                tax: tax.toFixed(2),
                tip: tip.toFixed(2),
                total: total.toFixed(2)
			};
		} else {
			return {
				subTotal: "0.00",
				tax: "0.00",
				tip: "0.00",
				total: "0.00"
			};
		}
    }

    replaceVariables(html) {
        return html.replace("className", "class");
    }

    hasAdditionalButtons() {
        return (this.props.tipButtonFeatureEnabled && (!this.props.tipForAlcoholOnlyFeatureEnabled || this.props.hasAlcoholInCart)) || this.props.employeeDiscountFeatureEnabled || this.props.loadedValueAccountEnabled || this.props.loyaltyFeatureEnabled || (this.props.donationEnabled && this.props.donationCalculation)
    }
    showTotal = () => {
        if (this.props.cartPricing && this.props.cartPricing.orderTotal.toFixed(2) == "0.00" && this.props.hideZeroDollars)
            return false
        return true
    }
    handleClose = () => {
        const marketplace = this.context;

        marketplace.setShowDiscountModal(false); 
        this.setState({ showDiscount: false });
    }
	render() {
        const marketplace = this.context;

        const CapitalCaseButton = styled(Button)(({ theme }) => ({
            textTransform: 'capitalize'
        }));

        const { openModal, startDonationAmountUpdate } = this.props;

        //initally
        let orderLineItems = (
            <ul className="cart-calculator__list">
                <li>
                    <span>{window.resources.cart.subtotal_text}</span>

                    <span>{localizeNumber(this.calculator().subTotal)}</span>
                </li>

                <li>
                    <span>{window.resources.cart.tax_text}</span>

                    <span>{localizeNumber(this.calculator().tax)}</span>
                </li>

                {this.showTotal() && <li className="total danger">
                    <strong>{window.resources.cart.total_text}</strong>

                    <strong>{this.props.currencySymbol}{localizeNumber(this.calculator().total)}</strong>
                </li>
                }
            </ul>);

        //Use server side orderLineItems once available.
        if (this.props.cartPricing) {
            const hasApplied = this.props.cartPricing.orderLineItems.findIndex(li => li.OrderLineItemTypeIdentifier === 'APPLIED') !== -1;
            orderLineItems = (<ul className="cart-calculator__list">
                {this.props.cartPricing.orderLineItems.filter(li => li.OrderLineItemTypeIdentifier !== 'TOTAL').map(function (li, idx) {
                    if (li.OrderLineItemTypeIdentifier === 'LOADED_VALUE_DEDUCTION') {
                        return (<li key={idx} class="deduction">
                            <span>{li.ItemLabel}</span>
                            <span>{localizeNumber(li.AmountDisplay)}</span>
                        </li>)
                    } else {
                        return (
                            li.OrderLineItemTypeIdentifier !== 'TIP' || !hasApplied ?
                            <li key={idx}>
                                {
                                    li.OrderLineItemTypeIdentifier === 'DONATION' ?
                                    <span className='cart-calculator-removeable'>
                                        <span className='cart-calculator-item-remove' 
                                              onClick={() => startDonationAmountUpdate(null)}></span>
                                        <span>{li.ItemLabel}</span>
                                    </span> :
                                    <span>{li.ItemLabel}</span>
                                }
                                <span>{localizeNumber(li.AmountDisplay)}</span>
                            </li> : null)
                    }
                })}
                {this.showTotal() && <li className="total danger">
                    <strong>{window.resources.cart.total_text}</strong>

                    <strong>{localizeNumber(this.props.cartPricing.orderLineItems.find(li => li.OrderLineItemTypeIdentifier === 'TOTAL').AmountDisplay)}</strong>
                </li>}
            </ul>);
        }

        let buttons = this.hasAdditionalButtons() ? (<div className="cart-calculator__group cart-calculator__group--flex">
					<div className="cart-calculator__group-inner">
                        {   this.props.donationEnabled && this.props.donationCalculation && 
                            !this.props.cartPricing.orderLineItems.find(li => li.OrderLineItemTypeIdentifier === 'DONATION') && 
                            <ul style={{marginBottom:'10px'}} className="cart-calculator__list-btns"><li>
                                <button
                                    onClick={() => openModal({
                                        type: MODAL_DONATIONS
                                    })}
                                    className="cart-calculator__btn">{window.resources.donation.donate}
                                </button>
                            </li></ul>
                        }
                        { (this.props.tipButtonFeatureEnabled && (!this.props.tipForAlcoholOnlyFeatureEnabled || this.props.hasAlcoholInCart)) || this.props.employeeDiscountFeatureEnabled || this.props.loadedValueAccountEnabled || this.props.loyaltyFeatureEnabled ? 
						<ul className="cart-calculator__list-btns">
                    {this.props.tipButtonFeatureEnabled && (!this.props.tipForAlcoholOnlyFeatureEnabled || this.props.hasAlcoholInCart) && <li>
                        <button
                            onClick={() => openModal({
                                type: MODAL_TIP
                            })}
                            className="cart-calculator__btn">{this.props.tipAdded ? window.resources.cart.edit_tip_text : window.resources.cart.add_tip_text}
                        </button>
                    </li>}
                    {this.props.employeeDiscountFeatureEnabled && <li>
                        <button
                            onClick={() => openModal({
                                type: MODAL_AIRLINE_EMPLOYEE_DISCOUNT
                            })}
                            className="cart-calculator__btn editable" dangerouslySetInnerHTML={{ __html: this.replaceVariables(window.resources.cart.employee_discount_html) }}>
                        </button>
                    </li>}
                    {this.props.loyaltyFeatureEnabled && <LoyaltyButton />}
                    {this.props.loadedValueAccountEnabled && <li>
                        <LoadedValueAccountButton />
                    </li>}
					</ul> : null}
            </div>
        </div>) : (<div className="cart-calculator__group--borderless"></div>);

        let marketplacebuttons = this.hasAdditionalButtons() ? (<div className="cart-calculator__group cart-calculator__group--flex">
            <React.Fragment>
                <ul className='marketplace-cart-calculator-btns'>
                    {this.props.donationEnabled && this.props.donationCalculation && !this.props.cartPricing.orderLineItems.find(li => li.OrderLineItemTypeIdentifier === 'DONATION') && <li>
                        <CapitalCaseButton sx={{ width: '146px' }} className='marketplace-alt-button' variant='outlined' onClick={() => openModal({ type: MODAL_DONATIONS })}>{window.resources.donation.donate}</CapitalCaseButton>
                    </li>}
                    {this.props.tipButtonFeatureEnabled && (!this.props.tipForAlcoholOnlyFeatureEnabled || this.props.hasAlcoholInCart) && <li>
                        <CapitalCaseButton sx={{ width: '146px' }} className='marketplace-alt-button' variant='outlined' onClick={() => this.setState({ showTips: true })}>{this.props.tipAdded ? window.resources.cart.edit_tip_text : window.resources.cart.add_tip_text}</CapitalCaseButton>
                    </li>}
                    {this.props.employeeDiscountFeatureEnabled && <li>
                        <CapitalCaseButton sx={{ width: '146px' }} className='marketplace-alt-button' variant='outlined' onClick={() => this.setState({ showDiscount: true })}>{this.props.cart.data.promotion ? 'Edit Discount' : 'Add Discount'}</CapitalCaseButton>
                    </li>}
                    {this.props.loyaltyFeatureEnabled && <LoyaltyButton />}
                    {this.props.loadedValueAccountEnabled && <li>
                        <LoadedValueAccountButton />
                    </li>}
                </ul>
            </React.Fragment>
        </div>) : (<div className="cart-calculator__group--borderless"></div>);

        if (!this.showTotal() && !this.hasAdditionalButtons()) return (<div/>)

		return (
			<div className="cart-calculator">
                {window.isMarketplace ? marketplacebuttons : buttons}
				<div className="cart-calculator__group">
                    {orderLineItems}
				</div>
                { window.isMarketplace && <DiscountCode
                    open={this.state.showDiscount || marketplace.showDiscountModal}
                    handleClose={() => this.handleClose()}
                    isEdit={this.props.cart.data.promotion} />}
                { window.isMarketplace && this.props.tipButtonFeatureEnabled && (!this.props.tipForAlcoholOnlyFeatureEnabled || this.props.hasAlcoholInCart) &&
                    <Tips 
                        open={this.state.showTips}
                        handleClose={() => this.setState({ showTips: false })}
                        title={ marketplace && marketplace.deliveryLocation.toLowerCase() === 'pickup' ? window.resources.marketplace.cart.store_tip_text : window.resources.marketplace.cart.runner_tip_text } />
                }
			</div>
		);
	}
}

export default connect(
    (state) => ({
        currencySymbol: state.app.currencySymbol,
        tipAmount: state.cart.data.tipAmount != null ? state.cart.data.tipAmount : 0.00,
        tipAdded: state.cart.data && ((state.cart.data.tipAmount && state.cart.data.tipAmount > 0) || (state.cart.data.tipPercentage && state.cart.data.tipPercentage > 0)),
        tipButtonFeatureEnabled: features.tipButtonFeatureEnabled(state),
		modifiers: state.cart.modifiers,
        cart: state.cart,
        employeeDiscountFeatureEnabled: features.employeeDiscountFeatureEnabled(state),
        loadedValueAccountEnabled: features.loadedValueAccountEnabled(state),
        loyaltyFeatureEnabled: features.loyaltyFeatureEnabled(state),
        hasAlcoholInCart: hasAlcoholInCart(state),
        tipForAlcoholOnlyFeatureEnabled: features.tipForAlcoholOnlyFeatureEnabled(state),
        hideZeroDollars: features.hideZeroDollars(state), 
        donationEnabled: features.donationEnabled(state), 
        donationCalculation: state.donation ? state.donation.donationinfo : null
	}),
	{
		openModal,
		updateCartTotal, 
        startDonationAmountUpdate
	}
)(CartCalculator);
