/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**
 * @ The internal dependencies.
 */

import { openModal, closeModal } from 'store/state/ui/actions';
import { MODAL_DINE_IN } from 'lib/constants';
import { MODAL_TAKEAWAY } from '../../lib/constants';
import { sendTableCheckRequest, setDineInTakeAwaySelected } from 'store/state/tablecheck/actions';
import * as features from '../../store/state/features/selectors'

class ModalChooseDineInTakeAway extends Component {
    state = {

    }


    componentDidMount() {

    }

	handleDineIn = (e) => {
        e.preventDefault();
        this.props.closeModal();

        this.props.openModal({
            type: MODAL_DINE_IN
        })
    }

    handleTakeAway = (e) => {
        e.preventDefault();

        if (this.props.takeAwayNameRequired) {
            this.props.closeModal();
            this.props.openModal({
                type: MODAL_TAKEAWAY
            })
        } else {
            this.props.sendTableCheckRequest({
                showSpinner: true,
                getOpenTickets: this.props.tabFeatureEnabled,
                tableNumber: 'vk'
            });
            this.props.setDineInTakeAwaySelected(true)
            this.props.closeModal();
        }
    }

	render() {
		return (
			<div className="modal-frame modal-frame--card">
                <div className="modal-frame__body">

                    <div className="modal-frame__card">
                        <div className="modal-frame__head">
                            <h6 className="modal-frame__title">{window.resources.home.choose_dinein_takeaway}</h6>
                        </div>
                            <div className="modal-frame__actions modal-frame__actions--flex">
                                <button
                                    onClick={this.handleDineIn}
                                    className="btn btn--secondary">{window.resources.home.dinein}</button>

                                <button
                                    onClick={this.handleTakeAway}
                                    className="btn">{window.resources.home.takeaway}</button>
                            </div>
					</div>
				</div>
			</div>
		);
	}
} 


const mapStateToProps = state => {
    return {
        data: state.ui.modal.data,
        takeAwayNameRequired: features.takeAwayNameRequired(state),
    };
}

export default withRouter(connect(
    mapStateToProps,
    {
        openModal,
        closeModal,
        sendTableCheckRequest,
        setDineInTakeAwaySelected,
	}
)(ModalChooseDineInTakeAway));
