/**
 * @ The external dependecies.
 */
import { handleActions } from 'redux-actions';
import base64url from 'base64url';

/**
 * @ The internal dependecies.
 */
import { updateObject } from '../../../lib/utility';

/**
 * @ The actions.
 */
import {
    clearTab,
    clearTabAuthorization,
    tabAuthorizationUpdate,
    tabAuthorizationReceiveResponse,
    updateOpenCheckTicketId
} from './actions';
import { receiveTableCheckResponse } from '../tablecheck/actions';

/**
 * @ The reducer.
 */

//persisted to local storage
const defaultState = {
    //Stored in Base64UrlEncoded rather than plain text.
    authKey: null,
    openCheckTicketID: '',
    posOpenTicketCostDisplay: '',
    posOpenTicketDueAmountDisplay: '', 
    posOpenTicketItemCount: 0,
    cardLastFour: '',
    requiresCCAuthorization: true,
    authResp: null
}

/**
 * The actions Handler.
 *
 * @type {Function}
 */

const onTabAuthorizationUpdate = (state, action) => {

    const expirationMin = 60;
    const expirationMS = expirationMin * 60 * 1000;
    const expiry = new Date().getTime() + expirationMS;

    var payload = {
        ...action.payload,
        expiry
    };

    var authKey = base64url(JSON.stringify(payload));

    return updateObject(state, { authKey: authKey, cardLastFour: payload.lastFour});
}

const onClearTab = (state, action) => {
    return updateObject(state, { ...defaultState });
}

const onClearTabAuthorization = (state, action) => {
    return updateObject(state, { authKey: null, cardLastFour: '', authResp: null });
}

const onReceiveTableCheckResponse = (state, action) => {
    if (action.payload.success) {
        var successState = updateObject(state, { openCheckTicketID: action.payload.openCheckTicketID, posOpenTicketCostDisplay: action.payload.posOpenTicketCostDisplay, posOpenTicketDueAmountDisplay: action.payload.posOpenTicketDueAmountDisplay, posOpenTicketItemCount: action.payload.posOpenTicketItemCount, requiresCCAuthorization: action.payload.hasGrabOrder  });
        return successState;
    }
    else {
        var failState = updateObject(state, { openCheckTicketID: '', posOpenTicketCostDisplay: '', posOpenTicketDueAmountDisplay: '', posOpenTicketItemCount: 0, requiresCCAuthorization: true });
        return failState;
    }
}

const onTabAuthorizationReceiveResponse = (state, action) => {
    return updateObject(state, { authResp: action.payload });
}

const onUpdateOpenCheckTicketId = (state, action) => {
    return updateObject(state, { openCheckTicketID: action.payload });
}

const tab = handleActions({
    [clearTab]: (state, action) => onClearTab(state, action),
    [clearTabAuthorization]: (state, action) => onClearTabAuthorization(state, action),
    [tabAuthorizationUpdate]: (state, action) => onTabAuthorizationUpdate(state, action),
    [receiveTableCheckResponse]: (state, action) => onReceiveTableCheckResponse(state, action),
    [tabAuthorizationReceiveResponse]: (state, action) => onTabAuthorizationReceiveResponse(state, action),
    [updateOpenCheckTicketId]: (state, action) => onUpdateOpenCheckTicketId(state, action),
}, defaultState);

export default tab;
