import React from 'react';
import QtyField from 'components/common/qty-field';

import { localizeNumber } from '../../assets/resources/resources-manager';
import { MODE_ORDER } from 'lib/constants';

const ComboQuantity = ({ product, handleQtyChange }) => {
	return (<div className="combo__section">
		<div className="combo__group">
			<h6 className="combo__title combo__title--alt">{window.resources.combo.quantity_text}</h6>

			<div className="combo__qty">
				<QtyField
					withanimation="true"
					min={1}
					onChange={handleQtyChange}
					value={product.quantity}
				/>
			</div>
		</div>
	</div>
    )
}

export default ComboQuantity;