/**
 * @ The external dependecies
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

/**
 * @ The internal dependecies.
 */
import { getAddOnProducts } from 'store/state/cart/selectors';
import QtyField from 'components/common/qty-field';
import { Aux } from '../../lib/utility'
import { appendArray, updateObject } from '../../lib/utility';
import {
    startAddToCart,
    updatePrevAddons
} from '../../store/state/cart/actions';
import { canWeTemptYouView, canWeTemptYouAddition } from '../../store/state/customer/actions';
import { localizeNumber } from '../../assets/resources/resources-manager';
import * as Analytics from '../../lib/analytics-ga4';
import { Context } from '../../store/state/marketplace/store';

/**
 * Class for Cart Suggestions.
 *
 * @class CartSuggestions (name)
 */

class CartSuggestions extends Component {
    state = {
        products: {},
        formIsValid: false
    }
    static contextType = Context;

    handleQtyChange = ({ index, id }) => (e) => {
        const productIdentifier = `${id}`;

        const updatedProduct = updateObject(this.state.products[productIdentifier], {
            quantity: Number(e.target.value)
        });

        const updatedproducts = updateObject(this.state.products, {
            [productIdentifier]: updatedProduct
        });

        let formIsValid = false;
        for (let key in updatedproducts) {
            const productQuantity = updatedproducts[key];
            if (productQuantity.active && productQuantity.quantity > 0) {
                formIsValid = true;
                break;
            }
        }

        this.setState({
            products: updatedproducts,
            formIsValid: formIsValid
        });
    }

    handleActiveChange = ({ index, id }) => (e) => {
        const productIdentifier = `${id}`;

        const updatedProduct = updateObject(this.state.products[productIdentifier], {
            active: !this.state.products[productIdentifier].active
        });

        const updatedproducts = updateObject(this.state.products, {
            [productIdentifier]: updatedProduct
        });

        let formIsValid = false;
        for (let key in updatedproducts) {
            const productQuantity = updatedproducts[key];
            if (productQuantity.active && productQuantity.quantity > 0) {
                formIsValid = true;
                break;
            }
        }

        //If true each time an item is clicked it is added to cart
        if (window.resources.cart_suggestions.add_single_item) {
            this.setState({
                products: updatedproducts,
                formIsValid: formIsValid
            }, () => {
                this.addToOrderHandler(null);
            });
        }
        else {
            this.setState({
                products: updatedproducts,
                formIsValid: formIsValid
            });
        }
    }

    addToOrderHandler = (event) => {
        const marketplace = this.context;
        let updatedProducts = this.state.products;

        const productsToAdd = [];
        for (let key in this.state.products) {
            const productQuantity = this.state.products[key];
            if (productQuantity.active) {
                const productToAdd = updateObject(this.props.products.find(p => p.inventoryItemID === productQuantity.inventoryItemID),
                    {
                        inventoryMainOptionChoice: {
                            choices: [],
                            options: []
                        },
                        quantity: productQuantity.quantity
                    });

                //Select the first size
                if (productToAdd.inventoryItemSubs.length > 0) {
                    productToAdd.inventoryItemSubs[0].selected = true;
                }

                this.props.canWeTemptYouAddition(productToAdd.inventoryItemID);

                productsToAdd.push(productToAdd);
            }

            const updatedProduct = updateObject(this.state.products[key], {
                quantity: 1,
                active: false
            });

            updatedProducts = updateObject(updatedProducts, {
                [key]: updatedProduct
            });
        }

        if (productsToAdd.length > 0) {
            this.props.startAddToCart({
                products: productsToAdd,
                showSuggestions: false, 
                type: 'addon products', 
                ga: window.isMarketplace ? marketplace.ga : this.props.ga,
                currencyCode: this.props.config.isoCurrencyCode
            });
            this.props.onAddToOrder(productsToAdd);
        }

        this.setState({ products: updatedProducts });
    }

	componentWillMount() {
        const marketplace = this.context;
        this.props.allProducts.forEach((product, i) => {
			this.setState(({ products }) => ({
				products: {
					...products,
                    [`${product.inventoryItemID}`]: {
                        quantity: 1,
                        inventoryItemID: product.inventoryItemID,
                        active: false
                    }
				}
			}));
        });

        //if true addons items only shown once
        if (window.resources.cart_suggestions.show_once_only)
        {
            const prevAddonItems = this.props.prevAddonItems ? appendArray(this.props.prevAddonItems, this.props.addonItems) : this.props.addonItems;
            this.props.updatePrevAddons({
                prevAddonItems: prevAddonItems
            });
        }
        //const cartData = updateObject(this.state.data, { prevAddonItems: prevAddonItems });
        //return updateObject(this.state, {
        //    data: cartData
        //});

        this.props.products.forEach((product, i) => {
            this.props.canWeTemptYouView(product.inventoryItemID);
        });
        if (window.isMarketplace && marketplace.ga) Analytics.logUpsellOrAddonProductList('addon products', this.props.products);
        if (!window.isMarketplace && this.props.ga) Analytics.logUpsellOrAddonProductList('addon products', this.props.products);
	}

	render() {
		const { products } = this.props;

        return (
            <Aux>
			    <div className="boxes">
                    {products && products.map((product, i) => (
					    <div
						    key={product.inventoryItemID + product.inventoryItemName + i}
						    className="box">
                            <input
                                onChange={this.handleActiveChange({
                                    index: i,
                                    id: product.inventoryItemID
                                })}
                                checked={this.state.products[`${product.inventoryItemID}`].active}
							    className="box__field"
							    type="checkbox"
							    id={`box-${i}`}
						    />

						    <label className="box__inner" htmlFor={`box-${i}`}>
							    <div
								    className="box__image"
								    style={{
                                        backgroundImage: `url('${encodeURI(product.imageV2.product_1_1 || product.inventoryItemImageName)}')`
								    }}>
								    <div className="box__qty">
									    <QtyField
										    modifier="small"
										    variant="primary"
										    withanimation="true"
										    min={1}
										    onChange={this.handleQtyChange({
											    index: i,
											    id: product.inventoryItemID
										    })}
										    value={this.state.products[`${product.inventoryItemID}`].quantity}
									    />
								    </div>
							    </div>

							    <div className="box__content">
								    <h6 className="box__title">{product.inventoryItemName}</h6>

								    <div className="box__meta">
									    <h6 className="box__price">{localizeNumber(product.inventoryItemSubs[0].cost)}</h6>

									    <span className="box__icon"></span>
								    </div>
							    </div>
						    </label>
					    </div>
				    ))}
                </div>

                {!window.resources.cart_suggestions.add_single_item && <div className="cart__section-actions">
                    <button onClick={this.addToOrderHandler}
                        disabled={!this.state.formIsValid}
                        className="btn btn--block">{window.resources.cart_suggestions.confirm_text}</button>
                </div>}
          </Aux>
		)
	}
}

export default connect(
    (state) => ({
        allProducts: state.app.data.inventoryItemMains,
        products: state.cart.cartPricingData && getAddOnProducts(appendArray(state.cart.data.arrCart, [state.ui.modal.data]), state.cart.cartPricingData.addOnItems, state.app.data.inventoryItemMains),
        addonItems: state.cart.cartPricingData.addOnItems,
        prevAddonItems: state.cart.data.prevAddonItems, 
        ga: state.app.ga, 
        config: state.app.config
	})
    , {
        startAddToCart,
        updatePrevAddons,
        canWeTemptYouView,
        canWeTemptYouAddition
    })(CartSuggestions);
