/**
 * @ The external dependencies.
 */
import { handleActions } from 'redux-actions';
import { assoc } from 'ramda';

/**
 * @ The internal dependencies.
 */
import {
	startRequest,
	endRequest,
	endRequestWithError,
	resetRequests
} from './actions';

/**
 * Track the status of all network requests across the application.
 *
 * @type   {Function}
 * @return {Object}
 */
const requests = handleActions({
	[startRequest]: (state, { payload: { id } }) => assoc(id, {
		running: true,
		error: ''
	}, state),

	[endRequest]: (state, { payload: { id } }) => assoc(id, {
		running: false,
		error: '',
	}, state),

	[endRequestWithError]: (state, { payload: { id, error } }) => assoc(id, {
		running: false,
		error
	}, state),

	[resetRequests]: (state, { payload }) => ({}),
}, {});

export default requests;
