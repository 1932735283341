/**
 * @ The external dependencies.
 */
import {
    all,
    call,
    takeLatest,
    takeEvery
} from 'redux-saga/effects';

import {
    requestPreOrderQuestionnaireData,
    receivePreOrderQuestionnaireData,
    sendPreOrderQuestionnaireAnswer,
    receivePreOrderQuestionnaireResponse,
    sendPreOrderQuestionnaireOrderInfo,
    receivePreOrderQuestionnaireOrderInfoResponse

} from './actions';
import makeRequest from '../../../lib/helpers/api-request';

export function* requestPreOrderQuestionnaireDataWorker(action) {
    yield call(makeRequest, {
        endpoint: 'getPreOrderQuestionnaireData',
        payload: action.payload,
        requestAction: action,
        receiveAction: receivePreOrderQuestionnaireData
    });
}

export function* savePreOrderQuestionnaireAnswerWorker(action) {
    yield call(makeRequest, {
        endpoint: 'saveQuestionnaireAnswer',
        payload: action.payload,
        requestAction: action,
        receiveAction: receivePreOrderQuestionnaireResponse
    });
}

export function* savePreOrderQuestionnaireOrderInfoWorker(action) {
    yield call(makeRequest, {
        endpoint: 'saveQuestionnaireOrderInfo',
        payload: action.payload,
        requestAction: action,
        receiveAction: receivePreOrderQuestionnaireOrderInfoResponse
    });
}

/**
 * Start all workers.
 *
 * @return {Generator}
 */
export default function* foreman() {
    yield all([
        takeLatest(requestPreOrderQuestionnaireData, requestPreOrderQuestionnaireDataWorker),
        takeEvery(sendPreOrderQuestionnaireAnswer, savePreOrderQuestionnaireAnswerWorker),
        takeEvery(sendPreOrderQuestionnaireOrderInfo, savePreOrderQuestionnaireOrderInfoWorker)
    ]);
}
