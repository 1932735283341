/**
 * @ The external dependencies.
 */
import {
	all,
    call,
	takeLatest,
	select
} from 'redux-saga/effects';

/**
 * @ The internal dependencies.
 */
import {
    startSendEmailReceipt,
    startSendTextReceipt,
    receiveSendEmailReceiptData,
    receiveSendTextReceiptData
} from './actions';

import { receiveTableCheckResponse } from '../tablecheck/actions';

import { getCheckout } from './selectors';

import makeRequest from 'lib/helpers/api-request';

export function* sendEmailReceiptRequest(action) {
    let checkout = yield select(getCheckout);

    const payload = {
        sendToEmail: action.payload,
        email: checkout.email,
        orderID: checkout.orderID,
        posOrderID: checkout.posOrderID,
        bExpensifyCC: false
    };

    yield call(makeRequest, {
        endpoint: 'sendOrderReceipt',
        payload: payload,
        requestAction: action,
        receiveAction: receiveSendEmailReceiptData
    });
}

function* sendTextReceiptWorker(action) {
	yield call(sendTextReceiptRequest, action);
}

export function* sendTextReceiptRequest(action) {
    let checkout = yield select(getCheckout);

    const payload = {
        phone: action.payload.phone,
        phoneCountry: action.payload.phoneCountry,
        orderID: checkout.orderID
    };

    yield call(makeRequest, {
        endpoint: 'sendOrderTextReceipt',
        payload: payload,
        requestAction: action,
        receiveAction: receiveSendTextReceiptData
    });
}

function* sendEmailReceiptWorker(action) {
    yield call(sendEmailReceiptRequest, action);
}

function* receiveTableCheckResponseWorker(action) {
   
}

/**
 * Start all workers.
 *
 * @return {Generator}
 */
export default function* foreman() {
	yield all([
        takeLatest(startSendEmailReceipt, sendEmailReceiptWorker),
        takeLatest(startSendTextReceipt, sendTextReceiptWorker),
        takeLatest(receiveTableCheckResponse, receiveTableCheckResponseWorker)
	]);
}
