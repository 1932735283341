/**
 * @ The internal dependecies.
 */
import braintreeImage from '../../assets/images/braintree.png';

/**
 * @ The external dependecies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';

/**
 * Class for braintree drop in.
 *
 * @class BraintreeDropIn (name)
 */
class BraintreeDropIn extends Component {
	state = {
		dropInInstance: null,
		isSubmitButtonDisabled: true, 

		isFailedPayment: false
	}
	retry = 0;
	dropintimeout = null;
	paymenttimeout = null;
	
	replaceVariables(html) {
		return html.replace("{paymentProvider}", "Braintree");
	}

	componentDidMount = () => {
		if (!this.props.braintree || this.state.dropInInstance) return
		
		this.dropintimeout = setInterval(() => {
					this.retry++;
					Sentry.captureException(new Error(`braintree call back not completed`), scope => {
						scope.setTag('error-type', 'bt-callback');
						scope.setTag('token', this.props.cartToken);
						scope.setTag('bt-retry', this.retry)
						scope.setTag('store', window.waypointID);
					});
					const element = document.getElementById('braintree-dropin-form-id');
					if (element) element.innerHTML = '';
					this.setup();
				}, 20000);
		this.setup();
	}

	shouldComponentUpdate = (nextProps, nextState) => {
		return (this.state.isSubmitButtonDisabled !== nextState.isSubmitButtonDisabled)
			|| (this.props.authorizationToken !== nextProps.authorizationToken)
			|| (this.props.locale !== nextProps.locale)
			|| (this.props.submitButtonText !== nextProps.submitButtonText)
			|| (this.props.message !== nextProps.message)
	}

	componentDidUpdate = (prevProps, prevState) => {
		if (
			this.props.authorizationToken && this.state.dropInInstance &&
			( prevProps.authorizationToken !== this.props.authorizationToken || prevProps.locale !== this.props.locale )
		) {
			this.tearDown().then(() => {
				this.setState({
					dropInInstance: null,
					isSubmitButtonDisabled: true
				}, () => {
					this.setup();
				})
			}).catch((err) => {
				if ( this.props.onError ) {
					this.props.onError(err);
				}
			});
		}
	}

	componentWillUnmount = () => {
		if ( !this.state.dropInInstance ) return

		this.tearDown().catch((err) => {
			if ( this.props.onError ) {
				this.props.onError(err);
			}
		});
	}

	setup = () => {
		let options = {
			authorization: this.props.authorizationToken,
			//container: '.braintree-dropin-form',
			selector: '#braintree-dropin-form-id',
			locale: this.props.locale,
			translations: this.props.translations,
			paypal: this.props.paypal,
			paypalCredit: this.props.paypalCredit,
			applePay: this.props.applePay,
			googlePay: this.props.googlePay,
			paymentOptionPriority: this.props.paymentOptionPriority,
			card: this.props.card
		};

		if (this.props.threeDSecure) {
			options.threeDSecure = true;
		}

		this.props.braintree.create(options, (err, dropinInstance) => {
			clearInterval(this.dropintimeout);
			if (err) {
				if ( this.props.onError ) {
					if (this.retry > 0) {
						Sentry.captureMessage(`braintree call back returned error`, scope => {
							scope.setTag('msg-type', 'bt-callback-error');
							scope.setTag('token', this.props.cartToken);
							scope.setTag('bt-retry', this.retry)
							scope.setTag('store', window.waypointID);
						});
					}
					this.props.onError(err)
				}
				return
			} else {
				if ( this.props.onCreate ) {
					if (this.retry > 0) {
						Sentry.captureMessage(`braintree call back completed`, scope => {
							scope.setTag('msg-type', 'bt-callback-recover');
							scope.setTag('token', this.props.cartToken);
							scope.setTag('bt-retry', this.retry)
							scope.setTag('store', window.waypointID);
						});
					}
					this.props.onCreate(dropinInstance);
				}
			}

			if (dropinInstance.isPaymentMethodRequestable()) {
				this.setState({
					isSubmitButtonDisabled: false
				});
			}

			dropinInstance.on('paymentMethodRequestable', (event) => {
				//console.log('paymentMethodRequestable: type = ' + event.type);
				//console.log('paymentMethodRequestable: paymentMethodIsSelected = ' + event.paymentMethodIsSelected);
                this.setState({
					isSubmitButtonDisabled: false
                });

                if (event.paymentMethodIsSelected && event.type !== 'CreditCard') {
                    // The customer has completed the flow and we are
                    // ready to submit the payment method nonce to the server.
                    this.handleSubmit(null);
                }
			});

			dropinInstance.on('noPaymentMethodRequestable', () => {
				this.setState({
					isSubmitButtonDisabled: true
				});
			});

			this.setState({
				dropInInstance: dropinInstance
			});
		});
	}

	tearDown = () => {
		if ( this.props.onDestroyStart ) {
			this.props.onDestroyStart()
		}

		return new Promise((resolve, reject) => {
			this.state.dropInInstance.teardown((err) => {
				if (this.props.onDestroyEnd) {
					this.props.onDestroyEnd(err);
				}

				if (err) {
					return reject(err);
				} else {
					return resolve();
				}
			});
		});
	}

	handleSubmit = (event) => {
		//console.log('handleSubmit: started');
		//console.log('handleSubmit: isSubmitButtonDisabled = ' + this.state.isSubmitButtonDisabled);
		//console.log('handleSubmit: paymentMethodIsSelected = ' + this.state.paymentMethodIsSelected);
		if (this.state.dropInInstance && (!this.state.isSubmitButtonDisabled || this.state.dropInInstance.paymentMethodIsSelected)) {
			this.setState({
				isSubmitButtonDisabled: true
            }, () => {
                if (this.props.onSubmit) {
                    this.props.onSubmit();
				}

				let requestPaymentMethodOptions = {};

				if (this.props.threeDSecure) {
					requestPaymentMethodOptions.threeDSecure = {
						amount: this.props.amount,
						email: this.props.email, 
						mobilePhoneNumber: this.props.phone, 
						challengeRequested: true
					};
				}

				// setup Sentry logging on timer 
				this.paymenttimeout = setTimeout(() => {
					Sentry.captureException(new Error(`braintree requestPaymentMethod callback not made`), scope => {
						scope.setTag('error-type', 'bt-payment-callback');
						scope.setTag('token', this.props.cartToken);
						scope.setTag('store', window.waypointID);
					});
					if (this.props.onError) this.props.onError();
					if (this.props.onSubmitResponse) this.props.onSubmitResponse();
				}, 30000);
				this.state.dropInInstance.requestPaymentMethod(requestPaymentMethodOptions, (err, payload) => {
					//console.log('requestPaymentMethod: err = ' + err);
					//console.log('requestPaymentMethod: payload = ' + payload);
					
					// clear payment timeout when requestpaymentmethod is called successfully
					clearTimeout(this.paymenttimeout);

					this.setState({
						isSubmitButtonDisabled: false
                    });

                    if (this.props.onSubmitResponse) {
                        this.props.onSubmitResponse();
					}

					if (err) {
						if (this.props.onError) {
							this.props.onError(err);
						}

                        this.state.dropInInstance.clearSelectedPaymentMethod();
                    } else {
                        var liabilityShifted = payload.liabilityShifted; // true || false
                        var liabilityShiftPossible = payload.liabilityShiftPossible; // true || false

						if (!liabilityShiftPossible || liabilityShifted) {
                            this.props.handlePaymentMethod(payload);
                        }
						else {
							//user failed 3D Secure authentication
							//https://developers.braintreepayments.com/guides/3d-secure/client-side/javascript/v3
							//https://developers.braintreepayments.com/guides/3d-secure/testing-go-live/ruby
                            if (this.props.onError) {
								this.props.onError('3D Secure authentication failed. liabilityShiftPossible: true (indicates that the payment method was eligible for 3D Secure). liabilityShifted: false (user failed 3D Secure authentication)');
							}

                            this.state.dropInInstance.clearSelectedPaymentMethod();
                        }
					}
				});
			});
		}
	}

	render = () => {
		return (
			<div className={this.props.className}>
				<div className='braintree-dropin-form' id='braintree-dropin-form-id'></div>

				<div className='braintree-dropin-submit-btn-wrapper'>
					{this.props.renderSubmitButton({
						onClick: this.handleSubmit,
						isDisabled: this.state.isSubmitButtonDisabled,
						text: this.props.submitButtonText
					})}
                </div>
				<div className='payment-secure'><img style={{width:'150px'}} src={braintreeImage} alt="Secured By Braintree" /></div>
				{this.props.message && <div className='payment-message editable' dangerouslySetInnerHTML={{ __html: this.props.message }}></div>}
			</div>
		)
	}
}

BraintreeDropIn.propTypes = {
	braintree: PropTypes.object.isRequired,
	authorizationToken: PropTypes.string.isRequired,
	handlePaymentMethod: PropTypes.func.isRequired,
	onCreate: PropTypes.func,
	onError: PropTypes.func,
	onDestroyStart: PropTypes.func,
	onDestroyEnd: PropTypes.func,
    locale: PropTypes.string,
    translations: PropTypes.object,
	paypal: PropTypes.object,
	paypalCredit: PropTypes.object,
	applePay: PropTypes.object,
	googlePay: PropTypes.object,
	paymentOptionPriority: PropTypes.array,
	card: PropTypes.object,
	submitButtonText: PropTypes.string,
	className: PropTypes.string,
    renderSubmitButton: PropTypes.func,
	amount: PropTypes.number,
	message: PropTypes.string
}

const renderSubmitButton = ({ onClick, isDisabled, text }) => {
	return (
		<button
			onClick={onClick}
			disabled={isDisabled}
		>{text}</button>
	)
}

renderSubmitButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	isDisabled: PropTypes.bool.isRequired,
	text: PropTypes.string.isRequired
}

BraintreeDropIn.defaultProps = {
	className: 'braintree-dropin-react',
	submitButtonText: 'Purchase',
	renderSubmitButton
}

export default connect((state) => ({
	cartToken: state.cart.data.cartToken
}))(BraintreeDropIn);