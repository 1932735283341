import React from 'react';

import {
    compose,
    sortBy,
    prop,
    sortWith,
    descend
} from 'ramda';

import { localizeNumber } from '../../assets/resources/resources-manager';
import { MODE_ORDER } from 'lib/constants';

const ComboSizes = ({ product, handleSizeChange, sortProductSizesByCostDescending,  mode }) => {
    const sizes = product.inventoryItemSubs;
    const hasSizesToDisplay = product.inventoryItemSubs.length > 1;

    const sortProductSizes = (sizes) => {
        if (sortProductSizesByCostDescending) {
            return sortWith([descend(compose(parseInt, prop('cost')))], sizes)
        }
        return sortBy(compose(parseInt, prop('inventoryOrder')), sizes)
    }

    return (hasSizesToDisplay && (<div className="combo__section">
            <div className="combo__group">
                <div role='checkbox' tabIndex={0} aria-required='true'><h6 className="combo__title combo__title--alt">{window.resources.combo.size_text} <span>*</span></h6></div>

                <div className="checkboxes">
                    {sortProductSizes(sizes).map((innerSize, i) => (
                        <div key={innerSize.inventoryItemSubID + i} className="checkbox">
                            <input
                                onChange={handleSizeChange({
                                    index: i,
                                    id: innerSize.inventoryItemSubID
                                })}
                                checked={product.inventoryItemSubs.filter(x => x.inventoryItemSubID === innerSize.inventoryItemSubID && x.selected).length > 0}
                                value={product.inventoryItemSubs.filter(x => x.inventoryItemSubID === innerSize.inventoryItemSubID)[0].selected}
                                id={innerSize.inventoryItemSubID + i}
                                type="checkbox" />

                            <label className={mode !== MODE_ORDER ? "hide" : ""} htmlFor={innerSize.inventoryItemSubID + i}>
                                {innerSize.inventoryItemSubName}
                                {innerSize.comboCost !== 0.00 && (` (${localizeNumber(innerSize.comboCost.toFixed(2))})`)}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </div>)
    )
}

export default ComboSizes;