/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/dist/style.css';
import { parsePhoneNumber } from 'react-phone-number-input'

/**
 * @ The internal dependencies.
 */
import { openModal, closeModal } from 'store/state/ui/actions';
import { startSendTextReceipt } from 'store/state/checkout/actions';
import { MODAL_RECEIPT_CHOICE, MODAL_RECEIPT_SUCCESS } from 'lib/constants';
import { updateObject, checkValidity } from '../../lib/utility';
import { updatePhone } from 'store/state/cart/actions';
import * as features from '../../store/state/features/selectors';
import { loyaltyLoggedIn } from '../../store/state/loyalty/selectors';

class ModalReceiptText extends Component {
   
      state = {
        form: {
            mobileNumber: {
                value: '',
                validation: {
                    required: true,
                    isMobile: true
                },
                valid: false,
                touched: false
            }
        },
        formIsValid: false
    }

    componentDidMount() {
        if (this.props.phone) {
            this.handleMobileNumberChange(this.props.phone, 'mobileNumber');
        }
        else if (this.props.loyaltyLoggedIn && this.props.mobileNumber) {
            let australianNumber = '+61' + this.props.mobileNumber;
            this.handleMobileNumberChange(australianNumber, 'mobileNumber');
            //let validAustralianNumber = checkValidity(australianNumber, { required: true, isMobile: true });
            //if (validAustralianNumber) {
                //this.handleMobileNumberChange(australianNumber, 'mobileNumber');
            //}
        }
    }

    checkValidityCustom(value, rules) {
        let isValid = checkValidity(value, rules);
        return isValid;
    }

    handleMobileNumberChange = (mobileNumberValue, field) => {
        const updatedFormElement = updateObject(this.state.form[field], {
            value: mobileNumberValue,
            valid: this.checkValidityCustom(mobileNumberValue, this.state.form[field].validation),
            touched: true
        });

        const updatedForm = updateObject(this.state.form, {
            [field]: updatedFormElement
        });

        let formIsValid = true;
        for (let field in updatedForm) {
            formIsValid = updatedForm[field].valid && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid: formIsValid });
    }

    handleCancelClick = (e) => {
        e.preventDefault();
        this.props.closeModal();

        if (this.props.data.send && this.props.emailReceiptFeatureEnabled) {
            this.props.openModal({
                type: MODAL_RECEIPT_CHOICE
            });
        }
    }


    handleSubmitButton = (e) => {
        e.preventDefault();

        const phoneNumber = parsePhoneNumber(this.state.form.mobileNumber.value);

        this.props.closeModal();

        if (this.props.data.send) {
            this.props.startSendTextReceipt({
                phoneCountry: phoneNumber.country,
                phone: phoneNumber.nationalNumber
            });

            this.props.openModal({
                type: MODAL_RECEIPT_SUCCESS,
                data: {
                    receiptRecipient: this.state.form.mobileNumber.value
                }
            });
        }
        else {
            this.props.updatePhone({
                phoneCountry: phoneNumber.country,
                phone: phoneNumber.nationalNumber
            });
        }

        if (this.props.data.fun) {
            this.props.data.fun();
        }
    }

	render() {
		return (
			<div className="modal-frame modal-frame--card">
				<div className="modal-frame__body">
					<div className="modal-frame__card">
						<div className="modal-frame__head">
                            <h6 className="modal-frame__title">{window.resources.receipt_text.label_text}</h6>
						</div>

						<div className="modal-frame__card-body">
	
                            <div className="modal-frame__card-field-preceder">
                                <PhoneInput enableLongNumbers={window.resources.receipt_text.enableLongNumbers} enableSearchField autoFormat={true} buttonClass="mobile-country-btn" value={this.state.form.mobileNumber.value} onChange={(mobileNumber) => this.handleMobileNumberChange(mobileNumber, 'mobileNumber')} inputClass="field" placeholder={window.resources.receipt_text.placeholder_text} defaultCountry={window.resources.receipt_text.default_country} disableAreaCodes={true} preferredCountries={window.resources.receipt_text.preferred_countries} preserveOrder={['preferredCountries']} countryCodeEditable={true} />
                            </div>

                            <div className="modal-frame__message">{window.resources.receipt_text.message_text}</div>

                        </div>
      
						<div className="modal-frame__actions modal-frame__actions--flex">
							<button
                                onClick={this.handleCancelClick}
                                className="btn btn--secondary">{window.resources.receipt_text.cancel_text}</button>

                            <button
                                disabled={!this.state.formIsValid}
                                onClick={this.handleSubmitButton}
                                className="btn">{window.resources.receipt_text.confirm_text}</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(
    (state) => ({
        phone: state.cart.data.phone,
        emailReceiptFeatureEnabled: features.emailReceiptFeatureEnabled(state),
        data: state.ui.modal.data,
        loyaltyLoggedIn: loyaltyLoggedIn(state),
        mobileNumber: state.loyalty.mobileNumber
	}),
    {
        startSendTextReceipt,
        openModal,
        closeModal,
        updatePhone
	}
)(ModalReceiptText);
