/**
 * @ The external dependencies.
 */
import { createAction } from 'redux-actions';

/**
 * Mark a network request as running.
 *
 * @type   {Function}
 * @param  {String|Function} action
 * @return {Object}
 */
export const startRequest = createAction('requests/start-request', action => ({
	id: `'${action}'`
}));

/**
 * Mark a network request as completed.
 *
 * @type   {Function}
 * @param  {String|Function} action
 * @return {Object}
 */
export const endRequest = createAction('requests/end-request', action => ({
	id: `'${action}'`
}));

/**
 * Mark a network request as failed.
 *
 * @type   {Function}
 * @param  {String|Function} action
 * @param  {String|Object}   error
 * @return {Object}
 */
export const endRequestWithError = createAction('requests/end-request-with-error', (action, error = '') => ({
	id: `'${action}'`,
	error
}));

/**
 * Reset requests
 *
 * @type   {Function}
 * @return {Object}
 */
export const resetRequests = createAction('requests/reset');
