/**
 * @ The external dependecies.
 */
import { handleActions } from 'redux-actions';

/**
 * @ The internal dependecies.
 */
import { getOfflineProducts } from 'store/state/cart/selectors';

/**
 * @ The actions.
 */
import { updateTick, receiveTableTopConfig, receiveTableTopData, receiveTableTopConfigBeforeOrder, receiveMultiConceptData } from './actions';
import { reciveCartData, receiveOrderConfirmationData } from '../cart/actions';
import { updateObject } from '../../../lib/utility';

/**
 * @ The reducer.
 */
const defaultState = {
    validMultiConcept: false,
    multiConceptData: null,
    validStore: false,
    loading: true,
    data: null,
    config : null,
    currencySymbol: '$',
    ga: null,
    surveyLink: ''
}

/**
 * The actions Handler.
 *
 * @type {Function}
 */

const onReceiveTableTopConfig = (state, action) => {
    const validStore = action.payload ? action.payload.validDomainStore : false;
    return updateObject(state, {
        config: action.payload,
        validStore: validStore,
        loading: false,
        ga: action.payload ? action.payload.GA_ID : null,
        currencySymbol: action.payload ? action.payload.currencySymbol : state.currencySymbol,
        surveyLink: action.payload ? action.payload.SurveyLink : ''
    });
}

const onReceiveTableTopConfigBeforeOrder = (state, action) => {
    const updatedData = updateObject(state.config,
        { bStoreIsCurrentlyOpen: action.payload.bStoreIsCurrentlyOpen }
    );

    return updateObject(state, {
        config: updatedData
    });
}

const onReceiveTableTopData = (state, action) => {
    //const validStore = action.payload ? true : false;
    return updateObject(state, {
        data: action.payload,
        //validStore: validStore,
        //loading: false,
        ga: action.payload ? action.payload.GA_ID : null,
        currencySymbol: action.payload ? action.payload.currencySymbol : state.currencySymbol,
        surveyLink: action.payload ? action.payload.SurveyLink : ''
    });
}

const onReceiveMultiConceptData = (state, action) => {
    const validMultiConcept = action.payload && action.payload.Enabled ? true : false;
    return updateObject(state, {
        multiConceptData: action.payload,
        validMultiConcept: validMultiConcept,
        loading: false,
    });
}

const onReciveCartData = (state, action) => {
    const itemsOffline = getOfflineProducts(action.payload);

    if (itemsOffline.length > 0 && state.data && state.data.inventoryItemMains) {
        const updatedData = updateObject(state.data,
            {
                inventoryItemMains: state.data.inventoryItemMains.filter((value, index, array) => !itemsOffline.includes(value.inventoryItemID)),
                mobileFeaturedItems: state.data.mobileFeaturedItems.filter((value, index, array) => !itemsOffline.includes(value.inventoryItemID)),
                mobileUpsellItems: state.data.mobileUpsellItems.filter((value, index, array) => !itemsOffline.includes(value.inventoryItemID))
            }
        );

        return updateObject(state, {
            data: updatedData
        });
    } else {
        return state;
    }
}

const onReceiveOrderConfirmationData = (state, action) => {
    const itemsOffline = getOfflineProducts(action.payload);

    if (itemsOffline.length > 0 && state.data && state.data.inventoryItemMains) {
        const updatedData = updateObject(state.data,
            {
                inventoryItemMains: state.data.inventoryItemMains.filter((value, index, array) => !itemsOffline.includes(value.inventoryItemID)),
                mobileFeaturedItems: state.data.mobileFeaturedItems.filter((value, index, array) => !itemsOffline.includes(value.inventoryItemID)),
                mobileUpsellItems: state.data.mobileUpsellItems.filter((value, index, array) => !itemsOffline.includes(value.inventoryItemID))
            }
        );

        return updateObject(state, {
            data: updatedData
        });
    } else {
        return state;
    }
}

const onUpdateTick = (state, action) => {
    return updateObject(state, {
        tick: new Date().getTime()
    });
}

const app = handleActions({
    [updateTick]: (state, action) => onUpdateTick(state, action),
    [receiveTableTopConfig]: (state, action) => onReceiveTableTopConfig(state, action),
    [receiveTableTopConfigBeforeOrder]: (state, action) => onReceiveTableTopConfigBeforeOrder(state, action),
    [receiveTableTopData]: (state, action) => onReceiveTableTopData(state, action),
    [reciveCartData]: (state, action) => onReciveCartData(state, action),
    [receiveOrderConfirmationData]: (state, action) => onReceiveOrderConfirmationData(state, action),
    [receiveMultiConceptData]: (state, action) => onReceiveMultiConceptData(state, action)
}, defaultState);

export default app;
