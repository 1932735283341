/**
 * The supported session types
 */
export const CUSTOMER = 'customer';
export const LOCALIZATION = 'localization';
export const STYLE = 'style';

/**
 * Sets the session customer.
 *
 * @param {Object}
 */
export const setSessionCustomer = info => localStorage.setItem(CUSTOMER, JSON.stringify(info));
export const removeSessionCustomer = () => localStorage.removeItem(CUSTOMER);

/**
 * Gets the session customer.
 *
 * @return {Object}
 */
export const getSessionCustomer = () => {
    const data = JSON.parse(localStorage.getItem(CUSTOMER));

    return data;
};

/**
 * Sets the session localization.
 *
 * @param {Object}
 */
export const setSessionLocalization = info => localStorage.setItem(LOCALIZATION, JSON.stringify(info));
export const removeSessionLocalization = () => localStorage.removeItem(LOCALIZATION);

/**
 * Gets the session customer.
 *
 * @return {Object}
 */
export const getSessionLocalization = () => {
    const data = JSON.parse(localStorage.getItem(LOCALIZATION));

    return data;
};

/**
 * Sets the session localization.
 *
 * @param {Object}
 */
export const setSessionStyle = info => localStorage.setItem(STYLE, JSON.stringify(info));
export const removeSessionStyle = () => localStorage.removeItem(STYLE);

/**
 * Gets the session customer.
 *
 * @return {Object}
 */
export const getSessionStyle = (kobp) => {
    const data = JSON.parse(localStorage.getItem(STYLE));
    if (data && data.kobp == kobp) {
        return data;
    }
    else {
        return { kobp: kobp, xpID: '', waypointID: '' };
    }
};