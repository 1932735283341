/**
 * @ The external dependecies.
 */
import { handleActions } from 'redux-actions';

/**
 * @ The internal dependecies.
 */
import { updateObject } from '../../../lib/utility';

/**
 * @ The actions.
 */
import {
    receiveOrderConfirmationData,
} from '../cart/actions';

import {
    clearSurvey,
    receiveSurveyResponse
} from './actions';

/**
 * @ The reducer.
 */
const defaultState = {
    questions: null
}

/**
 * The actions Handler.
 *
 * @type {Function}
 */

const onReceiveOrderConfirmationData = (state, action) => {
    if (!action.payload.exception) {
        return updateObject(state, {
            questions: action.payload.RatingQuestions
        });
    }
    else {
        return updateObject(state, {
            questions: null
        });
    }
}

const onReceiveSurveyResponse = (state, action) => {
     return state;
}

const onClearSurvey = (state, action) => {
    return updateObject(state, {
        ...defaultState
    });
}

const tablecheck = handleActions({
    [receiveSurveyResponse]: (state, action) => onReceiveSurveyResponse(state, action),
    [receiveOrderConfirmationData]: (state, action) => onReceiveOrderConfirmationData(state, action),
    [clearSurvey]: (state, action) => onClearSurvey(state, action)
}, defaultState);

export default tablecheck;