export const ratingInitialState = {
    ratingReview: {}, 
    loading: false, 
    error: false
}

const ratingReducer = (state = ratingInitialState, action) => {
    switch (action.type) {
        case 'FETCH_RATING_REVIEW_LOAD': {
            return {
                ...state, 
                loading: true, 
                error: false
            }
        }
        case 'FETCH_RATING_REVIEW': {
            return {
                ...state, 
                ratingReview: action.payload, 
                loading: false, 
                error: false
            }
        }
        case 'FETCH_RATING_REVIEW_ERR': {
            return {
                ...state, 
                loading: false, 
                error: true
            }
        }
        default: return state;
    }
}

export default ratingReducer;