import React from 'react';
import { useDispatch } from 'react-redux';

import { closeModal } from '../../store/state/ui/actions';

const ModalSplitAmountDetails = () => {
    const dispatch = useDispatch();

    return (
        <div className="modal-frame modal-frame--card">
            <div className="modal-frame__body">
                <div className="modal-frame__card">
                    <div className="modal-frame__actions--flex column-actions">
                        <div className='amount-info'>
                            <div className='title'>{window.resources.split_summary.total_check_text}</div>
                            <div>{window.resources.split_summary.total_check_desc}</div>
                        </div>
                        <div className='amount-info'>
                            <div className='title'>{window.resources.split_summary.remaining_text}</div>
                            <div>{window.resources.split_summary.remaining_desc}</div>
                        </div>
                        <div className='amount-info'>
                            <div className='title'>{window.resources.split_summary.portion_text}</div>
                            <div>{window.resources.split_summary.portion_desc}</div>
                        </div>
                        <div className='amount-close'>
                            <div onClick={() => dispatch(closeModal())}>{window.resources.split_summary.close_text}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalSplitAmountDetails;