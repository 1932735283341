import React from 'react';
import QtyField from 'components/common/qty-field';

const ProductQuantity = ({ product, handleQtyChange }) => {
	return (<div className="product-single__section">
		<div className="product-single__group">
			<h6 className="product-single__title product-single__title--alt">{window.resources.product.quantity_text}</h6>

			<div className="product-single__qty">
				<QtyField
					withanimation="true"
					min={1}
					onChange={handleQtyChange}
					value={product.quantity}
				/>
			</div>
		</div>
	</div>
    )
}

export default ProductQuantity;