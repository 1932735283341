import React from 'react';

/**
 * @ The internal dependencies.
 */
import IconSvg from 'components/common/icon-svg';
import HourGlassIcon from '../../assets/svg/hourglass.svg'

export const SelectDishesEndsBanner = ({time}) => {
    return (
        <div className='shell ending-banner'>
            <div style={{marginRight: '5px'}}><IconSvg src={HourGlassIcon} /></div>
            <div>{`${window.resources.day_part_ending.select_dishes_msg} ${time}`}</div>
        </div>
    )
}

export const ItemEndsBanner = ({time}) => {
    return (
        <div className='ending-banner small-ending-banner'>
            <div style={{marginRight: '5px'}}><IconSvg src={HourGlassIcon} /></div>
            <div>{`${window.resources.day_part_ending.item_ends_msg} ${time}`}</div>
        </div>
    )
}

export const BasketAvailBanner = ({time}) => {
    return (
        <div className='ending-banner small-ending-banner'>
            <div style={{marginRight: '5px'}}><IconSvg src={HourGlassIcon} /></div>
            <div>{`${window.resources.day_part_ending.item_basket_avail_msg} ${time}`}</div>
        </div>
    )
}