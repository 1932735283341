/**
 * The external dependencies.
 */
import { createAction } from 'redux-actions';

export const requestTableTopConfig = createAction('app/request-table-top-config');

export const receiveTableTopConfig = createAction('app/receive-table-top-config');

export const requestTableTopConfigBeforeOrder = createAction('app/request-table-top-config-before-order');

export const receiveTableTopConfigBeforeOrder = createAction('app/receive-table-top-config-before-order');

export const requestTableTopData = createAction('app/request-table-top-data');

export const receiveTableTopData = createAction('app/receive-table-top-data');

export const showSuggestionsModal = createAction('app/show-suggestions-modal');

export const requestMultiConceptData = createAction('app/request-multi-concept-data');

export const receiveMultiConceptData = createAction('app/receive-multi-concept-data');

export const updateTick = createAction('app/update-tick');