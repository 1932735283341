/**
 * @ The external dependencies.
 */
import { handleActions } from 'redux-actions';

/**
 * @ The actions.
 */
import { setSelectedCategory } from './actions';

/**
 * @ The reducer.
 */
const defaultState = {
	currentCategoryId: null
}

/**
 * The actions Handler.
 *
 * @type {Function}
 */
const categories = handleActions({
	[setSelectedCategory]: (state, { payload }) => ({
		...state,
		currentCategoryId: payload
	}),
}, defaultState);

export default categories;
