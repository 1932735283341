export const discountInitialState = {
    discountInfo: {}, 
    loading: false, 
    error: false
}

const discountReducer = (state = discountInitialState, action) => {
    switch (action.type) {
        case 'FETCH_VALIDATE_DISCOUNT_LOAD': {
            return {
                ...state, 
                loading: true, 
                error: false
            }
        }
        case 'FETCH_VALIDATE_DISCOUNT': {
            return {
                ...state, 
                discountInfo: action.payload, 
                loading: false, 
                error: false
            }
        }
        case 'FETCH_VALIDATE_DISCOUNT_ERR': {
            return {
                ...state, 
                loading: false, 
                error: true
            }
        }
        default: return state;
    }
}

export default discountReducer;