/**
 * @ The external dependecies.
 */
import { handleActions } from 'redux-actions';

/**
 * @ The internal dependecies.
 */
import { updateObject } from '../../../lib/utility';

import {
    endRequestWithError
} from '../requests/actions';

/**
 * @ The actions.
 */
import {
    clearLoyalty,
    sendLoyaltyLoginRequest,
    receiveLoyaltyLoginResponse,
    sendLoyaltyRegisterRequest,
    receiveLoyaltyRegisterResponse,
    loyaltyLogOut
} from './actions';

/**
 * @ The reducer.
 */
const defaultState = {
    accountNumber: null,
    name: null,
    lastName: null,
    emailAddress: null,
    mobileNumber: null,
    balance: null,
    tier: null,
    loggedIn: false,
    exception: null,
}

/**
 * The actions Handler.
 *
 * @type {Function}
 */

const onReceiveLoyaltyLoginResponse = (state, action) => {
    if (action.payload.success) {
        return updateObject(state, {
            accountNumber: action.payload.identifier,
            name: action.payload.firstName,
            lastName: action.payload.lastName,
            emailAddress: action.payload.emailAddress,
            mobileNumber: action.payload.mobileNumber,
            balance: action.payload.balance,
            tier: action.payload.tier,
            loggedIn: true
        });
    }
    else {
        return updateObject(state, {
            accountNumber: null,
            name: null,
            lastName: null,
            emailAddress: null,
            mobileNumber: null,
            balance: null,
            tier: null,
            loggedIn: false,
            exception: action.payload.exception
        });
    }
}

const onReceiveLoyaltyRegisterResponse = (state, action) => {
    if (action.payload.success) {
        return updateObject(state, {
            accountNumber: action.payload.identifier,
            name: action.payload.firstName,
            lastName: action.payload.lastName,
            emailAddress: action.payload.emailAddress,
            mobileNumber: action.payload.mobileNumber,
            balance: action.payload.balance,
            tier: action.payload.tier,
            loggedIn: true
        });
    }
    else {
        return updateObject(state, {
            accountNumber: null,
            name: null,
            lastName: null,
            emailAddress: null,
            mobileNumber: null,
            balance: null,
            tier: null,
            loggedIn: false,
            exception: action.payload.exception
        });
    }
}

const onLoyaltyLogOut = (state, action) => {
    return updateObject(state, {
        accountNumber: null,
        name: null,
        lastName: null,
        emailAddress: null,
        mobileNumber: null,
        balance: null,
        tier: null,
        loggedIn: false,
        exception: null,
    });
}

const onClearLoyalty = (state, action) => {
    return updateObject(state, {
        ...defaultState
    });
}

//Errors
const onEndRequestWithError = (state, action) => {
    if (action.payload.id === `'${sendLoyaltyLoginRequest}'`) {
        return updateObject(state, {
            accountNumber: null,
            name: null,
            lastName: null,
            emailAddress: null,
            mobileNumber: null,
            balance: null,
            tier: null,
            loggedIn: false,
            exception: null,
        });
    }
    else if (action.payload.id === `'${sendLoyaltyRegisterRequest}'`) {
        return state;
    }

    return state;
}

const tablecheck = handleActions({
    [receiveLoyaltyLoginResponse]: (state, action) => onReceiveLoyaltyLoginResponse(state, action),
    [receiveLoyaltyRegisterResponse]: (state, action) => onReceiveLoyaltyRegisterResponse(state, action),
    [loyaltyLogOut]: (state, action) => onLoyaltyLogOut(state, action),
    [clearLoyalty]: (state, action) => onClearLoyalty(state, action),
    [endRequestWithError]: (state, action) => onEndRequestWithError(state, action)
}, defaultState);

export default tablecheck;