export const deliveryInitialState = {
    deliverylocs: {}, 
    loading: false, 
    error: false
}

const deliveryReducer = (state = deliveryInitialState, action) => {
    switch (action.type) {
        case 'FETCH_DELIVERY_LOCS_LOAD': {
            return {
                ...state, 
                loading: true, 
                error: false
            }
        }
        case 'FETCH_DELIVERY_LOCS': {
            return {
                ...state, 
                deliverylocs: action.payload, 
                loading: false, 
                error: false
            }
        }
        case 'FETCH_DELIVERY_LOCS_ERR': {
            return {
                ...state, 
                loading: false, 
                error: true
            }
        }
        default: return state;
    }
}

export default deliveryReducer;