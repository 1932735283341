/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Ratings from 'react-ratings-declarative';
import update from 'immutability-helper';

/**
 * @ The internal dependencies.
 */
import { updateObject } from '../../lib/utility';
import { sendSurveyRequest } from 'store/state/survey/actions';

/**
 * Class for survey questions.
 *
 * @class SurveyQuestions (name)
 */
class SurveyQuestions extends Component {
    state = {
        answers: null
    }

    getRating(ratingQuestionID) {
        if (this.state.answers) {
            const index = this.state.answers.findIndex((e) => e.RatingQuestionID === ratingQuestionID);
            if (index !== -1)
                return this.state.answers[index].Rating;
        }

        return -1;
    }

    changeRating = (ratingQuestionID, newRating) => {
        const index = this.state.answers.findIndex((e) => e.RatingQuestionID === ratingQuestionID);

        const updatedAnswer = updateObject(this.state.answers[index], {
            Rating: newRating
        });

        const updatedAnswers = update(this.state.answers, { $splice: [[index, 1, updatedAnswer]] });

        this.setState({ answers: updatedAnswers });

        this.props.sendSurveyRequest(updatedAnswers);

        const newIndex = index + 1;
        this.updateScroll(newIndex);
    }

    componentDidMount() {
        this.setupQuestions();
        this.setupAccessiblity();
    }

    componentDidUpdate(prevProps) {
        this.setupQuestions();
        this.setupAccessiblity();
    }

    setupAccessiblity() {
        var questions = document.getElementsByClassName('widget-ratings');
        for (var q = 0; q < questions.length; q++) {
            var svgs = questions.item(q).querySelectorAll('.widget-svg');
            for (var s = 0; s < svgs.length; s++) {
                var el = svgs.item(s).querySelector('title');
                if (!el) {
                    var title = document.createElementNS("http://www.w3.org/2000/svg", "title");
                    title.innerHTML = (s == 0 ? window.resources.survey.star_text.replace("{n}", (s + 1)) : window.resources.survey.stars_text.replace("{n}", (s + 1)));
                    svgs.item(s).appendChild(title);
                }

                var atRole = svgs.item(s).getAttribute('role');
                if (!atRole) {
                    svgs.item(s).setAttribute('role', 'img');
                }
            }
        }
    }

    setupQuestions() {
        if (!this.state.answers && this.props.questions) {
            this.setState({ answers: this.props.questions });

            const index = this.props.questions.findIndex((e) => e.Rating === -1);
            this.updateScroll(index);
        }
    }

    updateScroll(newIndex) {
        if (newIndex > 0) {
            const container = document.querySelector('.survey-questions');
            const surveyQuestions = container.querySelectorAll('.survey-question');
            if (surveyQuestions.length > newIndex) {
                container.scrollTo({
                    left: surveyQuestions[newIndex].offsetLeft - container.offsetLeft,
                    behavior: 'smooth'
                });
            }
        }
    }

	render() {
        const questions = this.props.questions;
		return (
            <div className="survey-questions">
                {questions && questions.map((question) => (
                    <div key={question.RatingQuestionID} className="survey-question">
                    <div className="survey-question-text">{question.RatingQuestionText}</div>
                    <Ratings
                            rating={this.getRating(question.RatingQuestionID)}
                            widgetHoverColors="#EEB904"
                            widgetRatedColors="#EEB904"
                            changeRating={(newRating) => {this.changeRating(question.RatingQuestionID,newRating)}}
                        >
                        <Ratings.Widget widgetDimension="20px" />
                        <Ratings.Widget widgetDimension="20px" />
                        <Ratings.Widget widgetDimension="20px" />
                        <Ratings.Widget widgetDimension="20px" />
                        <Ratings.Widget widgetDimension="20px" />
                        </Ratings>
                </div>
                ))}
			</div>
		);
	}
}

export default connect(
    (state) => ({
        questions: state.survey.questions
	}),
    {
        sendSurveyRequest
	}
)(SurveyQuestions);
