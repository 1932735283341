/**
 * @ The external dependecies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Scrollspy from 'react-scrollspy';
import scrollToElement from 'scroll-to-element';
import {
	compose,
} from 'ramda';

/**
 * @ The internal dependencies.
 */
import Header from 'components/header/header';
import MenuItem from 'views/menu/menu-item';
import { getCategories } from 'store/state/category/selectors';
import { setSelectedCategory } from 'store/state/category/actions';
import { openModal } from 'store/state/ui/actions';
import { MODAL_WARNING, MODAL_COMBO, MODAL_COMBO_UPGRADE_PATHS } from '../../lib/constants';
import { hasTabOpen } from '../../store/state/tab/selectors';
import { loyaltyLoggedIn } from '../../store/state/loyalty/selectors'
import { getCurrentLanguageDirection } from '../../lib/helpers/language';
import { localizeNumber } from '../../assets/resources/resources-manager';
import * as features from '../../store/state/features/selectors'
import { SelectDishesEndsBanner } from '../../components/daypart/depart-banner';

/**
 * Class for menu.
 *
 * @class Menu (name)
 */
class MenuDisplay extends Component {
	isComboEligible = (inventoryItemID) => {
		const comboLink = this.props.data.comboEngine.comboLinks.find(x => x.inventoryItemID === inventoryItemID);
		const comboGroup = comboLink && this.props.data.comboEngine.comboGroups.find(x => x.comboID === comboLink.comboID);
		return comboGroup;
	}

	isCombo = (inventoryItemID) => {
		const comboGroupDetails = this.props.data.comboEngine.comboGroups.find(x => x.comboGroupDetails[0].inventoryItemID === inventoryItemID);
		return comboGroupDetails;
	}


	getProduct = (id) => {
		return this.props.data.inventoryItemMains.find(item => item.inventoryItemID === id);
	}

	getProducts = (id) => {
		return this.props.data.inventoryItemMains.filter(item => item.inventoryTitles.find(innerItem => innerItem.inventoryTitleID === id));
	}

	getDayPartTime = () => {
		const dayPartItems = this.props.data.inventoryItemMains.filter(item => item.showDayPartIcon);
		const sortedItems = dayPartItems ? dayPartItems.sort((a, b) => { return new Date(a.endTimeLocalString) - new Date(b.endTimeLocalString); }) : null;

		if (!sortedItems || sortedItems.length === 0) return null;

		const currentDateTime = new Date(this.props.data.serverTimeLocalDateTimeString);
		const endDateTime = new Date(sortedItems[0].endTimeLocalString);

		const diff = Math.round(((endDateTime.getTime() - currentDateTime.getTime()) / 1000) / 60);
		const inRange = diff < this.props.dayPartMsgWarningMin && diff > 0;

		return sortedItems && inRange ? new Date(sortedItems[0].endTimeLocalString).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : null;
	}

	getSelectedProducts = () => {
		return this.getCartProducts().map(item => ({
			inventoryItemID: item.inventoryItemID,
			quantity: item.quantity
		}));
	}

	getCartProducts = () => {
        const comobOrderIDs = [];
        const products = [];

		this.props.cart.arrCart.forEach(product => {
            if (product.comboOrderID) {
                if (!comobOrderIDs.includes(product.comboOrderID)) {
                    products.push(product);
                    comobOrderIDs.push(product.comboOrderID);
                }
            }
            else {
                products.push(product);
            }
        });
        return products;
    }

    getScrollspySections = () => {
        const sortedCategories = getCategories(this.props.data.inventoryTitles).concat().sort((a, b) => parseInt(a.inventoryTitleOrder, 10) - parseInt(b.inventoryTitleOrder, 10));
		var sections = sortedCategories.filter(x => x.inventoryTitleDescription.toLowerCase().includes(this.props.categoriesFilter)).map(title => title.inventoryTitleID);
        return sections;
    }

    //on category click => scroll up/down AND categories left/right
	handleScrollTo = (id) => (e) => {
		e.preventDefault();

		const element = document.getElementById(id);
		const navContainer = document.querySelector('.scrollspy-nav');

		element.focus();
		scrollToElement(element, {
            offset: this.props.offset,
			ease: 'inQuad',
			duration: 300
		});

		var dir = getCurrentLanguageDirection();
		switch (dir) {
			case 'rtl':
				navContainer.scrollTo({
					right: e.target.offsetRight - 12 + document.documentElement.clientWidth,
					behavior: 'smooth'
				});
				break;
			default:
				navContainer.scrollTo({
					left: e.target.offsetLeft - 12,
					behavior: 'smooth'
				});
		}
	}

    //on scroll up/down => move categories left/right
    handleScrollspyUpdate = section => {
        if (section) {
		    const navContainer = document.querySelector('.scrollspy-nav');
            const navLinks = navContainer.querySelectorAll('.section__link');

            const currentSectionId = section.getAttribute('id');

            navLinks.forEach(link => {
				if (link.getAttribute('href').slice(1) === currentSectionId) {
					var dir = getCurrentLanguageDirection();
					switch (dir) {
						case 'rtl':
							navContainer.scrollTo({
								left: link.offsetLeft - 12 + document.documentElement.clientWidth,
								behavior: 'smooth'
							});
							break;
						default:
							navContainer.scrollTo({
								left: link.offsetLeft - 12,
								behavior: 'smooth'
							});
					}
                }
            });
        }
	}

	componentDidMount() {
		if ( this.props.currentCategoryId ) {
			const element = document.getElementById(this.props.currentCategoryId);
			//const navContainer = document.querySelector('.scrollspy-nav');
            //const navLinks = navContainer.querySelectorAll('.section__link');

			if (element) {
				var offset = this.props.offset;

				scrollToElement(element, {
					offset: offset,
					ease: 'inQuad',
					duration: 100
				});

				//category height can change after render if some products dont have image.
				//up/down
				setTimeout(function () {
					if (Math.abs(offset + element.getBoundingClientRect().top) > 2) {
						scrollToElement(element, {
							offset: offset,
							ease: 'inQuad',
							duration: 10
						});
					}
				}, 350);
            }

            //move categories left/right
			//navLinks.forEach(link => {
            //if (link.getAttribute('href').slice(1) === this.props.currentCategoryId) {
			//		navContainer.scrollTo({
			//			left: link.offsetLeft - 12,
			//			behavior: 'smooth'
			//		});
			//	}
			//});
        }
    }

    handleItemClick = (categoryId, inventoryItemID, itemInAvailableWindow) => e => {
        e.preventDefault();
		this.props.setSelectedCategory(categoryId);

		if (this.isComboEligible(inventoryItemID)) {
			this.props.openModal({
				type: MODAL_COMBO,
				data: {
					inventoryItemID: inventoryItemID
				}
			});
		}
		else if (this.isCombo(inventoryItemID)) {
			var combo = this.isCombo(inventoryItemID);
			this.props.history.push(`/menu/combo/${combo.comboID}`);
		}
        else if (itemInAvailableWindow) {
			let product = this.getProduct(inventoryItemID);
			if (product.upgradePaths.length > 0) {
				this.props.openModal({
					type: MODAL_COMBO_UPGRADE_PATHS,
					data: {
						inventoryItemID: inventoryItemID
					}
				});
			}
			else {
				this.props.history.push(this.props.redirectPath + `/${inventoryItemID}`);
			}
        }
        else {
            this.props.openModal({
                type: MODAL_WARNING,
                data: {
                    message: window.resources.messages.mobile_ordering_unavailable_html,
                    btnText: window.resources.modal.confirm_text
                }
            });
        }
	}

	showBasketTotal = () => {
		if (this.props.cartPricing && this.props.cartPricing.orderTotal.toFixed(2) == "0.00" && this.props.hideZeroDollars)
			return false;
		return true
    }

	onCategoryKeyPressed = (e, category) => {
		if (e.key === 'Enter') {
			const products = this.getProducts(category.inventoryTitleID);
			const element = document.getElementById(products[0].inventoryItemID);
			element.focus();
		}
	}

	render() {
		return (
            <main className="main">
				<Header dayPartTime={this.props.dayPartMsgEnabled ? this.getDayPartTime() : null} />
				<div className="section-menu">
					{this.props.showHeader && <header className="section__head" style={{top: this.props.top + 'px'}}>
						{	this.props.sommelierEnabled && 
								<div style={{paddingBottom: '15px'}} className="shell btn-section"> 
									<Link 
										to='/sommelier'
										className='btn btn--block'
									>
										{window.resources.sommelier.help_choose_flight_text}
									</Link>
								</div>
						}   
						{this.props.dayPartMsgEnabled && this.getDayPartTime() && <div style={{paddingBottom: '10px'}} className="shell btn-section"><SelectDishesEndsBanner time={this.getDayPartTime()} /></div>}
						<div className="shell shell--alt">
							<Scrollspy
                                offset={this.props.offset}
								componentTag="div"
								currentClassName="current"
								items={this.getScrollspySections()}
								onUpdate={this.handleScrollspyUpdate}
								className="section__categories scrollspy-nav">
								{getCategories(this.props.data.inventoryTitles).concat().sort((a, b) => parseInt(a.inventoryTitleOrder, 10) - parseInt(b.inventoryTitleOrder, 10)).filter(x => x.inventoryTitleDescription.toLowerCase().includes(this.props.categoriesFilter)).map((title, i) => (
									<a
										onClick={this.handleScrollTo(title.inventoryTitleID)}
										key={title.inventoryTitleID}
										href={`#${title.inventoryTitleID}`}
										onKeyDown={(e) => this.onCategoryKeyPressed(e, title)}
										className="section__link">
										{title.inventoryTitleDescription}
									</a>
								))}
							</Scrollspy>
						</div>
					</header>}

					{this.props.children}

					<div className="section__body">
						<div className="shell">
							<div className="menu-items">
								{getCategories(this.props.data.inventoryTitles).concat().sort((a, b) => parseInt(a.inventoryTitleOrder, 10) - parseInt(b.inventoryTitleOrder, 10)).filter(x => x.inventoryTitleDescription.toLowerCase().includes(this.props.categoriesFilter)).map((title, i) => (
									<MenuItem
										id={title.inventoryTitleID}
										onItemClick={this.handleItemClick}
										key={title.inventoryTitleID + i}
										category={title}
										selectedProducts={this.getSelectedProducts()}
										products={this.getProducts(title.inventoryTitleID)}
										showCalories={this.props.showCalories}
										alcoholMealLimitEnabled={this.props.alcoholMealLimitEnabled}
										isCombo={this.isCombo}

										dayPartMsgEnabled = {this.props.dayPartMsgEnabled}
										dayPartMsgWarningMin = {this.props.dayPartMsgWarningMin}
										serverTimeLocal = {this.props.data.serverTimeLocalDateTimeString}
									/>
								))}
							</div>
						</div>
					</div>
					
					{this.props.showFooter && this.props.cart.arrCart.length > 0 && (
						<div className={this.props.sommelierEnabled ? 'section__foot__alt' : 'section__foot'}>
							<div className="shell">
								<Link
									to={window.isMarketplace ? `/marketplace/${window.marketplaceId}/${window.waypointID}/cart` : "/cart"}
									className="btn btn--block btn--flex-between">
									<span>
										<span className="label" aria-label={`${localizeNumber(this.getCartProducts().map(item => item.quantity).reduce((prev, next) => prev + next))} items in basket`}>{localizeNumber(this.getCartProducts().map(item => item.quantity).reduce((prev, next) => prev + next))}</span>

										{this.showBasketTotal() && <span>
											{localizeNumber(this.props.cartPricing ? this.props.cartPricing.orderTotal.toFixed(2) : "0.00")}
										</span>
										}
									</span>
									
                                    <span>{window.resources.menu.view_basket_text}</span>
								</Link>
							</div>
						</div>
					)}
				</div>
			</main>
		);
	}
}

export default compose(
	connect(
        (state) => ({
			top: 67 + (hasTabOpen(state) ? 62 : 0),
			offset: -126 + (hasTabOpen(state) ? -62 : 0),
			cart: state.cart.data,
			cartPricing: state.cart.cartPricingData,
			modifiers: state.cart.modifiers,
			currentCategoryId: state.category.currentCategoryId,
			alcoholMealLimitEnabled: features.alcoholMealLimitEnabled(state),
			showCalories: state.app.config && state.app.config.bShowCalorieDisplay,
			hideZeroDollars: features.hideZeroDollars(state), 
			sommelierEnabled: features.sommelierEnabled(state), 
			dayPartMsgEnabled: state.app.config && state.app.config.DayPartMsgEnabled, 
			dayPartMsgWarningMin: state.app.config && state.app.config.DayPartMsgEnabled && state.app.config.DayPartMsgMinWarning
		}),
        {
            openModal,
            setSelectedCategory
        }
	)
)(MenuDisplay);