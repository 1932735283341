/**
 * @ The external dependecies.
 */
import React from 'react';
import { Helmet } from "react-helmet";

const PrefetchImages = ({ images }) => {
    return (
        <Helmet>
        {
                images.filter(image => image).map((image,i) => (
                    <link key={i} rel="prefetch" as="image" href={image} />
                ))
        }
        </Helmet>);
}

export default PrefetchImages;