/**
 * @ The external dependecies.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	compose
} from 'ramda';

/**
 * @ The internal dependencies.
 */
import withDataValidation from 'lib/helpers/hocs/with-data-validation';
import Cart from './cart';
import MarketplaceCart from '../marketplace/cart/cart';
import { hasTabOpenAndAuthorized, getArrCart, getCartPricingForCartScreen } from '../../store/state/tab/selectors'

/**
 * Class for cart.
 *
 * @class Cart (name)
 */
class CartEdit extends Component {
    render() {
		return (
            window.isMarketplace ? 
            <MarketplaceCart
                tabMode={false} 
                allowEditMode={true} 
                arrCart={this.props.arrCart} 
                cartPricing={this.props.cartPricing} 
                existingTabArrCart={this.props.existingTabArrCart} 
                existingTabCartPricing={this.props.existingTabCartPricing} 
                total={this.props.total} 
                totalDisplay={this.props.totalDisplay} 
                title={window.resources.cart.title_text} 
                edit_title={window.resources.cart.edit_title_text} 
                is_empty={window.resources.cart.is_empty_text} />
            :
            <Cart tabMode={false} allowEditMode={true} arrCart={this.props.arrCart} cartPricing={this.props.cartPricing} existingTabArrCart={this.props.existingTabArrCart} existingTabCartPricing={this.props.existingTabCartPricing} total={this.props.total} totalDisplay={this.props.totalDisplay} title={window.resources.cart.title_text} edit_title={window.resources.cart.edit_title_text} is_empty={window.resources.cart.is_empty_text}/>
		);
	}
}

export default compose(
    withRouter,
	connect(
        (state) => ({
            arrCart: state.cart.data.arrCart,
            cartPricing: state.cart.cartPricingData,
            existingTabArrCart: hasTabOpenAndAuthorized(state) && getArrCart(state),
            existingTabCartPricing: hasTabOpenAndAuthorized(state) && getCartPricingForCartScreen(state),
            total: state.cart.data.totalCost,
            totalDisplay: state.cart.cartPricingData && state.cart.cartPricingData.orderTotalDisplay
		}),
		{

		}
	),
	withDataValidation
)(CartEdit);
