import base64url from 'base64url';
import * as features from '../features/selectors'

export const hasTabOpenAndAuthorized = (state) => {

    if (state.tab.authKey) {
        var json = base64url.decode(state.tab.authKey);
        var authData = JSON.parse(json);
        if (authData) {
            if (hasTabOpen(state) && authData.expiry && new Date().getTime() < authData.expiry && authData.waypointID === window.waypointID && authData.locationName === state.checkout.tableNumber && getTabId(state) === authData.openCheckTicketID) {
                return true;
            }
        }
    }

    return false;
}

export const hasTabOpen = (state) => {
    return features.tabFeatureEnabled(state) && !state.ui.clearingTab && (getTabId(state) || window.opentab);
}

export const getTabData = (state) => {
    var tableTabData = null;

    if (state.cart.cartPricingData && state.cart.cartPricingData.Tabs && state.cart.cartPricingData.Tabs.length > 0) {
        tableTabData = state.cart.cartPricingData.Tabs[0];
    }

    return tableTabData;
}

export const getArrCart = (state) => {
    var tableTabData = getTabData(state);

    if (tableTabData && tableTabData.OrderItems) {

        return tableTabData.OrderItems.map(item => ({
            inventoryItemName: item.ItemName,
            price: item.ItemPrice,
            priceDisplay: item.ItemPriceDisplay,
            quantity: item.Quantity,
            modifiersDisplay: item.ModifiersDisplay
        }));
    }
    else {
        return [];
    }
}

export const getCartPricingForTabScreen = (state) => {
    var tableTabData = getTabData(state);

    let currencySymbol = state.app.currencySymbol;

    if (tableTabData) {
        let orderTotalDisplay = currencySymbol + tableTabData.Total.toFixed(2);

        var pricing = {
            subTotal: tableTabData.Subtotal,
            orderTotal: tableTabData.Total,
            taxes: tableTabData.TaxAmount,
            orderTotalDisplay: orderTotalDisplay,
            orderLineItems: tableTabData.TabLineItemsForTabScreen
        };

        return pricing;
    }
    else {
        return null;
    }
}

export const getCartPricingForCartScreen = (state) => {
    var tableTabData = getTabData(state);

    let currencySymbol = state.app.currencySymbol;

    if (tableTabData) {
        let orderTotalDisplay = currencySymbol + tableTabData.Total.toFixed(2);

        var pricing = {
            subTotal: tableTabData.Subtotal,
            orderTotal: tableTabData.Total,
            taxes: tableTabData.TaxAmount,
            orderTotalDisplay: orderTotalDisplay,
            orderLineItems: tableTabData.TabLineItemsForCartScreen
        };

        return pricing;
    }
    else {
        return null;
    }
}

export const getDataForVerificationModal = (state) => {
    return {
        orderTotalDisplay: state.tab.posOpenTicketCostDisplay,
        orderDueAmountDisplay: state.tab.posOpenTicketDueAmountDisplay,
        itemCount: state.tab.posOpenTicketItemCount
    }
}

export const getTabId = (state) => {
    var tabId = state.tab.openCheckTicketID;
    return features.tabFeatureEnabled(state) && tabId ? tabId : '';
}

export const underTabThreshold = (state) => {
    return state.cart.cartPricingData && !state.cart.cartPricingData.tabMaximumReached;
}

export const tabAuthorizationSuccess = (state) => {
    return state.tab.authResp && state.tab.authResp.success;
}

export const tabRequiresCCAuthorization = (state) => {
    return state.tab.requiresCCAuthorization;
}