/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

/**
 * @ The internal dependencies.
 */
import { startCartSave } from 'store/state/cart/actions';
import { sendCloseTabRequest } from 'store/state/tab/actions';
import { openModal, closeModal } from 'store/state/ui/actions';
import { MODAL_TAB_VERIFICATION } from 'lib/constants';
import { getTabId } from 'store/state/tab/selectors';
import { getBlankCardDetails } from '../../components/payment/payment-helper';

/**
 * Class for modal allergens.
 *
 * @class ModalAllergens (name)
 */
class ModalTabNewOrder extends Component {
    handleCancelClick = () => {
        this.props.closeModal();
        this.props.openModal({
            type: MODAL_TAB_VERIFICATION,
            data: this.props.data
        });
    }

    replaceVariables(html) {
        return html.replace("{this.props.tableNumber}", this.props.tableNumber)
            .replace("className", "class");
    }

    handleYesClick = () => {
        this.props.closeModal();

        //this.props.sendCloseTabRequest({
        //    orderID: this.props.tabId
        //});

        var cardDetails = getBlankCardDetails();

        //close + table check for verification
        this.props.startCartSave(
            {
                ...cardDetails,
                showSpinner: false,
                createOrUpdateTab: false,
                sendCartItems: false,
                clearTab: true
            }
        );

        this.props.data.onNewOrder();
    }

	render() {
		//const { message, btnText } = this.props.data;

		return (

			<div className="modal-frame modal-frame modal-frame--card">
				<div className="modal-frame__body">
					<div className="modal-frame__card">
                        <div className="modal-frame__head">
                            <h6 className="modal-frame__title">{window.resources.tab_new_order.title_text}</h6>
                            <br/>
                            {this.replaceVariables(window.resources.tab_new_order.message_text)}
						</div>

                        <div className="modal-frame__actions modal-frame__actions--flex">
                            <button
                                onClick={this.handleCancelClick}
                                className="btn btn--secondary">{window.resources.tab_new_order.cancel_text}</button>

                            <button
                                onClick={this.handleYesClick}
                                className="btn">{window.resources.tab_new_order.confirm_text}</button>
                        </div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(connect(
    (state) => ({
        tabId: getTabId(state),
        tableNumber: state.checkout.tableNumber,
		cart: state.cart.data,
		data: state.ui.modal.data
	}),
    {
        openModal,
        closeModal,
        sendCloseTabRequest,
        startCartSave
	}
)(ModalTabNewOrder));
