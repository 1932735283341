/**
 * @ The external dependecies.
 */
import React, { Component } from 'react';

/**
 * @ The internal dependencies.
 */
import Loader from '../../components/loader/loader';

/**
 * Class for spinner.
 *
 * @class Spinner (name)
 */
class Spinner extends Component {

	render() {
        return (
            <Loader title={window.resources.spinners.loading_text} />
        );
	}
}

export default Spinner;