/**
 * @ The external dependecies.
 */
import { handleActions } from 'redux-actions';

/**
 * @ The internal dependecies.
 */
import { updateObject } from '../../../lib/utility';

import {
    endRequestWithError
} from '../requests/actions';

/**
 * @ The actions.
 */
import {
    clearTableCheck,
    receiveTableCheckResponse,
    sendTableCheckRequest,
    setDineInTakeAwaySelected,
    setSommelierSelected,
    clearURLTableNumber
} from './actions';

/**
 * @ The reducer.
 */
const defaultState = {
    validTableNumber: false,
    invalidTableNumber: false,
    exception: '',
    waypointOrderLocation: '',
    employeeNumber: '',
    urlTableNumber: "",
    dineInTakeAwaySelected: false,
    sommelierSelected: false
}

/**
 * The actions Handler.
 *
 * @type {Function}
 */

const onReceiveTableCheckResponse = (state, action) => {
    if (action.payload.success) {
        return updateObject(state, {
            waypointOrderLocation: action.payload.locationID,
            employeeNumber: action.payload.employeeNumber,
            urlTableNumber: action.payload.locationName,
            validTableNumber: true,
            invalidTableNumber: false,
            exception: ''
        });
    }
    else {
        return updateObject(state, {
            waypointOrderLocation: '',
            employeeNumber: '',
            urlTableNumber: "",
            validTableNumber: false,
            invalidTableNumber: true,
            exception: action.payload.exception
        });
    }
}

const onClearTableCheck = (state, action) => {
    return updateObject(state, {
        validTableNumber: false,
        invalidTableNumber: false,
        dineInTakeAwaySelected: false,
        sommelierSelected: false, 
        exception: ''
    });
}

const onSetDineInTakeAwaySelected = (state, action) => {
    return updateObject(state, {
        dineInTakeAwaySelected: action.payload
    })
}

const onSetSommelierSelected = (state, action) => {
    return updateObject(state,  {
        sommelierSelected: action.payload
    })
}

//Errors
const onEndRequestWithError = (state, action) => {
    if (action.payload.id === `'${sendTableCheckRequest}'`) {
        return updateObject(state, {
            waypointOrderLocation: '',
            employeeNumber: '',
            urlTableNumber: "",
            validTableNumber: false,
            invalidTableNumber: true,
            exception: action.payload.exception != null && action.payload.exception != '' ? action.payload : window.resources.messages.error_please_try_again_html
        });
    }

    return state;
}

const tablecheck = handleActions({
    [receiveTableCheckResponse]: (state, action) => onReceiveTableCheckResponse(state, action),
    [clearTableCheck]: (state, action) => onClearTableCheck(state, action),
    [endRequestWithError]: (state, action) => onEndRequestWithError(state, action),
    [setDineInTakeAwaySelected]: (state, action) => onSetDineInTakeAwaySelected(state, action),
    [setSommelierSelected]: (state, action) => onSetSommelierSelected(state, action)
}, defaultState);

export default tablecheck;