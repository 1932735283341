import React, { createContext, useReducer, useState, useEffect } from 'react';

import airportReducer, { airportInitialState } from './reducers/airportReducer';
import deliveryReducer, { deliveryInitialState } from './reducers/deliveryReducer';
import discountReducer, { discountInitialState } from './reducers/discountReducer';
import orderStatusReducer, { orderStatusInitialState } from './reducers/orderStatusReducer';
import ratingReducer, { ratingInitialState } from './reducers/ratingReducer';
import Api from '../../../lib/api';

const Store = ({children}) => {
    const [airportState, airportDispatch] = useReducer(airportReducer, airportInitialState);
    const [deliveryState, deliveryDispatch] = useReducer(deliveryReducer, deliveryInitialState);
    const [validateDiscountState, validateDiscountDispatch] = useReducer(discountReducer, discountInitialState);
    const [orderStatusState, orderStatusDispatch] = useReducer(orderStatusReducer, orderStatusInitialState);
    const [ratingState, ratingDispatch] = useReducer(ratingReducer, ratingInitialState);

    const [selectedStore, setSelectedStore] = useState(undefined);
    const [stores, setStores] = useState(undefined);
    const [deliveryLocation, setDeliveryLocation] = useState(undefined);
    const [discountEmail, setDiscountEmail] = useState(undefined);
    const [promoCode, setPromoCode] = useState(undefined);
    const [showDiscountModal, setShowDiscountModal] = useState(false);
    const [ga, setga] = useState(null);

    useEffect(() => {
        setDeliveryLocation(window.sessionStorage.getItem('deliverylocation'));
        setSelectedStore(JSON.parse(window.sessionStorage.getItem('selectedstore')));
        setPromoCode(window.sessionStorage.getItem('promoCode'));
    }, []);
    useEffect(() => {
        if (deliveryLocation) window.sessionStorage.setItem('deliverylocation', deliveryLocation);
    }, [deliveryLocation]);
    useEffect(() => {
        if (selectedStore) window.sessionStorage.setItem('selectedstore', JSON.stringify(selectedStore));
    }, [selectedStore]);
    useEffect(() => {
        if(promoCode) window.sessionStorage.setItem('promoCode', promoCode);
    });    

    const fetchAirportInfo = async (airportCode) => {
        const payload = { airportCode };
        airportDispatch({ type: 'FETCH_AIRPORTINFO_LOAD' });
        try {
            const response = await Api.getAirportInformation(payload);
            airportDispatch({ type: 'FETCH_AIRPORTINFO', payload: response });
        }
        catch (e) {
            airportDispatch({ type: 'FETCH_AIRPORTINFO_ERR' });
        }
    }
    const fetchDeliveryLocations = async (airportCode, terminalId) => {
        const payload = { airportCode, terminalId };
        deliveryDispatch({ type: 'FETCH_DELIVERY_LOCS_LOAD' });
        try {
            const response = await Api.getDeliveryLocations(payload);
            deliveryDispatch({ type: 'FETCH_DELIVERY_LOCS', payload: response })
        }
        catch (e) {
            deliveryDispatch({ type: 'FETCH_DELIVERY_LOCS_ERR' });
        }
    }
    const fetchValidateDiscount = async (promotionCode, email, storeWaypointID, isDeliveryOrder) => {
        const payload = { promotionCode, email, storeWaypointID, isDeliveryOrder, isAppliedToAccount: false }
        validateDiscountDispatch({ type: 'FETCH_VALIDATE_DISCOUNT_LOAD' });
        try {
            const response = await Api.validatePromotion(payload);
            validateDiscountDispatch({ type: 'FETCH_VALIDATE_DISCOUNT', payload: response });
        }
        catch (e) {
            validateDiscountDispatch({ type: 'FETCH_VALIDATE_DISCOUNT_ERR' });
        }
    }
    const fetchOrderStatus = async (kobp, orderId) => {
        const payload = { kobp, orderId };
        orderStatusDispatch({ type: 'FETCH_ORDER_STATUS_LOAD' });
        try {
            const response = await Api.getOrderStatus(payload);
            orderStatusDispatch({ type: 'FETCH_ORDER_STATUS', payload: response });
        }
        catch (e) {
            orderStatusDispatch({ type: 'FETCH_ORDER_STATUS_ERR' });
        }
    }
    const saveRatingReview = async (email, orderId, waypointId, rating, review, kobp) => {
        const payload = { email, orderId, waypointId, rating, review, kobp };
        ratingDispatch({ type: 'FETCH_RATING_REVIEW_LOAD' })
        try {
            const response = await Api.saveRatingReview(payload);
            ratingDispatch({ type: 'FETCH_RATING_REVIEW', payload: response });
        }
        catch (e) {
            ratingDispatch({ type: 'FETCH_RATING_REVIEW_ERR' });
        }
    }

    return (
        <Context.Provider value={{fetchAirportInfo, airportState, fetchDeliveryLocations, deliveryState, fetchValidateDiscount, validateDiscountState, 
                                  fetchOrderStatus, orderStatusState, saveRatingReview, ratingState, setPromoCode, promoCode, showDiscountModal, setShowDiscountModal, 
                                  setStores, stores, setSelectedStore, selectedStore, setDeliveryLocation, deliveryLocation, setDiscountEmail, discountEmail, 
                                  setga, ga}}>
            {children}
        </Context.Provider>
    )
}
export const Context = createContext();
export default Store;