/**
 * @ The external dependencies.
 */
import {
    all,
    call,
    put,
    takeLatest
} from 'redux-saga/effects';

import { sendLoyaltyLoginRequest, sendLoyaltyLoginRequestStart, sendLoyaltyLoginRequestEnd, receiveLoyaltyLoginResponse, sendLoyaltyRegisterRequest, receiveLoyaltyRegisterResponse } from './actions';
import makeRequest from '../../../lib/helpers/api-request';


export function* sendLoyaltyLoginRequestWorker(action) {
    yield put(sendLoyaltyLoginRequestStart());

    yield call(makeRequest, {
        endpoint: 'loyaltyLogin',
        payload: action.payload,
        requestAction: action,
        receiveAction: receiveLoyaltyLoginResponse
    });

    yield put(sendLoyaltyLoginRequestEnd());
}

export function* sendLoyaltyRegisterRequestWorker(action) {
    yield put(sendLoyaltyLoginRequestStart());

    yield call(makeRequest, {
        endpoint: 'loyaltyRegister',
        payload: action.payload,
        requestAction: action,
        receiveAction: receiveLoyaltyRegisterResponse
    });

    yield put(sendLoyaltyLoginRequestEnd());
}

/**
 * Start all workers.
 *
 * @return {Generator}
 */
export default function* foreman() {
    yield all([
        takeLatest(sendLoyaltyLoginRequest, sendLoyaltyLoginRequestWorker),
        takeLatest(sendLoyaltyRegisterRequest, sendLoyaltyRegisterRequestWorker)
    ]);
}
