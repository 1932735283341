/**
 * @ The external dependencies.
 */
import { createAction } from 'redux-actions';

/**
 * Sets the selected category.
 *
 * @return {String}  Id of the selected category
 */
export const setSelectedCategory = createAction('categories/set-selected-category');