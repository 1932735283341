import config from '../../config';

export const setupResources = (waypointIDs, language, onLoad, onError, multiLevelMerge = false) => {
    var defaultResourcesUrl = config.REACT_APP_RESOURCES_URL + 'resources' + (language !== 'en' ? '.' + language : '') + '.json';

    var overrideResourcesurls = waypointIDs.map(waypointID => config.REACT_APP_STORE_RESOURCES_URL + waypointID + '/resources' + (language !== 'en' ? '.' + language : '') + '.json');

    var fetchDefaultResourcesPromise = fetch(defaultResourcesUrl)
        .then(response => response.json())
        .then(resources => {
            window.resources = resources;
        })
        .catch(error => {
            window.resources = {};
            console.error(`Cannot load ${defaultResourcesUrl}`);
        });

    var fetchOverridePromises = overrideResourcesurls.map(overrideResourcesurl => fetch(overrideResourcesurl).then(response => response.json()));

    Promise.allSettled([fetchDefaultResourcesPromise, ...fetchOverridePromises]).then(results => {
        if (results.some(result => result.status === 'fulfilled')) {

            if (multiLevelMerge) {
                //multi level merge
                for (var i = 1; i < results.length; i++) {
                    if (results[i].status === 'fulfilled') {
                        var data = results[i].value;

                        //merge Default Resources + Store Specific Resources
                        for (let kvp in data) {
                            if (window.resources.hasOwnProperty(kvp)) {
                                window.resources[kvp] = { ...window.resources[kvp], ...data[kvp] }
                            }
                            else {
                                window.resources[kvp] = data[kvp];
                            }
                        }
                    }
                }
            }
            else {
                //single level merge
                for (var i = results.length - 1; i > 0; i--) {
                    if (results[i].status === 'fulfilled') {
                        var data = results[i].value;

                        //merge Default Resources + Store Specific Resources
                        for (let kvp in data) {
                            if (window.resources.hasOwnProperty(kvp)) {
                                window.resources[kvp] = { ...window.resources[kvp], ...data[kvp] }
                            }
                            else {
                                window.resources[kvp] = data[kvp];
                            }
                        }
                        break;
                    }
                }
            }

            if (onLoad) onLoad();
        }
        else {
            if (onError) onError();
        }
    })
}

export const setupResourcesMultiConcept = (multiConceptIDs, language, onLoad, onError, multiLevelMerge = false) => {
    var defaultResourcesUrl = config.REACT_APP_RESOURCES_URL + 'resources' + (language !== 'en' ? '.' + language : '') + '.json';
    var overrideResourcesurls = multiConceptIDs.map(multiConceptID => config.REACT_APP_MULTI_CONCEPT_RESOURCES_URL + multiConceptID + '/resources' + (language !== 'en' ? '.' + language : '') + '.json');

    var fetchDefaultResourcesPromise = fetch(defaultResourcesUrl)
        .then(response => response.json())
        .then(resources => {
            window.resources = resources;
        })
        .catch(error => {
            window.resources = {};
            console.error(`Cannot load ${defaultResourcesUrl}`);
        });

    var fetchOverridePromises = overrideResourcesurls.map(overrideResourcesurl => fetch(overrideResourcesurl).then(response => response.json()));

    Promise.allSettled([fetchDefaultResourcesPromise, ...fetchOverridePromises]).then(results => {
        if (results.some(result => result.status === 'fulfilled')) {
            if (multiLevelMerge) {
                //multi level merge
                for (var i = 1; i < results.length; i++) {
                    if (results[i].status === 'fulfilled') {
                        var data = results[i].value;

                        //merge Default Resources + Store Specific Resources
                        for (let kvp in data) {
                            if (window.resources.hasOwnProperty(kvp)) {
                                window.resources[kvp] = { ...window.resources[kvp], ...data[kvp] }
                            }
                            else {
                                window.resources[kvp] = data[kvp];
                            }
                        }
                    }
                }
            }
            else {
                //single level merge
                for (var i = results.length - 1; i > 0; i--) {
                    if (results[i].status === 'fulfilled') {
                        var data = results[i].value;

                        //merge Default Resources + Store Specific Resources
                        for (let kvp in data) {
                            if (window.resources.hasOwnProperty(kvp)) {
                                window.resources[kvp] = { ...window.resources[kvp], ...data[kvp] }
                            }
                            else {
                                window.resources[kvp] = data[kvp];
                            }
                        }
                        break;
                    }
                }
            }
            if (onLoad) onLoad();
        }
        else {
            if (onError) onError();
        }
    })
}

export const setupResourcesMarketplace = (marketplaceIds, language, onLoad, onError, multiLevelMerge = false) => {
    var defaultResourcesUrl = config.REACT_APP_RESOURCES_URL + 'resources' + (language !== 'en' ? '.' + language : '') + '.json';
    var overrideResourcesurls = marketplaceIds.map(marketplaceId => config.REACT_APP_MARKETPLACE_RESOURCES_URL + marketplaceId + '/resources' + (language !== 'en' ? '.' + language : '') + '.json');

    var fetchDefaultResourcesPromise = fetch(defaultResourcesUrl)
        .then(response => response.json())
        .then(resources => {
            window.resources = resources;
        })
        .catch(error => {
            window.resources = {};
            console.error(`Cannot load ${defaultResourcesUrl}`);
        });

    var fetchOverridePromises = overrideResourcesurls.map(overrideResourcesurl => fetch(overrideResourcesurl).then(response => response.json()));

    Promise.allSettled([fetchDefaultResourcesPromise, ...fetchOverridePromises]).then(results => {
        if (results.some(result => result.status === 'fulfilled')) {
            if (multiLevelMerge) {
                //multi level merge
                for (var i = 1; i < results.length; i++) {
                    if (results[i].status === 'fulfilled') {
                        var data = results[i].value;

                        //merge Default Resources + Store Specific Resources
                        for (let kvp in data) {
                            if (window.resources.hasOwnProperty(kvp)) {
                                window.resources[kvp] = { ...window.resources[kvp], ...data[kvp] }
                            }
                            else {
                                window.resources[kvp] = data[kvp];
                            }
                        }
                    }
                }
            }
            else {
                //single level merge
                for (var i = results.length - 1; i > 0; i--) {
                    if (results[i].status === 'fulfilled') {
                        var data = results[i].value;

                        //merge Default Resources + Store Specific Resources
                        for (let kvp in data) {
                            if (window.resources.hasOwnProperty(kvp)) {
                                window.resources[kvp] = { ...window.resources[kvp], ...data[kvp] }
                            }
                            else {
                                window.resources[kvp] = data[kvp];
                            }
                        }
                        break;
                    }
                }
            }
            if (onLoad) onLoad();
        }
        else {
            if (onError) onError();
        }
    })
}

export const localizeNumber = (input) => {
    var map = window.resources.localization.digits.split("");
    return String(input).split('').map(char => char.charCodeAt(0) >= 48 && char.charCodeAt(0) <= 57 ? map[Number(char)] : char).join('').replace('.', window.resources.localization.decimal);
}