/**
 * @ The external dependencies.
 */
import React, { Component, Suspense, lazy } from 'react';
import { Redirect } from 'react-router'
import { withRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

/**
 * @ The internal dependecies.
 */
// import Error from 'views/error/error';
import Home from 'views/home/home';
import Categories from 'views/category/categories';
import Menu from 'views/menu/menu';
// import Search from 'views/search/search';
import Product from 'views/product/product';
import Combo from 'views/combo/combo';
import CartEdit from 'views/cart/cart-edit';
import Receipt from 'views/receipt/receipt';
import CheckoutComplete from 'views/checkout/checkout-complete';

import ModalManager from 'components/modal/modal-manager';
import { Aux, multiLazy } from '../../lib/utility';
// import * as Analytics from '../../lib/analytics';
import * as Analytics from '../../lib/analytics-ga4';
import { MODAL_WARNING, MODAL_SUGGESTIONS, MODE_BROWSE, MODE_SPLITTAB_INVITE } from '../../lib/constants';
import PrefetchImages from 'components/prefetch/prefetch-images';
import * as resourcesManager from 'assets/resources/resources-manager';
import Spinner from 'views/spinner/spinner';

/**
 * @ The redux actions.
 */
import { requestTableTopConfig, requestTableTopData } from 'store/state/app/actions';
import { requestPreOrderQuestionnaireData } from 'store/state/questionnaire/actions';
import { loadCustomer } from 'store/state/customer/actions';
import InvalidStore from '../../components/error/invalid-store';

import { openModal, setLoadingResources } from 'store/state/ui/actions';
import { clearCart, startCartUpdate } from 'store/state/cart/actions';
import { clearLoyalty } from 'store/state/loyalty/actions';

/**
 * Class for app.
 *
 * @class App (name)
 */

export const ConfigContext = React.createContext();

const appConfig = {
    
};

// const Home = lazy(() => import('../home/home'));
const Error = lazy(() => import('../error/error'));
// const Categories = lazy(() => import('../category/categories'));
// const Menu = lazy(() => import('../menu/menu'));
const Search = lazy(() => import('../search/search'));
// const Combo = lazy(() => import('../combo/combo'));
// const Product = lazy(() => import('../product/product'));
// const CartEdit = lazy(() => import('../cart/cart-edit'));
// const Receipt = lazy(() => import('../receipt/receipt'));
const TabView = lazy(() => import('../tab/tab-view'));
const TabNew = lazy(() => import('../tab/tab-new'));
const TabReceipt = lazy(() => import('../tab/tab-receipt'));
// const CheckoutComplete = lazy(() => import('../checkout/checkout-complete'));
const Split = lazy(() => import('../split/split'));
const NoBalanceRemaining = lazy(() => import('../split/invite/error/no-balance-remaining'));
const SplitInvite = lazy(() => import('../split/split-invite'));
const SplitStore = lazy(() => import('../../store/state/split/store'));
const SplitInviteCheckoutComplete = lazy(() => import('../split/invite/checkout-complete'));

const [SommelierHome, SommelierChoose, SommelierAbout, SommelierMenu, SommelierProduct] = multiLazy([
    () => import('../sommelier/home/home'),
    () => import('../sommelier/choose/choose'),
    () => import('../sommelier/about/about'),
    () => import('../sommelier/menu/menu'),
    () => import('../sommelier/product/product')
]);

class AppStore extends Component {
    componentDidMount() {
        // this.unlisten = this.props.history.listen(Analytics.logPageView);

        window.addEventListener('message', (e) => {
            if (window.preview) {
                this.forceUpdate();
            }
        });

        if (this.props.ga) {
            Analytics.initialize(this.props.ga);
            // Analytics.logPageView();
        }

        this.clearCartIfHome();
        this.updatePlatformTypeFromStateTableNumber();

        if (this.props.location.pathname.startsWith('/split/invite') || this.props.mode === MODE_SPLITTAB_INVITE) {
            Promise.all([
                this.props.loadCustomer(),
                this.props.requestTableTopConfig({ performOrderCalc: false }), //Gets Config only.
            ]);
        }
        else {
            Promise.all([
                this.props.loadCustomer(),
                this.props.requestTableTopConfig({ performOrderCalc: true, asyncOrderCalc: this.isLandingPagePath() }), //Gets Config + Calc.
                this.props.requestTableTopData(), //Gets Menu Data
                this.props.requestPreOrderQuestionnaireData() //Gets PreOrder Questionnaire Data
            ]);
        }

        //The setupResources in index.js is called async to allow Store Data + Config api calls to be kicked off. setupResources may or may not be completed at this point so it is called again showing spinner.
        //If the resources are already downloaded the browser should get them from cache so wont be a performance hit.
        this.props.setLoadingResources(true);
        resourcesManager.setupResources(window.styleWaypointIDs, window.language, () => {
            this.props.setLoadingResources(false);
        }, () => {
            this.props.setLoadingResources(false);
        });
    }

    isLandingPagePath = () => {
        return (
            this.props.location.pathname === '/' || 
            this.props.location.pathname.search(/^\/([0-9]+|[vV][kK])$/) !== -1 || 
            this.props.location.pathname.startsWith('/split/invite') || 
            this.props.location.pathname.startsWith('/tab/receipt') || 
            this.props.location.pathname.startsWith('/checkout-complete') || 
            this.props.location.pathname.startsWith('/marketplace')
        );
    }

    clearCartIfHome = () => {
        //Whenever user visits root clear cart to prevent issues of localstorage cartitems having been deleted from backend and user returning causing initial postToCart call to error.
        //requestTableTopData() gets the store inventory AND additionally sends the cart for calculation calling postToCart. This is needed for the following scenarios:
        //1. Initial display data and
        //2. Non root page refreshes
        if (this.isLandingPagePath()) {
            this.props.clearCart();
            this.props.clearLoyalty();
        }
    }

    updatePlatformTypeFromStateTableNumber = () => {
        if (this.props.urlTableNumber && !this.isLandingPagePath()) {
            const platformType = this.props.urlTableNumber.toLowerCase().endsWith('vk') ? 7 : 3;
            window.platformType = platformType;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.showModalMessage && this.props.showModalMessage !== prevProps.showModalMessage && this.props.location.pathname !== '/' && this.props.location.pathname.search(/^\/([0-9]+|[vV][kK])$/) === -1) {
            this.props.openModal({
                type: MODAL_WARNING,
                data: {
                    message: this.props.modalMessage,
                    btnText: window.resources.modal.confirm_text
                }
            });
        }

        if (this.props.redirectToHome && this.props.redirectToHome !== prevProps.redirectToHome && this.props.location.pathname !== '/' && this.props.location.pathname.search(/^\/([0-9]+|[vV][kK])$/) === -1) {
            this.props.history.push(`/`);
        }

        if (this.props.redirectToCart && this.props.redirectToCart !== prevProps.redirectToCart && this.props.location.pathname !== '/cart') {
            this.props.startCartUpdate();
            this.props.history.push(`/cart`);
        }

        if (this.props.redirectToError && this.props.redirectToError !== prevProps.redirectToError) {
            this.props.history.push(`/error`);
        }
    }

    componentWillUnmount() {
        // this.unlisten();
    }
    
    componentWillReceiveProps(nextProps) {
        if (nextProps.showSuggestionsModal && this.props.showSuggestionsModal !== nextProps.showSuggestionsModal) {
            const showSuggestions = nextProps.cartPricingData && nextProps.cartPricingData.upsaleItems.length > 0

            if (showSuggestions) {
                this.props.openModal({
                    type: MODAL_SUGGESTIONS,
                    data: {
                        upsaleItems: nextProps.cartPricingData.upsaleItems
                    }
                });
            }
        }

        if (nextProps.ga && this.props.ga !== nextProps.ga) {
            Analytics.initialize(nextProps.ga);
            // Analytics.logPageView();
        }
    }

    render() {
        console.log('path name', this.props.location.pathname)
        if (this.props.refreshState === true && !this.isLandingPagePath()) {
            return <Redirect to='/' />
        }

        if (this.props.storeClosed && !this.isLandingPagePath()) {
            this.props.requestTableTopConfig();
            this.props.openModal({
                type: MODAL_WARNING,
                data: {
                    message: window.resources.messages.order_failed_kitchen_closed_html,
                    btnText: window.resources.modal.confirm_text
                }
            });
            return <Redirect to='/' />
        }

        let app = <InvalidStore />;

        if (this.props.loading || this.props.validStore) {
            app = (
                <Aux>
                    <Suspense fallback={<Spinner />}>
                        <Switch>
                            <Route exact path='/:tableNumber(\d+|[vV][kK])?' component={Home} />

                            <Route path='/error' render={() => <Error />} />

                            <Route path='/categories' component={Categories} />

                            <Route exact path='/menu' component={Menu} />

                            <Route exact path='/search' render={() => <Search />} />

                            <Route path='/menu/combo/:comboID/:comboOrderID?' component={Combo} />

                            <Route path='/menu/:id' component={Product} />

                            <Route path='/cart' component={CartEdit} />

                            <Route path='/receipt' component={Receipt} />

                            <Route path='/tab/view' render={() => <TabView />} />

                            <Route path='/tab/new' render={() => <TabNew />} />

                            <Route path='/tab/receipt' render={() => <TabReceipt />} />

                            <Route path='/checkout-complete' component={CheckoutComplete} />

                            <Route path='/split' exact render={() => <SplitStore><Split /></SplitStore>} />

                            <Route path='/split/invite/error/no-balance-remaining' render={() => <SplitStore><NoBalanceRemaining /></SplitStore>} />

                            <Route path='/split/invite/:type/:amount' render={() => <SplitStore><SplitInvite /></SplitStore>} />
                            <Route path='/split/invite/checkout-complete' render={() => <SplitStore><SplitInviteCheckoutComplete /></SplitStore>} />

                            <Route exact path='/sommelier' render={() => <SommelierHome />} />
                            <Route exact path='/sommelier/choose' render={() => <SommelierChoose />} />
                            <Route exact path='/sommelier/about' render={() => <SommelierAbout />} />
                            <Route exact path='/sommelier/menu' render={() => <SommelierMenu />} />
                            <Route exact path='/sommelier/menu/:id' render={() => <SommelierProduct />} />

                            <Route path='/spinner' component={Spinner} />
                        </Switch>
                    </Suspense>

                    <ModalManager key="modal" />
        
                    {this.props.categories && <PrefetchImages images={this.props.categories.map(category => category.imageNameWide)} />}
                    {this.props.products && <PrefetchImages images={this.props.products.map(product => {
                        const imgUrl = encodeURI(product.imageV2.product_1_1 || product.inventoryItemImageName);
                        return imgUrl;
                    })} />}
               </Aux>);
        }

        const title = this.props.location.pathname === '/categories' ? this.props.title + ' - Categories' :
                      this.props.location.pathname === '/menu' ? this.props.title + ' - Menu' : 
                      this.props.location.pathname.startsWith('/menu/') ? this.props.title + ' - Menu Item' : 
                      this.props.location.pathname === '/cart' ? this.props.title + ' - Cart' : 
                      this.props.location.pathname === '/checkout-complete' ? this.props.title + ' - Checkout Complete' : this.props.title;
        return (    
            <ConfigContext.Provider value={appConfig}>
                {!this.props.loading &&
                    (<Helmet>
                    <title>{title}</title>
                    <meta name="description" content={this.props.description} /> 
                    {this.props.noIndex && (<meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />)}
                    </Helmet>)}
                <div className="wrapper">
                    {app}
                </div>
            </ConfigContext.Provider>);
	}
}

const mapStateToProps = state => {
    return {
        showModalMessage: state.ui.showModalMessage,
        modalMessage: state.ui.modalMessage,
        redirectToHome: state.ui.redirectToHome,
        redirectToCart: state.ui.redirectToCart,
        validStore: state.app.validStore,
        redirectToError: state.ui.redirectToError,
        ga: state.app.ga,
        loading: state.app.loading,
        categories: state.app.data && state.app.data.inventoryTitles,
        products: state.app.data && state.app.data.inventoryItemMains,
        showSuggestionsModal: state.cart.showSuggestionsModal,
        title: (state.app.validStore && state.cart.data.storeName) || 'Invalid Store',
        description: (state.app.validStore && state.cart.data.storeWaypointDescription) || 'Invalid store',
        noIndex: !state.app.validStore,
        validTableNumber: state.tablecheck.validTableNumber,
        invalidTableNumber: state.tablecheck.invalidTableNumber,
        cartPricingData: state.cart.cartPricingData,
        storeClosed: (state.app.config && !state.app.config.bStoreIsCurrentlyOpen) || (state.checkout.error && state.checkout.error.includes("closed")),
        refreshState: state.cart.mode !== MODE_BROWSE && (state.cart.data != null && ((state.cart.data.kobp && state.cart.data.kobp !== window.kobp) || (state.cart.data.urlTableNumber === '' && state.cart.data.waypointOrderLocation === '')) && !window.preview),
        mode: state.cart.data.mode,
        urlTableNumber: state.cart.data.kobp === window.kobp ? state.cart.data.urlTableNumber : ''
    };
}

export default withRouter(connect(
    mapStateToProps,
    {
        openModal,
        requestTableTopConfig,
        requestTableTopData,
        requestPreOrderQuestionnaireData,
        loadCustomer,
        clearCart,
        startCartUpdate,
        setLoadingResources,
        clearLoyalty
	}
)(AppStore));
