/**
 * @ The external dependecies.
 */
import { handleActions } from 'redux-actions';

/**
 * @ The internal dependecies.
 */
import { updateObject } from '../../../lib/utility';

/**
 * @ The actions.
 */
import {
    receivePreOrderQuestionnaireData,
    preOrderQuestionnaireComplete,
    receivePreOrderQuestionnaireResponse,
    clearQuestionnaire
} from './actions';

/**
 * @ The reducer.
 */
const defaultState = {
    data: null,
    submitted: false,
    sessionID: null
}

/**
 * The actions Handler.
 *
 * @type {Function}
 */

const onReceivePreOrderQuestionnaireData = (state, action) => {
    if (!action.payload.exception) {
        return updateObject(state, {
            data: action.payload
        });
    }
    else {
        return updateObject(state, {
            data: null
        });
    }
}

const onReceivePreOrderQuestionnaireResponse = (state, action) => {
    return updateObject(state, {
        sessionID: action.payload.sessionID
    });
}

const onPreOrderQuestionnaireComplete = (state, action) => {
    return updateObject(state, {
        submitted: true
    });
}

const onClearQuestionnaire = (state, action) => {
    return updateObject(state, {
        submitted: false,
        sessionID: null
    });
}

const onHydrate = (state, action) => {
    if (action.payload) {
        return updateObject(state, {
            ...action.payload.questionnaire,
            submitted: false,
        });
    }
    else {
        return updateObject(state, {
            submitted: false,
        });
    }
}

const questionnaire = handleActions({
    [receivePreOrderQuestionnaireData]: (state, action) => onReceivePreOrderQuestionnaireData(state, action),
    [preOrderQuestionnaireComplete]: (state, action) => onPreOrderQuestionnaireComplete(state, action),
    [receivePreOrderQuestionnaireResponse]: (state, action) => onReceivePreOrderQuestionnaireResponse(state, action),
    [clearQuestionnaire]: (state, action) => onClearQuestionnaire(state, action),
    'persist/REHYDRATE': (state, action) => onHydrate(state, action),
}, defaultState);

export default questionnaire;