/**
 * @ The external dependencies.
 */
import { pathOr } from 'ramda';

/**
 * Check whether the specified request is running.
 *
 * @param  {Object}           state
 * @param  {String|Function}  request
 * @return {Boolean}
 */
export function isRequestRunning(state, request) {
	return pathOr(false, [
		'requests',
		`'${request}'`,
		'running'
	], state);
}

/**
 * Get the error occured during a network request.
 *
 * @param  {Object}           state
 * @param  {String|Function}  request
 * @return {String}
 */
export function getRequestError(state, request) {
	return pathOr('', [
		'requests',
		`'${request}'`,
		'error'
	], state);
}
