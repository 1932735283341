/**
 * @ The external dependencies.
 */
import { handleActions } from 'redux-actions';

/**
 * @ The internal dependencies.
 */
import { updateObject } from '../../../lib/utility';

import {
	saveSearch,
    clearSearch,
} from './actions';

/**
 * @ The reducer.
 */
const defaultState = {
	searchTerm: ''
}

const onSaveSearch = (state, action) => {
    return updateObject(state, {
        searchTerm: action.payload
    });
}

const onClearSearch = (state, action) => {
    return updateObject(state, {
        searchTerm: ''
    });
}

const search = handleActions({
    [saveSearch]: (state, action) => onSaveSearch(state, action),
    [clearSearch]: (state, action) => onClearSearch(state, action)
}, defaultState);

export default search;