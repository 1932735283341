import { createAction } from 'redux-actions';


export const sendLoyaltyLoginRequest = createAction('loyalty/send-loyalty-login-request');
export const sendLoyaltyLoginRequestStart = createAction('loyalty/send-loyalty-login-request-start');
export const sendLoyaltyLoginRequestEnd = createAction('loyalty/send-loyalty-login-request-end');
export const receiveLoyaltyLoginResponse = createAction('loyalty/receive-loyalty-login-response');

export const loyaltyLogOut = createAction('loyalty/loyalty-log-out');

export const sendLoyaltyRegisterRequest = createAction('loyalty/send-loyalty-register-request');
export const receiveLoyaltyRegisterResponse = createAction('loyalty/receive-loyalty-register-response');

export const clearLoyalty = createAction('survey/clear-loyalty');