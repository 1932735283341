import { createAction } from 'redux-actions';

export const sendLoadedValueAccountBalanceRequest = createAction('loadedvalueaccount/send-loaded-value-account-balance-request');

export const receiveLoadedValueAccountBalanceResponse = createAction('loadedvalueaccount/receive-loaded-value-account-balance-response');
export const clearLoadedValueAccountBalanceResponse = createAction('loadedvalueaccount/clear-loaded-value-account-balance-response')

export const reloadCartWithLoadedValueAccountNumber = createAction('loadevalueaccount/reload-cart-with-account-number')
export const addLoadedValueAccountNumberToCart = createAction('loadedvalueaccount/add-to-cart');
export const removeLoadedValueAccountNumberFromCart = createAction('loadedvalueacount/remove-cart');

