/**
 * @ The external dependecies
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import {
  compose,
  sortWith,
  sortBy,
  descend,
  prop,
  pickAll,
  isEmpty,
  clone,
  equals,
  findIndex,
} from 'ramda';

/**
 * @ The internal dependecies.
 */
import ProductSizes from 'views/product/product-sizes';
import ProductChoices from 'views/product/product-choices';
import ProductOptions from 'views/product/product-options';
import ProductQuantity from 'views/product/product-quantity';

import withDataValidation from 'lib/helpers/hocs/with-data-validation';
import {
  startAddToCart,
  updateCartTotal,
  updateModifiers,
  updateCartProducts,
  clearEditProduct,
  startCartUpdate,
} from 'store/state/cart/actions';
import * as features from '../../store/state/features/selectors';
import { openModal } from 'store/state/ui/actions';
import { MODAL_ALCOHOL, MODE_ORDER } from 'lib/constants';
import { clearSearch } from 'store/state/search/actions';
import { localizeNumber } from '../../assets/resources/resources-manager';
import { isLtr } from '../../lib/helpers/language';
import { ItemEndsBanner } from '../../components/daypart/depart-banner';

import { updateObject } from '../../lib/utility';
import InvalidItem from '../../components/error/invalid-item';
import { config } from 'process';
import { Context } from '../../store/state/marketplace/store';

/**
 * Class for product.
 *
 * @class Product (name)
 */
class ProductDisplay extends Component {
  state = {
    product: {},
    imageError: false,
  };
  static contextType = Context;

  //Lifecycle events
  componentWillMount() {
    const product = this.props.data.inventoryItemMains.find(
      (item) =>
        item.inventoryItemID ===
        (window.isMarketplace ? this.props.match.params.pid : this.props.match.params.id)
    );

    if (product) {
      if (this.props.editableProduct) {
        this.setState({
          product: clone(this.props.editableProduct),
          originalQty: this.props.editableProduct.quantity,
          productIdx: findIndex(equals(this.props.editableProduct))(this.props.arrCart),
        });
      } else {
        this.setState({
          product: this.setProductDefaults(clone(product)),
        });
      }
    } else this.setState({ product: null });
  }

  setProductDefaults(product) {
    if (product.inventoryItemSubs.length === 1) {
      sortBy(
        compose(
          parseInt,
          prop('inventoryOrder')
        ),
        product.inventoryItemSubs
      )[0].selected = true;
    }
    product.quantity = 1;
    return product;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.state.originalQty && this.state.originalQty !== this.props.editableProduct.quantity) {
      const updatedProduct = updateObject(this.state.product, {
        quantity: this.props.editableProduct.quantity,
      });

      this.setState({
        originalQty: this.props.editableProduct.quantity,
        product: updatedProduct,
      });
    }
  }

  componentWillUnmount() {
    this.props.clearEditProduct();
  }

  //Product Update - Size
  handleSizeChange = ({ index, id }) => (e) => {
    if (this.props.mode === MODE_ORDER) {
      const updateIndex = this.state.product.inventoryItemSubs.findIndex(
        (x) => x.inventoryItemSubID === id
      );

      const updatedInventoryItemSubs = clone(this.state.product.inventoryItemSubs);

      const updatedInventoryItemSub = updatedInventoryItemSubs[updateIndex];
      updatedInventoryItemSub.selected = !updatedInventoryItemSub.selected;

      let initalProductPrice = this.state.initalProductPrice;

      if (updatedInventoryItemSub.selected) {
        initalProductPrice = initalProductPrice + Number(updatedInventoryItemSub.cost);
        updatedInventoryItemSubs
          .filter((x) => x.selected && x.inventoryItemSubID !== id)
          .forEach((inventoryItemSub, i) => {
            inventoryItemSub.selected = false;
            initalProductPrice = initalProductPrice - Number(inventoryItemSub.cost);
          });
      } else {
        initalProductPrice = initalProductPrice - Number(updatedInventoryItemSub.cost);
      }

      const updatedProduct = updateObject(this.state.product, {
        inventoryItemSubs: updatedInventoryItemSubs,
      });

      this.setState(({ qty }) => ({
        product: updatedProduct,
        initalProductPrice: initalProductPrice,
        productTotal: initalProductPrice * qty,
      }));
    }
  };

  //Product Update - Choice and Option
  handleCheckboxChange = ({
    prop,
    itemsCollectionName,
    collectionName,
    parentIdx,
    itemIdx,
    limit,
  }) => (event) => {
    if (this.props.mode === MODE_ORDER) {
      const product = {
        ...this.state.product,
      };

      const selection = this.getSelection(collectionName, itemsCollectionName, parentIdx);

      if (
        product.inventoryMainOptionChoice[collectionName][parentIdx][itemsCollectionName][itemIdx]
          .selected
      ) {
        const itemCost = Number(
          product.inventoryMainOptionChoice[collectionName][parentIdx][itemsCollectionName][
            itemIdx
          ][`${prop}Cost`]
        );

        product.inventoryMainOptionChoice[collectionName][parentIdx][itemsCollectionName][
          itemIdx
        ].selected = false;

        this.setState(({ initalProductPrice, productTotal, qty }) => ({
          initalProductPrice: initalProductPrice - itemCost,
          productTotal: (initalProductPrice - itemCost) * qty,
        }));
      } else {
        const itemCost = Number(
          product.inventoryMainOptionChoice[collectionName][parentIdx][itemsCollectionName][
            itemIdx
          ][`${prop}Cost`]
        );

        product.inventoryMainOptionChoice[collectionName][parentIdx][itemsCollectionName][
          itemIdx
        ].selected = true;

        this.setState(({ initalProductPrice, productTotal, qty }) => ({
          initalProductPrice: initalProductPrice + itemCost,
          productTotal: (initalProductPrice + itemCost) * qty,
        }));
      }

      if (
        limit > 0 &&
        product.inventoryMainOptionChoice[collectionName][parentIdx][itemsCollectionName][itemIdx]
          .selected &&
        selection.length >= limit
      ) {
        this.limitSelection(prop, itemIdx, collectionName, itemsCollectionName, parentIdx);
        return;
      }

      this.setState({
        product: product,
      });
    }
  };

  limitSelection(prop, itemIdx, collectionName, itemsCollectionName, parentIdx) {
    const selection = this.getSelection(collectionName, itemsCollectionName, parentIdx);
    const withoutSelected = selection.filter(
      (item) =>
        item[`${prop}Description`] !==
        this.state.product.inventoryMainOptionChoice[collectionName][parentIdx][
          itemsCollectionName
        ][itemIdx][`${prop}Description`]
    );

    this.state.product.inventoryMainOptionChoice[collectionName][parentIdx][
      itemsCollectionName
    ].forEach((item) => {
      if (withoutSelected[withoutSelected.length - 1]) {
        if (
          withoutSelected[withoutSelected.length - 1][`${prop}Description`] ===
          item[`${prop}Description`]
        ) {
          item.selected = false;
        }
      }
    });
  }

  getSelection = (collectionName, itemsCollectionName, parentIdx) => {
    return this.state.product.inventoryMainOptionChoice[collectionName][parentIdx][
      itemsCollectionName
    ].filter((item) => item.selected);
  };

  //Product Update - Quantity
  handleQtyChange = (e) => {
    const updatedProduct = updateObject(this.state.product, {
      quantity: Number(e.target.value),
    });

    this.setState({ product: updatedProduct });
  };

  // Button Events
  handleBackButton = (e) => {
    e.preventDefault();

    this.props.history.goBack();
  };

  editProduct = () => {
    const cartProducts = clone(this.props.arrCart);
    cartProducts[this.state.productIdx] = this.state.product;

    this.props.updateCartProducts(cartProducts);
    this.props.startCartUpdate();
    window.isMarketplace
      ? this.props.history.push(`/marketplace/${window.marketplaceId}/${window.waypointID}/cart`)
      : this.props.history.push('/cart');
    this.props.clearEditProduct();
  };

  addToCart = (event) => {
    event.preventDefault();
    const marketplace = this.context;

    this.props.clearSearch();

    if (this.validateProduct()) {
      this.props.history.push(this.props.redirectPath);

      if (!this.props.alcoholAcknowledged && this.state.product.bAlcohol) {
        this.props.openModal({
          type: MODAL_ALCOHOL,
          data: {
            products: [this.state.product],
            showSuggestions: true,
          },
        });
      } else {
        this.props.startAddToCart({
          products: [this.state.product],
          showSuggestions: true,
          currencyCode: this.props.config.isoCurrencyCode,
          ga: window.isMarketplace ? marketplace.ga : this.props.ga,
        });
      }
    }
  };

  //Display
  getProductPrice(selectedProduct) {
    let totalCost = 0;
    let total = 0;

    selectedProduct.inventoryMainOptionChoice.choices.forEach((choice) => {
      choice.inventoryChoices.forEach((innerChoice) => {
        if (innerChoice.selected) {
          totalCost += Number(innerChoice.choiceCost);
        }
      });
    });

    selectedProduct.inventoryMainOptionChoice.options.forEach((option) => {
      option.inventoryOptions.forEach((innerOption) => {
        if (innerOption.selected) {
          totalCost += Number(innerOption.optionCost);
        }
      });
    });

    const size = selectedProduct.inventoryItemSubs.find((x) => x.selected);
    let sizeCost = 0;
    if (size) {
      sizeCost = Number(size.cost);
    }

    const totalPrice = totalCost + sizeCost;

    total += totalPrice * selectedProduct.quantity;

    if (total == 0.0 || (total.toFixed(2) == '0.00' && this.hideZeroDollars)) return '';

    if (total === 0.0) {
      return total.toFixed(2);
    } else {
      return total.toFixed(2);
    }
  }

  //Validation
  validateProduct = () => {
    const numberOfRequeredSelections = [];
    const numberOfCheckedSelections = [];
    const checks = [
      ...this.state.product.inventoryMainOptionChoice.options,
      ...this.state.product.inventoryMainOptionChoice.choices,
    ];
    const checkedSelections = [
      ...this.getSelectedItems(this.state.product.inventoryMainOptionChoice.options, 'option'),
      ...this.getSelectedItems(this.state.product.inventoryMainOptionChoice.choices, 'choice'),
    ];

    checks.forEach((item) => {
      //if ( item.optionSelection > 0 ) {
      //	numberOfRequeredSelections.push(true);
      //}

      if (item.choiceSelection > 0) {
        numberOfRequeredSelections.push(true);
      }
    });

    //size
    numberOfRequeredSelections.push(true);

    checkedSelections.forEach((item) => {
      //if ( item.inventoryOptions ) {
      //	if ( item.optionSelection <= 0 ) return;

      //	if ( item.inventoryOptions.length === Number(item.optionSelection)) {
      //		numberOfCheckedSelections.push(true);
      //	}
      //}

      if (item.inventoryChoices) {
        if (item.choiceSelection <= 0) return;

        if (item.inventoryChoices.length === Number(item.choiceSelection)) {
          numberOfCheckedSelections.push(true);
        }
      }
    });

    //size
    if (
      this.state.product.inventoryItemSubs.length === 0 ||
      this.state.product.inventoryItemSubs.filter((x) => x.selected).length === 1
    ) {
      numberOfCheckedSelections.push(true);
    }

    return numberOfRequeredSelections.length === numberOfCheckedSelections.length;
  };

  getSelectedItems = (items, prop) => {
    const capFirstLetter = `${prop[0].toUpperCase()}${prop.slice(1)}`;

    return items
      .map((item) => ({
        ...pickAll([`${prop}Selection`, `${prop}GroupName`], item),
        [`inventory${capFirstLetter}s`]: item[`inventory${capFirstLetter}s`].filter(
          (innerItem) => innerItem.selected
        ),
      }))
      .filter((selection) => !isEmpty(selection[`inventory${capFirstLetter}s`]));
  };

  returnToHome = () => {
    this.props.history.push(`/${this.props.tableNumber}`);
  };

  isInDayPartRange = (end) => {
    const currentDateTime = new Date(this.props.data.serverTimeLocalDateTimeString);
    const endDateTime = new Date(end);

    const diff = Math.round((currentDateTime.getTime() - endDateTime.getTime()) / 1000 / 60);
    return Math.abs(diff) < this.props.dayPartMsgWarningMin;
  };

  render() {
    const imgUrl = this.state.product
      ? encodeURI(
          this.state.product.imageV2.product_16_9 ||
            this.state.product.imageV2.product_1_1 ||
            this.state.product.inventoryItemImageName
        )
      : '';
    const style = {
      backgroundImage: `url('${imgUrl}')`,
      paddingBottom: !imgUrl || this.state.imageError ? '70px' : '56.25%',
    };

    return (
      <div className='product-single'>
        <header className='product-single__head'>
          <div className='shell shell--no-padding'>
            <figure className='product-single__image' style={style}>
              <img
                src={imgUrl}
                style={{ display: 'none' }}
                alt='productImage'
                onError={(e) => {
                  if (imgUrl) {
                    this.setState({
                      imageError: true,
                    });
                  }
                }}
              />
              <button
                onClick={this.handleBackButton}
                className='product-single__btn'
                aria-label={window.resources.navigation.back_text}
              >
                <i className='fas arrow-left' title={window.resources.navigation.back_text} />
              </button>
            </figure>
          </div>
        </header>

        {!this.state.product ? (
          <InvalidItem />
        ) : (
          <div className='product-single__body'>
            <div className='product-single__content'>
              <div className='shell'>
                {this.props.dayPartMsgEnabled &&
                  this.state.product.showDayPartIcon &&
                  this.state.product.endTimeLocalString &&
                  this.isInDayPartRange(this.state.product.endTimeLocalString) && (
                    <ItemEndsBanner
                      time={new Date(this.state.product.endTimeLocalString).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    />
                  )}
                <h6 tabIndex={0} className='product-single__title product-single__title--main'>
                  {this.state.product.inventoryItemName}{' '}
                  {this.props.showCalories &&
                    this.state.product.inventoryItemSubs.length === 1 &&
                    this.state.product.inventoryItemSubs[0].calorieDisplay &&
                    '(' +
                      this.state.product.inventoryItemSubs[0].calorieDisplay +
                      ' ' +
                      window.resources.product.calories_text +
                      ')'}
                </h6>

                <div tabIndex={0} className='product-single__entry'>
                  <p>{this.state.product.inventoryItemDescription}</p>
                </div>

                <div className='product-single__meta'>
                  <p className='product-single__price'>
                    {this.state.product.costDisplay.trim() === '0.00'
                      ? localizeNumber(window.resources.product.zero_text)
                      : localizeNumber(this.state.product.costDisplay)}
                  </p>
                </div>
              </div>
            </div>

            <div className='product-single__holder'>
              <div className='shell shell--no-padding'>
                <ProductSizes
                  product={this.state.product}
                  handleSizeChange={this.handleSizeChange}
                  mode={this.props.mode}
                  sortProductSizesByCostDescending={this.props.sortProductSizesByCostDescending}
                />

                <ProductChoices
                  product={this.state.product}
                  handleCheckboxChange={this.handleCheckboxChange}
                  mode={this.props.mode}
                />

                <ProductOptions
                  product={this.state.product}
                  handleCheckboxChange={this.handleCheckboxChange}
                  mode={this.props.mode}
                />

                {this.props.mode === MODE_ORDER && (
                  <ProductQuantity
                    product={this.state.product}
                    handleQtyChange={this.handleQtyChange}
                  />
                )}

                <div className='product-single__section product-single__section--alt'>
                  {this.props.mode === MODE_ORDER &&
                    (this.props.editableProduct ? (
                      <button
                        onClick={this.editProduct}
                        disabled={!this.validateProduct()}
                        className={classnames({
                          'btn btn--block btn--flex-between': true,
                          'btn--disabled': !this.validateProduct(),
                        })}
                      >
                        <span>{localizeNumber(this.getProductPrice(this.state.product))}</span>

                        <span>{window.resources.product.edit_text}</span>
                      </button>
                    ) : (
                      <button
                        aria-label='Add to order - upon selection the item will be added to cart and you will be returned to menu'
                        onClick={this.addToCart}
                        disabled={!this.validateProduct()}
                        className={classnames({
                          'btn btn--block btn--flex-between': true,
                          'btn--disabled': !this.validateProduct(),
                        })}
                      >
                        <span>{localizeNumber(this.getProductPrice(this.state.product))}</span>

                        <span>{window.resources.product.add_to_basket_text}</span>
                      </button>
                    ))}
                  {this.props.mode !== MODE_ORDER && (
                    <button
                      onClick={this.returnToHome}
                      className={classnames({
                        'btn btn--block': true,
                      })}
                    >
                      <span>{window.resources.product.return_to_home_text}</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      tableNumber: state.checkout.tableNumber,
      data: state.app.data,
      mode: state.cart.mode,
      total: state.cart.data.totalCost,
      taxRate: state.cart.modifiers.taxRate,
      arrCart: state.cart.data.arrCart,
      editableProduct: state.cart.editableProduct,
      cartItems: state.cart.data.arrCart,
      cartPricingData: state.cart.cartPricingData,
      showCalories: state.app.config && state.app.config.bShowCalorieDisplay,
      allProducts: state.app.data && state.app.data.inventoryItemMains,
      alcoholAcknowledged: state.cart.data && state.cart.data.alcoholAcknowledged,
      sortProductSizesByCostDescending: features.sortProductSizesByCostDescending(state),
      hideZeroDollars: features.hideZeroDollars(state),
      dayPartMsgEnabled: state.app.config && state.app.config.DayPartMsgEnabled,
      dayPartMsgWarningMin:
        state.app.config &&
        state.app.config.DayPartMsgEnabled &&
        state.app.config.DayPartMsgMinWarning,
      ga: state.app.ga,
      config: state.app.config,
    }),
    {
      startAddToCart,
      openModal,
      updateCartTotal,
      updateModifiers,
      updateCartProducts,
      clearEditProduct,
      startCartUpdate,
      clearSearch,
    }
  )
)(ProductDisplay);
