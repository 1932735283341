import React from 'react';

const Aux = (props) => {
    return props.children;
};

const modalInput = (props) => {
    const inputClasses = ['field'];
    if (props.invalid && props.touched) {
        //inputClasses.push('field-invalid');
    }

    //const onKeyUp = (evt) => {
    //    var e = evt || window.event;
    //    var charCode = e.keyCode || e.which
    //    var charTyped = String.fromCharCode(charCode);
    //    var regex1 = /^[0-9]*$/;

    //    if (!regex1.test(charTyped)) {
    //        e.preventDefault();
    //        e.stopPropagation();
    //    }
    //}

    //const onChange = (text) => {
    //    let newText = '';
    //    let numbers = '0123456789';

    //    for (var i = 0; i < text.length; i++) {
    //        if (numbers.indexOf(text[i]) > -1) {
    //            newText = newText + text[i];
    //        }
    //    }
    //    this.setState({ myNumber: newText })
    //}

    let input = (<input className={inputClasses.join(' ')} type={props.type} inputMode={props.inputMode} onChange={props.onChange} placeholder={props.placeholder} aria-label={props.placeholder} value={props.value} />);
    if (props.type === 'number') {
        input = (<input className={inputClasses.join(' ')} type={props.type} min="0" inputMode="numeric" pattern="[0-9]*" onChange={props.onChange} /*onKeyUp={onKeyUp}*/ placeholder={props.placeholder} value={props.value} title="Non-negative integral number" />);
        //input = (<NumericInput
        //    //style={styles.textInput}
        //    min={0}
        //    //onChange={props.onChange}
        ///>);
    }

    return (
        <Aux>
            <div className="modal-frame__head">
                <h6 className="modal-frame__title">{props.label}</h6>
            </div>

            <div className="modal-frame__card-body">
                <div className="modal-frame__card-field">
                    <div className="modal-frame__card-error">{props.errorMessage ? props.showErrorMessage ? props.errorMessage : '\u00A0' : ''}</div>
                    {input}
                </div>
            </div>

            {props.message && <div dangerouslySetInnerHTML={{ __html: props.message }} className="modal-frame__message editable"></div>}
        </Aux>
    );
}

export default modalInput;