/**
 * @ The external dependecies.
 */
import { handleActions } from 'redux-actions';

/**
 * @ The internal dependecies.
 */

import { getSessionCustomer } from 'lib/helpers/local-storage';

/**
 * @ The actions.
 */
import { loadCustomer, updateCustomer } from './actions';
import { updateObject } from '../../../lib/utility';

/**
 * @ The reducer.
 */

//persisted to local storage
const defaultState = {
    kobp: '',
    customerID: '',
    deviceUniqueKey: '',
    gID: ''
}

/**
 * The actions Handler.
 *
 * @type {Function}
 */

const onLoadCustomer = (state, action) => {
    const persistedCustomerData = getSessionCustomer();
    if (persistedCustomerData) {
        return updateObject(state, persistedCustomerData);
    }
    else {
        return state;
    }
}

const onUpdateCustomer = (state, action) => {
    return updateObject(state, action.payload);
}

const customer = handleActions({
    [loadCustomer]: (state, action) => onLoadCustomer(state, action),
    [updateCustomer]: (state, action) => onUpdateCustomer(state, action)
}, defaultState);

export default customer;