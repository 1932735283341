/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**
 * @ The internal dependencies.
 */
import ModalInput from './modal-input'

import { openModal, closeModal } from 'store/state/ui/actions';
import { startSendEmailReceipt } from 'store/state/checkout/actions';
import { updateObject, checkValidity } from '../../lib/utility';
import { MODAL_RECEIPT_CHOICE, MODAL_RECEIPT_SUCCESS } from 'lib/constants';
import * as features from '../../store/state/features/selectors';
import { updateEmail } from 'store/state/cart/actions';
import { loyaltyLoggedIn } from '../../store/state/loyalty/selectors';
import {Aux} from '../../lib/utility'

class ModalReceiptEmail extends Component {
    state = {
        form: {
            email: {
                value: '',
                validation: {
                    isEmail: true,
                    required: true
                },
                valid: false,
                touched: false
            }
        },
        formIsValid: false
    }

    replaceVariables(html) {
        return html.replace("{this.props.privacyPolicyUrl}", this.props.privacyPolicyUrl);
    }

    componentDidMount() {
        if (this.props.email) {
            this.handleInputChange(this.props.email || this.props.emailAddress, 'email');
        }
        else if (this.props.loyaltyLoggedIn && this.props.emailAddress) {
            this.handleInputChange(this.props.emailAddress, 'email');
        }
    }

    handleInputChange = (value, field) => {
        const updatedFormElement = updateObject(this.state.form[field], {
            value: value,
            valid: checkValidity(value, this.state.form[field].validation),
            touched: true
        });

        const updatedForm = updateObject(this.state.form, {
            [field]: updatedFormElement
        });

        let formIsValid = true;
        for (let field in updatedForm) {
            formIsValid = updatedForm[field].valid && formIsValid;
        }

        this.setState({ form: updatedForm, formIsValid: formIsValid });
    }

	handleSubmitButton = (e) => {
		e.preventDefault();

        this.props.closeModal();
        if (this.props.data.send) {
            this.props.startSendEmailReceipt(this.state.form.email.value);
            this.props.openModal({
                type: MODAL_RECEIPT_SUCCESS,
                data: {
                    receiptRecipient: this.state.form.email.value
                }
            });
        }
        else {
            this.props.updateEmail(this.state.form.email.value);
        }

        if (this.props.data.fun) {
            this.props.data.fun();
        }
    }

    handleCancelClick = (e) => {
        e.preventDefault();
        this.props.closeModal();

        if (this.props.data.send && this.props.textReceiptFeatureEnabled) {
            this.props.openModal({
                type: MODAL_RECEIPT_CHOICE
            });
        }
    }

	render() {
		return (
			<div className="modal-frame modal-frame--card">
				<div className="modal-frame__body">
                    <div className="modal-frame__card modal-frame__receipt">
                        <Aux>
                            <ModalInput touched={this.state.form.email.touched} message={this.props.privacyPolicyUrl && this.replaceVariables(window.resources.receipt_email.message_text)} invalid={!this.state.form.email.valid} label={window.resources.receipt_email.label_text} placeholder={window.resources.receipt_email.placeholder_text} type="email" value={this.state.form.email.value} onChange={(event) => this.handleInputChange(event.target.value, 'email')} />
                            <div className="modal-frame__actions modal-frame__actions--flex">
                                <button
                                    onClick={this.handleCancelClick}
                                    className="btn btn--secondary">{window.resources.receipt_email.cancel_text}</button>

                                <button
                                    disabled={!this.state.formIsValid}
                                    onClick={this.handleSubmitButton}
                                    className="btn">{window.resources.receipt_email.confirm_text}</button>
                            </div>
                        </Aux>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(connect(
    (state) => ({
        email: state.cart.data.email,
        privacyPolicyUrl: state.app.config.PrivacyURL,
        textReceiptFeatureEnabled: features.textReceiptFeatureEnabled(state),
        data: state.ui.modal.data,
        loyaltyLoggedIn: loyaltyLoggedIn(state),
        emailAddress: state.loyalty.emailAddress
    }),
    {
        openModal,
        closeModal,
        startSendEmailReceipt,
        updateEmail
	}
)(ModalReceiptEmail));
