/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IconSvg from 'components/common/icon-svg';

/**
 * @ The internal dependencies.
 */
import { getCurrentLanguageDescription } from 'lib/helpers/language';
import { openModal } from 'store/state/ui/actions';
import { MODAL_LANGUAGE } from 'lib/constants';
import GlobeLogo from 'assets/svg/globe.svg';

/**
 * Class for select language.
 *
 * @class SelectLanguage (name)
 */
class SelectLanguage extends Component {
    handleClick = (e) => {
        e.preventDefault();

        this.props.openModal({
            type: MODAL_LANGUAGE,
            data: null
        });
    }

    render() {
        const style = {
            display: this.props.inline ? "inline" : "block"
        };
        return (
            <div className="select-language" style={style} aria-label='select language'>
                <a
                    aria-label='select language'
                    tabIndex={0}
                    onClick={this.handleClick}
                    className="header__btn header__btn-back">
                    <IconSvg title={window.resources.navigation.language_text} src={GlobeLogo} className="select-language_icon" alt="Language" />
                    {this.props.showDescription === true && <span className="select-language_description">{getCurrentLanguageDescription()}</span>}
                </a> 
            </div>
		);
	}
}

export default withRouter(connect(
    (state) => ({

    }),
    {
        openModal
    }
)(SelectLanguage));
