/**
 * @ The external dependencies.
 */
import { all, call } from 'redux-saga/effects';

/**
 * @ The internal dependencies.
 */
import app from './state/app/effects';
import cart from './state/cart/effects';
import checkout from './state/checkout/effects';
import customer from './state/customer/effects';
import tablecheck from './state/tablecheck/effects';
import tab from './state/tab/effects';
import survey from './state/survey/effects';
import localization from './state/localization/effects';
import freedompay from './state/freedompay/effects';
import questionnaire from './state/questionnaire/effects';
import spreedly from './state/spreedly/effects';
import loadedvalueaccount from './state/loadedvalueaccount/effects';
import loyalty from './state/loyalty/effects';
import nets from './state/nets/effects';
import donation from './state/donation/effects';
import adyen from './state/adyen/effects';

/**
 * Start the workers.
 *
 * @return {Generator}
 */
export default function* foreman() {
    yield all([
        call(app),
        call(cart),
        call(checkout),
        call(customer),
        call(tablecheck),
        call(tab),
        call(survey),
        call(localization),
        call(freedompay),
        call(questionnaire),
        call(spreedly),
        call(loadedvalueaccount),
        call(loyalty),
        call(nets), 
        call(donation), 
        call(adyen)
	]);
}
