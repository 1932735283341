/**
 * @ The external dependecies.
 */
import React from 'react';
import classnames from 'classnames';

const Loader = (props) => {
	return (
		<div className={classnames('loader', [
				`${props.modifier}`
			])}>
			<div className="loader__content">
                <h2 className="loader__title">{props.title}</h2>
                <div className="circle-loader" >
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
			</div>
		</div>
	)
}

export default Loader;