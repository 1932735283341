/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPencilAlt } from '@fortawesome/free-solid-svg-icons';

/**
 * @ The internal dependencies.
 */
import { openModal } from 'store/state/ui/actions';
import { loyaltyLogOut } from 'store/state/loyalty/actions';
import { loyaltyLoggedIn } from 'store/state/loyalty/selectors';
import { MODAL_LOYALTY_LOGIN, MODAL_LOYALTY_AMOUNT } from 'lib/constants';
import loyaltyImageLogin from '../../assets/images/loyalty.png';
import loyaltyImagePay from '../../assets/images/loyalty2.png';
import { loyaltyAdded } from 'store/state/cart/selectors';
import { startCartUpdate } from 'store/state/cart/actions';

/**
 * Class for cart calculator.
 *
 * @class CartCalculator (name)
 */
class LoyaltyButton extends Component {

    loyaltyLoginHandler = () => {
        this.props.openModal({
            type: MODAL_LOYALTY_LOGIN,
            data: {
                onAuthorized: () => {
                    
                }
            }
        });
    }

    loyaltyLogOutHandler = () => {
        this.props.loyaltyLogOut();
        this.props.startCartUpdate();
    }

    loyaltyPayHandler = () => {
        if (this.props.loyaltyLoggedIn) {
            this.loyaltyEditAmountHandler();
        }
        else {
            this.props.openModal({
                type: MODAL_LOYALTY_LOGIN,
                data: {
                    onAuthorized: () => {
                        return this.loyaltyEditAmountHandler();
                    }
                }
            });
        }
    }

    loyaltyEditAmountHandler = () => {
        this.props.openModal({
            type: MODAL_LOYALTY_AMOUNT
        });
    }

    render() {
        let buttonLoginLogout = this.props.loyaltyLoggedIn ?
            <li key="buttonLogin"><button       
                className="cart-calculator__btn">
                <FontAwesomeIcon onClick={this.loyaltyLogOutHandler} style={{ float: 'left', width: '15px', marginRight: '10px', height: '15px', marginTop: '8px' }} icon={faTimes} />
                <p>
                    <span style={{fontSize:'8px'}}>RED ROYALTY ACCOUNT</span><br />
                    {this.props.accountNumber}
                </p>
            </button></li> : <li key="buttonLogout"><button
                onClick={this.loyaltyLoginHandler}
                className="cart-calculator__btn"><img style={{ float: 'left', width: '15px', marginRight: '5px', marginTop: '5px' }} src={loyaltyImageLogin} alt="" /><span style={{ fontSize: '10px', paddingTop: '5px', paddingBottom: '5px' }}>{window.resources.loyalty_button.log_in_text}</span>
            </button></li>

        let buttonPayEdit = this.props.loyaltyAdded ? <li key="buttonEdit"><button
            onClick={this.loyaltyEditAmountHandler}
            className="cart-calculator__btn"><FontAwesomeIcon style={{ float: 'left',width: '15px', marginRight: '5px', height: '15px', marginTop: '5px' }} icon={faPencilAlt} /><span style={{ fontSize: '10px', paddingTop: '5px', paddingBottom: '5px' }}>Edit Red Royalty Dollars</span>
        </button></li> : <li key="buttonPay"><button
                onClick={this.loyaltyPayHandler}
                className="cart-calculator__btn"><img style={{ float: 'left',width: '15px', marginRight: '5px', marginTop: '5px' }} src={loyaltyImagePay} alt="" /><span style={{ fontSize: '10px', paddingTop: '5px', paddingBottom: '5px' }}>Pay With Red Royalty Dollars</span>
        </button></li>

        return [buttonLoginLogout, buttonPayEdit]
    }
}

export default connect(
    (state) => ({
        loyaltyLoggedIn: loyaltyLoggedIn(state),
        loyaltyAdded: loyaltyAdded(state),
        accountNumber: state.loyalty.accountNumber
    }),
    {
        openModal,
        loyaltyLogOut,
        startCartUpdate
    }
)(LoyaltyButton);
