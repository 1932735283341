/**
 * @ The external dependencies.
 */
import {
    all,
    call,
    takeLatest,
    select
} from 'redux-saga/effects';

import {
    delay
} from 'redux-saga';

import config from 'config';
import { sendFormRequest, receiveFormResponse } from './actions';
import makeRequest from '../../../lib/helpers/api-request';
import { getSelectedItems } from '../cart/selectors';

import { updateObject } from '../../../lib/utility';

export function* sendFormRequestWorker(action) {

    let getFailed = false;

    const retries = config.RETRIES;
    const delayBetweenRetries = config.RETRY_DELAY;

    const onError = () => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log('Failed getting Nets form from Server, Retrying...')
        }
        getFailed = true;
    };

    //retries
    for (let i = 0; i < retries; i++) {

        getFailed = false;

        let cart = (yield select()).cart;

        const cartDataUpdated = updateObject(cart.data, {
            NetsSession: {
                PaymentId: "",
                CardType: "",
                CardLastFour: ""
            }
        });

        const payload = {
            ...cartDataUpdated,
            totalprice: cartDataUpdated.totalCost,
            currencyCode: cart.cartPricingData.isoCurrencyCode,
            checkoutUrl: window.location.origin + window.location.pathname,
            orderLineItems: cart.cartPricingData.orderLineItems,
            arrCart: cart.data.arrCart.map(product => ({
                inventoryItemSubs: product.inventoryItemSubs.filter(x => x.selected).map(item => ({
                    inventoryItemSubID: item.inventoryItemSubID,
                    inventoryItemSubName: item.inventoryItemSubName,
                    selected: item.selected,
                    cost: item.cost
                })),
                inventoryItemID: product.inventoryItemID,
                inventoryItemName: product.inventoryItemName,
                quantity: product.quantity,
                specialNotes: "",
                inventoryOrder: product.inventoryOrder,
                inventoryMainOptionChoice: {
                    options: getSelectedItems(product.inventoryMainOptionChoice.options, 'option'),
                    choices: getSelectedItems(product.inventoryMainOptionChoice.choices, 'choice'),
                },
                comboID: product.comboID,
                comboOrderID: product.comboOrderID,
                comboChoiceGroupID: product.comboChoiceGroupID
            }))
        }

        yield call(makeRequest, {
            endpoint: 'getNetsPaymentID',
            payload: payload,
            requestAction: action,
            receiveAction: receiveFormResponse,
            onError: onError
        });

        if (getFailed && i < retries) {
            yield delay(delayBetweenRetries);
        }
        else {
            //exit retry loop
            break;
        }
    }
}

/**
 * Start all workers.
 *
 * @return {Generator}
 */
export default function* foreman() {
    yield all([
        takeLatest(sendFormRequest, sendFormRequestWorker)
    ]);
}
