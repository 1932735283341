/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalInput from './modal-input'

/**
 * @ The internal dependencies.
 */
import { closeModal } from 'store/state/ui/actions';
import { updateName } from 'store/state/cart/actions';
import { sendTableCheckRequest, setDineInTakeAwaySelected } from 'store/state/tablecheck/actions';
import { updateObject, checkValidity } from '../../lib/utility';
import * as features from '../../store/state/features/selectors'
import { loyaltyLoggedIn } from '../../store/state/loyalty/selectors';

/**
 * Class for modal table number.
 *
 * @class ModalTableNumber (name)
 */
class ModalTakeAway extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            form: {
                name: {
                    value: '',
                    validation: {
                        required: true,
                        isString: true
                    },
                    valid: false,
                    touched: false,
                }
            },
            formIsValid: false
        }
    }

    componentDidMount() {
        if (this.props.loyaltyLoggedIn && this.props.name) {
            this.handleInputChange({ target: { value: this.props.name } }, 'name');
        }
    }

    checkValidityCustom(value, rules) {
        let isValid = checkValidity(value, rules);

        return isValid;
    }

    handleInputChange = (event, field) => {
        const updatedFormElement = updateObject(this.state.form[field], {
            value: event.target.value,
            valid: this.checkValidityCustom(event.target.value, this.state.form[field].validation),
            touched: true
        });

        const updatedForm = updateObject(this.state.form, {
            [field]: updatedFormElement
        });

        let formIsValid = true;
        for (let field in updatedForm) {
            formIsValid = updatedForm[field].valid && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid: formIsValid });
    }

    handleSubmitButton = (e) => {
        e.preventDefault();

        this.props.sendTableCheckRequest({
            showSpinner: true,
            getOpenTickets: this.props.tabFeatureEnabled,
            tableNumber: 'vk'
        });

        this.props.updateName(this.state.form.name.value);
        this.props.setDineInTakeAwaySelected(true)
        this.props.closeModal();
    }

    render() {
        return (
            <div className="modal-frame modal-frame--card">
                <div className="modal-frame__body">
                    <div className="modal-frame__card">
                        <div className="modal-frame__head">
                            <h5 className="modal-frame__title">{window.resources.home.takeaway.toLocaleUpperCase()}</h5>
                            <h6>{window.resources.home.takeaway_instructions}</h6>
                        </div>
                        <ModalInput touched={this.state.form.name.touched} invalid={!this.state.form.name.valid} placeholder={window.resources.home.your_name} type="string" value={this.state.form.name.value} onChange={(event) => this.handleInputChange(event, 'name')} onKeyUp={(event) => this.handleInputChange(event, 'name')} />
                        <div className="modal-frame__actions modal-frame__actions--flex">
                            <button
                                onClick={() => this.props.closeModal()}
                                className="btn btn--secondary">{window.resources.table_number.cancel_text}</button>
                            <button
                                disabled={!this.state.formIsValid}
                                onClick={this.handleSubmitButton}
                                className="btn">{window.resources.table_number.confirm_text}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tabFeatureEnabled: features.tabFeatureEnabled(state),
        loyaltyLoggedIn: loyaltyLoggedIn(state),
        name: state.loyalty.name
    };
}

export default withRouter(connect(
    mapStateToProps,
    {
        closeModal,
        sendTableCheckRequest,
        updateName,
        setDineInTakeAwaySelected
    }
)(ModalTakeAway));
