/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**
 * @ The internal dependencies.
 */
import { openModal, closeModal } from 'store/state/ui/actions';
import { updateObject, checkValidity } from '../../lib/utility';
import { MODAL_RECEIPT_EMAIL, MODAL_RECEIPT_TEXT } from 'lib/constants';
import * as features from '../../store/state/features/selectors';

/**
 * Class for modal table number.
 *
 * @class ModalTableNumber (name)
 */
class ModalReceiptChoice extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
                form: {

                },
                formIsValid: true
        }
    }

    checkValidityCustom(value, rules) {
        let isValid = checkValidity(value, rules);
        return isValid;
    }

    handleInputChange = (event, field) => {
        const updatedFormElement = updateObject(this.state.form[field], {
            value: event.target.value,
            valid: this.checkValidityCustom(event.target.value, this.state.form[field].validation),
            touched: true
        });

        const updatedForm = updateObject(this.state.form, {
            [field]: updatedFormElement
        });

        let formIsValid = true;
        for (let field in updatedForm) {
            formIsValid = updatedForm[field].valid && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid: formIsValid });
    }

    handleEmailClick = (e) => {
        e.preventDefault();
        this.props.closeModal();
        this.props.openModal({
            type: MODAL_RECEIPT_EMAIL,
            data: {
                send: true
            }
        });
    }

    handleTextClick = (e) => {
        e.preventDefault();    
        this.props.closeModal();
        this.props.openModal({
            type: MODAL_RECEIPT_TEXT,
            data: {
                send: true
            }
        });
    }

    handleCancelClick = (e) => {
        e.preventDefault();
        this.props.closeModal();
    }

    render() {
		return (
			<div className="modal-frame modal-frame--card">
				<div className="modal-frame__body">
                    <div className="modal-frame__card">
                        <div className="modal-frame__head">
                            <h6 className="modal-frame__title">{window.resources.receipt_choice.question_text}</h6>
                        </div>
                        <div className="modal-frame__actions modal-frame__actions--flex modal-frame__actions--space">
                            {this.props.emailReceiptFeatureEnabled && <button
                                disabled={!this.state.formIsValid}
                                onClick={this.handleEmailClick}
                                className="btn">{window.resources.receipt_choice.email_choice_text}</button>}
                            {this.props.textReceiptFeatureEnabled && <button
                                disabled={!this.state.formIsValid}
                                onClick={this.handleTextClick}
                                className="btn">{window.resources.receipt_choice.text_choice_text}</button>}
                        </div>
                        <div className="modal-frame__links">
                            <a
                             onClick={this.handleCancelClick}
                            >{window.resources.receipt_choice.cancel_text}</a>
                        </div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
    return {
        tableNumber: state.checkout.tableNumber,
        textReceiptFeatureEnabled: features.textReceiptFeatureEnabled(state),
        emailReceiptFeatureEnabled: features.emailReceiptFeatureEnabled(state)
    };
}

export default withRouter(connect(
    mapStateToProps,
    {
        openModal,
        closeModal
	}
)(ModalReceiptChoice));
