/**
 * @ The external dependecies
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import classnames from 'classnames';

/**
 * @ The internal dependecies.
 */

/**
 * Class for Cart Exiting Tab.
 *
 * @class CartExistingTab (name)
 */

class CartExistingTab extends Component {
    state = {
        expanded: false
    }

    handleExpandButton = (e) => {
        e.preventDefault();

        this.setState({
            expanded: !this.state.expanded
        });
    }

    render() {
        const { arrCart } = this.props;
        const subtotal = this.props.cartPricing.orderLineItems.find(li => li.OrderLineItemTypeIdentifier === 'Subtotal' || li.OrderLineItemTypeIdentifier === 'SUBTOTAL' || li.OrderLineItemTypeIdentifier === 'TOTAL').AmountDisplay;

        return (
            <div className="cart__section" onClick={this.handleExpandButton}>
                <div className="shell shell--no-padding">
                    <div className={`existing-tab ${this.state.expanded && 'existing-tab--expanded'}`}>
                        <table>
                            <thead>
                                <tr>
                                    <th className="existing-tab__title">
                                        {window.resources.cart.existing_tab_text}
                                    </th>

                                    {!this.state.expanded ? <th className="qty"> {window.resources.cart.previous_subtotal_text} {subtotal}</th> : <th className="qty"></th>}

                                    <th className="price">{!this.state.expanded ? <i className="fas fa-arrow-alt-circle-right"></i> : <i className="fas fa-arrow-alt-circle-down"></i>}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.expanded && arrCart.map((product, i) => (
                                    <tr key={product.inventoryItemName + i}>
                                        <td className={classnames({
                                            'name': true
                                        })}>

                                            {product.inventoryItemName}

                                            <span>
                                                {product.modifiersDisplay}
                                            </span>
                                        </td>

                                        <td className="qty">
                                            {product.quantity}
                                        </td>

                                        <td className="price">
                                            {product.priceDisplay}
                                        </td>
                                    </tr>
                                ))}
                                {this.state.expanded && (
                                    <tr>
                                        <td colSpan="2" className="subtotal-title">{window.resources.cart.previous_subtotal_text}</td>
                                        <td className="subtotal">{subtotal}</td>
                                    </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
		)
	}
}

export default connect(
    (state) => ({
        
	})
    , {
       
    })(CartExistingTab);
