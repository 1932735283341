/**
 * @ The external dependecies.
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
	compose,
} from 'ramda';


/**
 * @ The internal dependencies.
 */
import Header from 'components/header/header';
import withDataValidation from 'lib/helpers/hocs/with-data-validation';
import { getCategories } from 'store/state/category/selectors';
import { setSelectedCategory } from 'store/state/category/actions';
import * as features from '../../store/state/features/selectors'
import { Context } from '../../store/state/marketplace/store';

/**
 * Class for categories.
 *
 * @class Categories (name)
 */
class Categories extends Component {
	static contextType = Context;

	componentDidMount() {
		const marketplace = this.context;

		if (window.isMarketplace && marketplace && marketplace.promoCode && this.props.employeeDiscountFeatureEnabled) marketplace.setShowDiscountModal(true);
	}

	handleCategoryClick = id => e => {
		e.preventDefault();

		this.props.setSelectedCategory(id);

		this.props.history.push(window.isMarketplace ? `/marketplace/${window.marketplaceId}/${window.waypointID}/menu` : '/menu');
	}

    render() {
		return (
			<main className="main">
				<Header />

				<div className="section-categories">
					{this.props.sommelierEnabled && 
					 <div className="shell btn-section"> 
					 	<Link 
						 	to='/sommelier'
							className='btn btn--block'
						>
							{window.resources.sommelier.help_choose_flight_text}
						</Link>
					 </div>}    
                    <div className="shell">               
                        {getCategories(this.props.data.inventoryTitles).concat().sort((a, b) => parseInt(a.inventoryTitleOrder, 10) - parseInt(b.inventoryTitleOrder, 10)).map(category => (
							<Link
								to={window.isMarketplace ? `/marketplace/${window.marketplaceId}/${window.waypointID}/menu` : "/menu"}
								onClick={this.handleCategoryClick(category.inventoryTitleID)}
								key={category.inventoryTitleID}
								style={{
									backgroundImage: `url(${category.imageNameWide})`
								}}
								className={classnames({
									'banner': true,
									'banner-no-image': !category.imageNameWide.length,
									'banner-2-column': this.props.twoColumnCategoriesFeatureEnabled
								})}>
								<div className="banner__caption">
									<span>{category.inventoryTitleDescription}</span>
								</div>
							</Link>
						))}
					</div>
				</div>
			</main>
		);
	}
}

export default compose(
	connect(
		(state) => ({
			twoColumnCategoriesFeatureEnabled: features.twoColumnCategoriesFeatureEnabled(state),
			sommelierEnabled: features.sommelierEnabled(state), 
			employeeDiscountFeatureEnabled: features.employeeDiscountFeatureEnabled(state)
		}),
		{
			setSelectedCategory
		}
	),
	withDataValidation
)(Categories);
