/**
 * @ The external dependencies.
 */
import {
    all,
    call,
    takeLatest,
    put,
    select
} from 'redux-saga/effects';

import {
    delay
} from 'redux-saga';

/**
 * @ The internal dependencies.
 */
import makeRequest from '../../../lib/helpers/api-request';

/**
 * @ The actions.
 */
import {
    sendCloseTabRequest,
    receiveCloseTabResponse,
    tabAuthorization,
    tabAuthorizationStart,
    tabAuthorizationEnd,
    tabAuthorizationUpdate,
    clearTabAuthorization,
    tabAuthorizationReceiveResponse
} from './actions';

import { getTabId, tabAuthorizationSuccess } from './selectors'
import { startCartUpdate } from '../cart/actions';

/**
 * @ The sagas.
 */

export function* sendCloseTabRequestWorker(action) {
    const payload = {
        orderID: action.payload.orderID
    };

    yield call(makeRequest, {
        endpoint: 'closeTab',
        payload: payload,
        requestAction: action,
        receiveAction: receiveCloseTabResponse
    });
}

export function* tabAuthorizationWorker(action) {
    console.log('Performing Tab Authorization');

    let tabId = yield select(getTabId);

    var authDetails = {
        lastFour: action.payload.cardLastFour,
        waypointID: action.payload.waypointID,
        locationName: action.payload.tableName,
        openCheckTicketID: tabId
    };

    yield put(tabAuthorizationStart({ showSpinner: action.payload.authorized && (action.payload.showSpinner || action.payload.showSpinner === null)}));

    let isAuthorized = false;

    if (!action.payload.authorized) {
        yield call(makeRequest, {
            endpoint: 'authorizeTab',
            payload: authDetails,
            requestAction: action,
            receiveAction: tabAuthorizationReceiveResponse
        });

        isAuthorized = yield select(tabAuthorizationSuccess);
    }
    else {
        isAuthorized = true;
    }

    if (isAuthorized) {
        yield put(tabAuthorizationUpdate(authDetails));
    }
    else {
        yield put(clearTabAuthorization());
    }

    yield put(startCartUpdate({
        initializeTips: true,
        showSpinner: isAuthorized && (action.payload.showSpinner || action.payload.showSpinner === null)
    }));

    yield delay(500);

    yield put(tabAuthorizationEnd());
}

/**
 * Start all workers.
 *
 * @return {Generator}
 */
export default function* foreman() {
    //  effects will get executed in parallel
    yield all([
        takeLatest(sendCloseTabRequest, sendCloseTabRequestWorker),
        takeLatest(tabAuthorization, tabAuthorizationWorker)
    ]);
}