/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**
 * @ The internal dependencies.
 */
import { closeModal } from 'store/state/ui/actions';

/**
 * Class for modal table number.
 *
 * @class ModalLoyaltyLoginSuccess (name)
 */
class ModalLoyaltyLoginSuccess extends Component {
    replaceVariables(html) {
        return html.replace("{name}", this.props.name || '')
            .replace(' !', '!')
            .replace("{balance}", `${this.props.currencySymbol}${this.floorFigure(this.props.balance)}`)
            .replace("{tier}", this.props.tier || '!')
            .replace('Tier !', '');
    }

    floorFigure(figure, decimals = 2) {
        var d = Math.pow(10, decimals);
        return (parseInt(figure * d, 10) / d).toFixed(decimals);
    };

    handleSubmitButton = (e) => {
        e.preventDefault();
        this.props.closeModal();

        if (this.props.data && this.props.data.onAuthorized) {
            this.props.data.onAuthorized();
        }
    }

    render() {
        return (
            <div className="modal-frame modal-frame--card">
                <div className="modal-frame__body">
                    <div className="modal-frame__card">
                        <div className="modal-frame__head">
                            <h6 className="modal-frame__title" dangerouslySetInnerHTML={{ __html: this.replaceVariables(window.resources.loyalty_login_success.message_text) }}></h6>
                        </div>

                        <div className="modal-frame__actions modal-frame__actions--flex">
                            <button
                                onClick={this.handleSubmitButton}
                                className="btn">{window.resources.modal.confirm_text}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        currencySymbol: state.app.currencySymbol,
        name: state.loyalty.name,
        balance: state.loyalty.balance,
        tier: state.loyalty.tier,
        data: state.ui.modal.data
    };
}

export default withRouter(connect(
    mapStateToProps,
    {
        closeModal
    }
)(ModalLoyaltyLoginSuccess));
