import React, { useState } from 'react';
import IconSvg from './icon-svg';
import config from 'config';

export const MarketplaceAssetSvg = (props) => {
    const srcs = [window.marketplaceId].map(marketplaceId => config.REACT_APP_MARKETPLACE_RESOURCES_URL + marketplaceId + (props.path.startsWith('/') ? '' : '/') + props.path);

    const [index, setIndex] = useState(srcs.length - 1);

    return <IconSvg src={srcs[index]} onError={() => {
        if (index - 1 >= 0) {
            setIndex(index - 1);
        }
    }} {...props} />;
}

const StoreAssetSvg = (props) => {
    var srcs = []

    if (window.configServiceEnabled) {
        var key = "logo"
        if (props.path.includes('logo-menu')) key = "logoMenu"
        if (props.path.includes('logo-multi')) key = "logoMulti"
        srcs = window.styleWaypointIDs.map(styleWaypointID => `${window.configBaseURL}/waypoint/${styleWaypointID}/assets/${window.configENV}/${key}?platformTypeID=${window.platformType}`);
    } else {
        srcs = window.styleWaypointIDs.map(styleWaypointID => config.REACT_APP_STORE_ASSETS_URL + styleWaypointID + (props.path.startsWith('/') ? '' : '/') + props.path);
    }


    const [index, setIndex] = useState(srcs.length - 1);

    return <IconSvg src={srcs[index]} onError={() => {
        if (index - 1 >= 0) {
            setIndex(index - 1);
        }
    }} {...props} />;
}

export default StoreAssetSvg;