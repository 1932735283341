export const airportInitialState = {
    airportinfo: {}, 
    loading: false, 
    error: false, 
    isinvalid: false
}

const airportReducer = (state = airportInitialState, action) => {
    switch (action.type) {
        case 'FETCH_AIRPORTINFO_LOAD': {
            return {
                ...state, 
                loading: true, 
                error: false
            }
        }
        case 'FETCH_AIRPORTINFO': {
            if (action.payload)
                return {
                    ...state, 
                    airportinfo: action.payload, 
                    loading: false, 
                    error: false, 
                    isinvalid: false
                }
            else 
                return {
                    ...state, 
                    airportinfo: action.payload, 
                    loading: false, 
                    error: false,
                    isinvalid: true
                }
        }
        case 'FETCH_AIRPORTINFO_ERR': {
            return {
                ...state, 
                loading: false, 
                error: true
            }
        }
        default: return state;
    }
}

export default airportReducer;