/**
 * @ The external dependencies.
 */
import {
	pickAll,
	isEmpty
} from 'ramda';

/**
 * Gets the selected items.
 *
 * @param {Array}
 * @param {String}
 * @return {Array}
 */
export const getSelectedItems = (items, prop) => {
	const capFirstLetter = `${prop[0].toUpperCase()}${prop.slice(1)}`;

	return items.map(item => ({
		...pickAll([
			`${prop}Selection`,
			`${prop}GroupName`,
		], item),
		[`inventory${capFirstLetter}s`]: item[`inventory${capFirstLetter}s`].filter(innerItem => innerItem.selected)
	})).filter(selection => !isEmpty(selection[`inventory${capFirstLetter}s`]));
}

/**
 * Gets the featured products.
 *
 * @param {Array}
 * @return {Array}
 */
export const getFeaturedProducts = products => products.filter(product => product.inventoryFeatureItem === true);

export const getUpsaleProducts = (cartProducts, upsaleProductIds, products) => {
    var filteredUpsaleProducts = products.filter(product => upsaleProductIds && upsaleProductIds.includes(product.inventoryItemID) && (!cartProducts || !cartProducts.find(p => p && p.inventoryItemID === product.inventoryItemID)));
    return filteredUpsaleProducts.sort(function (a, b) {
        return upsaleProductIds.indexOf(a["inventoryItemID"]) - upsaleProductIds.indexOf(b["inventoryItemID"]);
    });
}
export const getAddOnProducts = (cartProducts, addonProductIds, products) => {
    var filteredAddonProducts = products.filter(product => addonProductIds && addonProductIds.includes(product.inventoryItemID));
    return filteredAddonProducts.sort(function (a, b) {
        return addonProductIds.indexOf(a["inventoryItemID"]) - addonProductIds.indexOf(b["inventoryItemID"]);
    });
}

export const getProductByInventoryItemID = (state, inventoryItemID) => {
    return state.app.data.inventoryItemMains.find(x => x.inventoryItemID === inventoryItemID);
}

export const getComboGroupByInventoryItemID = (state, inventoryItemID) => {
    const comboLink = state.app.data.comboEngine.comboLinks.find(x => x.inventoryItemID === inventoryItemID);
    const comboGroup = comboLink && state.app.data.comboEngine.comboGroups.find(x => x.comboID === comboLink.comboID);
    return comboGroup;
}

export const retryCartUpdate = (state) => {
    return state.cart.retryCartUpdate;
}

export const getOfflineProducts = (payload) => {
    return payload.cartExceptions ? payload.cartExceptions.filter((value, index, array) => value.exceptionType === 'ITEM_OFFLINE').map((ex) => ex.inventoryItemID) : [];
}

export const isItemOffline = (payload) => {
    return payload.cartExceptions ? payload.cartExceptions.filter((value, index, array) => value.exceptionType === 'ITEM_OFFLINE').length > 0 : false;
}

export const isSystemDown = (payload) => {
    return payload.cartExceptions ? payload.cartExceptions.filter((value, index, array) => value.exceptionType === 'SYSTEM_DOWN').length > 0 : false;
}

export const getInventoryProductByInventoryItemID = (state, inventoryItemID) => {
    return state.app.data.inventoryItemMains.find(x => x.inventoryItemID === inventoryItemID);
}

export const hasAlcoholInCart = (state) => {
    return state.cart.data.arrCart.some(item => item.bAlcohol)
}

export const loyaltyAdded = (state) => {
    return state.cart.data.loyaltyAmount > 0;
}