/**
 * @ The external dependecies.
 */
import { handleActions } from 'redux-actions';

/**
 * @ The internal dependecies.
 */
import { updateObject } from '../../../lib/utility';

import {
    endRequestWithError
} from '../requests/actions';

/**
 * @ The actions.
 */
import {
    sendLoadedValueAccountBalanceRequest,
    receiveLoadedValueAccountBalanceResponse,
    clearLoadedValueAccountBalanceResponse,
    addLoadedValueAccountNumberToCart,
    removeLoadedValueAccountNumberFromCart
} from './actions';
import { add } from 'ramda';

/**
 * @ The reducer.
 */
const defaultState = {
    loadedValueAccountBalanceCheckResponse: false,
}

/**
 * The actions Handler.
 *
 * @type {Function}
 */

const onSendLoadedValueAccountBalanceRequest = (state, action) => {
    return updateObject(state, {
        loadedValueAccountNumber: action.payload.accountNumber,
    });
}

const onReceiveLoadedValueAccountBalanceResponse = (state, action) => {
    if (action.payload.success) {
        return updateObject(state, {
            loadedValueAccountBalanceCheckResponse: parseFloat(action.payload.value),
        });
    }
    else {
        return updateObject(state, {
            loadedValueAccountBalanceCheckResponse: 'failed',
        });
    }
}

const onClearLoadedValueAccountBalanceResponse = (state, action) => {
    return updateObject(state, {
        loadedValueAccountBalanceCheckResponse: false,
        loadedValueAccountNumber: false,
    })
}


//Errors
const onEndRequestWithError = (state, action) => {
    if (action.payload.id === `'${sendLoadedValueAccountBalanceRequest}'`) {
        return updateObject(state, {
            loadedValueAccountBalanceCheckResponse: 'failed',
        });
    }

    return state;
}

const loadedvalueaccount = handleActions({
    [sendLoadedValueAccountBalanceRequest]: (state, action) => onSendLoadedValueAccountBalanceRequest(state, action),
    [receiveLoadedValueAccountBalanceResponse]: (state, action) => onReceiveLoadedValueAccountBalanceResponse(state, action),
    [clearLoadedValueAccountBalanceResponse]: (state, action) => onClearLoadedValueAccountBalanceResponse(state, action),
    [endRequestWithError]: (state, action) => onEndRequestWithError(state, action),
}, defaultState);

export default loadedvalueaccount;