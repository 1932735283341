export const orderStatusInitialState = {
    orderstatus: {}, 
    loading: false, 
    error: false
}

const orderStatusReducer = (state = orderStatusInitialState, action) => {
    switch (action.type) {
        case 'FETCH_ORDER_STATUS_LOAD': {
            return {
                ...state, 
                loading: true, 
                error: false
            }
        }
        case 'FETCH_ORDER_STATUS': {
            return {
                ...state, 
                orderstatus: action.payload, 
                loading: false, 
                error: false
            }
        }
        case 'FETCH_ORDER_STATUS_ERR': {
            return {
                ...state, 
                loading: false, 
                error: true
            }
        }
        default: return state;
    }
}

export default orderStatusReducer;