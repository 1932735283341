/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**
 * @ The internal dependencies.
 */
import { closeModal } from 'store/state/ui/actions';
import { clearLoadedValueAccountBalanceResponse } from 'store/state/loadedvalueaccount/actions';
/**
 * Class for modal table number.
 *
 * @class ModalLoadedValueAccountBalanceCheck (name)
 */
class ModalLoadedValueAccountBalanceResponse extends Component {

    handleSubmitButton = (e) => {
        e.preventDefault();
        this.props.clearLoadedValueAccountBalanceResponse();
        this.props.closeModal();
    }

    balance() {
        return Number(this.props.loadedValueAccountBalanceCheckResponse)
    }

    render() {
        return (
            <div className="modal-frame modal-frame--card">
                <div className="modal-frame__body">
                    <div className="modal-frame__card">
                        <div className="modal-frame__head">
                            <h6 className="modal-frame__title">{this.balance() > 0 ? window.resources.loaded_value_account.balance_response : window.resources.loaded_value_account.balance_response_bad_news}{` ${this.props.currencySymbol}`}{Number(this.balance()).toLocaleString()}</h6>
                        </div>

                        <div className="modal-frame__actions modal-frame__actions--flex">
                            <button
                                onClick={this.handleSubmitButton}
                                className="btn">{window.resources.modal.confirm_text}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        currencySymbol: state.app.currencySymbol,
        loadedValueAccountBalanceCheckResponse: state.loadedvalueaccount.loadedValueAccountBalanceCheckResponse,
    };
}

export default withRouter(connect(
    mapStateToProps,
    {
        closeModal,
        clearLoadedValueAccountBalanceResponse,
    }
)(ModalLoadedValueAccountBalanceResponse));
