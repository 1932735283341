/**
 * @ The internal dependecies.
 */

import ApplePayLogo from '../../assets/svg/apple-pay.svg';
import GooglePayLogo from '../../assets/svg/google-pay.svg';
import CardPayLogo from '../../assets/svg/card-pay.svg';
import VisaLogo from '../../assets/svg/visa.svg';
import MastercardLogo from '../../assets/svg/mastercard.svg';
import AmexLogo from '../../assets/svg/amex.svg';
import JcbLogo from '../../assets/svg/jcb.svg';
import DiscoverLogo from '../../assets/svg/discover.svg';
import { clearSpreedly, sendFormRequest } from '../../store/state/spreedly/actions';
import ComponentLoader from 'components/loader/component-loader';
import ApplePayButton from './apple-pay-button';

/**
 * @ The external dependecies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IconSvg from 'components/common/icon-svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import GooglePayButton from '@google-pay/button-react';

/**
 * Class for spreedly drop in.
 *
 * @class SpreedlyDropIn (name)
 */
class SpreedlyDropIn extends Component {
	state = {
		payWithCard: false,
		payWithGooglePay: false,
		payWithApplePay: false,
		submitted: false
	}

	replaceVariables(html) {
		return html.replace("{paymentProvider}", "Spreedly");
	}

	componentDidMount = () => {
		this.setupSpreedlyExpress();
		this.setSpreedlyExpressDisplayOptions();
		this.setSpreedlyExpressPaymentMethodParams();

		this.interval = setInterval(() => this.toggleSpreedlyExpressSubmitButton()	, 500);
	}

	componentDidUpdate = (prevProps, prevState) => {
		if (!this.props.isVisible && prevProps.isVisible) {
			this.setState({
				payWithCard: false,
				payWithGooglePay: false,
				payWithApplePay: false
			});
			this.props.clearSpreedly();
		}

		//if (this.props.isVisible && !prevProps.isVisible) {
		//	this.props.sendFormRequest();
		//}

		this.setSpreedlyExpressDisplayOptions();
		this.setSpreedlyExpressPaymentMethodParams();
	}

	componentWillUnmount = () => {
		this.props.SpreedlyExpress.unload();
		clearInterval(this.interval);
	}

	showSpreedlyExpress = () => {
		this.props.SpreedlyExpress.openView();
		document.getElementById("spreedly-submit-button").disabled = true;
	}

	setupSpreedlyExpress = () => {
		this.props.SpreedlyExpress.init(this.props.authorizationToken);

		//onCreate
		this.props.SpreedlyExpress.onInit(() => {
			document.getElementById("spreedly-submit-button").disabled = true;

			document.getElementById("spreedly-name").placeholder = this.props.displayOptions.full_name_placeholder;

			//spreedly-modal-form
			document.getElementById("spreedly-submit-button").addEventListener("click", () => {
				if (this.props.onSubmit) {
					this.props.onSubmit();
				}
			}, false);

			if (this.props.onCreate) {
				this.props.onCreate(this.props.SpreedlyExpress);
			}
		});

		//this.props.SpreedlyExpress.onViewClose(() => {
		//	if (this.props.closePyament) {
		//		this.props.closePyament(null);
		//	}
		//});

		//onSubmit
		this.props.SpreedlyExpress.onPaymentMethod(this.handleSpreedlyExpressPayment);

		this.props.sendFormRequest();
	}

	setSpreedlyExpressDisplayOptions = () => {
		this.props.SpreedlyExpress.setDisplayOptions({
			...this.props.displayOptions,
			amount: this.props.amountDisplay
		});
	}

	setSpreedlyExpressPaymentMethodParams = () => {
		this.props.SpreedlyExpress.setPaymentMethodParams(this.props.paymentMethodParams);
	}

	toggleSpreedlyExpressSubmitButton = () => {
		const submitButton = document.getElementById('spreedly-submit-button');
		if (submitButton) {
			submitButton.disabled = document.getElementsByClassName("spreedly-field-valid").length !== 4;
		}
	}

	handleSpreedlyExpressPayment = (token, formData) => {
		this.setState({
			submitted: true
		});

		const payload = {
			token,
			formData
		};

		if (this.props.onSubmitResponse) {
			this.props.onSubmitResponse();
		}

		if (token) {
			this.props.handlePaymentMethodSpreedlyExpress(payload);
		}
		else {
			if (this.props.onError) {
				this.props.onError('An Error has occured');
			}
		}
    }

	handleGooglePayPayment = (paymentData) => {
		this.setState({
			submitted: true
		});

		if (this.props.onSubmitResponse) {
			this.props.onSubmitResponse();
		}

		this.props.handlePaymentMethodGooglePay(paymentData);
	}

	handleApplePayPayment = (paymentData) => {
		this.setState({
			submitted: true
		});

		if (this.props.onSubmitResponse) {
			this.props.onSubmitResponse();
		}

		this.props.handlePaymentMethodApplePay(paymentData);
	}

	handleCard = (e) => {
		//this.setState({
		//	payWithCard: true
		//});
		this.showSpreedlyExpress();
	};

	handleGooglePay = (e) => {
		this.setState({
			payWithGooglePay: true
		});
	};

	handleApplePay = (e) => {
		this.setState({
			payWithApplePay: true
		});
	};

	handleChooseAnotherWayToPay = (e) => {
		this.setState({
			payWithCard: false,
			payWithGooglePay: false,
			payWithApplePay: false
		});
	};

	render = () => {
		return (
			<div className="spreedly-dropin">
				{this.props.loading && <div>
					<br />
					<br />
					<ComponentLoader />
					<br />
					<br />
				</div>}
				{!this.props.loading && !(this.state.payWithCard || this.state.payWithGooglePay || this.state.payWithApplePay) && (
					<div>
						<div className="spreedly-heading">Choose a way to pay</div>
						<div className="spreedly-options-list">
							{this.props.applePay && this.props.applePayEnabled && window.ApplePaySession && window.ApplePaySession.canMakePayments() && <div className="spreedly-option" tabIndex="2" onClick={this.handleApplePay}>
								<div className="spreedly-option__logo">
									<IconSvg className="spreedly-option__logo-svg" src={ApplePayLogo} />
								</div>
								<div className="spreedly-option__label" aria-label="Paying with Apple Pay">Apple Pay</div>
							</div>}
							{this.props.googlePay && this.props.googlePayEnabled && !(this.props.applePayEnabled && window.ApplePaySession && window.ApplePaySession.canMakePayments()) && <div className="spreedly-option" tabIndex="1" onClick={this.handleGooglePay}>
								<div className="spreedly-option__logo">
									<IconSvg className="spreedly-option__logo-svg" src={GooglePayLogo} />
								</div>
								<div className="spreedly-option__label" aria-label="Paying with Google Pay">Google Pay</div>
							</div>}
							{this.props.card && this.props.creditCardEnabled && <div className="spreedly-option" tabIndex="0" onClick={this.handleCard}>
								<div className="spreedly-option__logo">
									<IconSvg className="spreedly-option__logo-svg" src={CardPayLogo} />
								</div>
								<div className="spreedly-option__label" aria-label="Paying with Card">Card</div>
							</div>}
						</div>
					</div>)}
				{this.state.payWithCard && <div className="spreedly-sheet">
					<div className="spreedly-sheet__header">
						<div className="spreedly-sheet__header-label">
							<div className="spreedly-sheet__logo--header">
								<IconSvg className="spreedly-option__logo-svg" src={CardPayLogo} />
							</div>
							<div className="spreedly-sheet__label">Pay with card</div>
						</div>
						<div className="spreedly-sheet__icons">
							<div className="spreedly-sheet__card-icon">
								<IconSvg src={VisaLogo} />
							</div>
							<div className="spreedly-sheet__card-icon">
								<IconSvg src={MastercardLogo} />
							</div>
							<div className="spreedly-sheet__card-icon">
								<IconSvg src={AmexLogo} />
							</div>
							<div className="spreedly-sheet__card-icon">
								<IconSvg src={JcbLogo} />
							</div>
							<div className="spreedly-sheet__card-icon">
								<IconSvg src={DiscoverLogo} />
							</div>
						</div>
					</div>
					<div className="spreedly-sheet__content--form">
						{/* TODO Spreedly iFrame Payment Form */}
					</div>
				</div>}
				{this.state.payWithGooglePay && <div className="spreedly-sheet">
					<div className="spreedly-sheet__header">
						<div className="spreedly-sheet__header-label">
							<div className="spreedly-sheet__logo--header">
								<IconSvg className="spreedly-option__logo-svg" src={GooglePayLogo} />
							</div>
							<div className="spreedly-sheet__label">Google Pay</div>
						</div>
					</div>
					<div className="spreedly-sheet__content--form">
						<GooglePayButton
							//environment={this.props.isTestEnvironment ? 'TEST' : 'PRODUCTION' }
							environment={'PRODUCTION'}
							paymentRequest={{
								apiVersion: 2,
								apiVersionMinor: 0,
								allowedPaymentMethods: [
									{
										type: 'CARD',
										parameters: {
											allowedAuthMethods: [ "PAN_ONLY", "CRYPTOGRAM_3DS"],
											allowedCardNetworks: ["AMEX", "DISCOVER", "JCB", "MASTERCARD", "VISA"],
											billingAddressRequired: true,
											billingAddressParameters: {
												format: "MIN"
											}
										},
										tokenizationSpecification: {
											type: 'PAYMENT_GATEWAY',
											parameters: {
												gateway: 'spreedly',
												gatewayMerchantId: this.props.authorizationToken
											},
										}
									}
								],
								merchantInfo: {
									merchantId: '05111731990316967312',
								},
								transactionInfo: {
									totalPriceStatus: 'FINAL',
									totalPrice: this.props.amount.toFixed(2),
									currencyCode: this.props.currencyCode
								}
							}}
							onLoadPaymentData={this.handleGooglePayPayment}
						/>
					</div>
				</div>}
				{this.state.payWithApplePay && <div className="spreedly-sheet">
					<div className="spreedly-sheet__header">
						<div className="spreedly-sheet__header-label">
							<div className="spreedly-sheet__logo--header">
								<IconSvg className="spreedly-option__logo-svg" src={ApplePayLogo} />
							</div>
							<div className="spreedly-sheet__label">Apple Pay</div>
						</div>
					</div>
					<div className="spreedly-sheet__content--form">
						<ApplePayButton
							paymentRequest={{
								currencyCode: this.props.currencyCode,
								countryCode: this.props.countryCode,
								merchantCapabilities: ['supports3DS'],
								supportedNetworks: ['amex', 'discover', 'jcb', 'masterCard', 'visa'],
								requiredBillingContactFields: [
									'name',
									'postalAddress'
								],
								total: {
									label: 'Grab OAT',
									amount: this.props.amount.toFixed(2),
									type: 'final'
								}
							}}
							paymentSessionUrl="/api/cursus/Cursus_PaymentProvider_StartApplePaySession"
							onLoadPaymentData={this.handleApplePayPayment}
						/>
					</div>
				</div>}
				{(this.state.payWithCard || this.state.payWithGooglePay || this.state.payWithApplePay) &&
					<div onClick={this.handleChooseAnotherWayToPay} className="spreedly-large-button" tabindex="0">
						<span>Choose another way to pay</span>
					</div>}

				<div className='payment-secure'><FontAwesomeIcon icon={faLock} /> {this.replaceVariables(window.resources.payment.secure_text)}</div>
				{this.props.message && <div className='payment-message editable' dangerouslySetInnerHTML={{ __html: this.props.message }}></div>}
			</div>
		)
	}
}

SpreedlyDropIn.propTypes = {
	SpreedlyExpress: PropTypes.object.isRequired,
	authorizationToken: PropTypes.string.isRequired,
	handlePaymentMethodSpreedlyExpress: PropTypes.func.isRequired,
	handlePaymentMethodGooglePay: PropTypes.func.isRequired,
	handlePaymentMethodApplePay: PropTypes.func.isRequired,
	onCreate: PropTypes.func,
	onError: PropTypes.func,
	onDestroyEnd: PropTypes.func,
	displayOptions: PropTypes.object,
	paymentMethodParams: PropTypes.object,
	amountDisplay: PropTypes.string,
	currencyCode: PropTypes.string,
	countryCode: PropTypes.string
}

export default connect(
	(state) => ({
		loading: state.spreedly.data == null,
		creditCardEnabled: state.spreedly.data && state.spreedly.data.creditCardEnabled,
		googlePayEnabled: state.spreedly.data && state.spreedly.data.googlePayEnabled,
		applePayEnabled: state.spreedly.data && state.spreedly.data.applePayEnabled,
		isTestEnvironment: state.spreedly.data && state.spreedly.data.isTestEnvironment,
	}),
	{
		clearSpreedly,
		sendFormRequest
	}
)(SpreedlyDropIn);