/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

/**
 * @ The internal dependencies.
 */
import { closeModal } from 'store/state/ui/actions';
import { updateEmployeeID, startCartUpdate } from 'store/state/cart/actions';

/**
 * Class for modal airline employee discount.
 *
 * @class ModalAirlineEmployeeDiscount (name)
 */
class ModalAirlineEmployeeDiscount extends Component {
    state = {
        employeeNumber: ''
    }

    setEmployeeNumber = (e) => {
        this.setState({
            employeeNumber: e.target.value
		});
	}

	addDiscount = (e) => {
        e.preventDefault();

        this.props.updateEmployeeID(this.state.employeeNumber);
        this.props.startCartUpdate();

        this.closeModal();
    }

    handleCancelClick = (e) => {
        e.preventDefault();
        this.closeModal();
    }

    closeModal = () => {
        this.props.closeModal();
    }

	render() {
		return (
			<div className="modal-frame modal-frame--card">
				<div className="modal-frame__body">
					<div className="modal-frame__card">
						<div className="modal-frame__head">
                            <h6 className="modal-frame__title">{window.resources.employee_discount.label_text}</h6>
						</div>

						<div className="modal-frame__card-body">
                            <div className="modal-frame__card-field-preceder">
                                <input className="field" onChange={this.setEmployeeNumber} placeholder={window.resources.employee_discount.placeholder_text} value={this.state.employeeNumber} />
                            </div>

                            <div className="modal-frame__message"><i class="fas fa-exclamation-triangle"></i> {window.resources.employee_discount.message_text}</div>

                        </div>
      
						<div className="modal-frame__actions modal-frame__actions--flex">
							<button
                                onClick={this.handleCancelClick}
                                className="btn btn--secondary">{window.resources.employee_discount.cancel_text}</button>

                            <button
                                disabled={!this.state.employeeNumber.length && !this.state.tip}
								onClick={this.addDiscount}
                                className="btn">{window.resources.employee_discount.confirm_text}</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(
    (state) => ({

	}),
    {
        closeModal,
        updateEmployeeID,
        startCartUpdate
	}
)(ModalAirlineEmployeeDiscount);
