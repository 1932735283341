/**
 * @ The external dependecies.
 */
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

/**
 * Class for scroll to top.
 *
 * @class ScrollToTop (name)
 */
class ScrollToTop extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
		}
	}

	render() {
		return this.props.children
	}
}

export default withRouter(ScrollToTop);