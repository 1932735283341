/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

/**
 * @ The internal dependencies.
 */
import { closeModal } from 'store/state/ui/actions';

/**
 * Class for modal allergens.
 *
 * @class ModalAllergens (name)
 */
class ModalAllergens extends Component {
	handleButtonClick = () => {
		this.props.closeModal();

		if ( !!this.props.data.fun ) {
			this.props.data.fun();
		}
	}

	render() {
		//const { message, btnText } = this.props.data;

		return (
			<div className="modal-frame modal-frame--allergens modal-frame--card">
				<div className="modal-frame__body">
					<div className="modal-frame__card">
                        <div className="modal-frame__head">
                            <h1 className="modal-frame__title">{window.resources.allergen.title_text}</h1>
							<p className="editable" dangerouslySetInnerHTML={{ __html: window.resources.allergen.html }}>
                            </p>   
						</div>

						<div className="modal-frame__actions">
							<button
								onClick={this.handleButtonClick}
                                className="btn">{window.resources.allergen.confirm_text}</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(
	(state) => ({
		cart: state.cart.data,
		data: state.ui.modal.data
	}),
	{
		closeModal
	}
)(ModalAllergens);
