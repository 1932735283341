/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

/**
 * @ The internal dependencies.
 */
import { openModal } from 'store/state/ui/actions';
import { reloadCartWithLoadedValueAccountNumber, clearLoadedValueAccountBalanceResponse } from 'store/state/loadedvalueaccount/actions';

import { MODAL_LOADED_VALUE_ACCOUNT_BALANCE_RESPONSE, MODAL_LOADED_VALUE_ACCOUNT_BALANCE_CHECK, MODAL_WARNING } from 'lib/constants';
import * as features from '../../store/state/features/selectors';

/**
 * Class for cart calculator.
 *
 * @class CartCalculator (name)
 */
class LoadedValueAccountButton extends Component {

    replaceVariables(html) {
        var replaced = html.replace("className", "class");
        if (this.loadedValueAccountNumberAssociated()) {
            replaced = replaced.replace("##accountnumber##", this.props.cart.data.loadedValueAccountNumber)
        }
        return replaced;
    }

    loadedValueAccountNumberAssociated() {
        return this.props.cart.data.loadedValueAccountNumber !== null && this.props.cart.data.loadedValueAccountNumber !== undefined
    }

    removeAccountHandler() {
        this.props.reloadCartWithLoadedValueAccountNumber({
            accountNumber: null
        })
    }

    componentDidMount() {
        if (this.props.loadedValueAccountBalanceCheckResponse !== false) {
            if (this.props.loadedValueAccountBalanceCheckResponse === 'failed') {
                this.props.openModal({
                    type: MODAL_WARNING,
                    data: {
                        message: window.resources.messages.error_please_try_again_html,
                        btnText: window.resources.modal.confirm_text
                    }
                });
                this.props.clearLoadedValueAccountBalanceResponse();
            }
            else {
                this.props.clearLoadedValueAccountBalanceResponse();
                this.props.reloadCartWithLoadedValueAccountNumber({
                    accountNumber: this.props.loadedValueAccountNumber
                })
            }
        }
    }
    render() {
        const { openModal } = this.props;

        let button = this.loadedValueAccountNumberAssociated() ?
            <button
                onClick={() => this.removeAccountHandler()}
                className="cart-calculator__btn_border_primary">
                <div class="X"><FontAwesomeIcon icon={faTimesCircle} /></div>
                <div>
                    <span class="airline-employee-discount">{this.replaceVariables(window.resources.loaded_value_account.cart_button_text_remove)}</span>
                    <br/>
                    {this.props.cart.data.loadedValueAccountNumber}
                </div>
            </button> : <button
                onClick={() => openModal({
                    type: MODAL_LOADED_VALUE_ACCOUNT_BALANCE_CHECK
                })}
                className="cart-calculator_loaded_value_account_btn" dangerouslySetInnerHTML={{ __html: this.replaceVariables(window.resources.loaded_value_account.cart_button_text) }}>
            </button>
        return button
    }
}

export default connect(
    (state) => ({
        cart: state.cart,
        employeeDiscountFeatureEnabled: features.employeeDiscountFeatureEnabled(state),
        loadedValueAccountEnabled: features.loadedValueAccountEnabled(state),
        loadedValueAccountNumber: state.loadedvalueaccount.loadedValueAccountNumber,
        loadedValueAccountBalanceCheckResponse: state.loadedvalueaccount.loadedValueAccountBalanceCheckResponse,
        data: state.data
    }),
    {
        openModal,
        reloadCartWithLoadedValueAccountNumber,
        clearLoadedValueAccountBalanceResponse
    }
)(LoadedValueAccountButton);
