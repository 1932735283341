/**
 * @ The external dependecies.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPencilAlt, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import {
	compose,
	isEmpty
} from 'ramda';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

/**
 * @ The internal dependencies.
 */
import QtyField from 'components/common/qty-field';
import CartSuggestions from 'components/cart/cart-suggestions';
import CartCalculator from 'components/cart/cart-calculator';
import CartExistingTab from 'components/cart/cart-existing-tab';
//import Payment from 'components/payment/payment';
import {
	startRemoveFromCart,
	updateCartProducts,
	setEditProduct,
    startCartUpdate,
    startTipAmountUpdate,
    startCartSave,
    updatePaymentDetails
} from 'store/state/cart/actions';
import { sendPenniesCalculationRequest } from 'store/state/donation/actions';
import { openModal } from 'store/state/ui/actions';
import { MODAL_WARNING, MODAL_TIP, PAY_NOW, TAB_ADDITEMS, TAB_ADDITEMS_CLOSE, TAB_CLOSE, MODE_ORDER, MODE_PAYTABNOW, MODAL_CHOOSE_PAY_TYPE, MODAL_SPLIT_QR_CODE, MODAL_DONATIONS } from 'lib/constants';
import { getAddOnProducts } from '../../store/state/cart/selectors';
import { requestTableTopConfigBeforeOrder } from 'store/state/app/actions';
import { getBlankCardDetails } from '../../components/payment/payment-helper';
import { hasTabOpen, hasTabOpenAndAuthorized, underTabThreshold } from '../../store/state/tab/selectors'
import * as features from '../../store/state/features/selectors'
import { updateSaveMode } from 'store/state/checkout/actions';
import { localizeNumber } from '../../assets/resources/resources-manager';
import { isLtr } from '../../lib/helpers/language';
import { hasAlcoholInCart } from 'store/state/cart/selectors';
import { BasketAvailBanner } from '../../components/daypart/depart-banner';

/**
 * Class for cart.
 *
 * @class Cart (name)
 */
class Cart extends Component {
    state = {
		editModeIsOn: false,
        //paymentIsVisible: false,
        products:[],
        changesMade: [], 
        showUpdateMsg: false
    }

    handleMultiConceptButton = (e) => {
        e.preventDefault();

        const multiConceptUrl = window.location.protocol + "//" + window.location.host + "/" + window.posID + "/multi/" + this.props.multiConceptID + `/${this.props.tableNumber}`;

        if (this.props.showMultiConceptWarning) {
            this.props.openModal({
                type: MODAL_WARNING,
                data: {
                    allowRedirect: true,
                    message: window.resources.cart.multiconcept_html,
                    btnText: window.resources.cart.confirm_multiconcept_text,
                    btnTextCancel: window.resources.cart.cancel_multiconcept_text,
                    fun: () => {
                        window.location.href = multiConceptUrl;
                    },
                    funCancel: () => {

                    }
                }
            });
        }
        else {
            window.location.href = multiConceptUrl;
        }
    }
    handleShowUpdateMsg = () => {
        this.setState({ showUpdateMsg: true });
    }

    replaceVariables(html) {
        return html.replace("{maxPercentage}", window.resources.tip.maxPercentage)
        .replace('{this.props.waitStartTime}', this.props.waitTimeInfo.WaitTime)
        .replace('{this.props.waitEndTime}', this.props.waitTimeInfo.WaitTime + 5);
    }

    componentDidMount() {
        if (this.props.exceededMaxTipAmount) {
            this.handleExceededMaxTipAmount();
        }
        // get pennies donation calc 
        if (this.props.donationEnabled) {
            this.props.sendPenniesCalculationRequest({
                merch: this.props.donationMerchId, //|| '10001745', //'9990001',  
                amount: this.props.cart.totalCost, //2.55, 
                currency: this.props.currencyCode === 'GBP' ? 826 : 978, //826, 
                token: this.props.donationPenniesToken //|| '47M1AG' //'TEST1=' 
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.exceededMaxTipAmount && this.props.exceededMaxTipAmount !== prevProps.exceededMaxTipAmount)  {
            this.handleExceededMaxTipAmount();
        }
        // check if donation calc info
        if (!this.props.cart.donationsModalShown && this.props.donationEnabled && this.props.donationCalculation) {
            this.props.openModal({
                type: MODAL_DONATIONS
            });
        }
    }

    handleExceededMaxTipAmount = () => {
        this.props.startTipAmountUpdate(-1.00);
        this.props.openModal({
            type: MODAL_WARNING,
            data: {
                message: this.replaceVariables(window.resources.messages.exceeded_max_tip_percentage_text),
                btnText: window.resources.modal.confirm_text
            }
        });
    }

    saveCartDelegate = null;

    openModalOnUnmount = true;

    componentWillUnmount() {
        if (this.state.editModeIsOn && this.openModalOnUnmount && this.state.changesMade.length > 0) {
            this.props.openModal({
                type: MODAL_WARNING,
                data: {
                    preventOnBackOrForwardButtonEvent: true,
                    message: window.resources.cart.unsaved_edits_html,
                    btnText: window.resources.cart.confirm_edits_text,
                    btnTextCancel: window.resources.cart.cancel_edits_text,
                    fun: () => {
                        this.saveEditedItems();
                    },
                    funCancel: () => {

                    }
                }
            });
        }
    }

    handleBackToMenu = (e) => {
        e.preventDefault();

        const editMode = this.state.editModeIsOn;

        this.setState({
            editModeIsOn: false
        });

        this.openModalOnUnmount = false;

        if (editMode && this.state.changesMade.length > 0) {
            this.props.openModal({
                type: MODAL_WARNING,
                data: {
                    message: window.resources.cart.unsaved_edits_html,
                    btnText: window.resources.cart.confirm_edits_text,
                    btnTextCancel: window.resources.cart.cancel_edits_text,
                    fun: () => {
                        this.saveEditedItems();
                    },
                    funCancel: () => {

                    }
                }
            });
        }

        this.props.history.push(`/menu`);
    }

  //  closePyament = (e) => {
  //      if (e) {
  //          e.preventDefault();
  //      }

		//this.setState({
		//	paymentIsVisible: false
  //      });
  //  }

    //handlePaymentSuccess = (cardDetails) => {
    //    this.saveCartDelegate(cardDetails);
    //}

    alcoholLimitCheck = () => {
        let alcoholCount = this.props.arrCart.filter(el => el.bAlcohol).reduce((total, current) => { return total + current.quantity }, 0);
        let substantialMealCount = this.props.arrCart.filter(el => {
            if (el.inventoryTags === undefined || el.inventoryTags.length === 0) return false;
            for (let tag of el.inventoryTags) {
                if (tag.tagDescription === 'Substantial Meal') {
                    return true;
                }
            }
            return false;
        }).reduce((total, current) => { return total + current.quantity }, 0);

        if (alcoholCount > substantialMealCount * this.props.allowedAlcoholItemsPerMealItem) {
            this.props.openModal({
                type: MODAL_WARNING,
                data: {
                    message: window.resources.cart.substantial_meal_warning_text,
                    btnText: window.resources.modal.confirm_text,
                    fun: () => {
                    },
                }
            });
            return false;
        }

        return true;
    }

    isVirtualKiosk = () => {
        return this.props.tableNumber === 'vk' || this.props.tableNumber === 'VK';
    }

    getOrderItemsTotal = () => {
        return this.props.cartPricing && this.props.cartPricing.orderLineItems && this.props.cartPricing.orderLineItems.length > 0 &&
            this.props.cartPricing.orderLineItems.find(i => i.OrderLineItemTypeIdentifier === 'TOTAL') ? 
            this.props.cartPricing.orderLineItems.find(i => i.OrderLineItemTypeIdentifier === 'TOTAL').Amount
            : null;
    }

	openPyament = (e) => {
        e.preventDefault();

        this.props.updateSaveMode(PAY_NOW);

        //Check if store is closed.
        this.props.requestTableTopConfigBeforeOrder();

        if (this.props.alcoholMealLimitEnabled) {
            if (!this.alcoholLimitCheck()) return;
        }

        if (this.props.total > 0.00) {
            this.saveCartDelegate = this.makePaymentSave;
            if (this.props.paymentProvider === 'adyen') {
                if (!this.props.cart.tipsModalShown && this.props.tippingPopFeatureEnabled && (!this.props.tipForAlcoholOnlyFeatureEnabled || this.props.hasAlcoholInCart)) {
                    this.props.openModal({
                        type: MODAL_TIP, 
                        data: {
                            onClose: () => this.props.history.push('/receipt')
                        }
                    });
                }
                else this.props.history.push('/receipt');
            }
            else {
                this.props.history.push('/receipt');

                if (!this.props.cart.tipsModalShown && this.props.tippingPopFeatureEnabled && (!this.props.tipForAlcoholOnlyFeatureEnabled || this.props.hasAlcoholInCart)) {
                    this.props.openModal({
                        type: MODAL_TIP
                    });
                }
            }
        }
        else {
            var cardDetails = getBlankCardDetails();

            this.props.updatePaymentDetails(cardDetails.paymentDetails);

            if ((this.props.autoReceiptFeatureEnabled && !this.isVirtualKiosk()) || (this.props.autoReceiptVKFeatureEnabled && this.isVirtualKiosk())) {
                this.props.history.push('/receipt');
            }
            else {
                this.props.startCartSave(
                    {
                        createOrUpdateTab: false,
                        sendCartItems: true
                    }
                );
                this.props.history.push('/checkout-complete');
            }
        }
    }

    makePaymentSave = (cardDetails) => {
        this.props.updatePaymentDetails(cardDetails.paymentDetails);
        this.props.startCartSave(
            {
                createOrUpdateTab: false,
                sendCartItems: true
            }
        );

        this.props.history.push('/checkout-complete');
    }


    startATab = (e) => {
        e.preventDefault();
        if (this.props.alcoholMealLimitEnabled) {
            if (!this.alcoholLimitCheck()) return;
        }
        this.props.history.push(`tab/new`);
    }

    addItemsToTab = (e) => {
        e.preventDefault();

        this.props.updateSaveMode(TAB_ADDITEMS);

        //go to checkout-complete
        //or tab receipt.
        if (this.props.tabRequiresCCDetails && !this.props.orderOnlyNoPayment) {
            this.props.history.push({
                pathname: '/tab/receipt',
                state: {
                    total: this.props.total,
                    totalDisplay: this.props.totalDisplay
                }
            });
        }
        else {
            var cardDetails = getBlankCardDetails();

            this.props.updatePaymentDetails(cardDetails.paymentDetails);
            this.props.startCartSave(
                {
                    createOrUpdateTab: true,
                    sendCartItems: true
                }
            );
            this.props.history.push('/checkout-complete');
        }
    }

    addItemsAndCloseOutTab = (e) => {
        e.preventDefault();

        this.props.updateSaveMode(TAB_ADDITEMS_CLOSE);

        if (this.props.tabSplitPayEnabled && parseFloat(this.props.total) > this.props.tabSplitMinTab && (this.props.tabSplitEvenEnabled || this.props.tabSplitCustomEnabled)) {
            // get order items total to get possible updated total with applied payments
            const orderItemsTotal = this.getOrderItemsTotal()

            this.props.openModal({
                type: MODAL_CHOOSE_PAY_TYPE,
                data: {
                    total: orderItemsTotal || this.props.total,
                    arrCart: this.props.arrCart, 
                    onShowUpdateMsg: () =>  {
                        this.handleShowUpdateMsg();
                    }, 
                    onPayInFull: this.addItemsAndCloseOutTabTip
                }
            });
        }
        else {
            this.addItemsAndCloseOutTabTip();
        }
    }

    addItemsAndCloseOutTabTip = () => {
         if (!this.props.cart.tipsModalShown && this.props.tippingPopFeatureEnabled && (!this.props.tipForAlcoholOnlyFeatureEnabled || this.props.hasAlcoholInCart)) {
            // get order items total to get possible updated total with applied payments
            const updatedTotals = this.getOrderItemsTotal();

            this.props.openModal({
                 type: MODAL_TIP,
                 data: {
                    updatedTotals: updatedTotals && parseFloat(updatedTotals) < parseFloat(this.props.total) ? updatedTotals : null,
                     onClose: () => {
                         this.addItemsAndCloseOutTabInner();
                     }
                 }
             });
         }
         else {
             this.addItemsAndCloseOutTabInner();
         }

    }

    addItemsAndCloseOutTabInner = () => {

        //go to checkout-complete
        //else open payment screen.
        if (this.props.tabRequiresCCDetails) {
            //if (this.props.autoReceiptFeatureEnabled) {
                this.props.history.push({
                    pathname: '/tab/receipt',
                    state: {
                        total: this.props.total,
                        totalDisplay: this.props.totalDisplay
                    }
                });
            //}
            //else {
            //    this.saveCartDelegate = this.addItemsAndCloseOutTabSave;
            //    this.setState({
            //        paymentIsVisible: true
            //    });
            //}
        }
        else {
            var cardDetails = getBlankCardDetails();

            this.addItemsAndCloseOutTabSave(cardDetails);
        }
    }

    addItemsAndCloseOutTabSave = (cardDetails) => {
        // check if pay in full total is different than tab (for split payment)
        const orderItemsTotal = this.getOrderItemsTotal();
        const payBalance = orderItemsTotal && parseFloat(orderItemsTotal) < parseFloat(this.props.total);

        this.props.updatePaymentDetails(cardDetails.paymentDetails);
        this.props.startCartSave(
            payBalance ? 
            {
                createOrUpdateTab: false,
                sendCartItems: true, 
                payBalance: payBalance
            }
            :
            {
                createOrUpdateTab: false,
                sendCartItems: true
            }
        );

        this.props.history.push('/checkout-complete');
    }

    closeOutTab = (e) => {
        e.preventDefault();

        this.props.updateSaveMode(TAB_CLOSE);

        if (this.props.tabSplitPayEnabled && parseFloat(this.props.total) > this.props.tabSplitMinTab && (this.props.tabSplitEvenEnabled || this.props.tabSplitCustomEnabled)) {
            // get order items total to get possible updated total with applied payments
            const orderItemsTotal = this.getOrderItemsTotal();
            
            this.props.openModal({
                type: MODAL_CHOOSE_PAY_TYPE,
                data: {
                    total: orderItemsTotal || this.props.total,
                    cartPricing: this.props.cartPricing,
                    onShowUpdateMsg: () =>  {
                        this.handleShowUpdateMsg();
                    }, 
                    onPayInFull: this.closeOutTabTip
                }
            });
        }
        else {
            this.closeOutTabTip();
        }
    }

    closeOutTabTip = () => {
        if (!this.props.cart.tipsModalShown && this.props.tippingPopFeatureEnabled && (!this.props.tipForAlcoholOnlyFeatureEnabled || this.props.hasAlcoholInCart)) {
            const updatedTotals = this.getOrderItemsTotal();
            this.props.openModal({
                type: MODAL_TIP,
                data: {
                    updatedTotals: updatedTotals && parseFloat(updatedTotals) < parseFloat(this.props.total) ? updatedTotals : null,
                    onClose: () => {
                        this.closeOutTabInner();
                    }
                }
            });
        }
        else {
            this.closeOutTabInner();
        }
    }

    closeOutTabInner = () => {
        //go to checkout-complete
        //else open payment screen.
        if (this.props.tabRequiresCCDetails) {
            //if (this.props.autoReceiptFeatureEnabled) {
                this.props.history.push({
                    pathname: '/tab/receipt',
                    state: {
                        total: this.props.total,
                        totalDisplay: this.props.totalDisplay
                    }
                });
            //}
            //else {
            //    this.saveCartDelegate = this.closeOutTabSave;
            //    this.setState({
            //        paymentIsVisible: true
            //    });
            //}
        }
        else {
            var cardDetails = getBlankCardDetails();

            this.closeOutTabSave(cardDetails);
        }
    }

    closeOutTabSave = (cardDetails) => {
        // check if pay in full total is different than tab (for split payment)
        const orderItemsTotal = this.getOrderItemsTotal();
        const payBalance = orderItemsTotal && parseFloat(orderItemsTotal) < parseFloat(this.props.total);

        this.props.updatePaymentDetails(cardDetails.paymentDetails);
        this.props.startCartSave(
            payBalance ?
            {
                createOrUpdateTab: false,
                sendCartItems: false, 
                payBalance: payBalance
            }
            :
            {
                createOrUpdateTab: false,
                sendCartItems: false
            }
        );

        this.props.history.push('/checkout-complete');
    }

    sendPaymentInvite = (e) => {
        e.preventDefault();

        this.props.openModal({
            type: MODAL_SPLIT_QR_CODE,
            data: {
                splitType: 'custom',
                inviteAmount: 0
            }
        })
    }

    handleUpdateQty = ({ index, id }) => (e) => {
        if (Number(e.target.value) <= 0) {
            let additionalItems = this.getCartProducts().length > 1;

            this.props.openModal({
                type: MODAL_WARNING,
                data: {
                    message: window.resources.cart.remove_item_html,
                    btnText: window.resources.cart.confirm_remove_item_text,
                    btnTextCancel: window.resources.cart.cancel_remove_item_text,
                    fun: () => {
                        const newProducts = this.state.products.slice(); //copy the array
                        newProducts.splice(index, 1);

                        this.setState(({ products, changesMade }) => ({
                            products: newProducts,
                            changesMade: changesMade.filter(i => i !== index)
                        }));

                        var indexesToRemove = [];
                        var productToRemove = this.getCartProducts()[index];
                        if (productToRemove.comboOrderID) {
                            var relatedProducts = this.props.arrCart.filter(x => x.comboOrderID === productToRemove.comboOrderID);
                            relatedProducts.forEach(rp => {
                                indexesToRemove.push(this.props.arrCart.indexOf(rp));
                            });
                        }
                        else {
                            indexesToRemove.push(this.props.arrCart.indexOf(productToRemove));
                        }

                        return this.props.startRemoveFromCart(indexesToRemove);
                    }
                }
            });

            if (!additionalItems) {
                this.setState({
                    editModeIsOn: false
                });
                return;
            }
        }
        else {
            const newProducts = this.state.products.slice() //copy the array
            newProducts[index] = Number(e.target.value);

            this.setState(({ products, changesMade }) => ({
                products: newProducts,
                changesMade: [
                    ...changesMade,
                    index
                ]
            }));
        }
	}

	toggleEditMode = (e) => {
		e.preventDefault();

		this.setState(({ editModeIsOn }) => ({
            editModeIsOn: !editModeIsOn,
            changesMade: []
		}));

        const newProducts = [];
        this.getCartProducts().forEach((product, i) => {
            newProducts.push(Number(product.quantity));
        });

        this.setState(({ products }) => ({
            products: newProducts
        }));
    }

    onAddSuggestionsToOrder = (products) => {

        const newProducts = [];
        products.forEach((product, i) => {
            newProducts.push(Number(product.quantity));
        });

        this.setState({ products: [...this.state.products, ...newProducts] })
    }

    handleFinishedEditing = () => {
        this.setState({
            editModeIsOn: false
        });

        if (this.state.changesMade.length > 0) {
            this.saveEditedItems();
        }
    }

    saveEditedItems = () => {
        const cartProducts = this.getCartProducts().slice();

        cartProducts.forEach((product, i) => {
            cartProducts[i].quantity = this.state.products[i];

            const relatedProducts = this.props.arrCart.filter(x => x.comboOrderID && x.comboOrderID === cartProducts[i].comboOrderID && cartProducts[i].inventoryItemID !== x.inventoryItemID);
            relatedProducts.forEach(rp => {
                rp.quantity = this.state.products[i];
                cartProducts.push(rp);
            });
        });

        //updates cart
        this.props.updateCartProducts(cartProducts);



        //send cart to server
        this.props.startCartUpdate({ showSpinner: true });


	}

    getProductPrice(selectedProduct) {
        let total = 0;

        const relatedProducts = selectedProduct.comboOrderID ? this.props.arrCart.filter(x => x.comboOrderID === selectedProduct.comboOrderID) : [selectedProduct];
        relatedProducts.forEach(p => {
            let totalCost = 0;
            p.inventoryMainOptionChoice.choices.forEach(choice => {
                choice.inventoryChoices.forEach(innerChoice => {
                    if (innerChoice.selected) {
                        totalCost += Number(innerChoice.choiceCost);
                    }
                });
            });

            p.inventoryMainOptionChoice.options.forEach(option => {
                option.inventoryOptions.forEach(innerOption => {
                    if (innerOption.selected) {
                        totalCost += Number(innerOption.optionCost);
                    }
                });
            });

            let sizeCost = Number(p.inventoryItemSubs.filter(x => x.selected)[0].cost);
            if (p.comboOrderID) {
                sizeCost = Number(p.inventoryItemSubs.filter(x => x.selected)[0].comboCost);
            }

            const totalPrice = totalCost + sizeCost;
            total += (totalPrice * p.quantity);
        });

        if (total === 0.00) {
            return window.resources.cart.zero_text;
        }
        else {
            return total.toFixed(2);
        }
	}

	handleProductEdit = (product, id, index) => (e) => {
        e.preventDefault();

        const editMode = this.state.editModeIsOn;

        this.openModalOnUnmount = false;

        this.setState({
            editModeIsOn: false
        });

        if (editMode && this.state.changesMade.length > 0) {
            this.props.openModal({
                type: MODAL_WARNING,
                data: {
                    message: window.resources.cart.unsaved_edits_html,
                    btnText: window.resources.cart.confirm_edits_text,
                    btnTextCancel: window.resources.cart.cancel_edits_text,
                    fun: () => {
                        this.saveEditedItems();
                        const editedProduct = { ...this.getProduct(index) };
                        this.props.setEditProduct(editedProduct);
                    },
                    funCancel: () => {

                    }
                }
            });
        }

        if (product.comboOrderID) {
            this.props.setEditProduct(product);
            this.props.history.push(`menu/combo/${product.comboID}/${product.comboOrderID}`);
        }
        else {
            this.props.setEditProduct(product);
            this.props.history.push(`menu/${id}`);
        }
    }

    getProduct = (index) => {
        return this.getCartProducts()[index];
    }

    getCartProducts = () => {
        const comobOrderIDs = [];
        const products = [];

        this.props.arrCart.forEach(product => {
            if (product.comboOrderID) {
                if (!comobOrderIDs.includes(product.comboOrderID)) {
                    products.push(product);
                    comobOrderIDs.push(product.comboOrderID);
                }
            }
            else {
                products.push(product);
            }
        });
        return products;
    }
    getCartDayPartTime = () => {
		const dayPartItems = this.props.arrCart.filter(item => item.showDayPartIcon);
		const sortedItems = dayPartItems ? dayPartItems.sort((a, b) => { return new Date(a.endTimeLocalString) - new Date(b.endTimeLocalString); }) : null;

        if (!sortedItems || sortedItems.length === 0) return null;

		const currentDateTime = new Date(this.props.data.serverTimeLocalDateTimeString);
		const endDateTime = new Date(sortedItems[0].endTimeLocalString);

		const diff = Math.round(((endDateTime.getTime() - currentDateTime.getTime()) / 1000) / 60);
		const inRange = diff < this.props.dayPartMsgWarningMin && diff > 0;

		return sortedItems && inRange ? new Date(sortedItems[0].endTimeLocalString).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : null;
	}

	getOptions = (choices, options) => {
		const result = [];

		choices.forEach(choice => choice.inventoryChoices.forEach(innerChoice => result.push(innerChoice)));
		options.forEach(option => option.inventoryOptions.forEach(innerOption => result.push(innerOption)));

		return result.filter(item => item.selected).map(item => ({
			desc: item.choiceDescription || item.optionDescription,
			price: item.choiceCost || item.optionCost
		}));
    }

    getDescriptionSizeModifiersDisplay = (product) => {
        var descriptionSize = this.getDescriptionSizeDisplay(product);
        var modifiers = this.getModifiersDisplay(product);
        return `${descriptionSize}${modifiers ? ' ' + modifiers : ''}`;
    }

    getDescriptionSizeDisplay = (product) => {
        return product.inventoryItemName + (product.inventoryItemSubs && product.inventoryItemSubs.filter(x => x.selected && x.inventoryItemSubName && x.inventoryItemSubName !== window.resources.cart.default_size).length > 0 ? ' (' + product.inventoryItemSubs.find(x => x.selected && x.inventoryItemSubName && x.inventoryItemSubName !== window.resources.cart.default_size).inventoryItemSubName + ')' : '');
    }

    getModifiersAndComboProductsDisplay = (product) => {
        let display = this.getModifiersDisplay(product);

        const comboProducts = this.props.arrCart.filter(x => product.comboOrderID && x.comboOrderID === product.comboOrderID && product.inventoryItemID !== x.inventoryItemID);
        comboProducts.forEach(p => {

            display = display + (display ? ', ' : '') + this.getDescriptionSizeModifiersDisplay(p);
        });

        return display;
    }

    getModifiersDisplay = (product) => {
        return this.getOptions(product.inventoryMainOptionChoice.choices, product.inventoryMainOptionChoice.options).map((item, idx) => (
            item.price !== '0.00' ? (
                `${item.desc} (${isLtr() ? '+' : ''}${localizeNumber(item.price)})${idx === this.getOptions(product.inventoryMainOptionChoice.choices, product.inventoryMainOptionChoice.options).length - 1 ? '' : ', '}`
            ) : (
                `${item.desc}${idx === this.getOptions(product.inventoryMainOptionChoice.choices, product.inventoryMainOptionChoice.options).length - 1 ? '' : ', '}`
            )
        )).join('');
    }

    showPriceColumn = () => {
        if (this.props.cartPricing && this.props.cartPricing.orderTotal.toFixed(2) == "0.00" && this.props.hideZeroDollars)
            return false
        return true
    }

    render() {
        const { arrCart, allowEditMode } = this.props;
        const { editModeIsOn } = this.state;

        return (

            <div className="cart">
				<div className="cart__head">
					<div className="shell">
                        <div className="cart__head-inner">
                            <div className="cart__head-btn-container">
                                {this.props.multiConceptFeatureEnabled && <Link
                                    onClick={this.handleMultiConceptButton}
                                    to="/menu"
                                    className="cart__head-btn" aria-label={window.resources.navigation.home_text}>
                                    <i className="fas fa-home" title={window.resources.navigation.home_text}></i>
                                </Link>}
                                {(this.props.mode === MODE_ORDER || this.props.browseModeEnabled) && <Link
                                    onClick={this.handleBackToMenu}
                                    to="/menu"
                                    className="cart__head-btn" aria-label={window.resources.navigation.back_text}>
                                    <i className="fas arrow-left" title={window.resources.navigation.back_text}></i>
                                </Link>}
                           </div>
							
                            {!editModeIsOn ? (
                                <h6 className="cart__title">{this.props.title}</h6>
							):(
                                    <h6 className="cart__title">{this.props.edit_title}</h6>
							)}
						</div>
						
						<div className="cart__head-entry">
							<p></p>
						</div>
                        {   this.props.waitTimeEnabled && this.props.waitTimeInfo && this.props.waitTimeInfo.WaitTime && this.props.waitTimeInfo.AlertThreshold && 
                            <div className={classnames('wait-time-msg', { 'wait-time-alert': this.props.waitTimeInfo.WaitTime + 5 > this.props.waitTimeInfo.AlertThreshold })}>
                                {   this.props.waitTimeInfo.WaitTime + 5 > this.props.waitTimeInfo.AlertThreshold ?
                                    <React.Fragment>
                                        <FontAwesomeIcon icon={faExclamationTriangle} /> 
                                        <div dangerouslySetInnerHTML={{__html: this.replaceVariables(window.resources.wait_time.alert_text)}}></div>
                                    </React.Fragment> :
                                    <div dangerouslySetInnerHTML={{__html: this.replaceVariables(window.resources.wait_time.standard_text)}}></div>
                                }
                            </div>
                        }
					</div>
				</div>

				<div className="cart__body">
                    {this.props.dayPartMsgEnabled && this.getCartDayPartTime() && <BasketAvailBanner time={this.getCartDayPartTime()} />}
                    { this.state.showUpdateMsg &&
                        <div className='split-update-info-msg'>
                            <div style={{marginRight: '5px'}}><i className='fa fa-info-circle'></i></div>
                            <div>{window.resources.split_summary.updated_portion_msg}</div>
                        </div>
                    }
					{arrCart.length > 0 ? (
						<div className="cart__section">
							<div className="shell shell--no-padding">
								<div className="table">
									<table>
										<thead>
											<tr>
												<th>
													{allowEditMode && !editModeIsOn && (
														<button
															onClick={this.toggleEditMode}
															className="table__edit-btn">
                                                            <FontAwesomeIcon aria-labelledby="" title={window.resources.cart.edit_basket_text} icon={faPencilAlt} />

                                                            <span>{window.resources.cart.edit_basket_text}</span>
														</button>
													)}
												</th>

                                                <th className="qty">{window.resources.cart.qty_text}</th>

                                                {this.showPriceColumn() && <th className="price">{window.resources.cart.price_text}</th>}
											</tr>
										</thead>

										<tbody>
                                            {this.getCartProducts().map((product, i) => (
												<tr key={product.inventoryItemName + i}>
													<td className={classnames({
														'name': true,
														'is-editable': editModeIsOn
													})}>
														{editModeIsOn && (
                                                            <button aria-label='edit item' onClick={this.handleProductEdit(product, product.inventoryItemID, i)} type="button" className="btn-edit">
																<span className="icon">
																	<FontAwesomeIcon icon={faPencilAlt} />
																</span>
															</button>
														)}

                                                        <strong>{this.getDescriptionSizeDisplay(product)}</strong>

                                                        <span>                                         
                                                            {product.modifiersDisplay && product.modifiersDisplay}{product.modifiersDisplay === undefined && this.getModifiersAndComboProductsDisplay(product)}
														</span>
													</td>

													<td className="qty">
														{editModeIsOn ? (
															<QtyField
																modifier="small-alt"
																variant="secondary"
																min={0}
																onChange={this.handleUpdateQty({
																	index: i,
																	id: product.inventoryItemID
																})}
																value={this.state.products[i]} />
														):(
                                                                <strong>{localizeNumber(product.quantity)}</strong>
														)}
													</td>

                                                    {this.showPriceColumn() && <td className="price">
                                                        <strong>{product.priceDisplay && product.priceDisplay}{product.priceDisplay === undefined && localizeNumber(this.getProductPrice(product))}</strong>
                                                    </td>}
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					):(
                            <h3 style={{ textAlign: 'center' }}>{this.props.is_empty}</h3>
                        )}

                    {this.props.tabFeatureEnabled && this.props.existingTabArrCart && this.props.existingTabArrCart.length > 0 && this.props.existingTabCartPricing && <CartExistingTab arrCart={this.props.existingTabArrCart} cartPricing={this.props.existingTabCartPricing} />}

                    {!this.props.tabMode && (this.props.showSuggestions === undefined || this.props.showSuggestions) && this.props.hasAddOnProducts &&
                        <div className="cart__section cart__section--alt">
                        <div className="shell shell-suggestions">
                            <h6 className="cart__section-title">{window.resources.cart_suggestions.label_text}</h6>

                            <div className="cart__section-inner">
                                <CartSuggestions onAddToOrder={this.onAddSuggestionsToOrder} />
                            </div>
                        </div>
                    </div>
                    }

					<div className="cart__section cart__section--modified">
						<div className="shell shell--no-padding">
                            <CartCalculator arrCart={this.props.arrCart} cartPricing={this.props.cartPricing} />
						</div>
                    </div>
                    {   allowEditMode && this.props.hasTabOpenAndAuthorized && this.props.tabSplitPayEnabled && 
                        this.props.underTabThreshold && this.props.tabRequiresCCDetails &&
                        <div className='shell'>
                            <div className='split-update-info-msg add-items-split-msg'>
                                <div style={{margin: '15px'}}><i className='fa fa-info-circle'></i></div>
                                <div>{window.resources.cart.add_items_split_info_text}</div>
                            </div>
                        </div>
                    }
                </div>
				<div className="cart__foot">
					<div className="shell">
						{!editModeIsOn ? (
							<div className="cart__actions">
                                {this.props.mode === MODE_ORDER && <Link
                                    to="/menu"
                                    className="btn btn--secondary-2 btn--block">{window.resources.cart.order_more_text}</Link>}

                                {this.props.browseModeEnabled && this.props.mode === MODE_PAYTABNOW && <Link
                                    to="/menu"
                                    className="btn btn--secondary-2 btn--block">{window.resources.cart.browse_menu_text}</Link>}

                                {this.props.hasTabOpenAndAuthorized && !this.props.tabRequiresCCDetails && this.props.tabSplitPayEnabled && parseFloat(this.props.total) > this.props.tabSplitMinTab && (this.props.tabSplitEvenEnabled || this.props.tabSplitCustomEnabled) && (this.props.tabMode || arrCart.length === 0) && <button
                                    onClick={this.sendPaymentInvite}
                                    className="btn btn--block"> {window.resources.cart.payment_invite_text}</button>}

                                {this.props.tabFeatureEnabled && !this.props.hasTabOpen && !this.props.hasTabOpenAndAuthorized && this.props.underTabThreshold && <button
                                    disabled={isEmpty(arrCart) || (this.props.total === 0.00 && !this.props.orderOnlyNoPayment)}
                                    onClick={this.startATab}
                                    className="btn btn--block">{window.resources.cart.start_a_tab_text}</button>}

                                {allowEditMode && this.props.hasTabOpenAndAuthorized && this.props.underTabThreshold && <button
                                    disabled={isEmpty(arrCart)}
                                    onClick={this.addItemsToTab}
                                    className="btn btn--block">{window.resources.cart.add_items_to_tab_text} {this.props.cardLastFour && `(card \u2022${this.props.cardLastFour})`}</button>}

                                {!this.props.hasTabOpenAndAuthorized && !this.props.payNowFeatureDisabled && <button
                                    disabled={isEmpty(arrCart)}
                                    onClick={this.openPyament}
                                    className="btn btn--block">{this.props.total === 0.00 ? window.resources.cart.place_order_text : this.props.tabFeatureEnabled ? window.resources.cart.pay_now_text : window.resources.cart.continue_to_payment_text}</button>}

                                {this.props.hasTabOpenAndAuthorized && !this.props.orderOnlyNoPayment && (this.props.tabMode || arrCart.length === 0) && <button
                                    onClick={this.closeOutTab}
                                    className="btn btn--block"> {window.resources.cart.close_out_tab_text} </button>}

                                {this.props.hasTabOpenAndAuthorized && !this.props.tabMode && !this.props.orderOnlyNoPayment && arrCart.length > 0 && <button
                                    onClick={this.addItemsAndCloseOutTab}
                                    className="btn btn--block">{window.resources.cart.add_items_and_close_out_tab_text} {this.props.cardLastFour && `(card \u2022${this.props.cardLastFour})`} </button>}
							</div>
						):(
							<div className="cart__actions">
								<button
                                        onClick={this.handleFinishedEditing}
                                        className="btn btn--block">{window.resources.cart.finished_editing_text}</button>
							</div>
                            )}
                        {this.props.children}
					</div>
                </div>
                {/*
                <Payment
                    clientToken={this.props.clientToken}
                    total={this.props.total}
                    totalDisplay={this.props.totalDisplay}
                    countryCode={this.props.countryCode}
                    currencyCode={this.props.currencyCode}
                    onPaymentSuccess={this.handlePaymentSuccess}
                    closePyament={this.closePyament}
                    applePayEnabled={true}
                    isVisible={this.state.paymentIsVisible}
                    storeName={this.props.storeName}
                    paymentProvider={this.props.paymentProvider}
                    threeDSecureEnabled={this.props.threeDSecureEnabled}
                />
                */}
			</div>
		);
	}
}

export default compose(
    withRouter,
	connect(
        (state) => ({
            mode: state.cart.mode,
            exceededMaxTipAmount: window.resources.tip.maxPercentage !== 0 && state.cart.data.tipAmount > ((window.resources.tip.maxPercentage / 100) * Number(state.cart.cartPricingData ? state.cart.cartPricingData.subTotal : 0.00)),
            cardLastFour: state.tab.cardLastFour,
            tabRequiresCCDetails: !state.tab.cardLastFour,
            data: state.app.data,
			cart: state.cart.data,
            clientToken: state.cart.data && state.cart.data.clientToken,
            tippingPopFeatureEnabled: features.tippingPopFeatureEnabled(state),
            storeName: state.app.config && state.app.config.storeName,
            paymentProvider: state.app.config && state.app.config.PaymentProviderName,
            hasAddOnProducts: state.cart.cartPricingData && getAddOnProducts(state.cart.data.arrCart, state.cart.cartPricingData.addOnItems, state.app.data.inventoryItemMains).length > 0,
            currencyCode: state.cart.cartPricingData && state.cart.cartPricingData.isoCurrencyCode,
            countryCode: state.cart.cartPricingData && state.cart.cartPricingData.isoCountryCode,
            hasTabOpen: hasTabOpen(state),
            hasTabOpenAndAuthorized: hasTabOpenAndAuthorized(state),
            underTabThreshold: underTabThreshold(state),
            tabFeatureEnabled: features.tabFeatureEnabled(state),
            payNowFeatureDisabled: features.payNowFeatureDisabled(state),
            autoReceiptFeatureEnabled: features.autoReceiptFeatureEnabled(state),
            autoReceiptVKFeatureEnabled: features.autoReceiptVKFeatureEnabled(state),
            showMultiConceptWarning: true /*state.cart.data && state.cart.data.arrCart && state.cart.data.arrCart.length > 0*/,
            multiConceptFeatureEnabled: features.multiConceptFeatureEnabled(state),
            multiConceptID: state.app.config && state.app.config.MultiConceptID,
            tableNumber: state.checkout.tableNumber,
            //threeDSecureEnabled: features.threeDSecureEnabled(state),
            alcoholMealLimitEnabled: features.alcoholMealLimitEnabled(state),
            allowedAlcoholItemsPerMealItem: features.allowedAlcoholItemsPerMealItem(state),
            browseModeEnabled: features.browseModeEnabled(state),
            hasAlcoholInCart: hasAlcoholInCart(state),
            tipForAlcoholOnlyFeatureEnabled: features.tipForAlcoholOnlyFeatureEnabled(state),
            hideZeroDollars: features.hideZeroDollars(state),
            tabSplitPayEnabled: features.tabSplitPayEnabled(state),
            tabSplitEvenEnabled: features.tabSplitEvenEnabled(state), 
            tabSplitCustomEnabled: features.tabSplitCustomEnabled(state), 
            tabSplitMinTab: features.tabSplitMinTab(state), 
            orderOnlyNoPayment: features.orderOnlyNoPayment(state), 

            donationEnabled: features.donationEnabled(state), 
            donationMerchId: features.donationMerchId(state), 
            donationPenniesToken: features.donationPenniesToken(state), 
            donationCalculation: state.donation ? state.donation.donationinfo : null, 
            dayPartMsgEnabled: state.app.config && state.app.config.DayPartMsgEnabled, 
			dayPartMsgWarningMin: state.app.config && state.app.config.DayPartMsgEnabled && state.app.config.DayPartMsgMinWarning, 
            waitTimeEnabled: state.app.config && state.app.config.WaitTimeEnabled, 
            waitTimeInfo: state.app.config && state.app.config.WaitTimeInfo 
		}),
		{
			updateCartProducts,
			startRemoveFromCart,
			setEditProduct,
			startCartUpdate,
            sendPenniesCalculationRequest, 
            openModal,
            startCartSave,
            requestTableTopConfigBeforeOrder,
            updateSaveMode,
            startTipAmountUpdate,
            updatePaymentDetails
		}
	)
)(Cart);
