/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/**
 * @ The internal dependencies.
 */
import { closeModal } from 'store/state/ui/actions';

/**
 * Class for modal iframe.
 *
 * @class ModalIFrame (name)
 */
class ModalIFrame extends Component {
	constructor(props) {
		super(props);
		this.iframe = React.createRef();
	}

	componentDidMount() {
		const iframe = this.iframe.current;
		if (this.props.data.content) {
			const document = iframe.contentDocument;
			document.body.innerHTML = this.props.data.content;
		}
	}

	render() {
		return (
			<div className="modal-frame modal-frame--warning modal-frame--card">
				<div className="modal-frame__body">
					<div className="modal-frame__card">
						<div className="modal-frame__head">
							<h6 className="modal-frame__title"><div>{this.props.data.title}</div></h6>
						</div>
						<div className="modal-frame__card-body">
						<iframe ref={this.iframe} style={{
							width: '100%',
							height: '400px'
							}} src={this.props.data.src} title={this.props.data.title} /> 
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(connect(
	(state) => ({
		data: state.ui.modal.data
	}),
	{
		closeModal
	}
)(ModalIFrame));
