/**
 * @ The external dependencies.
 */
import {
    all,
    put,
    call,
    takeEvery,
    select
} from 'redux-saga/effects';

/**
 * @ The internal dependencies.
 */
import api from 'lib/api';
import { setSessionCustomer } from 'lib/helpers/local-storage';
import {
    startCreateCustomer,
    updateCustomer,
    peopleAlsoAddedView,
    peopleAlsoAddedAddition,
    peopleAlsoAddedCancel,
    canWeTemptYouView,
    canWeTemptYouAddition
} from './actions';
import { createUUID } from 'lib/utility';

function* startCreateCustomerWorker(action) {
    const { customer } = yield select();

    if (!customer.customerID) {
        const deviceUniqueKey = createUUID();

        const payload = {
            messageFormat: 'JSON',
            email: '',
            deviceUniqueKey: deviceUniqueKey,
            deviceType: window.navigator.userAgent,
            gid: customer.gID || ''
        };

        const resp = yield call([api, 'createCustomerId'], payload);

        const customerData = {
            kobp: window.kobp,
            deviceUniqueKey: deviceUniqueKey,
            customerID: resp.customerID,
            gID: customer.gID || ''
        }

        yield put(updateCustomer(customerData));

        yield call(setSessionCustomer, customerData);
    }
}

export function* trackRequest(trackID, action) {
    const { customer } = yield select();

    try {
        yield call([api, 'trackByCustomerID'], {
            customerID: customer.customerID,
            email: '',
            deviceUniqueKey: customer.deviceUniqueKey,
            deviceType: window.navigator.userAgent,
            arrTracks: [{
                trackDateTimeUTC: new Date().toISOString(),
                trackID: trackID,
                trackData: action.payload
            }],
            kobp: customer.kobp
        });
    }
    catch (error) {
    
    }
}

function* peopleAlsoAddedViewWorker(action) {
    yield call(trackRequest, 97, action);
}

function* peopleAlsoAddedAdditionWorker(action) {
    yield call(trackRequest, 98, action);
}

function* peopleAlsoAddedCancelWorker(action) {
    yield call(trackRequest, 10, action);
}

function* canWeTemptYouViewWorker(action) {
    yield call(trackRequest, 99, action);
}

function* canWeTemptYouAdditionWorker(action) {
    yield call(trackRequest, 100, action);
}

/**
 * Start all workers.
 *
 * @return {Generator}
 */
export default function* foreman() {
    yield all([
        takeEvery(startCreateCustomer, startCreateCustomerWorker),
        takeEvery(peopleAlsoAddedView, peopleAlsoAddedViewWorker),
        takeEvery(peopleAlsoAddedAddition, peopleAlsoAddedAdditionWorker),
        takeEvery(peopleAlsoAddedCancel, peopleAlsoAddedCancelWorker),
        takeEvery(canWeTemptYouView, canWeTemptYouViewWorker),
        takeEvery(canWeTemptYouAddition, canWeTemptYouAdditionWorker),
    ]);
}