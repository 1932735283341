/**
 * The suported modal types
 */
export const MODAL_SUGGESTIONS = 'ModalSuggestions';
export const MODAL_TIP = 'ModalTip';
export const MODAL_DISCOUNT = 'ModalDiscount';
export const MODAL_TABLE_NUMBER = 'ModalTableNumber';
export const MODAL_WARNING = 'ModalWarning';
export const MODAL_RECEIPT_CHOICE = 'ModalReceiptChoice';
export const MODAL_RECEIPT_EMAIL = 'ModalReceiptEmail';
export const MODAL_RECEIPT_TEXT = 'ModalReceiptText';
export const MODAL_RECEIPT_SUCCESS = 'ModalReceiptSuccess';
export const MODAL_ALLERGENS = 'ModalAllergens';
export const MODAL_ALCOHOL = 'ModalAlcohol';
export const MODAL_AIRLINE_EMPLOYEE_DISCOUNT = 'ModalAirlineEmployeeDiscount';
export const MODAL_TAB_VERIFICATION = 'ModalTabVerification';
export const MODAL_TAB_VERIFICATION_AUTO = 'ModalTabVerificationAuto';
export const MODAL_TAB_NEW_ORDER = 'ModalTabNewOrder';
export const MODAL_LANGUAGE = 'ModalLanguage';
export const MODAL_QUESTIONNAIRE = 'ModalQuestionnaire';
export const MODAL_IFRAME = 'ModalIFrame';
export const MODAL_SPREEDLY3DS = 'ModalSpreedly3DS';
export const MODAL_LOADED_VALUE_ACCOUNT_BALANCE_CHECK = "ModalLoadedValueAccountBalanceCheck"
export const MODAL_LOADED_VALUE_ACCOUNT_BALANCE_RESPONSE = "ModalLoadedValueAccountBalanceResponse"
export const MODAL_COMBO_UPGRADE_PATHS = 'ModalComboUpgradePaths';

export const MODAL_BROWSE = "ModalBrowse"
export const MODAL_COMBO = 'ModalCombo';
export const MODAL_CHOOSE_DINEIN_TAKEAWAY = "ModalChooseDineInTakeAway"
export const MODAL_DINE_IN = "ModalDineIn"
export const MODAL_TAKEAWAY = "ModalTakeAway"
export const MODAL_LOYALTY_LOGIN = "ModalLoyaltyLogin"
export const MODAL_LOYALTY_LOGIN_SUCCESS = "ModalLoyaltyLoginSuccess"
export const MODAL_LOYALTY_REGISTER = "ModalLoyaltyRegister"
export const MODAL_LOYALTY_AMOUNT = "ModalLoyaltyAmount"

export const MODAL_CHOOSE_PAY_TYPE = 'ModalChoosePayType';
export const MODAL_SPLIT_QR_CODE = 'ModalSplitQRCode';
export const MODAL_SPLIT_AMOUNT_DETAILS = 'ModalSplitAmountDetails';

export const MODAL_DONATIONS = 'ModalDonations';

export const PAY_NOW = 'PayNow';
export const TAB_NEW = 'TabNew';
export const TAB_ADDITEMS = 'TabAddItems';
export const TAB_ADDITEMS_CLOSE = 'TabAddItemsClose';
export const TAB_CLOSE = 'TabClose';
export const TAB_SPLIT = 'TabSplit';
export const SPLIT_CLOSE = 'SplitClose';

export const MODE_ORDER = 'Order';
export const MODE_PAYTABNOW = 'PayTabNow';
export const MODE_BROWSE = 'Browse';
export const MODE_SPLITTAB_INVITE = 'SplitTabInvite';

export const BALANCE_CHECK_INITIAL = 'BalanceCheckInitial';
export const BALANCE_CHECK_CHECKING = 'BalanceCheckChecking';
export const BALANCE_CHECK_UPDATED = 'BalanceCheckUpdated';  