/**
 * @ The external dependencies.
 */
import React from 'react';

const invalidStore = () => {
    return (
        <div
            className="intro"
            style={{
                backgroundImage: `url(${require('assets/images/temp/background.png')})`
            }}>
            <header className="intro__head">
                <div className="shell">

                </div>
            </header>

            <div className="intro__body">
                <div className="intro__body-inner">
                    <div className="shell">
                        <h4 className="intro__title editable" dangerouslySetInnerHTML={{ __html: window.resources.messages.invalid_store_html }}></h4>
                    </div>
                </div>

                <div className="intro__slider">
                    <div className="shell shell--no-padding">

                    </div>
                </div>
            </div>
        </div>);
}

export default invalidStore;