export const initialize = () => {
    console.log('Initializing iframe commands...');

    window.addEventListener('message', function (e) {
        var key = e.message ? 'message' : 'data';
        var data = e[key];
        switch (data.message_id) {
            case 'update_resources':
                console.log('updating resources...');
                for (let kvp in data.data) {
                    window.resources[kvp] = { ...window.resources[kvp], ...data.data[kvp]}
                }
                break;
            case 'update_styles':
                console.log('updating styles...');
                var element = document.getElementById('styles');
                let styles = ''
                for (let kvp in data.data) {
                    styles += `--${kvp}: ${data.data[kvp]}; `
                }
                element.innerHTML = `:root { ${styles} }`;
                break;
            default:
                break;
        }
    }, false);
}