/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**
 * @ The internal dependencies.
 */
import { closeModal } from 'store/state/ui/actions';
import { getInventoryProductByInventoryItemID } from 'store/state/cart/selectors'

/**
 * Class for modal combo upgrade paths.
 *
 * @class ModalComboUpgradePaths (name)
 */
class ModalComboUpgradePaths extends Component {
    state = {
        imageError: false
    }

    handleSelectUpgradePath = (inventoryItemID) => (e) => {
        e.preventDefault();
        this.props.history.push(`/menu/${inventoryItemID}`);
        this.props.closeModal();
    }

    handleOnlySelectThisItem = (e) => {
        e.preventDefault();
        this.props.history.push(`/menu/${this.props.inventoryItemID}`);
        this.props.closeModal();
    }

    replaceVariables = (html, upgradePath) =>
    {
        return html.replace('{comboDescriptor}', upgradePath.comboDescriptor)
    }

    render() {
        const imgUrl = encodeURI(this.props.firstComboProduct.imageV2.product_16_9 || this.props.firstComboProduct.imageV2.product_1_1 || this.props.firstComboProduct.inventoryItemImageName);

        return (
            <div className="modal-frame modal-frame--card">
                <div className="modal-frame__body">
                    <div className="modal-frame__card">
                        <div className="modal-frame__head">
                            <h5 className="modal-frame__title">{window.resources.combo_upgrade_paths.title_text}</h5>
                        </div>
                        <p style={{ textAlign: 'center' }}>{window.resources.combo_upgrade_paths.message_text}</p>
                        {!(!imgUrl || (this.state.imageError)) && <figure
                            className="modal-frame__centreimage"
                            style={{
                                backgroundImage: `url('${imgUrl}')`
                            }}>
                            <img src={imgUrl} style={{ display: 'none' }} alt="productImage" onError={(e) => {
                                if (imgUrl) {
                                    this.setState(prevState => {
                                        return {
                                            imageError: true
                                        };
                                    });
                                }
                            }} />
                        </figure>}
                        <br />
                        <div className="modal-frame__actions modal-frame__actions--flex">
                            {this.props.product.upgradePaths.map(upgradePath => <button
                                onClick={this.handleSelectUpgradePath(upgradePath.upgradeToInventoryItemID)}
                                className="btn btn--secondary btn-auto btn-combo editable" dangerouslySetInnerHTML={{ __html: this.replaceVariables(window.resources.combo_upgrade_paths.confirm_html, upgradePath) }}></button>)}
                        </div>
                        <div className="modal-frame__links">
                            <a onClick={this.handleOnlySelectThisItem}>{window.resources.combo_upgrade_paths.cancel_text}</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        inventoryItemID: state.ui.modal.data.inventoryItemID,
        product: getInventoryProductByInventoryItemID(state, state.ui.modal.data.inventoryItemID),
        firstComboProduct: getInventoryProductByInventoryItemID(state, getInventoryProductByInventoryItemID(state, state.ui.modal.data.inventoryItemID).upgradePaths[0].upgradeToInventoryItemID.toString())
    };
}

export default withRouter(connect(
    mapStateToProps,
    {
        closeModal
    }
)(ModalComboUpgradePaths));
