import React from 'react';

import {
    compose,
    sortBy,
    prop
} from 'ramda';

import { MODE_ORDER } from 'lib/constants';
import { localizeNumber } from '../../assets/resources/resources-manager';
import { isLtr } from '../../lib/helpers/language';

const ProductChoices = ({ product, handleCheckboxChange, mode }) => {
	const { choices } = product.inventoryMainOptionChoice;


	return (<div className="product-single__section">
		{choices.map((choice, choiceIdx) => (
			<div key={choice.choiceID} className="product-single__group">
				<div role='checkbox' tabIndex={3} aria-required={choice.choiceSelection !== '0'}><h6 className="product-single__title product-single__title--alt">{choice.choiceGroupName} {choice.choiceSelection > 1 ? <span style={{ fontSize: '12px', color: 'unset', fontStyle: 'italic' }}>{`(Choose ${choice.choiceSelection})`}</span> : null} {choice.choiceSelection !== "0" && (<span>*</span>)}</h6></div>

				<div className="checkboxes">
					{sortBy(compose(parseInt, prop('choiceOrder')), choice.inventoryChoices).map((innerChoice, i) => (
						<div key={innerChoice.choiceID + i} className="checkbox">
							<input
								onChange={handleCheckboxChange({
									prop: 'choice',
									collectionName: 'choices',
									itemsCollectionName: 'inventoryChoices',
									parentIdx: choiceIdx,
									itemIdx: i,
									limit: choice.choiceSelection
								})}
								checked={product.inventoryMainOptionChoice.choices[choiceIdx].inventoryChoices[i].selected}
								value={product.inventoryMainOptionChoice.choices[choiceIdx].inventoryChoices[i].selected}
								id={innerChoice.choiceID + i}
								type="checkbox" />

							<label aria-labelledby={"span_" + innerChoice.choiceID + i} className={mode !== MODE_ORDER ? "hide" : ""} htmlFor={innerChoice.choiceID + i}>
								<span id={"span_" + innerChoice.choiceID + i}>
									{innerChoice.choiceDescription}
									{innerChoice.choiceCost !== '0.00' && (` (${isLtr() ? '+' : ''}${localizeNumber(innerChoice.choiceCost)})`)}
								</span>
							</label>
						</div>
					))}
				</div>
			</div>
		))}
	</div>
    )
}

export default ProductChoices;