import React from 'react';
import cx from 'classnames';

/**
 * @ The internal dependencies.
 */
import IconSvg from 'components/common/icon-svg';
import HourGlassIcon from '../../assets/svg/hourglass-small.svg'

const Timer = ({ hasImage }) => {
    return (
        <div className={cx('timer-ellipse', {'timer-ellipse-no-image': !hasImage})}>
            <div><IconSvg src={HourGlassIcon} /></div>
        </div>
    )
}

export default Timer;