/**
 * @ The external dependecies.
 */
import { createAction } from 'redux-actions';

export const clearTab = createAction('tab/clear-tab');
export const clearTabAuthorization = createAction('tab/clear-tab-authorization');

//Close Tab
export const sendCloseTabRequest = createAction('tab/send-close-tab-request');
export const receiveCloseTabResponse = createAction('tab/receive-close-tab-response');

//Authorize
export const tabAuthorization = createAction('tab/tab-authorization');
export const tabAuthorizationStart = createAction('tab/tab-authorization-start');
export const tabAuthorizationEnd = createAction('tab/tab-authorization-end');

export const tabAuthorizationReceiveResponse = createAction('tab/tab-authorization-receive-response');

export const tabAuthorizationUpdate = createAction('tab/tab-authorization-update');

export const updateOpenCheckTicketId = createAction('tab/update-open-check-ticket-id');