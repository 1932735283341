/**
 * @ The external dependecies
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
    compose
} from 'ramda';

/**
 * @ The internal dependecies.
 */
import api from 'lib/api';
import StoreAssetSvg from 'components/common/store-asset-svg';
import Loader from 'components/loader/loader';
import { openModal, closeModal } from '../../store/state/ui/actions';
import { clearCartPartial, startCartSave, updateSpreedlyTransactionToken } from '../../store/state/cart/actions';
import { MODAL_RECEIPT_EMAIL, MODAL_RECEIPT_TEXT, MODAL_RECEIPT_CHOICE, MODAL_TAB_VERIFICATION, PAY_NOW, TAB_NEW, TAB_ADDITEMS, TAB_ADDITEMS_CLOSE, TAB_CLOSE, MODAL_IFRAME, MODAL_SPLIT_QR_CODE  } from '../../lib/constants';
import withDataValidation from '../../lib/helpers/hocs/with-data-validation';
import { hasTabOpen } from '../../store/state/tab/selectors'
import { clearTabAuthorization } from '../../store/state/tab/actions';
import { clearLoyalty } from '../../store/state/loyalty/actions';
import  SurveyQuestions from '../../components/survey/survey-questions';
import * as features from '../../store/state/features/selectors';
// import * as Analytics from '../../lib/analytics';
import * as Analytics from '../../lib/analytics-ga4';
import config from '../../config';
import { updateObject } from '../../lib/utility';
import { clearAdyenSession } from '../../store/state/checkout/actions';

/**
 * Class for CheckoutComplete.
 *
 * @class CheckoutComplete (name)
 */
class CheckoutComplete extends Component {
    state = {
        threeDS_ErrorMsg: "" 
    }
    componentDidMount() {
        window.addEventListener("message", this.receiveMessage);

        this.props.clearAdyenSession()
    }

    componentDidUpdate(prevProps) {
        if (this.props.required_3DS && this.props.spreedlyTransaction.CheckoutURL == null
            && ((prevProps.required_3DS != this.props.required_3DS || prevProps.spreedlyTransaction == null || prevProps.spreedlyTransaction.CheckoutURL != this.props.spreedlyTransaction.CheckoutURL))) {
            this.setupAndRunSpreedlyLifecycle();
        }
        else if (this.props.required_3DS && !prevProps.required_3DS) {
            this.launchSpreedly3DSModal();
        }

        if (this.props.ga && this.props.orderID && this.props.totalCost && 
            this.props.orderID !== prevProps.orderID && this.props.totalCost !== prevProps.totalCost) {
            
            // this.handleAnalyticsLogging(this.props.orderID, this.props.totalCost);
            const items = this.props.checkout.data.orderDetails.map(i => (
                {
                    item_id: i.inventoryItemID, 
                    item_name: i.inventoryItemName, 
                    price: i.cost, 
                    quantity: i.quantity
                }
            ))
            window.gtag('event', 'purchase', {
                currency: this.props.config.isoCurrencyCode, 
                transaction_id: this.props.orderID, 
                value: parseFloat(this.props.totalCost).toFixed(2), 
                tax: parseFloat(this.props.checkout.data.taxesCost).toFixed(2), 
                items: items
            })
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener("message", this.receiveMessage);
    }

    setupAndRunSpreedlyLifecycle = () => {
        var lifecycle = new window.Spreedly.ThreeDS.Lifecycle({
            environmentKey: '...',
            // The environmentKey field is required, but if omitted, you will receive a console warning message and the transaction will still succeed.
            hiddenIframeLocation: 'device-fingerprint',
            // The DOM node that you'd like to inject hidden iframes
            challengeIframeLocation: 'challenge',
            // The DOM node that you'd like to inject the challenge flow
            transactionToken: this.props.spreedlyTransaction.Token,
            // The token for the transaction - used to poll for state
            challengeIframeClasses: 'challenge_iframe'
            // The css classes that you'd like to apply to the challenge iframe.
            //
            // Note: This is where you'll change the height and width of the challenge
            //       iframe. You'll need to match the height and width option that you
            //       selected when collecting browser data with `Spreedly.ThreeDS.serialize`.
            //       For instance if you selected '04' for browserSize you'll need to have a
            //       CSS class that has width and height of 600px by 400px.
        });

        var thisHolder = this;

        var statusUpdates = function (event) {
            if (event.action === 'succeeded') {
                thisHolder.makeSaveOrderCall(event.token);
            } else if (event.action === 'error') {

                thisHolder.setState({ threeDS_ErrorMsg: "Error processing payment. " });
            } else if (event.action === 'challenge') {
                // Show your modal containing the div provided in `challengeIframeLocation` when
                // creating the lifecycle event.
                //
                //  and then we show the challenge-modal
                //
                document.getElementById('challenge-loader').classList.add('hidden');
                document.getElementById('challenge').classList.remove('hidden');
            } else if (event.action === 'trigger-completion') {
                // 1. make a request to your backend to do an authenticated call to Spreedly to complete the request
                //    The completion call is `https://core.spreedly.com/v1/transactions/[transaction-token]/complete.json (or .xml)`
                // 2a. if the transaction is marked as "succeeded" finish your checkout and redirect to success page
                // 2b. if the transaction is marked "pending" you'll need to call finalize `event.finalize(transaction)` with the transaction data from the authenticated completion call.

                // This is an example of the authenticated call that you'd make
                // to your service.
                const payload = {
                    transactionToken: event.token
                };
                api.sendSpreedlyComplete(payload).then((data) => {
                    if (data.transaction.state === 'succeeded') {
                        thisHolder.makeSaveOrderCall(data.transaction.token);
                    }
                    else if (data.transaction.state === 'pending') {
                        event.finalize(data.transaction);
                    }
                    else if (data.transaction.state === 'gateway_processing_failed') {
                        thisHolder.setState({ threeDS_ErrorMsg: "Error processing payment. " });
                    }
                });
            }
        }

        //Set:
        window.Spreedly.on('3ds:status', statusUpdates);
        lifecycle.start();
    }

    launchSpreedly3DSModal = () => {
        this.props.openModal({
            type: MODAL_IFRAME,
            data: {
                title: '3D Secure',
                src: this.props.spreedlyTransaction.CheckoutURL,
                content: this.props.spreedlyTransaction.CheckoutForm
            }
        });
    }

    receiveMessage = (e) => {
        var message = e.data, data = message.data;

        switch (message.type) {
            case 'spreedly_redirect':
                this.props.closeModal();
                this.makeSaveOrderCall(data.transaction_token);
                break;
            default:
                break;
        }
    }

    makeSaveOrderCall = (transactionToken) => {
        this.props.updateSpreedlyTransactionToken(transactionToken);

        //Retrigger save
        switch (this.props.saveMode) {
            case PAY_NOW:
                this.props.startCartSave(
                    {
                        newTab: false,
                        createOrUpdateTab: false,
                        sendCartItems: true
                    }
                );
                break;
            case TAB_NEW:
                this.props.startCartSave(
                    {
                        newTab: true,
                        createOrUpdateTab: true,
                        sendCartItems: true
                    }
                );
                break;
            case TAB_ADDITEMS:
                this.props.startCartSave(
                    {
                        newTab: false,
                        createOrUpdateTab: true,
                        sendCartItems: true
                    }
                );
                break;
            case TAB_ADDITEMS_CLOSE:
                this.props.startCartSave(
                    {
                        newTab: false,
                        createOrUpdateTab: false,
                        sendCartItems: true
                    }
                );
                break;
            case TAB_CLOSE:
                this.props.startCartSave(
                    {
                        newTab: false,
                        createOrUpdateTab: false,
                        sendCartItems: false
                    }
                );
                break;
            default:
        }
    }


    orderMoreFoodHandler = (e) => {
        e.preventDefault();
        this.props.clearAdyenSession()

        if (this.props.hasTabOpen) {
            this.props.openModal({
                type: MODAL_TAB_VERIFICATION,
                data: {
                    onAuthorized: () => {
                        this.props.history.push('/menu');
                    },
                    onNewOrder: () => {
                        this.props.history.push(`/${this.props.tableNumber}`);
                    }
                }
            });
        }
        else {
            this.props.history.push(`/${this.props.tableNumber}`);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.success && this.props.success !== nextProps.success) {
            this.props.clearCartPartial();
            this.props.clearTabAuthorization();
        }
        // if (nextProps.ga && this.props.ga !== nextProps.ga) this.handleAnalyticsLogging();
    }

    handleSendInvite = (e) => {
        e.preventDefault();

        this.props.openModal({
            type: MODAL_SPLIT_QR_CODE, 
            data: {
                splitType: 'custom',
                inviteAmount: 0, 
                token: this.props.location.state.token
            }
        });
    }

    handleEReceiptClick = (e) => {
        e.preventDefault();

        if (this.props.textReceiptFeatureEnabled && this.props.emailReceiptFeatureEnabled) {
            this.props.openModal({ type: MODAL_RECEIPT_CHOICE });
        }
        else if (this.props.emailReceiptFeatureEnabled) {
            this.props.openModal({
                type: MODAL_RECEIPT_EMAIL,
                data: {
                    send: true
                }
            });
        }
        else if (this.props.textReceiptFeatureEnabled) {
            this.props.openModal({
                type: MODAL_RECEIPT_TEXT,
                data: {
                    send: true
                }
            });
        }
    }
    handleAnalyticsLogging = (orderId, totalCost) => {
        // console.log('checkout', this.props.checkout)
        // const items = this.props.checkout.data.orderDetails.map(i => (
        //         {
        //             item_id: i.inventoryItemID, 
        //             item_name: i.inventoryItemName, 
        //             price: i.cost, 
        //             quantity: i.quantity
        //         }
        //     )
        // )
        // Analytics.initialize(this.props.ga);
        // Analytics.logEcommerceEvent('USD', orderId, totalCost, 
        //     this.props.checkout.data.taxesCost, items
        // )
    }

    replaceVariables(html) {
        return html.replace("{this.props.tableNumber}", this.props.tableNumber)
                   .replace("{this.props.posOrderID}", this.props.posOrderID || this.props.orderID)
                   .replace("{this.props.surveyLink}", this.props.surveyLink)
                   .replace("className", "class");

    }

    render() {
        let loading = (<div key="loading" className={classnames({
            'payment': true,
            'visible': this.props.processingOrder
        })}>
            <Loader
                title={window.resources.spinners.processing_order_text}
            />
        </div>);

		let checkoutComplete = (<div className="checkout">
				<div className="shell">
					<div className="checkout__inner">
						<div className="checkout__logo">
                            <StoreAssetSvg path='/logo.svg' />
						</div>
                        <div aria-live='assertive'>
                            <div className="checkout__table">
                                <p><strong>{this.props.isVirtualKiosk ? this.replaceVariables(window.resources.checkout.table_number_orderid_virtual_kiosk_text) : this.replaceVariables(window.resources.checkout.table_number_orderid_text)}</strong></p>
                            </div>
                            <div className="checkout__content">
                                <h2 className="checkout__title">{window.resources.checkout.thankyou_text}</h2>

                                {window.resources.checkout.drinks_html && <div className="checkout__collect">
                                    <span className="fa-stack fa-xs">
                                        <i className="fa fa-wine-glass fa-stack-2x"></i>
                                        <i className="fa fa-sm fa-exclamation fa-stack-1x"></i>
                                    </span><br />
                                    <p className="editable" dangerouslySetInnerHTML={{ __html: window.resources.checkout.drinks_html }}></p>
                                </div>}
                            </div>
                            <div className="checkout__delivery editable" dangerouslySetInnerHTML={{ __html: this.props.isVirtualKiosk ? this.replaceVariables(window.resources.checkout.delivery_virtual_kiosk_html) : this.props.hasTabOpen ? this.replaceVariables(window.resources.checkout.delivery_tab_html) : this.replaceVariables( window.resources.checkout.delivery_html) }}></div>
                        </div>

                        {this.props.showSurveyLink && this.props.surveyLink && <div tabIndex={0} className="checkout__survey editable" dangerouslySetInnerHTML={{ __html: this.replaceVariables(window.resources.checkout.survey_html) }}></div>}

                    <div className="checkout__actions">
                        {
                            !this.props.hasTabOpen && 
                            (this.props.showReceiptForZeroFeatureEnabled || this.props.totalCost !== '0.00') && 
                            (this.props.textReceiptFeatureEnabled || this.props.emailReceiptFeatureEnabled) && 
                            (<button onClick={this.handleEReceiptClick} className="btn btn--block">{window.resources.checkout.receipt_text}</button>)
                        }
                        {
                            this.props.location && this.props.location.state && this.props.location.state.showInvite && 
                            <button onClick={this.handleSendInvite} className="btn btn--block">{window.resources.cart.payment_invite_text}</button>
                        }
                        <button onClick={this.orderMoreFoodHandler} className="btn btn--secondary-2 btn--block">{window.resources.checkout.order_more_text}</button>
                    </div>

                    {this.props.surveyQuestionsFeatureEnabled && <div className="checkout__survey-questions">
                        <SurveyQuestions hasTabOpen={this.props.hasTabOpen} />
                    </div>}

                    {window.resources.checkout.proof_html && <div className="checkout__proof editable" dangerouslySetInnerHTML={{ __html: this.replaceVariables(window.resources.checkout.proof_html) }}>

                    </div>}
					</div>
				</div>
        </div>)

        let errorView = (<div className="checkout">
            <div className="shell">
                <div className="checkout__inner">
                    <div className="checkout__logo">
                        <StoreAssetSvg path='/logo.svg' />
                    </div>

                    <div className="checkout__content" aria-live='assertive'>
                        <h2 className="checkout__title editable" dangerouslySetInnerHTML={{ __html: this.replaceVariables(window.resources.messages.unknown_error_html) }}></h2>

                        <div className="checkout__entry">
                            <p>{this.state.threeDS_ErrorMsg || this.props.errorMessage}</p>
                        </div>
                    </div>

                    <div tabIndex={0} className="checkout__actions">
                        <Link to="/cart" className="btn btn--block">{window.resources.checkout.try_again_text}</Link>
                    </div>
                </div>
            </div>
        </div>);

        let threeDSChallengeView = (<div className="checkout">
            <div id="challenge-loader">
                <Loader
                    title={window.resources.spinners.processing_order_text}
                />
            </div>
            <div className="shell">
                <div className="checkout__inner">
                    <div className="checkout__logo">
                        <StoreAssetSvg path='/logo.svg' />
                    </div>
                    <div className="checkout__content">
                        <div id="device-fingerprint" className="hidden">
                        </div>
                        <div id="challenge-modal">
                            <div id="challenge" className="hidden">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);

        if (this.props.processingOrder && !this.state.threeDS_ErrorMsg) {
            if (this.props.required_3DS && this.props.spreedlyTransaction.CheckoutURL == null) {
                return threeDSChallengeView;
            }
            else {
                return loading;
            }
        }
        else {
            if (this.props.errorMessage || this.state.threeDS_ErrorMsg) {
                return errorView;
            }
            else {
                return checkoutComplete;
            }
        }
		
	}
}

export default compose(
    connect(
    (state) => ({
        orderID: state.checkout.orderID,
        posOrderID: state.checkout.posOrderID,
        prepTimeMin: state.checkout.prepTimeMin,
        prepTimeMax: state.checkout.prepTimeMax,
        totalCost: state.checkout.totalCost,
        checkout: state.checkout, 
        required_3DS: state.checkout.error === '3DS_REQUIRED' && state.checkout.spreedlyTransaction.State === 'pending',
        spreedlyTransaction: state.checkout.spreedlyTransaction,
        processingOrder: state.ui.savingCart || (state.checkout.error === '3DS_REQUIRED' && state.checkout.spreedlyTransaction.State === 'pending'),
        saveMode: state.checkout.saveMode,
        errorMessage: state.checkout.error,
        showSurveyLink: state.checkout.showSurveyLink,
        success: !state.ui.savingCart && !state.checkout.error,
        tableNumber: state.checkout.tableNumber,
        //collectMsg: stringResources.checkout_complete_collect_msg,
        ga: state.app.ga,
        surveyLink: state.app.surveyLink,
        isVirtualKiosk: state.cart.data && state.cart.data.isVirtualKiosk,
        hasTabOpen: hasTabOpen(state),
        surveyQuestionsFeatureEnabled: features.surveyQuestionsFeatureEnabled(state),
        tabFeatureEnabled: features.tabFeatureEnabled(state),
        textReceiptFeatureEnabled: features.textReceiptFeatureEnabled(state),
        emailReceiptFeatureEnabled: features.emailReceiptFeatureEnabled(state),
        showReceiptForZeroFeatureEnabled: features.showReceiptForZeroFeatureEnabled(state), 
        config: state.app.config
    }), {
        openModal,
        closeModal,
        clearCartPartial,
        clearTabAuthorization,
        startCartSave,
        updateSpreedlyTransactionToken,
        clearLoyalty, 
        clearAdyenSession
    }),
    withDataValidation
)(CheckoutComplete);
