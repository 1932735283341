/**
 * @ The external dependecies.
 */
import React, { Component } from 'react';

/**
 * @ The internal dependencies.
 */
import MenuDisplay from './menu-display';
import withDataValidation from 'lib/helpers/hocs/with-data-validation';

/**
 * Class for menu.
 *
 * @class Menu (name)
 */
class Menu extends Component {
	
	render() {
		return (
			<MenuDisplay {...this.props} redirectPath={window.isMarketplace ? `/marketplace/${window.marketplaceId}/${window.waypointID}/menu` : "/menu"} showHeader={true} categoriesFilter="" showFooter={true}>
			</MenuDisplay>
		);
	}
}

export default withDataValidation(Menu);