/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

/**
 * @ The internal dependencies.
 */
import { closeModal } from 'store/state/ui/actions';
import { updateModifiers } from 'store/state/cart/actions';

/**
 * Class for modal discount.
 *
 * @class ModalDiscount (name)
 */
class ModalDiscount extends Component {
	state = {
		discount: 0
	}

	setDiscount = (e) => {
		this.setState({
			discount: e.target.value
		});
	}

	addDiscount = (e) => {
		e.preventDefault();

		this.props.updateModifiers({
			discount: Number(this.state.discount)
		});

		this.props.closeModal();
	}

	render() {
		return (
			<div className="modal-frame modal-frame--card">
				<div className="modal-frame__head">
                    <h6 className="modal-frame__title">{window.resources.discount.label_text}</h6>
				</div>

				<div className="modal-frame__body">
					<div className="modal-frame__card">
						<div className="modal-frame__card-body">
							<div className="modal-frame__card-group modal-frame__card-group--alt">
								<div className="radio-group radio-group--stack">
									<div className="radio-square radio-square--stack">
										<input
											type="radio"
											id="radio-1"
											name="tip-group"
											value={15.00}
											onChange={this.setDiscount}
										/>

										<label htmlFor="radio-1">
                                            <strong>{window.resources.discount.military_text}</strong>
										</label>
									</div>

									<div className="radio-square radio-square--stack">
										<input
											type="radio"
											id="radio-2"
											name="tip-group"
											value={10.00}
											onChange={this.setDiscount}
										/>

										<label htmlFor="radio-2">
                                            <strong>{window.resources.discount.employee_text}</strong>
										</label>
									</div>

									<div className="radio-square radio-square--stack">
										<input
											type="radio"
											id="radio-3"
											name="tip-group"
											value={5.00}
											onChange={this.setDiscount}
										/>

										<label htmlFor="radio-3">
                                            <strong>{window.resources.discount.senior_text}</strong>
										</label>
									</div>
								</div>
							</div>

							<div className="modal-frame__card-entry">
                                <p>{window.resources.discount.message_text}</p>
							</div>
						</div>

						<div className="modal-frame__actions modal-frame__actions--flex">
							<button
								onClick={() => this.props.closeModal()}
                                className="btn btn--secondary">{window.resources.discount.cancel_text}</button>

							<button
								onClick={this.addDiscount}
                                className="btn">{window.resources.discount.confirm_text}</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(
	null,
	{
		closeModal,
		updateModifiers
	}
)(ModalDiscount);
