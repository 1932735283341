/**
 * @ The external dependecies.
 */
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import localStorage from 'redux-persist/lib/storage';

/**
 * @ The internal dependecies.
 */
import reducer from './reducer';
import effects from './effects';

/**
 * @ Setup the middlewares.
 */
const sagaMiddleware = createSagaMiddleware()
const middleware = applyMiddleware(sagaMiddleware);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * Create a store instance.
 *
 * @param  {Object} [state]
 * @param  {Object} options
 * @return {Object}
 */

const persistConfig = {
    key: 'grab_oat_app_state',
    storage: localStorage,
    stateReconciler: autoMergeLevel2,
    whitelist: ['cart', 'checkout', 'tab', 'survey', 'loyalty', 'questionnaire', 'donation'] 
}

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(persistedReducer, {}, composeEnhancers(
	middleware
));

/**
 * @ Enable Hot Module Replacement
 */
if (module.hot && process.env.NODE_ENV !== 'production') {
    console.log('HMR Enabled for Reducers');
    module.hot.accept('./reducer', () => {
        console.log('HMR Reducers');
        const newReducer = persistReducer(persistConfig, reducer);
        store.replaceReducer(newReducer);
    });
}

export const persistor = persistStore(store);

sagaMiddleware.run(effects);

//--- Cart Data ----
//storeWaypointID (e.g 10000) = cart.data.storeWaypointID
//kobp (e.g grab_tabletop_{storeWaypointID}) = cart.data.kobp
//storeId (e.g 10) = cart.data.storeId

//--- Table Data ---
//tableId (e.g 15907) = cart.data.waypointOrderLocation
//tableNumber/tableName (e.g 1) = checkout.tableNumber

//--- Tab Data ---
//authKey = tab.authKey
//tabId = tab.posOpenTicketID