import { isValidPhoneNumber } from 'react-phone-number-input'
import { lazy } from 'react';

export const updateObject = (oldObject, updatedValues) => {
    return {
        ...oldObject,
        ...updatedValues
    }
}; 

export const appendArray = (oldArray, newArrayValue) => {
    return [
        ...oldArray,
        ...newArrayValue
    ]
}; 

export const checkValidity = (value, rules) => {
    let isValid = true;

    if (!rules) {
        return true;
    }

    if (rules.required) {
        isValid = value !== undefined && value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail) {
        isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value.trim()) && isValid;
    }

    if (rules.isNumber) {
        isValid = /^\d+$/.test(value.trim()) && isValid;
    }

    if (rules.isCurrency) {
        isValid = value && /^((\d+)?(?:\.\d{1,2})?)$/.test(value.trim()) && isValid;
    }

    if (rules.isMobile) {
        isValid = value !== undefined && isValidPhoneNumber(value.trim()) && isValid;
    }

    if (rules.isMobileNoCountryCode) {
        isValid = /^\d{10}$/.test(value.trim()) && isValid;
    }

    return isValid;
}

export const Aux = (props) => { return props.children; };

export const createUUID = () => {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";

    var uuid = s.join("");
    return uuid;
}

export const loadDynamicScript = (scriptId, url, callback, onError) => {
    const existingScript = document.getElementById(scriptId);

    if (existingScript) {
        existingScript.parentNode.removeChild(existingScript);
    }

    const script = document.createElement('script');
    script.src = url; // URL for the third-party library being loaded.
    script.id = scriptId; // e.g., googleMaps or stripe
    document.head.appendChild(script);

    script.onload = () => {
        if (callback) callback();
    };

    script.onerror = () => {
        if (onError) onError();
    };
};

export const multiLazy = moduleLoaderArray => {
    const promises = Promise.all(moduleLoaderArray.map(loader => loader()));
    return moduleLoaderArray.map((m, index) =>
        lazy(() => promises.then(results => results[index]))
    );
};



export const getGetSmsOptInEnabledFromConfig = (mpconfigs) => {
    return getValueForConfig("SMS_OPT_IN", mpconfigs);
};

export const getGetMarketingOptInEnabledFromConfig = (mpconfigs) => {
    return getValueForConfig("MARKETING_OPT_IN", mpconfigs);
};

export const getGetPhoneRequiredFromConfig = (mpconfigs) => {
    return getValueForConfig("REQUIRE_PHONE", mpconfigs);
};

export const getGetEmailRequiredFromConfig = (mpconfigs) => {
    return getValueForConfig("REQUIRE_EMAIL", mpconfigs);
};

export const getGetG4IDFromConfig = (mpconfigs) => {
    return getValueForConfig("G4_ID", mpconfigs);
};

//export const getGetAlcoholEnabledFromConfig = (mpconfigs) => {
//    return getValueForConfig("ALCOHOL_ENABLED", mpconfigs);
//};

export const getGetTipEnabledFromConfig = (mpconfigs) => {
    return getValueForConfig("TIPS_ENABLED", mpconfigs);
};

export const getValueForConfig = (configTypeName, configArray) => {
    var selectedConfigArray = configArray.find(item => item.ConfigTypeName === configTypeName);
    if (selectedConfigArray) {
        if (selectedConfigArray.ConfigValueTypeName === "bool")
            return selectedConfigArray.MarketPlaceConfigValue === "true";
        else if (selectedConfigArray.ConfigValueTypeName === "int" ||
            selectedConfigArray.ConfigValueTypeName === "long")
            return +selectedConfigArray.MarketPlaceConfigValue;
        else
            return selectedConfigArray.MarketPlaceConfigValue;
    }
    else { return ""; }
};