/**
 * @ The external dependencies.
 */
 import {
    all,
    call,
    takeLatest
} from 'redux-saga/effects';

import { sendPenniesCalculationRequest, receivePenniesCalculationResponse } from './actions';
import makeRequest from '../../../lib/helpers/api-request';

export function* sendPenniesCalculationRequestWorker(action) {
    const payload = {
        merch: action.payload.merch,
        amount: action.payload.amount,
        currency: action.payload.currency, 
        token: action.payload.token
    };
    
    yield call(makeRequest, {
        endpoint: 'getPenniesCalculation',
        payload: payload,
        requestAction: action,
        receiveAction: receivePenniesCalculationResponse
    });
}

/**
 * Start all workers.
 *
 * @return {Generator}
 */
 export default function* foreman() {
    yield all([
        takeLatest(sendPenniesCalculationRequest, sendPenniesCalculationRequestWorker)
    ]);
}