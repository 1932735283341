/**
 * @ The external dependecies.
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/**
 * @ The internal dependecies.
 */
import MenuProduct from 'views/menu/menu-product';

/**
 * Class for menu item.
 *
 * @class MenuItem (name)
 */
class MenuSubCategory extends Component {
    state = {
       toggle: false
    }

	totalSelectedCount(id) {
		let totalCount = 0;

		// eslint-disable-next-line
		this.props.selectedProducts.find(product => {
            if (product.inventoryItemID === id) {
                totalCount = totalCount + product.quantity;
			}
		});

		return totalCount !== 0 ? totalCount : null;
    }

	isProductInRange = (product) => {
		if (!this.props.dayPartMsgEnabled || !product.showDayPartIcon) return false;

		const dayPartItems = this.props.products.filter(item => item.showDayPartIcon);
		const sortedItems = dayPartItems ? dayPartItems.sort((a, b) => { return new Date(a.endTimeLocalString) - new Date(b.endTimeLocalString); }) : null;

		if (!sortedItems || sortedItems.length === 0) return false;

		const currentDateTime = new Date(this.props.serverTimeLocal);
		const endDateTime = new Date(product.endTimeLocalString);

		const diff = Math.round(((endDateTime.getTime() - currentDateTime.getTime()) / 1000) / 60);
		const inRange = diff < this.props.dayPartMsgWarningMin && diff > 0;

		return inRange;
	}

    componentDidMount() {

	}

	handleClick = (e) => {
        e.preventDefault();

		this.setState(state => ({
			toggle: !state.toggle
		}));
	}

	render() {
        const { category, products, id } = this.props;

		return (
			<div id={id} className="sub-category">
				<div className="sub-category__head" onClick={this.handleClick}>
					<div>
						<h6 className="sub-category__title">{category.inventorySubTitleDescription}</h6>

						<div className="sub-category__entry">
							<p>{category.detailsDescription}</p>
						</div>
					</div>
					<i className={'sub-category__icon fas ' + (this.state.toggle ? 'fa-chevron-down' : 'fa-chevron-right')}></i>
				</div>

				<div className="sub-category__body">
					{this.state.toggle && products.map((product, index) => {
						return <MenuProduct key={product.inventoryItemID + index} showCalories={this.props.showCalories} inventoryTitleID={this.props.id} selectedProducts={this.props.selectedProducts} product={product} alcoholMealLimitEnabled={this.props.alcoholMealLimitEnabled} onItemClick={this.props.onItemClick} isCombo={this.props.isCombo(product.inventoryItemID)} inRange={this.isProductInRange(product)} />
					})}
				</div>
			</div>
		);
	}
}

export default MenuSubCategory;
