/**
 * @ The external dependencies.
 */
import { handleActions } from 'redux-actions';

/**
 * @ The internal dependencies.
 */
import { updateObject } from '../../../lib/utility';

import {
	openModal,
    closeModal,
    redirectToError,
    setLoadingResources
} from './actions';

import {
    showSuggestionsModal
} from '../app/actions';

import {
    tabAuthorizationStart,
    tabAuthorizationEnd
} from '../tab/actions';

import {
    cartUpdateInitiated,
    cartUpdateCompleted,
    cartSaveCompleted,
    startCartSave,
    reciveCartData,
    receiveOrderConfirmationData
} from '../cart/actions';

import {
    sendTableCheckRequest,
    receiveTableCheckResponse
} from '../tablecheck/actions';

import {
    endRequestWithError
} from '../requests/actions';

import {
    sendLoadedValueAccountBalanceRequest,
    receiveLoadedValueAccountBalanceResponse
} from '../loadedvalueaccount/actions';

import {
    sendLoyaltyLoginRequestStart,
    sendLoyaltyLoginRequestEnd
} from '../loyalty/actions';

import { isSystemDown, isItemOffline} from '../cart/selectors'

/**
 * @ The reducer.
 */
const defaultState = {
	modal: {
		isOpen: false,
		type: null,
		data: null
    },
    showSpinnerCartSaveOrClearTab: false, //Shows Loading Spinner
    showSpinnerCartUpdate: false, //Shows Loading Spinner
    tabAuthorizing: false,
    savingCart: false,
    clearingTab: false,
    modalMessage: '',
    showModalMessage: null,
    redirectToError: null,
    redirectToHome: null,
    redirectToCart: null,
    loadingResources: false,
    loyaltyLoggingIn: false
}

//Modal
const onOpenModal = (state, action) => {
    return updateObject(state, {
        modal: {
            isOpen: true,
            type: action.payload.type,
            data: action.payload.data ? action.payload.data : null
        }
    });
}

const onCloseModal = (state, action) => {
    return updateObject(state, {
        modal: defaultState.modal
    });
}

//Tab Authorizing
const onTabAuthorizationStart = (state, action) => {
    return updateObject(state, {
        tabAuthorizing: true,
        showSpinnerCartUpdate: action.payload.showSpinner ? true : state.showSpinnerCartUpdate
    });
}

const onTabAuthorizationEnd = (state, action) => {
    return updateObject(state, {
        tabAuthorizing: false
    });
}

//Cart Calculation
const onCartUpdateInitiated = (state, action) => {
    if (action.payload && action.payload.showSpinner) {
        return updateObject(state, {
            showSpinnerCartUpdate: true
        });
    }

    return state;
}

const onCartUpdateCompleted = (state, action) => {
    if (action.payload && action.payload.showSpinner) {
        return updateObject(state, {
            showSpinnerCartUpdate: false
        });
    }

    return state;
}

const onReciveCartData = (state, action) => {
    const modalMessage = (action.payload.cartExceptions && action.payload.cartExceptions.length > 0) ? action.payload.cartExceptions[0].exception : '';
    const systemDown = isSystemDown(action.payload);

    return updateObject(state, {
        showModalMessage: modalMessage ? new Date() : state.showModalMessage,
        modalMessage: modalMessage,
        redirectToHome: systemDown ? new Date() : state.redirectToHome
    });
}

//Saving Cart
const onStartCartSave = (state, action) => {
    let updatedState = !action.payload.clearTab ? updateObject(state, { savingCart: true, clearingTab: false }) : updateObject(state, { savingCart: false, clearingTab: true });
    updatedState = action.payload.showSpinner ? updateObject(updatedState, { showSpinnerCartSaveOrClearTab: true }) : updatedState;
    return updatedState;
}

const onCartSaveCompleted = (state, action) => {
    return updateObject(state, {
        savingCart: false,
        clearingTab: false,
        showSpinnerCartSaveOrClearTab: false
    });
}

const onReceiveOrderConfirmationData = (state, action) => {
    const modalMessage = (action.payload.cartExceptions && action.payload.cartExceptions.length > 0) ? action.payload.cartExceptions[0].exception : '';
    const systemDown = isSystemDown(action.payload);
    const itemOffline = isItemOffline(action.payload);

    return updateObject(state, {
        showModalMessage: modalMessage ? new Date() : state.showModalMessage,
        modalMessage: modalMessage,
        redirectToHome: systemDown ? new Date() : state.redirectToHome,
        redirectToCart: itemOffline ? new Date() : state.redirectToCart
    });
}

//Suggestions
const onShowSuggestionsModal = (state, action) => {
    return updateObject(state, {
        showSpinnerCartUpdate: true
    });
}

//Table Check
const onSendTableCheckRequest = (state, action) => {
    if (action.payload.showSpinner) {
        return updateObject(state, {
            showSpinnerCartSaveOrClearTab: true
        });
    }

    return state;
}

const onReceiveTableCheckResponse = (state, action) => {
    return updateObject(state, {
        savingCart: false,
        clearingTab: false,
        showSpinnerCartSaveOrClearTab: false
    });
}

//Loaded Value Account Balance Check
const onSendLoadedValueAccountBalanceCheck = (state, action) => {
    if (action.payload.showSpinner) {
        return updateObject(state, {
            showSpinnerCartSaveOrClearTab: true
        });
    }

    return state;
}

const onRecieveLoadedValueAccountBalanceCheckResponse = (state, action) => {
    return updateObject(state, {
        showSpinnerCartSaveOrClearTab: false
    });
}

//Loyalty
const onSendLoyaltyLoginRequestStart = (state, action) => {
    return updateObject(state, {
        loyaltyLoggingIn: true
    });
}

const onSendLoyaltyLoginRequestEnd = (state, action) => {
    return updateObject(state, {
        loyaltyLoggingIn: false
    });
}

//Errors
const onEndRequestWithError = (state, action) => {
    if (action.payload.id === `'${sendTableCheckRequest}'`) {
        return updateObject(state, {
            savingCart: false,
            clearingTab: false,
            showSpinnerCartSaveOrClearTab: false
        });
    }

    if (action.payload.id === `'${tabAuthorizationStart}'`) {
        return updateObject(state, {
            tabAuthorizing: false
        });
    }

    return state;
}

const onRedirectToError = (state, action) => {
    return updateObject(state, {
        redirectToError: new Date()
    });
}

const onSetLoadingResources = (state, action) => {
    return updateObject(state, {
        loadingResources: action.payload
    });
}

const ui = handleActions({
    [openModal]: (state, action) => onOpenModal(state, action),
    [closeModal]: (state, action) => onCloseModal(state, action),

    [tabAuthorizationStart]: (state, action) => onTabAuthorizationStart(state, action),
    [tabAuthorizationEnd]: (state, action) => onTabAuthorizationEnd(state, action),

    [sendLoyaltyLoginRequestStart]: (state, action) => onSendLoyaltyLoginRequestStart(state, action),
    [sendLoyaltyLoginRequestEnd]: (state, action) => onSendLoyaltyLoginRequestEnd(state, action),

    [cartUpdateInitiated]: (state, action) => onCartUpdateInitiated(state, action),
    [cartUpdateCompleted]: (state, action) => onCartUpdateCompleted(state, action),

    [startCartSave]: (state, action) => onStartCartSave(state, action),
    [cartSaveCompleted]: (state, action) => onCartSaveCompleted(state, action),
    [reciveCartData]: (state, action) => onReciveCartData(state, action),

    [receiveOrderConfirmationData]: (state, action) => onReceiveOrderConfirmationData(state, action),

    [sendTableCheckRequest]: (state, action) => onSendTableCheckRequest(state, action),
    [receiveTableCheckResponse]: (state, action) => onReceiveTableCheckResponse(state, action),
    [endRequestWithError]: (state, action) => onEndRequestWithError(state, action),

    [showSuggestionsModal]: (state, action) => onShowSuggestionsModal(state, action),

    [redirectToError]: (state, action) => onRedirectToError(state, action),

    [setLoadingResources]: (state, action) => onSetLoadingResources(state, action),

    [sendLoadedValueAccountBalanceRequest]: (state, action) => onSendLoadedValueAccountBalanceCheck(state, action),
    [receiveLoadedValueAccountBalanceResponse]: (state, action) => onRecieveLoadedValueAccountBalanceCheckResponse(state, action)

}, defaultState);

export default ui;