export const initialize = () => {
    const css = document.createElement("style");
    css.type = 'text/css';
    css.innerHTML = '@keyframes highlight { 0% { background: yellow; } 100% { background: none; } } .highlight { color:white !important; background: #308CFA !important; /*animation: highlight 2s;*/ }';
    document.head.appendChild(css);

    let target = document.body;
    const options = {
        childList: true,
        attributes: false,
        characterData: true,
        subtree: true,
    };

    const observer = new MutationObserver(mCallback);

    function mCallback(mutations) {
        for (let mutation of mutations) {
            switch (mutation.type) {
                case 'childList':
                    if (mutation.target.classList.contains("editable")) {
                        console.log(mutation);
                        mutation.target.classList.add("highlight");
                        setTimeout(function () { mutation.target.classList.remove("highlight"); }, 2000);
                    }
                    break;
                case 'characterData':
                    if (mutation.target.parentElement.tagName !== 'BODY') {
                        console.log(mutation);
                        mutation.target.parentElement.classList.add("highlight");
                        setTimeout(function () { mutation.target.parentElement.classList.remove("highlight"); }, 2000);
                    }
                    break;
                case 'attributes':
                    break;
                default:
                    break;
            }
        }
    }

    observer.observe(target, options);
}