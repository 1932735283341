/* eslint-disable */

/**
 * @ The external dependecies.
 */
import React, { Component } from 'react';
import Cleave from 'cleave.js/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import { localizeNumber } from '../../assets/resources/resources-manager';

/**
 * Class for QtyField.
 *
 * @class QtyField (name)
 */
class QtyField extends Component {
	handleMinusClick = e => {
		e.preventDefault();

		const minValue = this.qty_field.element.min;

		if ( minValue ) {
			if ( this.qty_field.element.value <= minValue ) {
				this.qty_field.element.value = minValue;
			} else {
				this.qty_field.element.value--
			}
		} else {
			this.qty_field.element.value--
		}

		this.emitOnChange();
	}

	handlePlusClick = e => {
		e.preventDefault();

		const maxValue = this.qty_field.element.max;

		if ( maxValue ) {
			if ( this.qty_field.element.value >= maxValue ) {
				this.qty_field.element.value = maxValue;
			} else {
				this.qty_field.element.value++
			}	
		} else {
			this.qty_field.element.value++
		}

		this.emitOnChange();
	}

	emitOnChange = () => {
		var event = new Event('input', { bubbles: true});
		this.qty_field.element.dispatchEvent(event);

		this.props.onChange(event);
	}

	render() {
		return (
			<div className={classnames('quantity', [
					this.props.modifier && `quantity--${this.props.modifier}`,
					this.props.variant && `quantity--${this.props.variant}`
				])}>

				{ !(!!this.props.withanimation && this.props.value === 1) && 
					<button
						aria-label={window.resources.product.decrease_quantity_text}
						className='quantity__btn quantity__btn--minus'
						onClick={this.handleMinusClick}
					>
						<FontAwesomeIcon icon={faMinus} />
					</button>
				}

				<Cleave
					ref={el => this.qty_field = el}
					style={{display:'none'}}
					className="quantity__field"
					readOnly
					options={{
						numeral: true,
						delimiter: ''
					}}
					{...this.props}
				/>
				<input aria-label={window.resources.product.quantity_text} className="quantity__field" readOnly modifier="small-alt" value={localizeNumber(this.props.value)} variant="secondary"/>

				<button
					aria-label={window.resources.product.increase_quantity_text}
					className="quantity__btn quantity__btn--plus"
					onClick={this.handlePlusClick}
				>
					<FontAwesomeIcon icon={faPlus} />
				</button>
			</div>
		)
	}
}

export default QtyField;
