/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/**
 * @ The internal dependencies.
 */
import { closeModal } from 'store/state/ui/actions';
import * as features from '../../store/state/features/selectors';

/**
 * Class for modal warning.
 *
 * @class ModalWarning (name)
 */
class ModalBrowse extends Component {
    state = {
        backPressed: false,
        confirmPressed: false
    }

    replaceVariables(html) {
        return html.replace("{this.props.startText}", window.resources.home.start_text);
    }

    handleButtonClick = (e) => {
        this.setState({ confirmPressed: true }, () => {
            this.props.closeModal();
            if (this.props.data.fun) {
                this.props.data.fun();
            }
        });
    }

    handleCancelClick = () => {
        this.props.closeModal();

        if (this.props.data.funCancel) {
            this.props.data.funCancel();
        }
    }

    handleBackOrForwardButton = () => {
        this.setState({ backPressed: true });
        this.handleCancelClick();
    }
    
    componentDidMount() {
        this.props.history.push(this.props.location.pathname);
        window.onpopstate = this.handleBackOrForwardButton;
    }

    componentWillUnmount() {
        window.onpopstate = null;
        if (!this.state.backPressed && !this.state.confirmPressed) {
            this.props.history.goBack();
        }
    }

	render() {
		return (
			<div className="modal-frame modal-frame--warning modal-frame--card">
				<div className="modal-frame__body">
					<div className="modal-frame__card">
						<div className="modal-frame__head">
                            <div className="modal-frame__head">
                                <h6 className="modal-frame__title">{window.resources.browse_modal.title_text}</h6>
                            </div>

                            <p>{this.props.orderingModeEnabled ? this.replaceVariables(window.resources.browse_modal.ordering_text) : window.resources.browse_modal.browse_text}</p>
						</div>

                        <div className="modal-frame__actions modal-frame__actions--flex">
                            <button
                                onClick={this.handleCancelClick}
                                className="btn btn--secondary">{window.resources.browse_modal.back_text}</button>
							<button
								onClick={this.handleButtonClick}
                                className="btn">{window.resources.browse_modal.confirm_text}</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(connect(
	(state) => ({
        data: state.ui.modal.data,
        orderingModeEnabled: features.orderingModeEnabled(state)
	}),
	{
		closeModal
	}
)(ModalBrowse));
