/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

/**
 * @ The internal dependecies.
 */
import Slider from 'components/slider/slider';
import StoreAssetSvg from 'components/common/store-asset-svg';
import withDataValidation from 'lib/helpers/hocs/with-data-validation';
import { openModal } from 'store/state/ui/actions';
import {
  MODAL_TABLE_NUMBER,
  MODAL_QUESTIONNAIRE,
  MODAL_LOYALTY_LOGIN,
  MODAL_CHOOSE_DINEIN_TAKEAWAY,
  MODAL_DINE_IN,
  MODAL_TAKEAWAY,
} from 'lib/constants';
import { startCreateCustomer } from 'store/state/customer/actions';
import { clearCart, clearCartPartial, startCartUpdate, updateMode } from 'store/state/cart/actions';
import {
  sendTableCheckRequest,
  clearTableCheck,
  setDineInTakeAwaySelected,
  setSommelierSelected,
} from 'store/state/tablecheck/actions';
import { clearTab } from 'store/state/tab/actions';
import { clearQuestionnaire } from 'store/state/questionnaire/actions';
import { clearCheckout } from 'store/state/checkout/actions';
import { clearLoadedValueAccountBalanceResponse } from 'store/state/loadedvalueaccount/actions';

import {
  MODAL_ALLERGENS,
  MODAL_WARNING,
  MODAL_TAB_VERIFICATION,
  MODAL_TAB_VERIFICATION_AUTO,
  MODAL_LOADED_VALUE_ACCOUNT_BALANCE_CHECK,
  MODAL_LOADED_VALUE_ACCOUNT_BALANCE_RESPONSE,
  MODAL_BROWSE,
  MODE_BROWSE,
  MODE_PAYTABNOW,
  MODE_ORDER,
} from '../../lib/constants';

import { hasTabOpen } from '../../store/state/tab/selectors';
import * as features from '../../store/state/features/selectors';
import SelectLanguage from 'components/localization/select-language';
import { showLanguageWidget } from 'lib/helpers/language';
import config from '../../config';
import LoyaltyLogo from '../../assets/svg/loyalty.svg';

/**
 * Class for home.
 *
 * @class Home (name)
 */

class Home extends Component {
  replaceVariables(html) {
    return html
      .replace('{this.props.termsUrl}', this.props.termsUrl)
      .replace(
        '{this.props.startText}',
        this.props.orderingModeEnabled
          ? window.resources.home.start_text
          : this.props.payTabNowModeEnabled
          ? window.resources.home.pay_text
          : window.resources.home.browse_text
      )
      .replace(
        '{this.props.waitStartTime}',
        this.props.waitTimeInfo ? this.props.waitTimeInfo.WaitTime : ''
      )
      .replace(
        '{this.props.waitEndTime}',
        this.props.waitTimeInfo ? this.props.waitTimeInfo.WaitTime + 5 : ''
      );
  }

  clearData = () => {
    this.props.clearCart();
    this.props.clearTab();
    this.props.clearCheckout();
    this.props.clearQuestionnaire();
  };

  isSommelierSelected = false;

  startOrderResetSommelierExperience = () => {
    this.props.setSommelierSelected(false);
    this.isSommelierSelected = false;
    this.startOrderHandler();
  };
  startSommelierExperience = () => {
    this.props.setSommelierSelected(true);
    this.isSommelierSelected = true;
    this.startOrderHandler();
  };
  startOrderHandler = () => {
    this.props.updateMode(MODE_ORDER);

    this.props.startCreateCustomer();
    if (this.props.config.bStoreIsCurrentlyOpen) {
      if (!this.props.match.params.tableNumber) {
        this.clearData();
        if (this.props.config.bStoreIsClosingSoon) {
          //console.log('Store closing soon. Open Modal Table Number');
          this.props.openModal({
            type: MODAL_WARNING,
            data: {
              message: window.resources.messages.kitchen_closing_soon_html,
              btnText: window.resources.modal.confirm_text,
              fun: () => {
                if (this.props.questions) {
                  //console.log('Open PreOrder Questionnaire');
                  return this.props.openModal({
                    type: MODAL_QUESTIONNAIRE,
                    data: {
                      fun: () => {
                        return this.chooseDineInOrTakeAwayHandler();
                      },
                    },
                  });
                } else {
                  //console.log('Open Modal Table Number');
                  return this.chooseDineInOrTakeAwayHandler();
                }
              },
            },
          });
        } else {
          if (this.props.questions) {
            //console.log('Open PreOrder Questionnaire');
            this.props.openModal({
              type: MODAL_QUESTIONNAIRE,
              data: {
                fun: () => {
                  return this.chooseDineInOrTakeAwayHandler();
                },
              },
            });
          } else {
            //console.log('Open Modal Table Number');
            return this.chooseDineInOrTakeAwayHandler();
          }
        }
      } else {
        if (this.props.tableCheckException) {
          this.props.openModal({
            type: MODAL_WARNING,
            data: {
              message: this.props.tableCheckException,
              btnText: window.resources.modal.confirm_text,
            },
          });
        } else if (
          this.props.validTableNumber &&
          this.props.config.bStoreIsCurrentlyOpen &&
          !this.props.config.bStoreIsClosingSoon
        ) {
          //console.log('Table Number In Url valid');
          if (this.props.questions) {
            //console.log('Open PreOrder Questionnaire');
            this.props.openModal({
              type: MODAL_QUESTIONNAIRE,
              data: {
                fun: () => {
                  return this.validTableNumberHandler();
                },
              },
            });
          } else {
            this.validTableNumberHandler();
          }
        } else if (this.props.config.bStoreIsClosingSoon) {
          //console.log('Table Number In Url valid and store closing soon');
          this.props.openModal({
            type: MODAL_WARNING,
            data: {
              message: window.resources.messages.kitchen_closing_soon_html,
              btnText: window.resources.modal.confirm_text,
              fun: () => {
                if (this.props.questions) {
                  //console.log('Open PreOrder Questionnaire');
                  return this.props.openModal({
                    type: MODAL_QUESTIONNAIRE,
                    data: {
                      fun: () => {
                        return this.validTableNumberHandler();
                      },
                    },
                  });
                } else {
                  return this.validTableNumberHandler();
                }
              },
            },
          });
        } else {
          if (this.props.questions) {
            //console.log('Open PreOrder Questionnaire');
            this.props.openModal({
              type: MODAL_QUESTIONNAIRE,
              data: {
                fun: () => {
                  return this.validTableNumberHandler();
                },
              },
            });
          } else {
            this.validTableNumberHandler();
          }
        }
      }
    } else {
      this.kitchenClosedHandler();
    }
  };

  payTabNowHandler = () => {
    this.props.updateMode(MODE_PAYTABNOW);
    this.props.startCreateCustomer();

    if (!this.props.match.params.tableNumber) {
      this.clearData();

      //console.log('Open Modal Table Number');
      this.props.openModal({
        type: MODAL_TABLE_NUMBER,
      });
    } else {
      this.validTableNumberHandler(MODE_PAYTABNOW);
    }
  };

  throwError = () => {
    throw 'Sentry Error';
  };

  throwConsoleError = () => {
    console.error('Sentry console error v2');
  };
  throwConsoleWarning = () => {
    console.warn('Sentry console warning');
  };

  browseHandler = () => {
    this.props.updateMode(MODE_BROWSE);
    this.props.startCreateCustomer();

    if (!this.props.match.params.tableNumber) {
      this.clearData();
    }

    this.props.openModal({
      type: MODAL_BROWSE,
      data: {
        fun: () => {
          this.props.history.push('/categories');
          this.props.clearTableCheck();
        },
      },
    });
  };

  chooseDineInOrTakeAwayHandler = () => {
    if (!this.props.CYOAEnabled) {
      if (this.props.dineInNameRequired || this.props.dineInNameOptional) {
        return this.props.openModal({
          type: MODAL_DINE_IN,
        });
      }
      return this.props.openModal({
        type: MODAL_TABLE_NUMBER,
      });
    }

    return this.props.openModal({
      type: MODAL_CHOOSE_DINEIN_TAKEAWAY,
    });
  };

  kitchenClosedHandler = () => {
    this.props.openModal({
      type: MODAL_WARNING,
      data: {
        message: window.resources.messages.kitchen_closed_html,
        btnText: window.resources.modal.confirm_text,
      },
    });
  };

  allergenNotificationHandler = () => {
    this.props.openModal({
      type: MODAL_ALLERGENS,
      data: {
        title: 'Allergen Information',
      },
    });
  };

  validTableNumberHandler = (mode = this.props.mode) => {
    if (this.props.CYOAEnabled && !this.props.dineInTakeAwaySelected) {
      this.chooseDineInOrTakeAwayHandler();
      return;
    }
    if (
      this.props.takeAwayNameRequired &&
      !this.props.CYOAEnabled &&
      !this.props.dineInTakeAwaySelected &&
      this.isVK()
    ) {
      this.props.openModal({
        type: MODAL_TAKEAWAY,
      });
      return;
    }
    if (
      (this.props.dineInNameOptional || this.props.dineInNameRequired) &&
      !this.props.CYOAEnabled &&
      !this.props.dineInTakeAwaySelected
    ) {
      this.props.openModal({
        type: MODAL_DINE_IN,
      });
      return;
    }
    if (mode === MODE_PAYTABNOW) {
      if (this.props.hasTabOpen) {
        this.props.openModal({
          type: MODAL_TAB_VERIFICATION_AUTO,
          data: {
            onAuthorized: () => {
              //Authorization + Cart Update
              this.props.history.push('/tab/view');
              this.props.clearTableCheck();

              //this.props.startCartUpdate();
            },
          },
        });
      } else {
        if (this.props.browseModeEnabled) {
          this.props.openModal({
            type: MODAL_WARNING,
            data: {
              allowRedirect: true,
              message: window.resources.pay_tab_now_modal.browse_text,
              btnText: window.resources.pay_tab_now_modal.confirm_text,
              btnTextCancel: window.resources.pay_tab_now_modal.cancel_text,
              fun: () => {
                return this.browseHandler();
              },
            },
          });
        } else {
          this.props.openModal({
            type: MODAL_WARNING,
            data: {
              message: window.resources.pay_tab_now_modal.pay_tab_now_text,
              btnText: window.resources.modal.confirm_text,
            },
          });
        }
      }
    } else if (mode === MODE_ORDER) {
      //check if the table has a tab. if so authorize
      if (this.props.hasTabOpen) {
        this.props.openModal({
          type: MODAL_TAB_VERIFICATION,
          data: {
            onAuthorized: () => {
              //Authorization + Cart Update
              this.pushCategories(false);
            },
            onNewOrder: () => {
              //Save + Table Check with Spinner
              this.pushCategories(true);
            },
          },
        });
      } else {
        this.props.sommelierSelected || this.isSommelierSelected
          ? this.pushSommelier(true)
          : this.pushCategories(true);
      }
    }
  };

  pushCategories = (updateCart) => {
    this.props.history.push('/categories');
    this.props.clearTableCheck();
    if (updateCart) {
      this.props.startCartUpdate();
    }
  };

  pushSommelier = (updateCart) => {
    this.props.history.push('/sommelier');
    this.props.clearTableCheck();
    if (updateCart) this.props.startCartUpdate();
  };

  loadedValueAccountBalanceHandler = () => {
    this.props.openModal({
      type: MODAL_LOADED_VALUE_ACCOUNT_BALANCE_CHECK,
    });
  };

  loyaltyLoginHandler = () => {
    this.props.openModal({
      type: MODAL_LOYALTY_LOGIN,
      data: {
        onAuthorized: this.startOrderHandler,
      },
    });
  };

  isVK() {
    return (
      this.props.match.params.tableNumber === 'vk' || this.props.match.params.tableNumber === 'VK'
    );
  }

  isWaitTimeAlert = () => {
    debugger;
    return this.props.waitTimeInfo.WaitTime + 5 > this.props.waitTimeInfo.AlertThreshold;
  };

  componentDidMount() {
    if (
      this.props.CYOAEnabled &&
      this.props.dineInTakeAwaySelected &&
      this.props.tableCheckSent &&
      this.props.validTableNumber
    ) {
      this.validTableNumberHandler();
      return;
    }
    if (
      !this.props.CYOAEnabled &&
      this.props.dineInTakeAwaySelected &&
      this.props.tableCheckSent &&
      this.props.validTableNumber
    ) {
      this.validTableNumberHandler();
      return;
    }
    if (this.props.match.params.tableNumber) {
      console.log('1');
      if (!this.props.tableCheckSent) {
        console.log('2');

        //console.log('Table Number In Url - Send Check Request');
        this.props.updateMode(MODE_ORDER);
        this.clearData();
        this.props.sendTableCheckRequest({
          showSpinner: true,
          getOpenTickets: this.props.tabFeatureEnabled,
          tableNumber: this.props.match.params.tableNumber,
        });
      } else if (this.props.invalidTableNumber) {
        if (this.props.tableCheckException) {
          //console.log('Table Number In Url invalid - Redirecting to /');
          this.props.history.push('/');
        }
      }
    } else {
      if (this.props.validTableNumber) {
        //console.log('Modal Table Number Valid');
        this.validTableNumberHandler();
      } else if (this.props.invalidTableNumber) {
        //console.log('Modal Table Number Invalid');
        this.props.openModal({
          type: MODAL_WARNING,
          data: {
            message: this.props.tableCheckException,
            btnText: window.resources.modal.confirm_text,
          },
        });
      } else {
        this.props.updateMode(MODE_ORDER);
      }
    }

    if (this.props.loadedValueAccountBalanceCheckResponse === 'failed') {
      this.props.openModal({
        type: MODAL_WARNING,
        data: {
          message: window.resources.messages.error_please_try_again_html,
          btnText: window.resources.modal.confirm_text,
        },
      });
      this.props.clearLoadedValueAccountBalanceResponse();
    } else if (this.props.loadedValueAccountBalanceCheckResponse !== false) {
      this.props.openModal({
        type: MODAL_LOADED_VALUE_ACCOUNT_BALANCE_RESPONSE,
      });
    }
  }

  render() {
    return (
      <div className='intro' style={{}}>
        <header className='intro__head'>
          <div className='shell'>
            {showLanguageWidget() && <SelectLanguage showDescription={true} inline={false} />}
            <div style={{ clear: 'both' }} />
            <span className='intro__logo'>
              <StoreAssetSvg path='/logo.svg' />
            </span>
            {this.props.waitTimeEnabled &&
              this.props.waitTimeInfo &&
              this.props.waitTimeInfo.WaitTime &&
              this.props.waitTimeInfo.AlertThreshold && (
                <div
                  className={classnames('wait-time-msg', {
                    'wait-time-alert':
                      this.props.waitTimeInfo.WaitTime + 5 > this.props.waitTimeInfo.AlertThreshold,
                  })}
                >
                  {this.props.waitTimeInfo.WaitTime + 5 > this.props.waitTimeInfo.AlertThreshold ? (
                    <React.Fragment>
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.replaceVariables(window.resources.wait_time.alert_text),
                        }}
                      />
                    </React.Fragment>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.replaceVariables(window.resources.wait_time.standard_text),
                      }}
                    />
                  )}
                </div>
              )}
          </div>
        </header>

        <div className='intro__body'>
          <div className='intro__body-inner'>
            <div className='shell'>
              <div className='intro__entry'>
                <p />
              </div>
            </div>
          </div>

          <div className='intro__slider'>
            <div className='shell shell--no-padding'>
              <Slider
                className='slider-products'
                settings={{
                  infinite: false,
                  centerMode: true,
                  centerPadding: '37px',
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  arrows: false,
                  dots: true,
                  autoplay: true,
                  autoplaySpeed: 10000,
                }}
              >
                {this.props.config.mobileFeaturedItems.map((item) => (
                  <div key={item.inventoryItemID} className='slider__slide'>
                    <div
                      className='product'
                      style={{
                        backgroundImage: `url('${encodeURI(
                          (item.imageV2 && item.imageV2.launch_1_1) || item.inventoryItemImageName
                        )}')`,
                      }}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          <div className='intro__holder'>
            <div className='shell'>
              <div className='intro__actions'>
                <div
                  className='intro__text editable editable'
                  dangerouslySetInnerHTML={{ __html: window.resources.home.discount_html }}
                />
                {this.props.sommelierEnabled
                  ? this.props.orderingModeEnabled && (
                      <div className='multi__primary'>
                        <button
                          onClick={this.startOrderResetSommelierExperience}
                          className='btn btn--md'
                          dangerouslySetInnerHTML={{ __html: window.resources.home.start_text }}
                        />
                        <button
                          onClick={this.startSommelierExperience}
                          className='btn btn--md'
                          dangerouslySetInnerHTML={{ __html: window.resources.home.sommelier_text }}
                        />
                      </div>
                    )
                  : this.props.orderingModeEnabled && (
                      <button
                        tabIndex={1}
                        aria-label={window.resources.home.start_text}
                        onClick={this.startOrderHandler}
                        className='btn btn--md'
                        dangerouslySetInnerHTML={{ __html: window.resources.home.start_text }}
                      />
                    )}
                {this.props.payTabNowModeEnabled &&
                  !(
                    this.props.match.params.tableNumber === 'vk' ||
                    this.props.match.params.tableNumber === 'VK'
                  ) && [
                    <br key='br' />,
                    <button key='button' onClick={this.payTabNowHandler} className='btn btn--md'>
                      {window.resources.home.pay_text}
                    </button>,
                  ]}
                {this.props.browseModeEnabled && [
                  <br key='br' />,
                  <button key='button' onClick={this.browseHandler} className='btn btn--md'>
                    {window.resources.home.browse_text}
                  </button>,
                ]}
                {window.styleWaypointIDs.includes('20000') && (
                  <React.Fragment>
                    <br />{' '}
                    <button key='buttonSentry' onClick={this.throwError} className='btn btn--md'>
                      Test Sentry
                    </button>
                    <br />{' '}
                    <button
                      key='buttonSentryConsole'
                      onClick={this.throwConsoleError}
                      className='btn btn--md'
                    >
                      Test Sentry Console
                    </button>
                    <br />{' '}
                    <button
                      key='buttonSentryConsoleWarning'
                      onClick={this.throwConsoleWarning}
                      className='btn btn--md'
                    >
                      Test Sentry Console Warning
                    </button>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>

        <footer className='intro__foot'>
          <div className='shell'>
            {this.props.loyaltyFeatureEnabled && (
              <div className='intro__outro editable'>
                <a onClick={this.loyaltyLoginHandler}>
                  <img
                    style={{ marginBottom: '5px', marginRight: '5px' }}
                    src={LoyaltyLogo}
                    alt='Loyalty'
                  />
                  {window.resources.home.loyalty_text}
                </a>
              </div>
            )}
            {window.resources.allergen.html &&
              !(
                window.resources.home.allergen_link_overwrite_text &&
                window.resources.home.allergen_link_overwrite_ext_url
              ) && (
                <div className='intro__outro editable'>
                  <a onClick={this.allergenNotificationHandler}>
                    {window.resources.home.allergen_link_text}
                  </a>
                </div>
              )}
            {window.resources.home.allergen_link_overwrite_text &&
              window.resources.home.allergen_link_overwrite_ext_url && (
                <div className='intro__outro editable'>
                  <a href={window.resources.home.allergen_link_overwrite_ext_url} target='_blank'>
                    {window.resources.home.allergen_link_overwrite_text}
                  </a>
                </div>
              )}
            {this.props.loadedValueAccountEnabled && (
              <div className='intro__outro editable'>
                <a onClick={this.loadedValueAccountBalanceHandler}>
                  {window.resources.home.loaded_value_account_balance_check_text}
                </a>
              </div>
            )}
            <div
              className='intro__outro editable'
              dangerouslySetInnerHTML={{ __html: window.resources.home.footer_html }}
            />
            {/* <script id="CookieDeclaration" src="https://consent.cookiebot.com/96f8bc56-fe76-434d-86b6-b72c7965f9bb/cd.js" type="text/javascript" async></script> */}
            <div
              className='intro__outro editable'
              tabIndex={2}
              dangerouslySetInnerHTML={{
                __html: this.replaceVariables(window.resources.home.terms_html),
              }}
            />
          </div>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mode: state.cart.mode,
    termsUrl: state.app.config && state.app.config.TermsURL,
    hasTabOpen: hasTabOpen(state),
    tabFeatureEnabled: features.tabFeatureEnabled(state),
    tableCheckSent: state.tablecheck.validTableNumber || state.tablecheck.invalidTableNumber,
    validTableNumber: state.tablecheck.validTableNumber,
    invalidTableNumber: state.tablecheck.invalidTableNumber,
    tableCheckException: state.tablecheck.exception,
    questions:
      state.questionnaire.data &&
      state.questionnaire.data.contentList.length > 0 &&
      !state.questionnaire.submitted,
    loadedValueAccountEnabled: features.loadedValueAccountEnabled(state),
    loadedValueAccountBalanceCheckResponse:
      state.loadedvalueaccount.loadedValueAccountBalanceCheckResponse,
    orderingModeEnabled: features.orderingModeEnabled(state),
    payTabNowModeEnabled: features.payTabNowModeEnabled(state),
    browseModeEnabled: features.browseModeEnabled(state),
    CYOAEnabled: features.CYOAEnabled(state),
    sommelierEnabled: features.sommelierEnabled(state),
    dineInTakeAwaySelected: state.tablecheck.dineInTakeAwaySelected,
    sommelierSelected: state.tablecheck.sommelierSelected,
    loyaltyFeatureEnabled: features.loyaltyFeatureEnabled(state),
    takeAwayNameRequired: features.takeAwayNameRequired(state),
    dineInNameOptional: features.dineInNameOptional(state),
    dineInNameRequired: features.dineInNameRequired(state),
    waitTimeEnabled: state.app.config && state.app.config.WaitTimeEnabled,
    waitTimeInfo: state.app.config && state.app.config.WaitTimeInfo,
  };
};

export default compose(
  connect(
    mapStateToProps,
    {
      openModal,
      clearCart,
      clearCartPartial,
      startCartUpdate,
      startCreateCustomer,
      sendTableCheckRequest,
      clearTableCheck,
      clearTab,
      clearCheckout,
      updateMode,
      clearLoadedValueAccountBalanceResponse,
      setDineInTakeAwaySelected,
      setSommelierSelected,
      clearQuestionnaire,
    }
  ),
  withDataValidation
)(Home);
