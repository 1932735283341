/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/**
 * @ The internal dependencies.
 */
import { closeModal, setLoadingResources } from 'store/state/ui/actions';
import { getSupportedLanguageList } from 'lib/helpers/language';
import { setLanguage } from '../../store/state/localization/actions';
import * as resourcesManager from 'assets/resources/resources-manager';

/**
 * Class for modal language.
 *
 * @class ModalLanguage (name)
 */
class ModalLanguage extends Component {
    state = {
        language: window.language
    }
    handleLanguageSelect = (language) => {
        if (language !== window.language) {
            this.setState({ language: language });
            this.props.setLoadingResources(true);
            resourcesManager.setupResources(window.styleWaypointIDs, language, () => {
                this.props.setLoadingResources(false);
            }, () => {
                this.props.setLoadingResources(false);
            });
            this.props.setLanguage({
                language: language
            });
        }
        this.props.closeModal();
    }

    render() {
        const supportedLanguages = getSupportedLanguageList();
		return (
            <div className="modal-frame modal-frame--card">
                <div className="modal-frame__body">
                    <div className="modal-frame__card">
                        <div className="modal-frame__card-body">
                            <div className="radio-buttons">
                                {Reflect.ownKeys(supportedLanguages).map((language, i) => (
                                    <div key={language} className='radio'>
                                        <RadioButton 
                                            key={language}
                                            label={supportedLanguages[language]}
                                            checked={this.state.language === language} //{window.language === language}
                                            onChange={() => this.handleLanguageSelect(language)}
                                            onKeyDown={(e) => { if (e.key === 'Enter') this.handleLanguageSelect(language) }}
                                            tabIndex={i}
                                        />
                                    </div>
                                    // <div key={language} className="radio" onClick={() => this.handleLanguageSelect(language)}>
                                    //     <input
                                    //         readOnly
                                    //         checked={window.language === language}
                                    //         value={window.language === language} 
                                    //         id={language}
                                    //         type="checkbox" />

                                    //     <label htmlFor={language}>
                                    //         {supportedLanguages[language]}
                                    //     </label>
                                    // </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		);
	}
}

const RadioButton = ({ label, checked, onChange, onKeyDown, tabIndex }) => {
    return (
        <React.Fragment>
            <input id={label} type='radio' checked={checked} onChange={onChange} onKeyDown={onKeyDown} tabIndex={tabIndex} />
            <label htmlFor={label}>{label}</label>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        
    };
}

export default withRouter(connect(
    mapStateToProps,
    {
        setLanguage,
        closeModal,
        setLoadingResources
	}
)(ModalLanguage));
