/**
 * @ The external dependencies.
 */
import {
    all,
    call,
    takeLatest
} from 'redux-saga/effects';

import { sendSurveyRequest, receiveSurveyResponse } from './actions';
import makeRequest from '../../../lib/helpers/api-request';


export function* sendSurveyRequestWorker(action) {
    yield call(makeRequest, {
        endpoint: 'saveOrderRatings',
        payload: action.payload,
        requestAction: action,
        receiveAction: receiveSurveyResponse
    });
}

/**
 * Start all workers.
 *
 * @return {Generator}
 */
export default function* foreman() {
    yield all([
        takeLatest(sendSurveyRequest, sendSurveyRequestWorker)
    ]);
}
