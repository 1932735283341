/**
 * @ The external dependencies.
 */
import {
    all,
    call,
    takeLatest,
    select
} from 'redux-saga/effects';

import {
    delay
} from 'redux-saga';

import config from 'config';
import { sendFormRequest, receiveFormResponse } from './actions';
import makeRequest from '../../../lib/helpers/api-request';

export function* sendFormRequestWorker(action) {

    let getFailed = false;

    const retries = config.RETRIES;
    const delayBetweenRetries = config.RETRY_DELAY;

    const onError = () => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log('Failed getting Freedom Pay form from Server, Retrying...')
        }
        getFailed = true;
    };

    //retries
    for (let i = 0; i < retries; i++) {

        getFailed = false;

        let cart = (yield select()).cart;

        const payload = {
            totalprice: cart.data.totalCost
        }

        yield call(makeRequest, {
            endpoint: 'getFreedomPayForm',
            payload: payload,
            requestAction: action,
            receiveAction: receiveFormResponse,
            onError: onError
        });

        if (getFailed && i < retries) {
            yield delay(delayBetweenRetries);
        }
        else {
            //exit retry loop
            break;
        }
    }
}

/**
 * Start all workers.
 *
 * @return {Generator}
 */
export default function* foreman() {
    yield all([
        takeLatest(sendFormRequest, sendFormRequestWorker)
    ]);
}
