/**
 * @ The external dependencies.
 */
import { combineReducers } from 'redux';

/**
 * @ The internal dependencies.
 */
import app from './state/app/reducer';
import category from './state/category/reducer';
import cart from './state/cart/reducer';
import checkout from './state/checkout/reducer';
import requests from './state/requests/reducer';
import ui from './state/ui/reducer';
import customer from './state/customer/reducer';
import tablecheck from './state/tablecheck/reducer';
import tab from './state/tab/reducer';
import survey from './state/survey/reducer';
import search from './state/search/reducer';
import freedompay from './state/freedompay/reducer';
import questionnaire from './state/questionnaire/reducer';
import spreedly from './state/spreedly/reducer';
import loadedvalueaccount from './state/loadedvalueaccount/reducer';
import loyalty from './state/loyalty/reducer';
import nets from './state/nets/reducer';
import donation from './state/donation/reducer';
import adyen from './state/adyen/reducer';

export default combineReducers({
	app,
	category,
    cart,
    checkout,
	ui,
    requests,
    customer,
    tablecheck,
    tab,
    survey,
    search,
    freedompay,
    questionnaire,
    spreedly,
    loadedvalueaccount,
    loyalty,
    nets, 
    donation, 
    adyen
});
