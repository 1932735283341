import React from 'react';

import {
    compose,
    sortBy,
    prop
} from 'ramda';

import { MODE_ORDER } from 'lib/constants';
import { localizeNumber } from '../../assets/resources/resources-manager';
import { isLtr } from '../../lib/helpers/language';

const ProductOptions = ({ product, handleCheckboxChange, mode }) => {
	const { options } = product.inventoryMainOptionChoice;

	return (<div className="product-single__section">
		{options.map((option, optionIdx) => (
			<div key={option.optionID} className="product-single__group">
				<h6 className="product-single__title product-single__title--alt">{option.optionGroupName}</h6>

				<div className="checkboxes">
					{sortBy(compose(parseInt, prop('optionOrder')), option.inventoryOptions).map((innerOption, i) => (
						<div key={innerOption.optionID + i} className="checkbox">
							<input
								onChange={handleCheckboxChange({
									prop: 'option',
									collectionName: 'options',
									itemsCollectionName: 'inventoryOptions',
									parentIdx: optionIdx,
									itemIdx: i,
									limit: option.optionSelection
								})}
								checked={product.inventoryMainOptionChoice.options[optionIdx].inventoryOptions[i].selected}
								value={product.inventoryMainOptionChoice.options[optionIdx].inventoryOptions[i].selected}
								id={innerOption.optionID + i}
								type="checkbox"
							/>

							<label aria-labelledby={"span_" + innerOption.optionID + i} className={mode !== MODE_ORDER ? "hide" : ""} htmlFor={innerOption.optionID + i}>
								<span id={"span_" + innerOption.optionID + i}>
									{innerOption.optionDescription}
									{innerOption.optionCost !== '0.00' && (` (${isLtr() ? '+' : ''}${localizeNumber(innerOption.optionCost)})`)}
								</span>
							</label>
						</div>
					))}
				</div>
			</div>
		))}
	</div>
    )
}

export default ProductOptions;