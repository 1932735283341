import { createAction } from 'redux-actions';

export const requestPreOrderQuestionnaireData = createAction('questionnaire/request-pre-order-questionnaire-data');

export const receivePreOrderQuestionnaireData = createAction('questionnaire/receive-pre-order-questionnaire-data');

export const preOrderQuestionnaireComplete = createAction('questionnaire/pre-order-questionnaire-complete');

export const sendPreOrderQuestionnaireAnswer = createAction('questionnaire/send-pre-order-questionnaire-answer');

export const receivePreOrderQuestionnaireResponse = createAction('questionnaire/receive-pre-order-questionnaire-response');

export const sendPreOrderQuestionnaireOrderInfo = createAction('questionnaire/send-pre-order-questionnaire-order-info');

export const receivePreOrderQuestionnaireOrderInfoResponse = createAction('questionnaire/receive-pre-order-questionnaire-order-info-response');

export const clearQuestionnaire = createAction('questionnaire/clear-questtionnaire');

