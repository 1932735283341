import { createAction } from 'redux-actions';

export const sendTableCheckRequest = createAction('tablecheck/send-table-check-request');

export const receiveTableCheckResponse = createAction('tablecheck/receive-table-check-response');

export const clearTableCheck = createAction('tablecheck/clear-table-check');

export const setDineInTakeAwaySelected = createAction('tablecheck/set-dinein-takeaway-selected');

export const setSommelierSelected = createAction('tablecheck/set-sommelier-selected');