import config from 'config';

export const CreateStoreAssetHeaderLink = ({ id, rel, type, as, path }) => {

    const hrefs = window.styleWaypointIDs.map(styleWaypointID => config.REACT_APP_STORE_ASSETS_URL + styleWaypointID + (path.startsWith('/') ? '' : '/') + path);

    let index = hrefs.length - 1;
    const linkElement = document.createElement("link");
    if (id)
        linkElement.id = id;
    if (type)
        linkElement.type = type;
    if (rel)
        linkElement.rel = rel;
    if (as)
        linkElement.as = as;

    linkElement.href = hrefs[index];
    linkElement.onerror = () => {
        if (index - 1 >= 0) {
            index = index - 1;
            linkElement.href = hrefs[index];
        }
    }

    document.head.appendChild(linkElement);
};

export const CreateStoreCSSAssetHeaderLink = ({ id, rel, type, as, path }) => {

    const hrefs = window.styleWaypointIDs.map(styleWaypointID => config.REACT_APP_STORE_CSS_URL + styleWaypointID + (path.startsWith('/') ? '' : '/') + path);

    let index = hrefs.length - 1;
    const linkElement = document.createElement("link");
    if (id)
        linkElement.id = id;
    if (type)
        linkElement.type = type;
    if (rel)
        linkElement.rel = rel;
    if (as)
        linkElement.as = as;

    linkElement.href = hrefs[index];
    linkElement.onerror = () => {
        if (index - 1 >= 0) {
            index = index - 1;
            linkElement.href = hrefs[index];
        }
    }

    document.head.appendChild(linkElement);
};

//export default CreateStoreAssetHeaderLink; CreateStoreCSSAssetHeaderLink;