/**
 * @ The external dependecies.
 */
import React from 'react';

const ComponentLoader = (props) => {
	return (
        <div className="circle-loader" >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
	)
}

export default ComponentLoader;