/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalInput from './modal-input'

/**
 * @ The internal dependencies.
 */
import { closeModal } from 'store/state/ui/actions';
import { startCartUpdate } from 'store/state/cart/actions';
import { sendTableCheckRequest } from 'store/state/tablecheck/actions';
import { updateObject, checkValidity } from '../../lib/utility';
import * as features from '../../store/state/features/selectors'

/**
 * Class for modal table number.
 *
 * @class ModalTableNumber (name)
 */
class ModalTableNumber extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
                form: {
                    tableNumber: {
                        value: '',
                        validation: {
                            required: true
                            //isNumber: true
                        },
                        valid: false,
                        touched: false
                    }
                },
                formIsValid: false
        }
    }

    checkValidityCustom(value, rules) {
        let isValid = checkValidity(value, rules);

        return isValid;
    }

    handleInputChange = (event, field) => {
        const updatedFormElement = updateObject(this.state.form[field], {
            value: event.target.value,
            valid: this.checkValidityCustom(event.target.value, this.state.form[field].validation),
            touched: true
        });

        const updatedForm = updateObject(this.state.form, {
            [field]: updatedFormElement
        });

        let formIsValid = true;
        for (let field in updatedForm) {
            formIsValid = updatedForm[field].valid && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid: formIsValid });
    }

    handleSubmitButton = (e) => {
        e.preventDefault();

        this.props.sendTableCheckRequest({
            showSpinner: true,
            getOpenTickets: this.props.tabFeatureEnabled,
            tableNumber: this.state.form.tableNumber.value
        });

        this.props.closeModal();
    }

    render() {
        const label = (
            <div>
                {window.resources.table_number.label_text}
            </div>
        );

		return (
			<div className="modal-frame modal-frame--card">
				<div className="modal-frame__body">
                    <div className="modal-frame__card">
                        <ModalInput touched={this.state.form.tableNumber.touched} invalid={!this.state.form.tableNumber.valid} label={label} placeholder={window.resources.table_number.placeholder_text} type={this.state.form.tableNumber.value} value={this.state.form.tableNumber.value} onChange={(event) => this.handleInputChange(event, 'tableNumber')} onKeyUp={(event) => this.handleInputChange(event, 'tableNumber')} />
                        {/*<ModalInput touched={this.state.form.tableNumber.touched} invalid={!this.state.form.tableNumber.valid} label={label} placeholder="Table number" value={this.state.form.tableNumber.value} onChange={(event) => this.handleInputChange(event, 'tableNumber')} onKeyUp={(event) => this.handleInputChange(event, 'tableNumber')} /> */}
						<div className="modal-frame__actions modal-frame__actions--flex">
							<button
								onClick={() => this.props.closeModal()}
                                className="btn btn--secondary">{window.resources.table_number.cancel_text}</button>
                            <button
                                disabled={!this.state.formIsValid}
								onClick={this.handleSubmitButton}
                                className="btn">{window.resources.table_number.confirm_text}</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
    return {
        tabFeatureEnabled: features.tabFeatureEnabled(state),
        inputType:  "number"//features.tableTentOverride(state) ? "text" : "number"
    };
}

export default withRouter(connect(
    mapStateToProps,
	{
        closeModal,
        sendTableCheckRequest,
        startCartUpdate
	}
)(ModalTableNumber));
