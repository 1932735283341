/**
 * @ The external dependecies.
 */
import { handleActions } from 'redux-actions';

/**
 * @ The internal dependecies.
 */
import { updateObject } from '../../../lib/utility';

/**
 * @ The actions.
 */
import {
    clearSpreedly,
    receiveFormResponse
} from './actions';

/**
 * @ The reducer.
 */
const defaultState = {
    data: null
}

/**
 * The actions Handler.
 *
 * @type {Function}
 */

const onReceiveFormResponse = (state, action) => {
    if (!action.payload.exception) {
        return updateObject(state, {
            data: action.payload
        });
    }
    else {
        return updateObject(state, {
            data: null
        });
    }
}

const onClearSpreedly = (state, action) => {
    return updateObject(state, {
        ...defaultState
    });
}

const spreedly = handleActions({
    [receiveFormResponse]: (state, action) => onReceiveFormResponse(state, action),
    [clearSpreedly]: (state, action) => onClearSpreedly(state, action)
}, defaultState);

export default spreedly;