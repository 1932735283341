/**
 * @ The external dependencies.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalInput from './modal-input'

/**
 * @ The internal dependencies.
 */
import { closeModal } from 'store/state/ui/actions';
import { sendLoadedValueAccountBalanceRequest } from 'store/state/loadedvalueaccount/actions';
import { updateObject, checkValidity } from '../../lib/utility';

/**
 * Class for modal table number.
 *
 * @class ModalLoadedValueAccountBalanceCheck (name)
 */
class ModalLoadedValueAccountBalanceCheck extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
                form: {
                    accountNumber: {
                        value: '',
                        validation: {
                            required: true
                            //isNumber: true
                        },
                        valid: false,
                        touched: false
                    }
                },
                formIsValid: false
        }
    }

    checkValidityCustom(value, rules) {
        let isValid = checkValidity(value, rules);

        return isValid;
    }

    handleInputChange = (event, field) => {
        const updatedFormElement = updateObject(this.state.form[field], {
            value: event.target.value,
            valid: this.checkValidityCustom(event.target.value, this.state.form[field].validation),
            touched: true
        });

        const updatedForm = updateObject(this.state.form, {
            [field]: updatedFormElement
        });

        let formIsValid = true;
        for (let field in updatedForm) {
            formIsValid = updatedForm[field].valid && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid: formIsValid });
    }

    handleSubmitButton = (e) => {
        e.preventDefault();

        this.props.sendLoadedValueAccountBalanceRequest({
            accountNumber: this.state.form.accountNumber.value,
            showSpinner: true
        });

        this.props.closeModal();
    }

    render() {
        const label = (
            <div>
                {window.resources.loaded_value_account.enter_text}
            </div>
        );

		return (
			<div className="modal-frame modal-frame--card">
				<div className="modal-frame__body">
                    <div className="modal-frame__card">
                        <ModalInput touched={this.state.form.accountNumber.touched} invalid={!this.state.form.accountNumber.valid} label={label} placeholder={window.resources.loaded_value_account.placeholder_text} type="number" value={this.state.form.accountNumber.value} onChange={(event) => this.handleInputChange(event, 'accountNumber')} onKeyUp={(event) => this.handleInputChange(event, 'accountNumber')} />
                        
                        <div className="modal-frame__actions modal-frame__actions--flex">
							<button
								onClick={() => this.props.closeModal()}
                                    className="btn btn--secondary">{window.resources.loaded_value_account.cancel_text}</button>
                            <button
                                disabled={!this.state.formIsValid}
								onClick={this.handleSubmitButton}
                                    className="btn">{window.resources.loaded_value_account.confirm_text}</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = state => {
    return {
    };
}

export default withRouter(connect(
    mapStateToProps,
	{
        closeModal,
        sendLoadedValueAccountBalanceRequest,
	}
)(ModalLoadedValueAccountBalanceCheck));
